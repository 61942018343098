import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Field} from "@app/interfaces";
import {Upload} from "@app/interfaces/entities/upload";
import {Subscription} from "rxjs";
import {FormHelperService} from "@app/util/form-helper.service";

@Component({
  selector: 'app-form-input-connected-dropdown',
  templateUrl: './form-input-connected-dropdown.component.html',
  styleUrls: ['./form-input-connected-dropdown.component.scss']
})
export class FormInputConnectedDropdownComponent implements OnDestroy, OnChanges {

  @Input() control: FormGroup;

  @Input() field: Field<Upload[]>;

  private connectedControlSubscription: Subscription;

  constructor(private ref: ChangeDetectorRef, private formHelperService: FormHelperService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue) {
      this.setupListener(changes.control.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.connectedControlSubscription) {
      this.connectedControlSubscription.unsubscribe();
    }
  }

  keys(input: any) {
    return Object.keys(input);
  }

  private setupListener(control: any) {
    const connectedControl = this.formHelperService.findControl(this.field.config.connectedDropdown, control);

    this.field.config.choices = [];

    if (connectedControl) {
      //  If we already have a value for the connected control, setup the choices
      if (connectedControl.value) {
        this.control.enable();
        this.field.config.choices = [...this.field.config.connectedChoices[this.keys(connectedControl.value)[0]]];

        if (this.field.value) {
          for (const choice of this.field.config.choices) {
            if (this.keys(this.field.value)[0] === this.keys(choice)[0]) {
              //this.field.value = choice;
              this.control.setValue(choice);
              break;
            }
          }
        }
      } else {
        this.control.disable();
        this.control.setValue(null);
      }

      //  Listen for changes and update choices
      this.connectedControlSubscription = connectedControl.valueChanges
        .subscribe((connectedControlValue) => {
          if (!connectedControlValue) {
            this.control.disable();
            this.control.setValue(null);
            return;
          }

          //  We have to check if the existing value is "valid"
          //  If it is, we keep it, otherwise, we delete it
          this.field.config.choices = [...this.field.config.connectedChoices[this.keys(connectedControlValue)[0]]];

          if (this.control.value) {
            const valueInChoices = this.field.config.choices.find(choice => Object.keys(choice)[0] === Object.keys(this.control.value)[0]);

            if (!valueInChoices) {
              this.control.setValue(null);
            }
          } else {
            this.control.enable();
            this.control.setValue(null);
          }
        });
    }

    this.ref.markForCheck();
  }
}
