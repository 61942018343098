<div class="relative">
    <button (click)="myDatepicker.open()"
            class="absolute right-0 z-20 p-4 rounded-full text-gray hover:text-grayDark fadeInFadeOut"
            style="padding: 0.75rem">
        <i class="fas fa-calendar-alt"></i>
    </button>

    <input [matDatepicker]="myDatepicker" [formControl]="control"
           [ngClass]="{'input-error': control.invalid }"
           placeholder="{{field.config.placeholder}}"
           [attr.data-field]="field?.fieldUuid"
           [attr.data-typeId]="field?.config?.typeId"
           [attr.tabIndex]="0"
    >

    <mat-datepicker #myDatepicker></mat-datepicker>
</div>

