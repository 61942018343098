import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@src/environments/environment';
import {PublicAccount, PublicVehicle, PublishedVehicleDetails, PublishedVehicleViews, SingleResult} from '@app/interfaces';
import {VirtualGarageSettings} from "@app/interfaces/entities/advertising/VirtualGarageSettings";
import {AdvertisingForm} from '@app/interfaces/entities/advertising-form';

@Injectable({
  providedIn: 'root'
})
export class VirtualSalonApiService {

  constructor(private http: HttpClient,
              @Inject(LOCALE_ID) private locale: string,
  ) {
  }

  getPublishedVehicle(vehicleUuid: string) {
    return this.http.get<SingleResult<{
      publicAccount: PublicAccount,
      publicVehicle: PublicVehicle,
      publishedVehicleDetails: PublishedVehicleDetails,
      totalViews: number
      viewCounts: PublishedVehicleViews[]
    }>>(`${environment.apiUrl}/api/virtual-garage/published-vehicle/${vehicleUuid}`);
  }

  getPublishedVehicles() {
    return this.http.get<SingleResult<{ publicVehicles: PublicVehicle[], publishedVehiclesDetails: PublishedVehicleDetails[], publicAccount: PublicAccount }
    >>(`${environment.apiUrl}/api/virtual-garage/published-vehicles`);
  }


  getVirtualGarageSettings(accountUuid?: string): Observable<SingleResult<VirtualGarageSettings>> {
    return this.http.post<SingleResult<VirtualGarageSettings>>(`${environment.apiUrl}/api/virtual-garage/settings`, {accountUuid});
  }

  updateVirtualGarageSettings(accountUuid: string, settings: any) {
    return this.http.post<SingleResult<VirtualGarageSettings>>(`${environment.apiUrl}/api/virtual-garage/update-settings`, {...settings, accountUuid});
  }

  getForm(businessUnitUuid?: string): Observable<SingleResult<AdvertisingForm>> {
    let params = new HttpParams();
    if (businessUnitUuid) {
      params = params.append('businessUnitUuid', businessUnitUuid);
    }

    return this.http.get<SingleResult<AdvertisingForm>>(`${environment.apiUrl}/api/virtual-garage/settings-form`, {params});
  }

  saveForm(fields: { [key: string]: any }, businessUnitUuid?: string): Observable<SingleResult<unknown>> {
    return this.http.post<SingleResult<unknown>>(`${environment.apiUrl}/api/virtual-garage/settings-form`, {...fields, businessUnitUuid});
  }
}

