import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Field} from "@app/interfaces";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-form-input-vehicle-model',
  templateUrl: './form-input-vehicle-model.component.html',
  styleUrls: ['./form-input-vehicle-model.component.scss']
})
export class FormInputVehicleModelComponent implements OnChanges {

  @Input() control: AbstractControl;

  @Input() field: Field<string>;

  public initialModelUuid;

  public disabled = false;

  private subscription: Subscription;

  constructor(private ref: ChangeDetectorRef) {

  }

  onVehicleSelect($event: any) {
    if ($event) {
      this.control.setValue($event.vehicleModelUuid);
    } else {
      this.control.setValue(null);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue) {
      this.initialModelUuid = changes.control.currentValue.value;
      this.ref.markForCheck();

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = changes.control.currentValue.statusChanges.subscribe((status: string) => {
        this.disabled = status.toLowerCase() === 'disabled';
        this.ref.markForCheck();
      });
    }
  }
}
