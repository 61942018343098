import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  private subject = new Subject<KeyboardEvent>();

  constructor() {
  }

  emit(event: KeyboardEvent) {
    this.subject.next(event);
  }

  events() {
    return this.subject;
  }
}
