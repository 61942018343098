import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, publishReplay, refCount} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiCachingService {
  private cache: { [key: string]: Observable<any> } = {};
  private cacheTtls: { [key: string]: number } = {};

  constructor(private authService: AuthService) {
    authService.getAccountControlChanges().subscribe(() => this.clearCache());
    authService.getAuthChanges().subscribe(() => this.clearCache());
    authService.getInvalidateConfigChanges().subscribe(() => {
      this.clearCache()
    });
  }

  getCached<T = unknown>(key: string, defaultObservable: Observable<T>): Observable<T> {
    if (!this.cache[key]) {
      this.cache[key] = defaultObservable.pipe(
        catchError((error: HttpErrorResponse) => {
          this.clearCached(key);
          return throwError(error);
        }),
        publishReplay(1),
        refCount()
      );
    }

    return this.cache[key];
  }

  getCachedWithTTL(key: string, ttlSeconds: number = 5, defaultObservable: Observable<any>) {
    if (this.cache[key] && this.cacheTtls[key] < Date.now()) {
      this.clearCached(key);
    }

    this.cacheTtls[key] = Date.now() + (ttlSeconds * 1000);

    return this.getCached(key, defaultObservable);
  }

  clearCached(key: string): void {
    delete this.cache[key];
  }

  clearCache() {
    Object.keys(this.cache).forEach((key) => {
      this.clearCached(key);
    });
  }
}
