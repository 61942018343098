import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@src/environments/environment';
import { tap } from 'rxjs/operators';
import { Field, PaginatedResult, SingleResult } from "@app/interfaces";
import { SuperAdminAccount } from "@app/interfaces/entities/super-admin/super-admin-account";

@Injectable({
  providedIn: 'root'
})
export class FieldApiService {

  //  Used as a memory cache as this seldom changes
  private templateResponse: any = null;

  constructor(private httpClient: HttpClient) {
  }

  getFields(typeIds: string[]): Observable<SingleResult<Field<any>[]>> {
    let params = new HttpParams();

    for (const typeId of typeIds) {
      params = params.append('typeIds[]', typeId.toString());
    }

    return this.httpClient.get<PaginatedResult<Field<any>[]>>(`${environment.apiUrl}/api/fields`, { params });
  }

  getFieldTemplates() {
    if (this.templateResponse) {
      return of(this.templateResponse);
    }

    return this.httpClient.get(`${environment.apiUrl}/api/field-templates`)
      .pipe(tap((data) => this.templateResponse = data));
  }

  updateField(fieldUuid: string, config: any) {
    return this.httpClient.put(`${environment.apiUrl}/api/fields/${fieldUuid}`, { config });
  }
}
