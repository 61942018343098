<div class="card z-50" style="width: 500px;">
  <div class="card-body">
    <div class="card">
      <div class="card-header flex justify-between mb-3">
        <div>
          <span i18n class="font-bold">Obvestila</span>
        </div>
        <div class="spacer" style="min-width: 3em;">
        </div>
        <div>
            <span class="text-primary cursor-pointer" style="font-size: 0.8rem" (click)="readAllNotifications()" i18n>Označi vse kot prebrano</span>
        </div>
      </div>
      <app-notification
        *ngFor="let notification of dropDownNotifications"
        [notification]="notification"
        (onClose)="childCloseEventHandler()">
      </app-notification>

      <ng-container *ngIf="notifications.length < 1"><div class="flex flex-row justify-center text-sm" i18n>Ni obvestil</div></ng-container>

      <div class="flex flex-row justify-center">
        <button class="btn-primary-link btn-sm my-3 text-center" [routerLink]="'/admin/notifications'"><span class="text-sm" i18n>Poglej vsa
          obvestila</span>
        </button>
      </div>
    </div>
  </div>
</div>
