import {Component, Input, OnInit} from '@angular/core';
import {Vehicle} from '@app/interfaces';
import {ImportedVehicle} from "@app/interfaces/integration/imported-vehicle";

@Component({
  selector: 'app-vehicle-identification-table-cell',
  templateUrl: './vehicle-identification-table-cell.component.html',
  styleUrls: ['./vehicle-identification-table-cell.component.scss']
})
export class VehicleIdentificationTableCellComponent implements OnInit {

  @Input() directionRow = false;

  public vinNumber?: string;
  public internalId: string;
  public registrationNumber: string;

  constructor() {
  }

  @Input() set vehicle(vehicle: Vehicle | ImportedVehicle) {
    if (vehicle && "sourceVehicleId" in vehicle) {
      this.vinNumber = vehicle.vinNumber;
      this.internalId = vehicle.internalId;
      this.registrationNumber = vehicle.registrationNumber;
    } else {
      this.vinNumber = vehicle.vinNumber;
      this.registrationNumber = vehicle.registrationNumber;
    }
  }

  ngOnInit(): void {
  }

}
