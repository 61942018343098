import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Observable, Subject} from 'rxjs';
import {BusinessUnitsService} from "@app/services/accounts/business-units.service";
import {map, shareReplay, take, takeUntil} from "rxjs/operators";
import {BusinessUnit} from "@app/interfaces/entities/accounts/BusinessUnit";

@Component({
    selector: 'app-form-input-business-unit',
    templateUrl: './form-input-business-unit.component.html',
    styleUrls: ['./form-input-business-unit.component.scss']
})
export class FormInputBusinessUnitComponent implements OnInit, OnDestroy {

    @Input() control: AbstractControl;

    field: Field<number>;

    public dropdownOptions$: Observable<BusinessUnit[]>;
    private destroy$ = new Subject<void>();

    constructor(private businessUnitsService: BusinessUnitsService) {
    }

    ngOnInit(): void {
        this.control.disable();

        const units$ = this.businessUnitsService.getSelectedBusinessUnit().pipe(takeUntil(this.destroy$), shareReplay(1));

        this.dropdownOptions$ = units$.pipe(
            map(([selected, allUnits]) => {
                // Limit options to only the selected business unit and active units
                return allUnits.map(unit => ({...unit, isActive: unit.isActive && (!selected?.uuid || unit.uuid === selected?.uuid)}))
            })
        );

        this.dropdownOptions$.subscribe((options) => {
            this.control.enable();
        });

        units$.subscribe((([selectedUnit]) => {
            if (this.field.value === null && !this.control.value) {
                this.control.setValue(selectedUnit?.uuid || '');
            } else {
                this.control.setValue(this.field.value);
            }
        }))
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @Input('field')
    set setField(field: Field<number>) {
        this.field = field;

        if (field.value && this.control?.value) {
            this.dropdownOptions$.pipe(take(1)).subscribe((options) => {
                const selected = options.find((option) => option.uuid === this.control.value);

                if (selected) {
                    this.control.setValue(selected.uuid);
                }
            });
        }
    }
}
