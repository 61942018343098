import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {LeadGenerationService} from '@app/services/lead-generation.service';
import {NotificationService} from '@app/services/notification.service';
import {RequireHelpDialogComponent} from '@app/dialogs/require-help-dialog/require-help-dialog.component';
import {LeadGenerationEnum} from "@app/enums/LeadGenerationEnum";

@Component({
  selector: 'app-help-contact-us',
  templateUrl: './help-contact-us.component.html',
  styleUrls: ['./help-contact-us.component.scss']
})
export class HelpContactUsComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isSuccess = false;

  private timeouter;
  private timeoutInMs = 4000;

  private extra = 'Zahteva po pomoči';

  constructor(private leadService: LeadGenerationService, private notificationService: NotificationService, private matDialog: MatDialog) {
  }

  ngOnDestroy() {
    this.clearTimeouter();
  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = RequireHelpDialogComponent.open(this.matDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.generateLead(this.extra + ': ' + result);
      }
    })
  }

  generateLead(lead: string) {
    this.isLoading = true;

    this.leadService.generateLead({extra: {zahteva: lead, type: LeadGenerationEnum.HELP}})
      .subscribe(() => {
        this.isLoading = false;
        this.isSuccess = true;
        this.clearTimeouter();
        this.setTimeouter();
      }, (error) => {
        this.isLoading = false;
        this.isSuccess = false;
        this.notificationService.showErrorAlert();
      });
  }

  private clearTimeouter() {
    if (this.timeouter) {
      clearTimeout(this.timeouter);
      this.timeouter = undefined;
    }
  }

  private setTimeouter() {
    this.clearTimeouter();

    this.timeouter = setTimeout(() => {
      this.isLoading = false;
      this.isSuccess = false;
      this.clearTimeouter();
    }, this.timeoutInMs);
  }
}
