<div class="flex flex-row items-center justify-between">
  <div class="flex flex-row items-center group">
    <div class="mr-2">
      <i class="fa"
         [ngClass]="{'group-hover:text-primary text-greenDarkOlive fadeInFadeOut':!(disableLink || client.isDeleted),
         'fa-user-circle': client.isPerson,
           'fa-building': !client.isPerson}"></i>
    </div>

    <a *ngIf="!(disableLink || client.isDeleted)"
       [routerLink]="['/admin/clients', client.clientUuid, {outlets: {tab: 'data/client/'+ client.clientUuid}}]"
       class="hover:no-underline focus:no-underline">
      <div class="text-greenDarkOlive text-sm font-semibold leading-normal group-hover:text-primary fadeInFadeOut">{{ getName(client) }}</div>
    </a>

    <ng-container *ngIf="(disableLink || client.isDeleted)">
      <div class="text-grayDarker text-sm font-semibold leading-normal">{{ getName(client) }}</div>
    </ng-container>
  </div>
  <div *ngIf="client.alarmNotifications?.length > 0" class="card bg-orange border-0 flex items-center justify-center"
       style="width:32px; height:32px;">
    <span matTooltipClass="bg-black px-4 py-2 text-white text-sm" [matTooltip]="getIconTitle()" class=""><i
            class="fas fa-bell text-white"></i></span>
  </div>
</div>
