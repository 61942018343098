import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {map} from 'rxjs/operators';
import {AccountConfig} from '@app/interfaces/config/account.config';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  constructor(private configApi: ConfigAPIService) {
  }

  getFeatureFlags(accountUuid: string = null): Observable<AccountConfig['accountFeatureFlags']> {
    return this.configApi.getAccountConfig(accountUuid).pipe(map((accountConfig) => accountConfig.accountFeatureFlags));
  }
}
