import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translations = {
    maintenance: $localize`:@@title.maintenance:Vzdrževanje`,
    notifications: $localize`:@@title.notifications:Obvestila`,
    login: $localize`:@@title.login:Prijava`,
    register: $localize`:@@title.register:Registracija`,
    tryDemo: $localize`:@@title.try-demo:Preizkusi demo`,
    demoCode: $localize`:@@title.demo-code:Prijava z demo kodo`,
    forgotPassword: $localize`:@@title.forgot-password:Pozabljeno geslo`,
    resetPassword: $localize`:@@title.reset-password:Ponastavi geslo`,
    logout: $localize`:@@title.logout:Odjava`,
    pageNotFound: $localize`:@@title.page-not-found:Stran ne obstaja`,
    vehicleListing: $localize`:@@title.vehicle-listing:Vozilo`,
    vehicleListingList: $localize`:@@title.vehicle-listing:Vozila`,
    vehicleListingsForDealer: $localize`:@@title.vehicle-listings-for-dealer:Vsa vozila`,
    dashboard: $localize`:@@title.dashboard:Nadzorna plošča`,
    businessOverview: $localize`:@@title.businessOverview:Pregled poslovanja`,
    entityWorkflowForm: $localize`:@@title.entity-workflow-form:Voden vnos podatkov`,
    entityWorkflowResult: $localize`:@@title.entity-workflow-result:Pregled vodenega vnosa podatkov`,
    virtualSalonSettings: $localize`:@@title.virtual-salon-settings:Virtualni salon`,
    administrationSettings: $localize`:@@title.administration-settings:Administracija`,
    providers: $localize`:@@title.financing-providers:Ponudniki financiranj`,
    financingList: $localize`:@@title.financing-list:Financiranja`,
    financingListCompleted: $localize`:@@title.financing-completed:Zaključena financiranja`,
    financingListPending: $localize`:@@title.financing-pending:Financiranja v obdelavi`,
    providersLeanpay: $localize`:@@title.financing-providers.leanpay:Leanpay financiranje`,
    testPlatesSettings: $localize`:@@title.test-plates-settings:Preizkusne tablice`,
    adIntegrationsSettings: $localize`:@@ttitle.integrations-settings:Integracije`,
    packagesPage: $localize`:@@title.packagesPage:Produktni Paketi`,
    rolesPage: $localize`:@@title.packagesPage:Vloge`,
    addikoOffer: $localize`:@@title.addikoOffer:Addiko Ponudba`,
    addikoForm: $localize`:@@title.addikoForm:Addiko Vloga`,
    addikoCalculation: $localize`:@@title.addikoCalculation:Addiko Izračun`,

    vehicles: $localize`:@@title.vehicles:Vozila`,
    vehiclesAll: $localize`:@@title.vehicles:Vsa Vozila`,
    vehiclesArchived: $localize`:@@title.vehicles:Arhivirana Vozila`,
    vehiclesAd: $localize`:@@title.vehicles:Oglaševana vozila`,
    vehicle: $localize`:@@title.vehicle:Vozilo`,
    addVehicle: $localize`:@@title.add-vehicle:Dodajanje vozila`,
    editVehicle: $localize`:@@title.edit-vehicle:Urejanje vozila`,
    vehiclesImported: $localize`:@@title.vehicles-imported:Uvoz vozila`,
    printVehicle: $localize`:@@title.print-vehicle:Tiskanje podatkov`,
    processFormVehicle: $localize`:@@title.process-form-vehicle:Procesni način`,
    vehicleWorkflows: $localize`:@@title.vehicle-workflows:Vodeni vnosi podatkov`,
    vehicleMarketStudies: $localize`:@@title.vehicle-market-studies:Analiza trga`,
    vehicleAds: $localize`:@@title.vehicle-ads:Oglaševanje`,
    vehicleFiles: $localize`:@@title.vehicle-files:Datoteke`,
    vehicleDocuments: $localize`:@@title.vehicle-documents:Dokumenti`,
    clientDocuments: $localize`:@@title.client-documents:Dokumenti`,
    vehicleWarranty: $localize`:@@title.vehicle-warranty:Jamstva`,
    vehicleExpenditures: $localize`:@@title.vehicle-expenditures:Stroški`,
    editIntegrationVehicleAd: $localize`:@@title.vehicle-ad-form:Obrazec za oglas vozila`,

    leasings: $localize`:@@title.vehicle-financing:Financiranja`,
    leasingForm: $localize`:@@title.vehicle-leasing-form:Obrazec za izdajo leasinga`,
    leasingSuccess: $localize`:@@title.vehicle-leasing-success:Uspešno oddano financiranje`,

    warranties: $localize`:@@title.warranties:Jamstva`,

    documents: $localize`:@@title.documents:Izdani dokument`,
    availableDocuments: $localize`:@@title.documents:Izdaj dokument`,
    documentCreationSuccess: $localize`:@@title.document-creation-success:Dokument uspešno ustvarjen`,

    marketStudy: $localize`:@@title.market-study:Valuacija`,

    clients: $localize`:@@title.clients:Imenik`,
    entityRelationships: $localize`:@@title.entity-relationships:Povezave`,

    partners: $localize`:@@title.partners:Partnerji`,
    transporters: $localize`:@@title.transporters:Prevozniki`,
    suppliers: $localize`:@@title.suppliers:Dobavitelji`,
    customers: $localize`:@@title.customers:Stranke`,
    'authorized-services': $localize`:@@title.authorized-services:Pooblaščeni servisi`,

    client: $localize`:@@title.client:Imenik`,
    addClient: $localize`:@@title.add-client:Dodajanje kontakta`,
    editClient: $localize`:@@title.edit-client:Urejanje kontakta`,
    clientWorkflows: $localize`:@@title.client-workflows:Vodeni vnosi podatkov`,
    clientConsents: $localize`:@@title.client-consents:Soglasja`,
    clientAlarms: $localize`:@@title.client-alarms:Opomniki`,
    clientFiles: $localize`:@@title.client-files:Datoteke`,
    clientInvoices: $localize`:@@title.client-invoices-archive:Arhiv računov`,
    clientLeasings: $localize`:@@title.client-financing-archive:Arhiv financiranj`,

    userProfile: $localize`:@@title.user-profile:Profil uporabnika`,
    accountProfile: $localize`:@@title.account-profile:Profil podjetja`,
    manageUsers: $localize`:@@title.manage-users:Uporabniki`,

    administration: $localize`:@@title.administration:Administracija`,

    nonimported: $localize`:@@title.imported.nonimported:Prenešeno`,
    imported: $localize`:@@title.imported.imported:Na zalogi`,

    total: $localize`:@@title.total:Skupaj`,
    assets: $localize`:@@title.assets:Blago & Storitve`,

    excelImport: $localize`:@@title.excel:Excel`,
    browserExtension: $localize`:@@title.browser-extension:Vtičnik v brskalniku`,

    successfulExtensionLogin: $localize`:@@title.successfull-extension-login:Uspešna prijava v vtičnik`,
  };

  getTranslation(prop: string) {
    if (!this.translations[prop]) {
      console.warn(`Translation for ${prop} is not defined in translation service.`);
    }

    return this.translations[prop] ?? prop;
  }
}
