import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsApiService {

  constructor(private http: HttpClient) {
  }

  vehicleAdDescription(vehicleUuid: string, existingContent?: string) {
    return this.http.post<{ content: string }>(`${environment.apiUrl}/api/suggestions/vehicle/${vehicleUuid}/ad-description`, {existingContent});
  }

  vehicleGeneralDescription(vehicleUuid: string, existingContent?: string) {
    return this.http.post<{ content: string }>(`${environment.apiUrl}/api/suggestions/vehicle/${vehicleUuid}/general-description`, {existingContent});
  }
}
