<div class="mx-auto"   [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId">
  <signature-pad [options]="signaturePadOptions"></signature-pad>
  <div class="relative w-1/3 bg-grayDark mx-auto pointer-events-none border-b-2 border-grayDark" style="top: -1rem"></div>
</div>
<div class="mt-4 flex flex-row justify-end">
  <button class="btn btn-primary-outline btn-sm mr-auto" (click)="openSignatureComponent()" *ngIf="!control.value"><span i18n>Digitalni podpis</span></button>

  <button class="btn btn-primary-outline btn-sm" (click)="clear()"><span i18n>Počisti</span></button>
  <button class="btn btn-primary btn-sm ml-4" (click)="submit()" [disabled]="control.value" *ngIf="!control.value"><span i18n>Potrdi podpis</span></button>
</div>
