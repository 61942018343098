<div [hidden]="isHidden" [ngClass]="{'py-3': sectionItem.type !== 'group'}">
  <div *ngIf="sectionItem.title && sectionItem.type !== 'group' && !hideHeader" class="h-20">
    <div class="bg-greenDarkOlive py-4 px-8 absolute ribbon" style="width: calc(100% + 1rem); left: -0.5rem">
      <h4 class="text-white text-sm font-bold">
        {{ sectionItem.title }}
        <app-tooltip [content]="sectionItem.description"></app-tooltip>
      </h4>
    </div>
  </div>

  <div [ngClass]="{'grid-group md:px-10': sectionItem.type === 'group'}">
    <ng-container *ngFor="let item of sectionItem.items; let i = index">

      <ng-container [ngSwitch]="item.type">

        <ng-container *ngSwitchCase="'field'">
          <ng-container>
            <div
                    *ngIf="form.fields[asFieldItem(item).fieldUuid].config.isVisible"
                    [ngClass]="{'mb-3': i < sectionItem.items.length - 1, 'flex-1': sectionItem.type === 'group', 'mx-0 md:mx-10': sectionItem.type !== 'group', 'flex flex-col-reverse': form.fields[asFieldItem(item).fieldUuid].type==='boolean'}">
              <app-form-input [formGroup]="formGroup" [field]="form.fields[asFieldItem(item).fieldUuid]"
                              [half]="form.fields[asFieldItem(item).fieldUuid].type !== 'boolean'"
                              *ngIf="form.fields[asFieldItem(item).fieldUuid].type !== 'currency'; else currencyDiv"
                              (keydown.enter)="focusNextField($event)">
              </app-form-input>
              <ng-template #currencyDiv>
                <app-inline-field-edit [cancelEditOnSave]="true" textSize="sm"
                                       [updateField]="false"
                                       [formGroup]="formGroup"
                                       [field]="form.fields[asFieldItem(item).fieldUuid]"
                                       (update)="fieldsUpdated($event)"
                                       (keydown.enter)="focusNextField($event)"></app-inline-field-edit>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div [ngClass]="{'mb-3': asSectionItem(item).items.length % 2 == 1}">

            <app-form-layout [sectionItem]="asSectionItem(item)"
                             [formGroup]="formGroup"
                             [hidden]="isHidden"
                             [hideHeader]="false"
                             [form]="form"></app-form-layout>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
