<ng-container *ngIf="vehicle">
  <div>
    <h1 class="mb-2 text-greenDarkOlive font-bold text-2xl leading-normal tracking-wide">{{ vehicleName }}</h1>

    <p class="mb-2 text-grayDark leading-normal" *ngIf="vehicleVersion">{{ vehicleVersion }}</p>

    <p class="text-greenDarkOlive font-bold text-3xl leading-tight tracking-wide" *ngIf="vehicle.publishDetails.price">{{ vehicle.publishDetails.price | currency:"EUR":'symbol':'1.0-0' }}</p>
    <p class="text-greenDarkOlive font-bold text-xl leading-tight mt-1" *ngIf="!vehicle.publishDetails.price"><span i18n="Vehicle price widget">Pokličite za ceno!</span></p>
  </div>
</ng-container>
