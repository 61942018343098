<div class="w-full h-screen flex justify-center items-center" style="background: #F5F5F5; margin-top: 55px; padding-bottom: 55px;">

  <div class="flex flex-col md:flex-row justify-center items-center w-full" style="max-width: 900px">
    <div class="grow p-4" style="flex-basis: 100px">
      <img src="/assets/images/404.png" alt="404 error">
    </div>
    <div class="grow p-4" style="flex-basis: 100px">

      <h1 class="text-2xl font-bold" data-test='page-not-found-text'><span i18n>Ups, ta stran ne obstaja!</span></h1>

      <p><span i18n>Stran, ki ste jo iskali, ne obstaja.</span></p>

      <div class="mt-8">
        <button class="btn btn-primary mx-auto" [routerLink]="['/admin/vehicles']">Nazaj na vsa vozila</button>
      </div>
    </div>

  </div>
</div>
