import {Component, Input, OnDestroy} from '@angular/core';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {collect} from "collect.js";
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnDestroy {

  public lastNotification?: InAppNotification;
  private notifications: InAppNotification[];

  private destroy$ = new Subject<void>();

  private ignoreIds = [];

  constructor() {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @Input('notifications$')
  set setNotifications(notifications$: Subject<InAppNotification[]>) {
    notifications$.pipe(takeUntil(this.destroy$))
      .subscribe((notifications) => {
        this.notifications = notifications.filter((notification) => !this.ignoreIds.includes(notification.id));
        this.updateLastNotification();
      });
  }

  private updateLastNotification() {
    this.lastNotification = collect(this.notifications).sortBy('updated_at').first();
  }

  removePopup(id: string) {
    this.ignoreIds.push(id);
    this.notifications = this.notifications.filter((notification, index) => notification.id !== id);

    this.updateLastNotification();
  }
}
