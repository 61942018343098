import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {Field, PaginatedResult, SingleResult, WorkflowExcerpt} from '@app/interfaces';
import {Client, ClientData} from '@app/interfaces/entities/client';
import {Form} from '@app/interfaces/entities/form';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {tap} from 'rxjs/operators';
import {ApiHelperService} from '@app/util/api-helper.service';
import {PaginationFilter} from '@app/interfaces/pagination/paginationFilter';
import {ClientConsentResponse} from '@app/interfaces/entities/clients/client-consent-response';
import {ClientConsentData} from '@app/interfaces/clients/client-consent-data';

@Injectable({
  providedIn: 'root'
})
export class ClientApiService {

  private clientRefreshStream: Subject<Client> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
  }

  getClientRefreshStream() {
    return this.clientRefreshStream;
  }

  getPaginatedClients(paginationFilter: PaginationFilter): Observable<PaginatedResult<Client[]>> {
    let params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<Client[]>>(`${environment.apiUrl}/api/clients`, {params});
  }

  getClient(clientUuid: string): Observable<SingleResult<Client>> {
    return this.http.get<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${clientUuid}`);
  }

  getClientInvoices(clientUuid: string, sort: { column: string, order: string }, filter: {}): Observable<PaginatedResult<any>> {
    let params = new HttpParams();

    if (sort.column) {
      params = params.append('sortBy', sort.column.toString());

      if (sort.order) {
        params = params.append('sortOrder', sort.order.toString());
      }
    }

    const filters = [];

    for (const prop in filter) {
      if (filter.hasOwnProperty(prop)) {
        if (filter[prop]) {
          filters.push({key: prop, value: filter[prop]});
        }
      }
    }

    if (filters.length) {
      filters.forEach((item) => {
        params = params.append('filter[]', `${item.key}:${item.value}`);
      });
    }

    return this.http.get<PaginatedResult<any>>(`${environment.apiUrl}/api/clients/${clientUuid}/invoices`, {params});
  }

  patchField(clientUuid: string, fieldUuid: string, value: any): Observable<SingleResult<Client>> {
    return this.http.patch<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${clientUuid}/fields`, {
      fields: [{
        fieldUuid,
        value
      }]
    }).pipe(tap((result) => {
      this.clientRefreshStream.next(result.data);
    }));
  }

  patchFields(clientUuid: string, fields): Observable<SingleResult<Client>> {
    return this.http.patch<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${clientUuid}/fields`, {
      fields
    }).pipe(tap((result) => {
      this.clientRefreshStream.next(result.data);
    }));
  }

  getForm(clientUuid: string, clientType: string): Observable<SingleResult<Form>> {
    if (clientUuid) {
      return this.getFieldsForClients(clientUuid);
    }

    let params = new HttpParams();
    params = params.append('type', 'client');
    params = params.append('formType', clientType);

    return this.http.get<SingleResult<Form>>(`${environment.apiUrl}/api/forms`, {params});
  }

  store(fields: { [key: string]: any }, clientType: string, clientGroup?: string): Observable<SingleResult<Client>> {
    return this.http.post<SingleResult<Client>>(`${environment.apiUrl}/api/clients`, {...fields}, {params: {clientType, clientGroup}});
  }

  update(entityUuid: string, fields: { [key: string]: any }): Observable<SingleResult<Client>> {
    return this.http.put<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${entityUuid}`, {...fields});
  }

  delete(clientUuid: string) {
    return this.http.delete(`${environment.apiUrl}/api/clients/${clientUuid}`);
  }

  getField(clientUuid: string, fieldUuid: string): Observable<SingleResult<Field<any>>> {
    return this.http.get<SingleResult<Field<any>>>(`${environment.apiUrl}/api/clients/${clientUuid}/fields/${fieldUuid}`);
  }

  getFieldsForClients(entityUuid: string): Observable<SingleResult<Form>> {
    return this.http.get<SingleResult<Form>>(`${environment.apiUrl}/api/clients/${entityUuid}/form`);
  }

  updateStatus(clientUuid: string, statusMachineUuid: string, statusUuid: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/clients/${clientUuid}/status`, {statusMachineUuid, statusUuid});
  }

  updateEntityCategory(clientUuid: string, entityCategoryUuid: string): Observable<SingleResult<Client>> {
    return this.http.put<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${clientUuid}/entity-category`, {
      entityCategoryUuid
    });
  }

  updateEntityGroups(clientUuid: string, entityGroups: string[]): Observable<SingleResult<Client>> {
    return this.http.put<SingleResult<Client>>(`${environment.apiUrl}/api/clients/${clientUuid}/entity-groups`, {
      entityGroups
    }).pipe(tap((result) => {
      this.clientRefreshStream.next(result.data);
    }));
  }

  getClientWorkflows(entityUuid: string): Observable<ArrayResult<WorkflowExcerpt>> {
    return this.http.get<ArrayResult<WorkflowExcerpt>>(`${environment.apiUrl}/api/clients/${entityUuid}/workflows`);
  }

  getMappedClients(clientUuids: string[]) {
    let params = new HttpParams();

    for (const clientUuid of clientUuids) {
      params = params.append('clientUuids[]', clientUuid);
    }

    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/helpers/clients/map`, {params});
  }

  getClientData(entityUuid: string): Observable<SingleResult<ClientData>> {
    return this.http.get<SingleResult<ClientData>>(`${environment.apiUrl}/api/clients/${entityUuid}/data`);
  }

  getClientConsents(entityUuid: string): Observable<SingleResult<ClientConsentResponse>> {
    return this.http.get<SingleResult<ClientConsentResponse>>(`${environment.apiUrl}/api/clients/${entityUuid}/consents`);
  }

  updateClientConsents(clientConsentData: ClientConsentData) {
    return this.http.post<SingleResult<{ success: boolean }>>(`${environment.apiUrl}/api/clients/${clientConsentData.clientUuid}/consents`, {...clientConsentData});
  }
}
