import {Component, Input, OnInit} from '@angular/core';
import {ShimmerComponent} from '../shimmer/shimmer.component';

@Component({
  selector: 'app-form-shimmer',
  templateUrl: './form-shimmer.component.html',
  styleUrls: ['./form-shimmer.component.scss']
})
export class FormShimmerComponent extends ShimmerComponent {

  @Input() height: string;

  constructor() {
    super();
  }
}
