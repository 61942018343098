import { Component, Inject, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-require-help-dialog',
  templateUrl: './require-help-dialog.component.html',
  styleUrls: ['./require-help-dialog.component.scss']
})
export class RequireHelpDialogComponent implements OnInit {

  public form: FormGroup = this.formBuilder.group({help: this.formBuilder.control("", [Validators.required])})

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<unknown>) {
  }

  static open(dialog: MatDialog) {
    return dialog.open(RequireHelpDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
      }
    });
  }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close(this.form.controls.help.value);
  }

  close() {
    this.dialogRef.close(false);
  }
}
