import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CompanySearchResult} from '@app/interfaces/responses/company-search-result';
import {ArrayResult} from "@app/interfaces/responses/array-result";

@Injectable({
  providedIn: 'root'
})
export class CompaniesSearchApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  getSearchCompanyByText(searchText, locale = null): Observable<ArrayResult<CompanySearchResult>> {
    const body: { [key: string]: string } = {
      q: searchText
    };
    if (locale) {
      body.locale = locale;
    }
    return this.http.post<ArrayResult<CompanySearchResult>>(`${environment.apiUrl}/api/search/company`, body);
  }
}
