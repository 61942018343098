import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {PaginationFilter} from "@app/interfaces/pagination/paginationFilter";

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {
  static buildPaginationParams(paginationFilter: PaginationFilter): HttpParams {
    let params = new HttpParams();
    if (paginationFilter.sort) {
      params = params.append('sortBy', paginationFilter.sort.toString());

      if (paginationFilter.order) {
        params = params.append('sortOrder', paginationFilter.order.toString());
      }
    }
    params = params.append('page', paginationFilter.page || 1);
    params = params.append('perPage', paginationFilter.perPage.toString());

    if (paginationFilter.archived !== undefined) {
      params = params.append('archived', !!paginationFilter.archived);
    }

    for (const prop in paginationFilter.filter) {
      if (Array.isArray(paginationFilter.filter[prop])) {
        paginationFilter.filter[prop].forEach((item) => {
          params = params.append(prop + '[]', item);
        });
      } else if (paginationFilter.filter[prop]) {
        params = params.append(prop, paginationFilter.filter[prop]);
      }
    }

    return params;
  }
}
