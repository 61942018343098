import {NgModule} from "@angular/core";
import {CdkVirtualScrollViewportPatchDirective} from "./cdk-virtual-scroll-viewport-patch.directive";

@NgModule({
  declarations: [
    CdkVirtualScrollViewportPatchDirective

  ],
  exports: [
    CdkVirtualScrollViewportPatchDirective
  ]
})
export class DirectivesModule {
}
