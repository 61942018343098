<div>
    <div class="relative md:mr-3">
        <input type="text" name="search" id="search" [formControl]="control"
               style="padding-left: 2.25rem; padding-right: 2.25rem"
               placeholder="Išči ..."
               autocomplete="off"
               i18n-placeholder
               [attr.tabIndex]="0"
        >

        <button class="text-grayDark hover:text-red absolute top-0 right-0 mt-1 mr-1 p-2" *ngIf="control.value"
                type="button" (click)="clearSearchAndHideDropdown()" i18n-title="Pobriši" title="Pobriši">
            <i class="fas fa-times"></i>
        </button>

        <div class="absolute text-grayDark pointer-events-none left-0 top-0 ml-3 mt-3">
            <i class="fas fa-search"></i>
        </div>
    </div>
    <ng-container *ngIf="searchResults && showDropdown && !isLoading">
        <div class="mt-11" *ngIf="(showDropdown && !isLoading && searchResults.length > 0); else noResultFoundMessage"
             style="z-index: 200; position: absolute;">
            <div class="global-searchbar-dropdown card p-3 pt-2 bg-white shadow-2xl" style="z-index: 201">
                <div *ngFor="let searchResult of searchResults; let i = index"
                     (click)="selectResult($event, searchResult)"
                     class="hover:bg-grayHover cursor-pointer"
                     [ngStyle]="{'border-bottom' : i+1 < searchResults.length ? '1px solid darkgray' : '0'}">
                    <div class="py-2 px-3">
                        {{searchResult['name']}}
                    </div>
                    <div class="px-3 pt-0 text-gray flex">
                        {{searchResult['address_full']}}
                    </div>
                    <div class="pb-2 px-3 pt-0 text-gray flex">
                        <div class="mr-2">
                            <span i18n>DŠ: </span>{{searchResult['tax_number']}}
                        </div>
                        <div>
                            <span i18n>MŠ: </span>{{searchResult['registration_number']}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noResultFoundMessage>
            <div class="global-searchbar-dropdown card p-3 pt-2 bg-white shadow-2xl absolute" style="z-index: 201">
                <span class="text-sm text-grayDarker" i18n>Ni rezultatov</span>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="isLoading">
        <div class="absolute mt-11 w-1/4" *ngIf="showDropdown" style="z-index: 201">
            <div class="card p-3 bg-white mr-2 shadow-2xl">
                <div class="grid grid-cols-1 gap-x-2">
                    <ng-container>
                        <app-content-shimmer height="2rem"></app-content-shimmer>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
