<div class="card bg-white relative border-none">
  <div class="card-header p-5 sm:p-5 flex">
    <div class="w-full flex items-center justify-center">
      <p class="flex mb-0 pb-0 pr-0 text-greenDarkOlive text-lg sm:text-xl font-bold text-center"><span i18n>Odprta ponudba za izbiro Paketa</span>
      </p>
    </div>
  </div>
  <div class="card-content">
    <p>
      <span i18n>S klikom na gumb boste preusmerjeni na stran s ponudbo za izbiro paketa. Tam boste lahko izbrali tudi avtomatski sistem za plačilo s kartico. V kolikor ne uporabljate službene plačilne kartice, lahko plačate tudi preko SEPA.</span>
    </p>
    <br>
    <p>
      <span class="font-bold" i18n>Za nadaljnjo uporabo AutoBrief platforme morate potrditi ponudbo.</span>
    </p>
    <br>
    <div class="flex flex-row justify-center mt-5 gap-2">
      <button [disabled]="isLoading"  class="btn btn-primary" (click)="refresh()"><span
              i18n>Zapri</span></button>
      <button [disabled]="isLoading" *ngIf="accountProductPackage.paymentUrl && !accountProductPackage.isPaid && !accountProductPackage.isRejected && !accountProductPackage.isSigned" class="btn btn-primary" (click)="recheckStateAndRefresh()"><span
              i18n>Ponudbo smo sprejeli/zavrnili</span></button>
      <button class="btn btn-primary" *ngIf="accountProductPackage.paymentUrl && !accountProductPackage.isPaid && !accountProductPackage.isRejected && !accountProductPackage.isSigned" [disabled]="isLoading"  (click)="openPaymentUrlInTab()"><span
              i18n>Na ponudbo</span></button>
    </div>
  </div>

</div>
