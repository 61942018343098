import {Component, Inject} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {map, takeUntil} from 'rxjs/operators';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {ProductPackageSubscriptionTypeEnum} from '@app/enums/ProductPackageSubscriptionTypeEnum';
import {NotificationService} from '@app/services/notification.service';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {ProformaProductPackagePaymentDialogComponent} from '@app/dialogs/proforma-product-package-payment-dialog/proforma-product-package-payment-dialog.component';

@Component({
  selector: 'app-upgrade-product-package-dialog',
  templateUrl: './upgrade-product-package-dialog.component.html',
  styleUrls: ['./upgrade-product-package-dialog.component.scss']
})
export class UpgradeProductPackageDialogComponent {

  public isLoading = true;

  public productPackage: ProductPackage;
  public activePackage: AccountProductPackage;

  public productType: string;

  public readonly productPackageSubscriptionTypeEnum = ProductPackageSubscriptionTypeEnum;

  private destroy$ = new Subject<void>();
  public disableButton: boolean;
  accountConfig$: Observable<AccountConfig>;
  nextYearlyDate: string;
  private accountConfig: AccountConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<any>,
    private matDialog: MatDialog,
    private productPackagesService: ProductPackagesService,
    private configApi: ConfigAPIService,
    private notificationService: NotificationService,
  ) {
    this.productPackage = data.productPackage;
    this.productType = data.productType;
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.accountConfig$ = this.configApi.getAccountConfig();
    this.accountConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.accountConfig = data;
        this.activePackage = data.activeAccountProductPackage;
        this.updateYearToNextOccurrence();
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changeProductPackage() {
    this.disableButton = true;
    this.productPackagesService.changeProductPackage(this.productPackage.uuid, this.productType)
      .pipe(takeUntil(this.destroy$), map((response) => response.data))
      .subscribe((accountProductPackage) => {
        this.activePackage = accountProductPackage;
        this.updateYearToNextOccurrence();
        this.notificationService.showApplicationSuccessfulAlert();
        this.close(this.productPackage.uuid);

        ProformaProductPackagePaymentDialogComponent.open(this.matDialog, this.productPackage, this.productType, this.accountConfig, accountProductPackage);
      });
  }

  close(uuid?: string) {
    this.dialogRef.close(uuid);
  }

  static open(dialog: MatDialog, productPackage, productType) {
    return dialog.open(UpgradeProductPackageDialogComponent, {
      width: '400px',
            disableClose: false,
      data: {
        productPackage,
        productType
      }
    });
  }

  updateYearToNextOccurrence() {
    const fromDate = new Date(this.activePackage.billingDate);
    const fromMonth = fromDate.getMonth();
    const fromDay = fromDate.getDate();

    const today = new Date();

// Calculate the next occurrence year
    let nextYear = today.getFullYear();
    if (
      fromMonth < today.getMonth() ||
      (fromMonth === today.getMonth() && fromDay <= today.getDate())
    ) {
      nextYear++; // Increment the year if the day and month have already occurred this year
    }

    fromDate.setFullYear(nextYear);

    this.nextYearlyDate = fromDate.toDateString();

  }
}
