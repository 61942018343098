import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';
import {PaginatedResult, SingleResult} from '@app/interfaces';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {map, startWith, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  public unreadNotifications = new BehaviorSubject<InAppNotification[]>([]);
  public last5notifications = new BehaviorSubject<InAppNotification[]>([]);
  public loudNotifications = new BehaviorSubject<InAppNotification[]>([]);

  private timeIntervalSubscription: Subscription;

  constructor(private http: HttpClient) {
  }

  // Gets all unread notifications
  startFetchingNotifications() {
    this.timeIntervalSubscription?.unsubscribe();

    this.timeIntervalSubscription = interval(30000)
      .pipe(
        startWith(0),
        switchMap(() => this.reloadNotifications()),
      )
      .subscribe({error: (err) => console.log('HTTP Error', err)});
  }

  reloadNotifications() {
    return this.http.get<ArrayResult<InAppNotification>>(`${environment.apiUrl}/api/notifications/`).pipe(map((response) => {
      const notifications = response.data;

      const unreadNotifications: InAppNotification[] = notifications.filter((notification) => !notification.read_at);
      const last5notifications: InAppNotification[] = notifications.slice(0, 5);
      const loudNotifications: InAppNotification[] = unreadNotifications.filter((notification) => notification.data?.loud === true);

      this.unreadNotifications.next(unreadNotifications);
      this.last5notifications.next(last5notifications);
      this.loudNotifications.next(loudNotifications);

      return notifications;
    }));
  }

  // Sets notifications readed when open the notification
  getAllNotifications(): Observable<ArrayResult<any>> {
    return this.http.get<ArrayResult<any>>(`${environment.apiUrl}/api/notifications/get-all`, {});
  }

  // Sets notifications readed when open the notification
  readAllNotifications(): Observable<SingleResult<any>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/notifications/read-all`, {});
  }

  // Sets the value of isClicked to true
  readNotification(uuid: string): Observable<SingleResult<any>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/notifications/read-notification`, {uuid});
  }

  markNotificationRead(id: string) {
    return this.readNotification(id).pipe(switchMap(() => this.reloadNotifications()));
  }

  // Updates a notificationTemplate
  updateNotificationTemplate(values: object):
    Observable<PaginatedResult<InAppNotification[]>> {
    return this.http.get<PaginatedResult<InAppNotification[]>>(`${environment.apiUrl}/api/vehicles`, {});
  }

  // Creates a new notification Template
  createNotificationTemplate(values: object):
    Observable<PaginatedResult<InAppNotification[]>> {
    return this.http.get<PaginatedResult<InAppNotification[]>>(`${environment.apiUrl}/api/vehicles`, {});
  }
}
