import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SingleResult} from '../interfaces';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(private http: HttpClient) {
  }

  refresh() {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/signature-hooks`);
  }

  startHook(fieldUuid: string, label: string) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/signature-hooks`, {fieldUuid, label});
  }

  update(signature: string) {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/signature-hooks`, {signature});
  }

  clear() {
    return this.http.delete<SingleResult<any>>(`${environment.apiUrl}/api/signature-hooks`);
  }
}
