<a class="bg-white border rounded border-grayTableBorder p-3 flex flex-row hover:border-primary cursor-pointer"
   (click)="redirect()"
   [routerLink]="'/admin/workflow-results/'+result.workflow_result_uuid"
   [queryParams]="{documentUuid: result.uuid}"
   style="min-height: 90px;">
  <div class="flex flex-col w-full my-auto pointer-events-none">
    <div class="flex flex-row items-center group">
      <i class="fa fa-book text-greenDarkOlive group-hover:text-primary fadeInFadeOut mr-2"></i>
      <span class="text-greenDarkOlive text-sm font-semibold leading-normal group-hover:text-primary fadeInFadeOut"
            i18n>{{ result.title }}</span>
    </div>

    <div class="leading-loose" [ngClass]="{'mt-1': result.reference_number}">
      <ul class="text-grayDark text-xs ml-6">
        <li class="leading-relaxed" *ngIf="result.reference_number">
          <span i18n class="">Št:</span>
          <span
            class="ml-1 font-semibold">{{ result.reference_number }}</span>
        </li>
        <li class="leading-relaxed" *ngIf="result.client_name">
          <span i18n class="">Stranka</span>
          <span
            class="ml-1 font-semibold">{{ result.client_name }}</span>
        </li>
      </ul>
    </div>
  </div>
</a>
