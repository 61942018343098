import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NotificationService} from '@app/services/notification.service';
import {LeadGenerationService} from '@app/services/lead-generation.service';
import {UserApiService} from '@app/services/api/user-api.service';
import {Subject, zip} from 'rxjs';
import {SwitchToFullModeSuccessfullDialogComponent} from "../switch-to-full-mode-successfull-dialog/switch-to-full-mode-successfull-dialog.component";
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {PolicyLinks} from "@app/interfaces/config/initial.config";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-signup-form-dialog',
  templateUrl: './signup-form-dialog.component.html',
  styleUrls: ['./signup-form-dialog.component.scss']
})
export class SignupFormDialogComponent implements OnInit, OnDestroy {

  isLoading = false;
  isIniting = false;

  public companyName;
  public daysTillTrialEnds;
  public policyLinks: PolicyLinks;
  private destroy$ = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private notificationService: NotificationService,
              private leadGeneration: LeadGenerationService,
              private userApi: UserApiService,
              private configApi: ConfigAPIService,
              public dialogRef: MatDialogRef<any>,
              private matDialog: MatDialog) {
  }

  static open(dialog: MatDialog) {
    return dialog.open(SignupFormDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {}
    });
  }

  ngOnInit() {
    this.isIniting = true;
    zip(this.configApi.getAccountConfig(), this.userApi.getPublicAccount(), this.userApi.getDaysTillEndOfFreePeriod(), this.configApi.getInitialConfig(null))
      .pipe(takeUntil(this.destroy$))
      .subscribe(([accountConfig, publicAccount, daysTillEnd, initialConfig]) => {
        this.companyName = publicAccount.data.name;
        this.daysTillTrialEnds = daysTillEnd.data;
        this.policyLinks = initialConfig.policyLinks[accountConfig.locale] || initialConfig.policyLinks.default;

        this.isIniting = false;
      }, (error) => this.notificationService.showErrorAlert());
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }


  confirm() {
    this.isLoading = true;
    this.leadGeneration.convertToFullMode()
      .subscribe(() => {
        this.close();
        SwitchToFullModeSuccessfullDialogComponent.open(this.matDialog);
      }, (error) => {
        this.notificationService.showErrorAlert();
        this.isLoading = false;
      });
  }
}
