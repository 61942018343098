import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Field, SingleResult} from '@app/interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormHelperService} from '@app/util/form-helper.service';
import {KeyboardService} from '@app/services/keyboard.service';
import {Observable, Subscription} from 'rxjs';
import {InlineFieldEventStatus, InlineFieldService} from '@app/services/inline-field.service';
import {FieldCurrencyFormDialogComponent} from '@app/dialogs/field-currency-form-dialog/field-currency-form-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FieldApiService} from '@app/services/api/field-api.service';

@Component({
  selector: 'app-inline-field-edit',
  templateUrl: './inline-field-edit.component.html',
  styleUrls: ['./inline-field-edit.component.scss']
})
export class InlineFieldEditComponent implements OnInit, OnChanges, OnDestroy {

  @Input() field: Field<any>;
  @Input() isHiddenRepeaterField = false;
  @Input() isSaving = false;
  @Input() cancelEditOnSave = false;
  @Input() textSize = 'lg';
  @Input() hideLabel = false;
  @Input() replaceNullValueWith = '-';
  @Input() removeBoldFromValue = false;
  @Input() expandToFullSize = false;
  @Input() getFieldApi: { getField(entityUuid: string, fieldUuid: string): Observable<SingleResult<Field<any>>> };
  @Input() entityUuid: string;
  @Input() minWidth: string;
  @Input() updateField = true;
  @Input() subFields: Array<Field<any>>;
  @Input() isHidden = false;
  @Input() isEditable = true;
  @Input() formGroup: FormGroup;

  @Output('update') onUpdate = new EventEmitter<Field<any>>();

  public fieldEditMap: Map<string, FieldEdit> = new Map();

  private keyboardSubscription: Subscription;
  private inlineEditEventsSubscription: Subscription;
  private originalFieldValue: unknown;

  constructor(private formBuilder: FormBuilder,
              private formBuilderHelper: FormHelperService,
              private inlineFieldService: InlineFieldService,
              private matDialog: MatDialog,
              private fieldApi: FieldApiService,
              private keyboardService: KeyboardService,
              private formHelperService: FormHelperService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field) {
      if (changes.field.previousValue !== changes.field.currentValue) {
        this.fieldEditMap = new Map();
        this.cancelSubscriptions();
      }
    }
  }

  ngOnInit(): void {
    if (!this.isHiddenRepeaterField && this.formGroup && 'value' in this.formGroup && this.formGroup.value[this.field.fieldUuid]) {
      this.field.value = this.formGroup.value[this.field.fieldUuid];
    }

    if (this.formGroup && this.formGroup.controls[this.field.fieldUuid]) {
      // @ts-ignore
      this.isHidden = this.formGroup.controls[this.field.fieldUuid].hidden;

      this.formGroup.controls[this.field.fieldUuid].valueChanges.subscribe((x) => {
        // @ts-ignore
        this.isHidden = this.formGroup.controls[this.field.fieldUuid].hidden;
      });
    }

    this.formHelperService.modifyOnSetFields(this.field.value, this.field, this.formGroup);
  }

  ngOnDestroy(): void {
    this.cancelSubscriptions();
  }

  enableEdit(field: Field<any>) {
    this.originalFieldValue = field.value;

    if (this.getFieldApi) {
      this.getFieldApi.getField(this.entityUuid, field.fieldUuid).subscribe((result) => {
        const fetchedField = result.data;
        this.originalFieldValue = field.value;
        const controls = {};
        controls[field.fieldUuid] = this.formBuilderHelper.prepareFormGroupControlForFieldItem(fetchedField);
        const formGroup = this.formGroup ? this.formGroup : this.formBuilder.group(controls);
        this.fieldEditMap.set(field.fieldUuid, {
          originalField: field,
          updatedField: Object.assign({}, fetchedField),
          formGroup
        });

        this.inlineFieldService.fire(field.fieldUuid, InlineFieldEventStatus.opened);
        this.setupListener(field);
      });
    } else {
      const controls = {};
      controls[field.fieldUuid] = this.formBuilderHelper.prepareFormGroupControlForFieldItem(field);
      const formGroup = this.formGroup ? this.formGroup : this.formBuilder.group(controls);

      this.fieldEditMap.set(field.fieldUuid, {originalField: field, updatedField: Object.assign({}, field), formGroup});

      this.inlineFieldService.fire(field.fieldUuid, InlineFieldEventStatus.opened);
      this.setupListener(field);
    }

    this.formGroup?.controls[field.fieldUuid].setErrors({'incorect': true});
  }

  private setupListener(field: Field<any>) {
    this.keyboardSubscription = this.keyboardService.events().subscribe((event) => {
      //  TODO: HACK! Vehicle model should not be automatically confirmed when pressing enter as it breaks it
      if (event.key.toLowerCase() === 'enter' && field.type !== 'vehicle_model' && field.type !== 'text_rich') {
        this.saveEdit(field);
      } else if (event.key.toLowerCase() === 'escape') {
        this.cancelEdit(field);
      }
    });

    //  Remove all subscriptions if we opened/closed a different inline field
    this.inlineFieldService.events().subscribe((event) => {
      if (event.fieldUuid !== field.fieldUuid) {
        this.cancelSubscriptions();
      }
    });
  }

  cancelEdit(field: Field<any>) {
    this.fieldEditMap.delete(field.fieldUuid);
    this.inlineFieldService.fire(field.fieldUuid, InlineFieldEventStatus.closed);

    this.cancelSubscriptions();
    this.formGroup?.controls[field.fieldUuid].setErrors(null);
    // Reset to the original value
    this.formGroup?.controls[field.fieldUuid].setValue(this.originalFieldValue);
    this.field.value = this.originalFieldValue;
  }

  saveEdit(field: Field<any>) {
    this.formGroup?.controls[field.fieldUuid].setErrors(null);
    const fieldEdit = this.fieldEditMap.get(field.fieldUuid);
    fieldEdit.updatedField.value = fieldEdit.formGroup.controls[field.fieldUuid].value;

    if (fieldEdit.formGroup.controls[field.fieldUuid].valid) {
      this.originalFieldValue = fieldEdit.updatedField.value;
      this.field.value = fieldEdit.updatedField.value;
      this.onUpdate.emit(fieldEdit.updatedField);
    }

    if (this.cancelEditOnSave) {
      this.cancelEdit(field);
    }
  }

  getClass() {
    return {
      'text-lg': this.textSize === 'lg',
      'text-sm': this.textSize === 'sm',
    };
  }

  private cancelSubscriptions() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }

    if (this.inlineEditEventsSubscription) {
      this.inlineEditEventsSubscription.unsubscribe();
    }
  }

  editOrOpenEditDialog(field: Field<any>) {
    if (field.type === 'currency' && (field.currencyConversion && !field.currencyConversion.isDefault)) {
      return this.openEditCurrencyDialog(field);
    }
    return this.enableEdit(field);
  }

  private openEditCurrencyDialog(field: Field<any>) {
    if (this.getFieldApi) {
      this.getFieldApi.getField(this.entityUuid, field.fieldUuid).subscribe((result) => {
        const fetchedField = result.data;
        const connectedField = result.data.connectedField;

        const dialog = FieldCurrencyFormDialogComponent.open(this.matDialog, this.entityUuid, this.getFieldApi, fetchedField, connectedField);

        dialog.afterClosed().subscribe((updated) => {
          if (updated) {
            this.onUpdate.emit(updated);
          }
        });
      });
    } else if (!this.updateField && this.subFields) {
      let connectedField;
      let currencyField;

      for (const subFieldUuid of Object.keys(this.subFields)) {
        if (this.subFields[subFieldUuid].type === 'currency_conversion_rate') {
          connectedField = this.subFields[subFieldUuid];
          connectedField.value = this.field.connectedField.value ? this.field.connectedField.value : this.field.currencyConversion.conversionRate;
        } else if (this.subFields[subFieldUuid].type === 'currency') {
          currencyField = this.subFields[subFieldUuid];
          currencyField.value = this.field.value;
        }
      }

      const dialog = FieldCurrencyFormDialogComponent.open(this.matDialog, this.entityUuid, this.getFieldApi, currencyField, connectedField);

      dialog.afterClosed().subscribe((updated) => {
        if (updated) {
          updated.forEach((setField) => {
            if (setField.fieldUuid === this.field.fieldUuid) {
              this.field.value = setField.value;
            } else if (setField.fieldUuid === this.field.connectedField.fieldUuid) {
              this.field.connectedField.value = setField.value;
              this.field.currencyConversion.conversionRate = setField.value;
            }
          });
          this.onUpdate.emit(updated);
        }
      });
    } else if (!this.updateField) {

      const dialog = FieldCurrencyFormDialogComponent.open(this.matDialog, this.entityUuid, this.getFieldApi, this.field, this.field.connectedField);

      dialog.afterClosed().subscribe((updated) => {
        if (updated) {
          updated.forEach((setField) => {
            if (setField.fieldUuid === this.field.fieldUuid) {
              this.field.value = setField.value;
            } else if (setField.fieldUuid === this.field.connectedField.fieldUuid) {
              this.field.connectedField.value = setField.value;
              this.field.currencyConversion.conversionRate = setField.value;
            }
          });
          this.onUpdate.emit(updated);
        }
      });
    }
  }
}

interface FieldEdit {
  originalField: Field<any>;
  updatedField: Field<any>;
  formGroup: FormGroup;

  connectedField?: Field<any>;
}
