<div class="card bg-white border-none max-h-75vh sm:max-h-initial">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Polni dostop je aktiviran</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <hr class="mt-0 mb-0 block lg:hidden">
  <div class="card-content overflow-y-auto max-h-75vh sm:max-h-full">

    <div class="text-center">
      <img src="assets/images/img_fullmode_activated.png" alt="Car icon" class="mx-auto my-6">
    </div>
    <div class="text-center">
      <p class="text-greenDarkOlive font-bold mb-2">Čestitke!</p>

      <p class="leading-normal" i18n>Sedaj lahko izdajate vse fakture in dokumente brez restrikcij.</p>


      <div class="my-12" *ngIf="isLoading">
        <div class="text-center">
          <div class="flex flex-row justify-center items-center">
            <div class="mr-3">
              <app-spinner></app-spinner>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading">

        <div class="my-6 card border-red bg-redWashed" *ngIf="administrationForm.invalid">
          <div class="card-content text-center">
            <p class="text-red">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              <span i18n class="font-bold">Izpolnite potrebne podatke za izdajanje računov</span>
            </p>

            <p class="mt-2 text-sm text-red"><span i18n>Za pravilen izpis podatkov je treba dopolniti nastavitve za administracijo.</span></p>

            <div class="mt-4">
              <a  target="_blank" [routerLink]="['/admin/settings/administration']"><button class="btn btn-primary">Odpri nastavitve administracije</button></a>
            </div>

          </div>
        </div>

        <div class="my-6 card border-red bg-redWashed" *ngIf="accountForm.invalid">
          <div class="card-content text-center">
            <p class="text-red">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              <span i18n class="font-bold">Izpolnite potrebne podatke za profil podjetja</span>
            </p>

            <p class="mt-2 text-sm text-red"><span i18n>Za pravilen izpis podatkov je treba dopolniti profil podjetja.</span></p>

            <div class="mt-4">
              <a  target="_blank" [routerLink]="['/admin/settings/public-account']"><button class="btn btn-primary">Odpri profil podjetja</button></a>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="text-center mt-8 text-sm text-grayDark" i18n>
      V kratkem vas bo kontaktiral naš svetovalec in vam predstavil našo ponudbo uporabe AutoBrief platforme.
    </div>
    <div class="flex flex-row justify-end mt-4">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Zapri</button>
    </div>
  </div>


</div>

