import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 503) {
          if (!this.router.isActive('/maintenance', false)) {
            this.router.navigate(['/maintenance'], {
              queryParams: {
                redirectTo: this.document.location.href
              }
            });
          }
        }

        return throwError(err);
      })
    );
  }
}
