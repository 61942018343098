export enum DialogType {
    DEFAULT = 'default',
    DELETE_VEHICLE = 'delete_vehicle',
    DELETE_CLIENT = 'delete_client',
    DELETE_ALARM = 'delete_alarm',
    DELETE_PHOTO = 'delete_photo',
    DELETE_REPEATER_ITEM = 'delete_repeater_item',
    REMOVE_USER_FROM_ACCOUNT = 'remove_user_from_account',
    REMOVE_EXPENSE = 'remove_expense',
    REMOVE_AD = 'remove_ad',
    DISABLE_ADVERTISING = 'disable_advertising',
    CREATE_DOCUMENT = 'create_document',
    ARCHIVE_VEHICLE = 'archive_vehicle',
    UNARCHIVE_VEHICLE = 'unarchive_vehicle',
    REMOVE_PAYMENT = 'remove_payment',
    REMOVE_INVOICE_DOCUMENT = 'remove_invoice_document',
    UNCANCEL_INVOICE_DOCUMENT = 'uncancel_invoice_document',
    REMOVE_INVOICE_ASSET_DOCUMENT = 'cancel_invoice_asset_document',
    ARCHIVE_DOCUMENT = 'archive_document',
    UNARCHIVE_DOCUMENT = 'unarchive_document',
    REMOVE_ENTITY_GROUP = 'remove_entity_group',
    UPDATE_DOCUMENT = 'update_document',
    DELETE_NLB_DRAFT = 'delete_nlb_draft',
    REMOVE_CASH_RECEIPT = 'remove_cash_receipt',
    REACTIVATE_ACCOUNT = 'reactivate_account',
    CHANGE_BUSINESS_UNIT = 'change_business_unit',
    ARCHIVE_ASSET = 'archive_asset',
    UNARCHIVE_ASSET = 'unarchive_asset',
    DELETE_BUSINESS_UNIT = 'delete_business_unit',
    RESUBSCRIBE = 'resubscribe',
}
