import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {NotificationApiService} from '@app/services/api/notification-api.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  @Input() notification: InAppNotification;
  @Input() showCloseMark = false;
  @Input() loudNotification = false;
  @Output() onClose = new EventEmitter();

  public notificationType: string = NotificationType.NORMAL;
  public createdAt: string;

  constructor(public router: Router,
              private notificationApiService: NotificationApiService) {
  }

  ngOnInit(): void {
    const m1 = moment(new Date(this.notification.created_at));
    const m2 = new Date();
    const duration = moment.duration(m1.diff(m2));

    this.createdAt = duration.humanize(true);

    this.setNotificationType(this.notification);
  }

  removeNotification() {
    this.onClose.emit(this.notification.id);
    this.notificationApiService.markNotificationRead(this.notification.id).subscribe();
  }

  redirectToNotification(notification: InAppNotification) {
    // Starts with http/https
    if (RegExp('https?').test(notification.data.button_link)) {
      window.open(notification.data.button_link, '_blank');
    } else {
      this.router.navigateByUrl(`admin/${notification.data.button_link}`);
    }
    this.onClose.emit(this.notification.id);
    this.notificationApiService.markNotificationRead(this.notification.id).subscribe();
  }

  private setNotificationType(notification: InAppNotification) {
    if (notification.data.type) {
      this.notificationType = notification.data.type;
    }
  }
}

export enum NotificationType {
  NORMAL = 'normal',
  INFORMATION = 'information',
  ALERT = 'alert'
}
