import {Component, Inject, OnInit} from '@angular/core';
import {PingService} from '@app/services/api/ping.service';
import {NotificationService} from '@app/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {SafariScreenFixService} from '@app/services/safari-screen-fix.service';

@Component({
  selector: 'app-maintenance-mode-page',
  templateUrl: './maintenance-mode-page.component.html',
  styleUrls: ['./maintenance-mode-page.component.scss']
})
export class MaintenanceModePageComponent implements OnInit {

  public isLoading = false;

  constructor(private pingService: PingService,
              private notificationService: NotificationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              private safariScreenFix: SafariScreenFixService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.isLoading = true;
    this.pingService.ping().subscribe((result) => {
      let redirect = this.activatedRoute.snapshot.queryParams.redirectTo;

      if (!redirect) {
        redirect = this.router.createUrlTree(['/admin/vehicles']).toString();
      }

      this.document.location.href = redirect;
    }, (error) => {
      if (error.status === 503) {
        this.isLoading = false;
      } else {
        this.notificationService.showErrorAlert();
      }
    });
  }

  isSafari() {
    return this.safariScreenFix.getIsSafari();
  }
}
