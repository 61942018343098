import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {ExportApiService} from '../../../services/api/export-api.service';

@Component({
  selector: 'app-form-prefill-document-template-dialog',
  templateUrl: './form-prefill-document-template-dialog.component.html',
  styleUrls: ['./form-prefill-document-template-dialog.component.scss']
})
export class FormPrefillDocumentTemplateDialogComponent implements OnInit {

  public document = undefined;
  public isSubmitting;
  public files = [];

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    workflowTypeId: new FormControl(''),
    workflowUuid: new FormControl(''),
    typeId: new FormControl(''),
    hasClient: new FormControl(false),
    hasVehicle: new FormControl(false),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private exportApiService: ExportApiService) {
    this.document = data.document ? data.document : null;

    if (this.document) {
      this.form.controls.name.setValue(this.document.name);
      this.form.controls.description.setValue(this.document.description);
      this.form.controls.workflowTypeId.setValue(this.document.workflowTypeId);
      this.form.controls.workflowUuid.setValue(this.document.workflowUuid);
      this.form.controls.typeId.setValue(this.document.typeId);
      this.form.controls.hasClient.setValue(this.document.hasClient);
      this.form.controls.hasVehicle.setValue(this.document.hasVehicle);
    }
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.addedFiles) {
      this.files.push(...$event.addedFiles);
    }
  }

  ngOnInit() {

  }

  save() {

    if (this.form.valid) {
      if (!this.document) {
        if (this.files.length > 0) {
          this.submit();
        }
      } else {
        this.submit();
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  private submit() {
    this.isSubmitting = true;
    this.form.disable();

    const data = this.form.value;

    if (!data.workflowUuid) {
      delete data.workflowUuid;
    }

    if (!data.workflowTypeId) {
      delete data.workflowTypeId;
    }

    if (this.document) {
      this.exportApiService.updateDocument(this.document.prefillDocumentTemplateUuid, data, this.files[0])
        .subscribe(result => {
          // @ts-ignore
          this.dialogRef.close(result.data);
        }, error => {
          this.isSubmitting = false;
          console.error(error);
        });
    } else {
      this.exportApiService.storeDocument(data, this.files[0])
        .subscribe(result => {
          // @ts-ignore
          this.dialogRef.close(result.data);
        }, error => {
          this.isSubmitting = false;
          console.error(error);
        });
    }
  }
}
