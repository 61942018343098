import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() {
    this.registerGlobalErrorHandler();
  }

  public debug(...args) {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }

  public log(...args) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  public info(...args) {
    // eslint-disable-next-line no-console
    console.info(...args);
  }

  public warning(...args) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  public error(...args) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }

  private registerGlobalErrorHandler() {
    const previousErrorHandler = window.onerror;

    window.onerror = (...args) => {
      this.error(...args);

      previousErrorHandler?.(...args);
    };
  }
}
