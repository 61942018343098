<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5 pb-0">
    <div class="flex items-center justify-between">
      <p i18n="Select  files from vehicle or customer" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Izberi datoteke iz vozila ali stranke</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
    <div class="flex items-center justify-between">
      <p i18n="Select from where you would like to upload files">Izberite iz kje boste naložili obstoječe datoteke</p>
    </div>
  </div>
  <div class="card-content">
    <div class="flex flex-row flex-wrap justify-evenly mx-auto">
      <button style="min-width: 180px; max-width: 200px; flex-basis: 200px;"
              class="btn py-10 border rounded border-grayTableBorder bg-grayNavi font-bold text-grayDark w-1/4 mx-2 mb-6 flex justify-center shadow-none flex-col items-center"
              (click)="showCarExistingFilesDialog()">
        <div class="text-center text-2xl opacity-85 mb-2">
          <i class="fas fa-car"></i>
        </div>

        <span i18n="Vehicle">Vozilo</span>
      </button>

      <button style="min-width: 180px; max-width: 200px; flex-basis: 200px;"
              class="btn py-10 border rounded border-grayTableBorder bg-grayNavi font-bold text-grayDark w-1/4 mx-2 mb-6 flex justify-center shadow-none flex-col items-center"
              (click)="showCustomerExistingFilesDialog()">
        <div class="text-center text-2xl opacity-85 mb-2">
          <i class="fas fa-user"></i>
        </div>
        <span i18n="Client">Stranka</span>
      </button>
    </div>

    <div class="mt-10 flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Zapri</button>
    </div>
  </div>
</div>

