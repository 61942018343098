<div class="w-full h-screen flex justify-center items-center" style="background: #F5F5F5">

  <div class="flex flex-col md:flex-row justify-center items-center w-full" style="max-width: 900px">
    <div class="grow p-4" style="flex-basis: 100px">
      <img src="assets/images/img_maintenance_mode.png" alt="Maintenance mode">
    </div>
    <div class="grow p-4" style="flex-basis: 100px">
      <ng-container *ngIf="!isLoading">
        <h1 class="text-2xl font-bold"><span i18n>Izvajamo vzdrževalna dela!</span></h1>

        <p><span i18n>Trenutno so v teku vzdrževalna dela. Prosim poskusite kasneje.</span></p>

        <div class="mt-8">
          <button class="btn btn-primary mx-auto" (click)="refresh()">Osveži stran</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isLoading">
        <div class="flex justify-center">
          <app-spinner></app-spinner>
        </div>
      </ng-container>
    </div>

  </div>
</div>
