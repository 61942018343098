import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-share-vehicle-widget',
  templateUrl: './share-vehicle-widget.component.html',
  styleUrls: ['./share-vehicle-widget.component.scss']
})
export class ShareVehicleWidgetComponent implements OnInit {

  @Input() url: string;

  public mediums: Medium[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: object, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.mediums.push({id: 'fb', url: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`});
    this.mediums.push({id: 'email', url: `mailto:?body=${this.url}`});
    this.mediums.push({id: 'twitter', url: `https://twitter.com/intent/tweet?text=${this.url}`});
  }

  hasShare() {
    return isPlatformBrowser(this.platformId) && navigator && (navigator as any).share;
  }

  share() {
    const shareData = {
      title: '',
      text: '',
      url: this.url,
    };

    (navigator as any).share(shareData).then(() => {
      },
      error => (
        this.notificationService.showErrorAlert()
      ));

  }
}

interface Medium {
  id: string;
  url: string;
}
