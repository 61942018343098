import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, ReplaySubject} from 'rxjs';
import {environment} from '@src/environments/environment';
import {PaginatedResult, SingleResult, Status} from '@app/interfaces';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {Tax} from '@app/interfaces/entities/tax';
import {ApiCachingService} from './api-caching.service';
import {catchError, finalize, map, share} from 'rxjs/operators';
import {InitialConfig} from '@app/interfaces/config/initial.config';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {VehicleModelResponse} from '@app/interfaces/responses/vehicle-model.response';
import {AuthService} from '@app/services/auth.service';
import {AddressPostResponse} from '@app/interfaces/form/address-post';
import {EntityData} from '@app/interfaces/entities/entity-data';

@Injectable({
  providedIn: 'root'
})
export class ConfigAPIService {
  private initialConfig$: Observable<InitialConfig>;
  private accountConfig$: ReplaySubject<AccountConfig>;
  private configInLocale: string;

  constructor(private http: HttpClient,
              private apiCaching: ApiCachingService,
              authService: AuthService) {

    authService.getAccountControlChanges().subscribe(() => this.resetConfigCaches());
    authService.getAuthChanges().subscribe(() => this.resetConfigCaches());
    authService.getInvalidateConfigChanges().subscribe(() => {
      this.resetConfigCaches();
    });
  }

  getTaxes(filter?: string): Observable<ArrayResult<Tax>> {
    let params = new HttpParams();
    let cacheKey = 'taxes';

    if (filter) {
      params = params.append('filter', filter);
      cacheKey += filter;
    }

    return this.apiCaching.getCached(cacheKey, this.http.get<ArrayResult<Tax>>(`${environment.apiUrl}/api/config/taxes`, {params}));
  }

  getLocation(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/config/locations`);
  }

  getStatuses(entityType: string): Observable<PaginatedResult<Status[]>> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);

    return this.apiCaching.getCached(`statuses${entityType}`, this.http.get<PaginatedResult<Status[]>>(`${environment.apiUrl}/api/config/statuses`, {params}));
  }

  getEntityData(entityType: string) {
    return this.apiCaching.getCached(`entityData${entityType}`, this.http.get<EntityData>(`${environment.apiUrl}/api/config/entity-data/${entityType}`));
  }

  //  Because we have 'legacy' statuses, we want to filter the valid from the invalid
  isStatusValid(status: Status) {
    switch (status?.typeId) {
      case 'briefd.default_vehicle_status_being_prepared':
      case 'briefd.default_vehicle_status_in_transit':
      case 'briefd.default_vehicle_status_reserved':
      case 'briefd.default_vehicle_status_ready':
      case 'briefd.default_vehicle_status_rented':
        return true;
    }

    return false;
  }

  isStatusValidImportedVehicles(status: Status) {
    switch (status?.typeId) {
      case 'briefd.is_imported':
      case 'briefd.not_imported':
        return true;
    }

    return false;
  }

  getVehicleBrands(q: string): Observable<SingleResult<any>> {
    let params = new HttpParams();
    params = params.append('q', q);

    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/config/vehicle-brands`, {params});
  }

  getVehicleModels(vehicleBrandUuid: string, q: string) {
    let params = new HttpParams();
    params = params.append('vehicleBrandUuid', vehicleBrandUuid);
    params = params.append('q', q);

    return this.http.get(`${environment.apiUrl}/api/config/vehicle-models`, {params});
  }

  getVehicleModelTypes(vehicleModelUuid: string, q: string) {
    let params = new HttpParams();
    params = params.append('vehicleModelUuid', vehicleModelUuid);
    params = params.append('q', q);

    return this.http.get(`${environment.apiUrl}/api/config/vehicle-types`, {params});
  }

  getVehicleModelType(typeUuid: string) {
    return this.http.get(`${environment.apiUrl}/api/config/vehicle-types/${typeUuid}`);
  }

  getVehicleModel(modelUuid: string): Observable<SingleResult<VehicleModelResponse>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/config/vehicle-models/${modelUuid}`);
  }

  getStatusesForStateMachine(stateMachineUuid: string): Observable<ArrayResult<Status>> {
    let params = new HttpParams();
    params = params.append('stateMachineUuid', stateMachineUuid);

    return this.http.get<ArrayResult<Status>>(`${environment.apiUrl}/api/config/statuses`, {params});
  }

  getAddressPosts(countryCode?: string) {
    let params = new HttpParams();
    let cacheKey = 'configAddressPosts';

    if (countryCode) {
      params = params.append('countryCode', countryCode);
      cacheKey += countryCode;
    }

    return this.apiCaching.getCached(cacheKey, this.http.get<SingleResult<AddressPostResponse>>(`${environment.apiUrl}/api/config/address-posts`, {params}));
  }

  getDocumentTypes() {
    return this.apiCaching.getCached(`configDocumentTypes`, this.http.get(`${environment.apiUrl}/api/config/document-type-ids`, {}));
  }

  getTaxClauses(): Observable<SingleResult<any>> {
    return this.apiCaching.getCached('getTaxClauses', this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/config/tax-clauses`, {}));
  }

  private loadInitialConfig(locale: string = null): Observable<InitialConfig> {
    const headers = {};
    if (locale) {
      headers['locale'] = locale;
      headers['Cache-Control'] = 'no-cache';
      headers['Pragma'] = 'no-cache';
    }

    const httpCall$ = this.apiCaching.getCachedWithTTL(`initialConfig_ttl_${locale}`, 3600, this.http.get<SingleResult<InitialConfig>>(`${environment.apiUrl}/api/config/initial?locale=${locale}`, {headers}).pipe(finalize(() => console.log("Fetched fresh initalConfig"))));

    this.initialConfig$ = httpCall$.pipe(map(response => response.data));

    return this.initialConfig$;
  }

  public getInitialConfig(locale: string | null): Observable<InitialConfig> {
    if (!this.initialConfig$ || (!this.configInLocale || (locale && this.configInLocale !== locale))) {
      if (locale) {
        this.configInLocale = locale;
      }

      this.loadInitialConfig(this.configInLocale);
    }

    return this.initialConfig$.pipe();
  }

  public loadAccountConfig(accountUuid: string = null): Observable<AccountConfig> {
    if (!this.accountConfig$) {
      this.accountConfig$ = new ReplaySubject<AccountConfig>(1);
    }

    let params = {};

    if (accountUuid) {
      params = {accountUuid: accountUuid};
      this.accountConfig$ = new ReplaySubject<AccountConfig>(1);
    }

    this.http.get<SingleResult<AccountConfig>>(`${environment.apiUrl}/api/config/account`, {params})
      .pipe(
        map(response => response.data),
        catchError(err => of(null)),
        share()
      )
      .subscribe((config: AccountConfig) => {
        if (config) {
          // this.accountConfig$ could be null by the time the API call returns
          this.accountConfig$?.next(config);
        } else {
          this.accountConfig$?.error('Unable to get accountConfig');
          this.accountConfig$?.complete();
          this.accountConfig$ = null;
        }
      });

    // Return current value and next loaded value from API, then close stream
    return this.accountConfig$.asObservable();
  }

  public getAccountConfig(accountUuid: string = null, resetCache: boolean = false): Observable<AccountConfig> {
    if (resetCache) {
      this.resetConfigCaches();
    }
    if (!this.accountConfig$ || accountUuid) {
      return this.loadAccountConfig(accountUuid);
    }
    return this.accountConfig$.asObservable();
  }

  public resetConfigCaches() {
    this.accountConfig$?.complete();

    delete this.accountConfig$;
    delete this.initialConfig$;
  }

  public getProducts(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/config/products`);
  }
}
