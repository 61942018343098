<div class="card bg-white relative border-none">
  <div class="card-header p-5 pb-0">
    <h2 class="text-greenDarkOlive text-xl font-bold"><span i18n="">Dodajanje uporabnika</span></h2>
  </div>
  <div class="card-content">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="mb-5">
        <label for="email" class="block"><span i18n="">Email</span>
          <input type="text" name="email" id="email" formControlName="email" autocomplete="email"
                 [ngClass]="{'input-error': (form.controls.email.invalid && form.controls.email.touched) || (error) }">
        </label>

        <p class="input-error-message mt-1" *ngIf="form.controls.email.invalid && form.controls.email.touched">
          <span i18n="Form validation error">Polje je obvezno!</span>

        </p>

        <div *ngIf="error">
          <p class="input-error-message mt-1">
            <span i18n="" *ngIf="!errorMessage">Neznana napaka</span>
            <span *ngIf="errorMessage">{{errorMessage}}</span>
          </p>
        </div>
      </div>

      <div class="mt-12 flex flex-row" *ngIf="!isSaving">
        <button  class="btn btn-grayDark-link ml-auto mr-2"
                 type="button"
                 i18n-title="Prekliči@@cancel"
                 title="Prekliči@@cancel"
                (click)="close()"><span i18n="Prekliči@@cancel">Prekliči</span></button>
        <button class="btn text-right btn-primary-link ml-2 border-none" type="submit"><span i18n="">Dodaj uporabnika</span>
        </button>
      </div>

      <div class="mt-12 flex justify-center items-center" *ngIf="isSaving">
        <app-spinner></app-spinner>
      </div>
    </form>
  </div>
</div>
