import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {UserApiService} from '@app/services/api/user-api.service';
import {SwitchToFullModeDialogComponent} from '@app/dialogs/switch-to-full-mode-dialog/switch-to-full-mode-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ScrollToTopService} from '@app/services/scroll-to-top.service';
import {SafariScreenFixService} from '@app/services/safari-screen-fix.service';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {NotificationApiService} from '@app/services/api/notification-api.service';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss']
})
export class AdminRootComponent implements OnInit, OnDestroy, AfterViewInit {

  isNotInAdministration = true;

  private subscription: Subscription = new Subscription();
  private scrollSubscription: Subscription;
  private userSubscription: Subscription = new Subscription();

  public isDemo;

  @ViewChild('pageWindow') pageWindow;
  public loudNotifications$: Subject<InAppNotification[]>;

  public AccountFeaturesEnum = AccountFeaturesEnum;

  constructor(
    private router: Router,
    private notificationApiService: NotificationApiService,
    private userApiService: UserApiService,
    private matDialog: MatDialog,
    private scrollToTopService: ScrollToTopService,
    private safariScreenFix: SafariScreenFixService
  ) {
    //  Make sure that you have this in constructor, otherwise you don't get the first page load event
    this.subscription.add(
      this.router
        .events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.checkUrl(event.urlAfterRedirects);
        }));

    this.subscription.add(scrollToTopService.getObserable().subscribe(() => this.scrollToTop()));
  }

  ngOnInit() {
    this.userSubscription.add(this.userApiService.getIsDemoStream().subscribe((result) => {
      this.isDemo = result;
    }));

    // TODO: This must be made with Websockets and also with one request
    this.notificationApiService.startFetchingNotifications();

    this.loudNotifications$ = this.notificationApiService.loudNotifications;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.scrollSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  private checkUrl(url: string) {
    this.isNotInAdministration = url.indexOf('/admin/administration') === -1;
  }

  ngAfterViewInit(): void {
    this.scrollSubscription = this.router.events.pipe(
      filter(item => item instanceof NavigationEnd),
      filter(item => this.router.getCurrentNavigation().extractedUrl.root.children.primary.children.tab !== undefined)) // we only want to scroll to top when we the "tab" router outlet is active
      .subscribe((result) => {
        this.pageWindow.nativeElement.scrollTop = 0;
      });
  }

  openSwitchToFullMode() {
    const dialogRef = SwitchToFullModeDialogComponent.open(this.matDialog);
  }

  private scrollToTop() {
    this.pageWindow.nativeElement.scrollTop = 0;
  }

  isSafari() {
    return this.safariScreenFix.getIsSafari();
  }
}
