import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from "dayjs";
import {OpUnitType, QUnitType} from "dayjs";

@Pipe({
  name: 'datediff'
})
export class DatediffPipe implements PipeTransform {

  /**
   * Convert date/datestring to number of unit in diff
   * @param value
   * @param unit
   * @param float
   * @param args
   */
  transform(value: string | Date, unit: QUnitType | OpUnitType = 'days', float: boolean = false, ...args: unknown[]): unknown {
    const start = dayjs(value);
    const today = dayjs();
    return today.diff(start, unit, float);
  }
}
