<div class="dropdown flex-auto">
    <select [formControl]="control" autocomplete="off"
            [ngClass]="{'input-error': control.invalid }"
            [attr.data-field]="field?.fieldUuid"
            [attr.data-typeId]="field?.config?.typeId"
            [attr.tabIndex]="0"
    >
        <option value="" *ngIf="field.config.allowNull !== false || !field.config.hasOwnProperty('allowNull')"></option>

        <option *ngFor="let optionValue of (dropdownOptions$ | async)"
                [attr.disabled]="!optionValue.isActive ? 'disabled' : null"
                [ngValue]="optionValue.uuid">

            {{ optionValue.name }}
        </option>
    </select>
</div>
