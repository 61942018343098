import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationListPageComponent} from './notification-list-page/notification-list-page.component';
import {NotificationsRoutingModule} from './notifications-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {NotificationDropdownComponent} from './partials/notification-dropdown/notification-dropdown.component';
import {NotificationComponent} from './partials/notification/notification.component';
import {NotificationPopupComponent} from './partials/notification-popup/notification-popup.component';
import {PipesModule} from '@app/shared/pipes/pipes.module';


@NgModule({
  declarations: [NotificationListPageComponent, NotificationDropdownComponent, NotificationComponent, NotificationPopupComponent],
  exports: [
    NotificationDropdownComponent,
    NotificationComponent,
    NotificationPopupComponent,
  ],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    PipesModule,
  ]
})
export class NotificationsModule {
}
