import Quill from "quill";

export class GenerateTextModule {
  private quill: Quill;
  private options = {
    fetchContent: (selected, content) => new Promise((resolve, reject) => reject('fetchContent not implemented: ' + selected)),
    buttons: {}
  };

  constructor(quill, options) {
    this.quill = quill;
    this.options = options; // Options for the button

    // Create a dropdown element
    let dropdown = document.createElement('select');
    dropdown.innerHTML = `<option value="" i18n>Select Option</option>`;

    // Populate dropdown with options
    Object.keys(this.options.buttons).forEach(key => {
      let option = document.createElement('option');
      option.value = key;
      option.textContent = this.options.buttons[key];
      dropdown.appendChild(option);
    });

    // Add event listener to dropdown
    dropdown.addEventListener('change', () => {
      if (dropdown.value) {
        this.fetchTextFromAPI(dropdown.value);
      }

      dropdown.value = '';
    });
    // Add button to Quill toolbar
    let toolbar = quill.getModule('toolbar');
    if (toolbar) {
      toolbar.container.appendChild(dropdown);
    }
  }

  fetchTextFromAPI(optionKey: string) {
    let existingText = this.quill.getText() || '';
    this.quill.setText($localize`:@@loading:loading`);

    console.log('fetchTextFromAPI', optionKey, this.options[optionKey], existingText);
    this.options.fetchContent(optionKey, this.quill.getText()).then((text: string) => {
      if (existingText) {
        existingText += '\n\n';
      }
      existingText += text;
      this.quill.setText(existingText);
    })
      .catch((error) => {
        this.quill.setText(existingText);

        return console.error('Error:', error);
      });
  }
}
