import { Injectable } from '@angular/core';
import {environment} from "@src/environments/environment";
import {SingleResult} from "@app/interfaces";
import {HttpClient} from "@angular/common/http";
import {AutocompleteValues} from "@app/interfaces/entities/autocomplete-values";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AutocompleteValuesApiService {

  constructor(private http: HttpClient) { }

  getOptions(listKey: string): Observable<SingleResult<AutocompleteValues[]>>  {
    const params: { listKey: string } = { listKey };

    return this.http.get<SingleResult<AutocompleteValues[]>>(`${environment.apiUrl}/api/autocomplete-values`, {params});
  }
  saveOptions(options: any[]) {
    return this.http.put(`${environment.apiUrl}/api/autocomplete-values`, {options});
  }
}
