import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollToTopService {

  private observable = new Subject<void>();

  public getObserable() {
    return this.observable;
  }

  public scrollToTop() {
    this.observable.next();
  }
}
