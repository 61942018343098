import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';
import {pairwise, Subject} from "rxjs";
import {startWith, takeUntil} from "rxjs/operators";
import {FormHelperService} from "@app/util/form-helper.service";
import {firstKey, firstValue, isNullable} from "@app/util/object.helper";

@Component({
  selector: 'app-form-input-dropdown',
  templateUrl: './form-input-dropdown.component.html',
  styleUrls: ['./form-input-dropdown.component.scss']
})
export class FormInputDropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() formGroup: FormGroup;

  public outputChoices = [];

  public field: Field<number>;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private formHelperService: FormHelperService) {
  }

  @Input('field') set fieldInput(value: Field<number>) {
    this.field = value;

    this.prepareChoices(this.field);
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.control.valueChanges
      .pipe(takeUntil(this.destroy$),
        startWith(null),
        pairwise()
      )
      .subscribe(([previousValue, value]) => {
        if (value !== previousValue) {
          this.setDropdownValue(value);
        }
      });

    if (this.control.value) {
      this.setDropdownValue(this.control.value);
    } else if (this.field.value) {
      this.setDropdownValue(this.field.value);
    } else if (this.field.config.default) {
      this.setDropdownValue(this.field.config.default);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {
    // force value if allowNull is false and value is null
    if (this.field.config.allowNull === false
      && isNullable(this.control?.value)
      && isNullable(this.field.value)
      && !isNullable(this.field.config.default)) {
      this.control.setValue(this.outputChoices[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field?.currentValue) {
      if (this.control.value) {
        this.setDropdownValue(this.control.value);
      }
    }
  }

  setDropdownValue(value) {
    const targetKey = firstKey(value) || value;
    
    for (const choice of this.outputChoices) {
      const optionsKey = firstKey(choice);
      if (targetKey === optionsKey || value === optionsKey) {
        this.control.setValue(choice);
        this.formHelperService.modifyOnSetFields(value, this.field, this.formGroup);
        return;
      }
    }

    // Search also by display values (e.g. for countries)
    for (const choice of this.outputChoices) {
      const optionsValue = firstValue(choice);
      if (targetKey === optionsValue) {
        this.control.setValue(choice);
        this.formHelperService.modifyOnSetFields(value, this.field, this.formGroup);
        return;
      }
    }

    // No match, reset to null
    this.control.setValue(null);
    this.formHelperService.modifyOnSetFields(null, this.field, this.formGroup);
  }

  prepareChoices(field: Field<number>): void {
    if (!field.config.priorityList) {
      field.config.choices.sort((a, b) => {
        const aKey = firstValue(a);
        const bKey = firstValue(b);
        return aKey.localeCompare(bKey);
      });

      this.outputChoices = field.config.choices;

      return;
    }

    const priorityKeys = field.config.priorityList;
    const choices = field.config.choices.reduce((acc, item) => {
      const key = firstKey(item);
      acc[key] = item;
      return acc;
    });

    const outputChoices = [];
    const priorityChoicesHash = {};

    priorityKeys.forEach(key => {
      priorityChoicesHash[key] = key;
      if (choices[key]) {
        outputChoices.push(choices[key]);
      }
    });

    outputChoices.push({disabled: '        '});

    Object.keys(choices).forEach(key => {
      if (!priorityChoicesHash[key]) {
        outputChoices.push(choices[key]);
      }
    });

    this.outputChoices = outputChoices;
  }

  protected readonly firstValue = firstValue;
}
