import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit {

  @Input() identifier: string;
  @Input() mode = '';

  constructor() {
  }

  ngOnInit() {
  }

}
