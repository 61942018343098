import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Field} from "../../../../interfaces";
import {Upload} from "../../../../interfaces/entities/upload";
import {Subscription} from "rxjs";
import {FormHelperService} from "../../../../util/form-helper.service";

@Component({
  selector: 'app-form-input-filtered-dropdown',
  templateUrl: './form-input-filtered-dropdown.component.html',
  styleUrls: ['./form-input-filtered-dropdown.component.scss']
})
export class FormInputFilteredDropdownComponent implements OnInit, OnChanges, OnDestroy {

  @Input() control: FormGroup;

  @Input() field: Field<unknown>;

  private connectedControlSubscription: Subscription;

  private unfilteredChoices: any[] = [];

  constructor(private ref: ChangeDetectorRef, private formHelperService: FormHelperService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field.currentValue) {
      this.unfilteredChoices = changes.field.currentValue.config.choices;
    }

    if (changes.control.currentValue) {
      this.setupListener(changes.control.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.connectedControlSubscription) {
      this.connectedControlSubscription.unsubscribe();
    }
  }

  keys(input: any) {
    return Object.keys(input);
  }

  private setupListener(control: any) {
    const connectedControl = this.formHelperService.findControl(this.field.config.connectedDropdown, control);

    this.field.config.choices = [];

    if (connectedControl) {
      this.setFilteredChoices(connectedControl.value);

      if (connectedControl.value) {
        this.control.enable();
        this.control.setValue(null);

        if (this.field.value) {
          for (const choice of this.field.config.choices) {
            if (this.keys(this.field.value)[0] === this.keys(choice)[0]) {
              this.field.value = choice;
              this.control.setValue(choice);
              break;
            }
          }
        }
      } else {
        this.control.disable();
      }
    } else {
      this.setFilteredChoices(null);
      this.control.disable();
    }

    //  Listen for changes and update choices
    this.connectedControlSubscription = connectedControl.valueChanges
      .subscribe((connectedControlValue) => {
        this.setFilteredChoices(connectedControlValue);

        if (!connectedControlValue) {
          this.control.disable();
          this.control.setValue(null);
          return;
        }

        //  We have to check if the existing value is "valid"
        //  If it is, we keep it, otherwise, we delete it
        if (this.control.value) {
          const valueInChoices = this.field.config.choices.find(choice => Object.keys(choice)[0] === Object.keys(this.control.value)[0]);

          if (!valueInChoices) {
            this.control.setValue(null);
          }
        }

        this.control.enable();
      });

    this.ref.markForCheck();
  }

  private setFilteredChoices(connectedControlValue) {
    if (!connectedControlValue) {
      this.field.config.choices = [];
      return;
    }

    const filterKeys = this.keys(this.field.config.filters);
    const enabled = [];

    filterKeys.forEach(filterKey => {
      if (this.field.config.filters[filterKey].indexOf(this.keys(connectedControlValue)[0]) !== -1) {
        enabled.push(filterKey);
      }
    });
    this.field.config.choices = [...this.unfilteredChoices.filter((item) => enabled.indexOf(Object.keys(item)[0]) >= 0)];
  }
}
