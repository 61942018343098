<div>
  <!--
  <div class="text-center my-8">
    <p class="" i18n="Photo input">Odložite vaše fotografije v spodnji prostor</p>
  </div>
  -->

  <div class="grid mb-2 mt-1" *ngIf="images.length"
       [ngClass]="{'justify-between': images.length > 2}" cdkDropList (cdkDropListDropped)="onDrop($event)">

    <ng-container *ngFor="let item of images; let i = index">
      <div [ngClass]="{'sm:w-64': i === 0 && images.length === 1}" cdkDrag [cdkDragDisabled]="isMobile">
        <ng-container *ngIf="item.upload; else showLoadingPhoto">
          <div class="relative">
            <img [ngClass]="{
                  'border-2 border-primary': checkIfItemSelected(item) != -1,
                  }"
                 class="border border-0 hover:border-2 hover:border-primary cursor-pointer photo object-cover rounded w-full"
                 [src]="item.upload.files?.[0]?.conversions?.thumbnail"
                 loading="lazy"
                 (click)="onItemSelectChange(item)">

            <div class="absolute photo pointer-events-none w-full h-full photo-overlay top-0"></div>
            <div
                    class="absolute rounded w-10 h-10 flex justify-center h text-white items-center border border-white top-0 left-0 mt-2 ml-2"
                    style="background: rgba(255,255,255,0.31)">{{ i + 1}}
            </div>
            <div
                    *ngIf="i === 0"
                    class="absolute rounded w-10 h-10 flex justify-center h text-white items-center bg-white border border-white top-0 right-0 mt-2 "
                    style="color: #EEC120; margin-right: 3.65rem"><i class="fas fa-star"></i>
            </div>
            <button (click)="removeFile(item)" type="button"
                    class="absolute rounded w-10 h-10 flex justify-center text-red bg-white items-center hover:bg-whiteOff border border-white top-0 right-0 mt-2 mr-3">
              <i class="fas fa-trash-alt"></i></button>

            <button (click)="moveBack(item)" type="button"
                    *ngIf="i < images.length - 1 && images.length > 1"
                    class="absolute rounded w-10 h-10  opacity-85 hover:opacity-100 flex justify-center text-primary bg-white items-center hover:bg-whiteOff border border-white bottom-0 right-0 mb-2 mr-3">
              <i class="fas fa-chevron-right"></i></button>

            <button (click)="moveForward(item)" type="button"
                    *ngIf="i > 0"
                    class="absolute rounded w-10 h-10  opacity-85 hover:opacity-100 flex justify-center text-primary bg-white items-center hover:bg-whiteOff border border-white bottom-0 left mb-2 ml-3">
              <i class="fas fa-chevron-left"></i></button>
          </div>
        </ng-container>

        <ng-template #showLoadingPhoto>
          <div class="photo bg-grayNavi rounded border border-gray relative" style="padding-top: 100%">
            <div class="absolute w-full h-full flex justify-center flex-col top-0 items-center">
              <app-spinner></app-spinner>
              <div class="mt-3 text-black text-sm" i18n="File upload">Nalagam...</div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="mb-2">
    <div ngx-dropzone (change)="onSelect($event)" [multiple]="true"
         class="dropzone bg-grayHover"
         style="background: #F3F3F3 !important; color: black !important; border-color: #A3A3A3 !important; height: 9rem !important;"
         [accept]="'image/jpeg,image/jpg,image/png,image/gif'"
         [attr.data-field]="field?.fieldUuid"
         [attr.data-typeId]="field?.config?.typeId"
         [maxFileSize]="(field?.config?.maxFileSize || 5120) * 1024">
      <ngx-dropzone-label>
        <div class="px-4 text-gray text-3xl"><i class="fas fa-image"></i></div>
        <div class="text-md text-black font-bold mt-1"><span
                i18n="Photo hint">Mesto za odlaganje fotografij</span></div>
        <p class="text-grayDark text-sm mt-2" i18n="Photo upload">S klikom na to mesto lahko tudi <span
                class="text-primary hover:underline">izberete fotografije</span>.</p>
      </ngx-dropzone-label>
    </div>
  </div>

  <div>
    <p class="text-xs text-grayDark">
      <span i18n="file input">Največja dovoljena velikost datoteke je</span> {{ (field?.config?.maxFileSize || 5120) / 1024 | number:'1.0-0' }}MB</p>
    <p i18n="Photo input" class="text-xs text-grayDark">Dovoljene so le fotografije. (formati: jpg, jpeg, png,
      gif)</p>
  </div>
</div>
