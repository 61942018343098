import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../../../../interfaces';
import {GalleryService} from '../../../../services/gallery.service';
import {ConfigAPIService} from '../../../../services/api/config-api.service';
import {ExportApiService} from '../../../../services/api/export-api.service';
import {NotificationService} from '../../../../services/notification.service';
import {saveAs as importedSaveAs} from 'file-saver';
import {environment} from '../../../../../environments/environment';
import {IAlbum} from 'ngx-lightbox';
import {Observable, of} from 'rxjs';
import {BusinessUnitsService} from '@app/services/accounts/business-units.service';
import {map, take} from 'rxjs/operators';
import {AccountConfig} from '@app/interfaces/config/account.config';

@Component({
  selector: 'app-field-table-value',
  templateUrl: './field-table-value.component.html',
  styleUrls: ['./field-table-value.component.scss']
})
export class FieldTableValueComponent implements OnInit {
  @Input() field: Field<any>;

  public isOpened = false;

  public isDownloading = false;
  public accountConfig$: Observable<AccountConfig>;

  public vehicleName: string;
  kwToHpRatio = environment.kwToHpRatio;

  constructor(private gallery: GalleryService,
              private configAPIService: ConfigAPIService,
              private notificationService: NotificationService,
              private exportApi: ExportApiService,
              private businessUnitsService: BusinessUnitsService
  ) {
  }

  ngOnInit() {
    this.accountConfig$ = this.configAPIService.getAccountConfig();

    if (this.field.type === 'vehicle_model') {
      if (this.field.value) {
        this.configAPIService.getVehicleModel(this.field.value).subscribe((result) => {
          this.vehicleName = result.data.vehicleBrand.name + ' ' + result.data.name;
        });
      }
    }
  }

  showValue(field: Field<any>) {
    if (field.type === 'dropdown') {
      return of(field.value[Object.keys(field.value)[0]]);
    }

    if (field.type === 'business_unit') {
      return this.businessUnitsService.getBusinessUnits().pipe(take(1),
        map((businessUnits) => {
          return businessUnits.find((businessUnit) => businessUnit.uuid === field.value)?.name || field.value;
        }));
    }

    return of(field.value.toString());
  }

  prepareMultiCheckboxValues(field: Field<any>) {
    if (field.value !== null) {
      const checkBoxName = Object.keys(field.value)[0];
      const checkboxValues = field.value[checkBoxName];

      const arrayValues = [];

      const itemsMap = new Map();
      for (const [v, k] of Object.entries(field.config.sections[0].items)) {
        itemsMap.set(Object.keys(k)[0], Object.values(k)[0]);
      }

      for (const [key, value] of Object.entries(checkboxValues)) {
        if (value === true) {
          arrayValues.push(itemsMap.get(key));
        }
      }
      arrayValues.sort();

      return arrayValues;
    }
  }

  openGallery(field: Field<any>, index: number) {
    const images: IAlbum[] = [];

    field.value.forEach((upload) => {
      images.push({
        src: upload.files?.[0]?.conversions.large,
        downloadUrl: upload.files?.[0]?.conversions.large,
        caption: '',
        thumb: upload.files?.[0]?.conversions.thumbnail,
      });
    });

    this.gallery.openGallery(images, index);
  }

  toggleIsOpened() {
    this.isOpened = !this.isOpened;
  }

  toRepeaterField(subField: any, value: any) {
    const clone = {...subField, value};
    return clone;
  }

  downloadPhotos(field: Field<any>) {
    this.isDownloading = true;

    this.exportApi.downloadUploads(field.value.map((item) => item.uploadUuid)).subscribe((success) => {
      importedSaveAs(success, field.config.label + '.zip');
      this.isDownloading = false;
    }, (error) => {
      this.isDownloading = false;
      this.notificationService.showErrorAlert();
    });
  }

  getHpFromKw() {
    return Math.round(this.field.value * this.kwToHpRatio);
  }
}
