import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from '@app/services/feature-flag.service';
import {Subject} from 'rxjs';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';
import {takeUntil} from "rxjs/operators";

@Directive({
  selector: '[appIfAccountFeatureFlag]',
})
export class IfAccountFeatureFlagDirective implements OnInit, OnDestroy {

  @Input('appIfAccountFeatureFlag') public accountFlag: AccountFeaturesEnum | AccountFeaturesEnum[];
  @Input('appIfAccountFeatureFlagHideFor') public hideFor: AccountFeaturesEnum[];

  private destroy$ = new Subject<void>();

  constructor(private viewContainerRef: ViewContainerRef,
              private featureFlagsService: FeatureFlagService,
              private templateRef: TemplateRef<any>) {
  }

  ngOnInit(): void {
    this.featureFlagsService.getFeatureFlags().pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.viewContainerRef.clear();
        if (this.isAccountFeatureFlagEnabled(result)) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      }, (error) => {
        this.viewContainerRef.clear();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.viewContainerRef.clear();
  }

  private isAccountFeatureFlagEnabled(result: { [p: string]: boolean }) {
    if (!result || !this.accountFlag) {
      return false;
    }

    if (this.hideFor?.length > 0 && this.hideFor?.some(forbiddenFlag => result?.[forbiddenFlag])) {
      return false;
    }

    if (typeof this.accountFlag === 'string') {
      return result[this.accountFlag];
    }

    if (Array.isArray(this.accountFlag)) {
      return this.accountFlag.every(flag => result[flag]);
    }

    return false;
  }
}
