import {Component, Inject} from '@angular/core';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {takeUntil} from 'rxjs/operators';
import {UserApiService} from '@app/services/api/user-api.service';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {RequireHelpDialogComponent} from '@app/dialogs/require-help-dialog/require-help-dialog.component';
import {LeadGenerationService} from '@app/services/lead-generation.service';
import {FormGroup} from '@angular/forms';
import {NotificationService} from '@app/services/notification.service';
import {DeleteAccountDialogStepsEnum} from '@app/enums/DeleteAccountDialogStepsEnum';
import {LeadGenerationEnum} from "@app/enums/LeadGenerationEnum";

@Component({
  selector: 'app-cancel-product-feedback-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss']
})
export class DeleteAccountDialogComponent {

  public productPackage: ProductPackage;
  public activePackage: AccountProductPackage;

  public userProfile: UserProfile;

  public step: DeleteAccountDialogStepsEnum = DeleteAccountDialogStepsEnum.YOU_SURE;

  public DeleteAccountDialogStepsEnum = DeleteAccountDialogStepsEnum;

  private help = 'Zahteva po pomoči';

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<any>,
    private productPackagesService: ProductPackagesService,
    private userService: UserApiService,
    private leadService: LeadGenerationService,
    private notificationService: NotificationService,
    private matDialog: MatDialog
  ) {
    this.activePackage = data.activePackage;
  }

  ngOnInit(): void {
    this.userService.getUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.userProfile = result.data;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  save(formGroup: FormGroup = null) {
    this.productPackagesService.deleteAccount(this.getFeedbackFromFormGroup(formGroup))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.notificationService.showApplicationSuccessfulAlert();
        this.close();
        window.location.reload();
      });
  }

  increaseStep() {
    this.step++;
  }

  close() {
    this.dialogRef.close();
  }

  contactUs() {
    const dialogRef = RequireHelpDialogComponent.open(this.matDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.generateLead(this.help + ': ' + result);
      }
    });
  }

  generateLead(lead: string) {
    this.leadService.generateLead({extra: {zahteva: lead, type: LeadGenerationEnum.HELP}})
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });
  }

  private getFeedbackFromFormGroup(formGroup: FormGroup) {
    let feedback = formGroup.controls.feedback.value === 'other' ?
      formGroup.controls.otherOption.value :
      formGroup.controls.feedback.value;

    return {
      feedback: feedback,
    };
  }

  static open(dialog: MatDialog, activePackage) {
    return dialog.open(DeleteAccountDialogComponent, {
      width: '550px',
            disableClose: false,
      data: {
        activePackage,
      }
    });
  }
}
