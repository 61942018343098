import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-form-input-email',
  templateUrl: './form-input-email.component.html',
  styleUrls: ['./form-input-email.component.scss']
})
export class FormInputEmailComponent {
  @Input() control: FormControl;

  @Input() field: Field<string>;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {

    this.control.addValidators(Validators.email);
    this.control.updateValueAndValidity();

    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setPreset(preset: string) {
    this.control.setValue(preset);
  }
}
