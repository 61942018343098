import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {QuickCreateEntityDialogComponent} from '@app/dialogs/quick-create-entity-dialog/quick-create-entity-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-form-input-client',
  templateUrl: './form-input-client.component.html',
  styleUrls: ['./form-input-client.component.scss']
})
export class FormInputClientComponent implements OnInit, AfterViewInit {

  @Input() control: FormControl;

  @Input() field: Field<number>;

  public dropdownOptions$ = new BehaviorSubject<any>([]);

  constructor(
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.dropdownOptions$.next(this.field.config.clients);
  }

  ngAfterViewInit() {
    if (this.field.config.allowNull === false && (this.field.value === null || this.field.value === undefined)) {
      this.control.setValue(this.field.config.clients[0]);
    }
  }

  quickAddClient() {
    const quickEntityAddDialogRef = QuickCreateEntityDialogComponent.open(this.matDialog, {
      entityType: 'client',
      clientType: 'company',
      clientGroup: this.field.config.client_type ?? null
    });

    quickEntityAddDialogRef.afterClosed().subscribe((createdClient) => {
      createdClient['uuid'] = createdClient['clientUuid'];
      createdClient['organization_name'] = createdClient['organizationName'];

      const clients = [...this.field.config.clients, createdClient];
      this.dropdownOptions$.next(clients);

      this.control.setValue(createdClient);
    });
  }
}
