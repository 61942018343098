import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Status} from '../../../interfaces';
import {ConfigAPIService} from '../../../services/api/config-api.service';
import {VehicleApiService} from '../../../services/api/vehicle-api.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements OnInit {

  public currentStatus;
  public selectedStatusUuid: any;
  public isLoading = true;
  public isLoadingInvoices = true;

  public statuses: Status[] = [];

  public vehicleUuid;

  public vehicleInvoices = [];

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private configService: ConfigAPIService,
              private vehicleApiService: VehicleApiService,
              private notificationService: NotificationService,
              public dialogRef: MatDialogRef<unknown>) {
    this.currentStatus = data.currentStatus;
    this.selectedStatusUuid = data.currentStatus ? data.currentStatus.statusUuid : null;
    this.vehicleUuid = data.vehicleUuid;
  }


  static open(dialog: MatDialog,
              currentStatus: Status,
              statusMachineUuid: string,
              vehicleUuid: string) {
    return dialog.open(ChangeStatusDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        currentStatus,
        statusMachineUuid,
        vehicleUuid,
      }
    });
  }

  ngOnInit() {
    this.configService.getStatusesForStateMachine(this.data.statusMachineUuid)
      .subscribe((response) => {
        this.isLoading = false;
        this.statuses = response.data.filter(this.configService.isStatusValid);
      });

    this.vehicleApiService.getVehicleInvoices(this.vehicleUuid, {column: 'date', order: 'desc'}, {
      type: 'invoice', canceled: false, draft: false,
    }, true).subscribe((result) => {
      this.vehicleInvoices = result.data.filter((item) => {
        return item.documentItems.find((documentItem) => {
          return documentItem.custom?.lineItemType === 'sale' && documentItem.custom?.vehicleUuid === this.vehicleUuid;
        });
      });

      this.isLoadingInvoices = false;
    });
  }

  updateStatus() {
    this.dialogRef.close({statusMachineUuid: this.data.statusMachineUuid, statusUuid: this.selectedStatusUuid});
  }


  close() {
    this.dialogRef.close(false);
  }

  isValidStatus(currentStatus) {
    return this.configService.isStatusValid(currentStatus);
  }

  archiveVehicle() {
    this.isLoading = true;

    this.vehicleApiService.archive(this.vehicleUuid)
      .subscribe((result) => {
        this.dialogRef.close({vehicleUuid: this.vehicleUuid});
      }, (error) => {
        this.notificationService.showErrorAlert();
        this.isLoading = false;
      });
  }
}
