<div class="card bg-white relative border-none">
  <ng-container *ngIf="step===UnsubscribeDialogStepsEnum.WARNING">
    <div class="card-header p-3 sm:p-5">
      <p class="flex mb-0 pb-0 pr-0 text-greenDarkOlive text-lg sm:text-xl font-bold text-center"><span i18n>Ali zares želite preklicati svojo naročnino?</span>
      </p>
      <button (click)="close()"
              class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut absolute right-0 top-0 p-5">
        <i class="fas fa-times text-lg"></i>
      </button>
    </div>
    <div class="card-content text-justify">
      <div class="p-2 text-grayDark text-sm">
        <span i18n>
          V kolikor boste sedaj preklicali svojo naročnino na paket
          </span>
        {{ productPackage.name }}
        <span i18n>
         ,se bo ta iztekla s koncem tekočega meseca. Do takrat lahko AutoBrief platformo uporabljate kot običajno v okviru svojega izbranega paketa
      </span>
        {{ productPackage.name }}
      </div>
      <div class="p-2">
        <span class="text-grayDark text-sm" i18n>Po izteku tega obdobja boste lahko uporabljali AutoBrief le v obsegu paketa MINI, kar lahko pomeni izgubo dostopa do določenih funkcionalnosti platforme in podatkov, ki jih paket MINI ne zajema. </span>
      </div>

      <div class="flex justify-between mt-10">
        <button class="btn btn-primary px-4 text-sm" (click)="close()" i18n>Rad bi ostal
          uporabnik
        </button>
        <button class="btn btn-danger-outline ml-4 px-4 text-sm" (click)="this.nextStep()"><span
                i18n>Nadaljuj z preklicom</span>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === UnsubscribeDialogStepsEnum.FEEDBACK">
    <div class="card-header p-3 sm:p-5">
      <p class="flex mb-0 pb-0 pr-0 text-greenDarkOlive text-lg sm:text-xl font-bold text-center"><span i18n>Ali zares želite preklicati svojo naročnino?</span>
      </p>
      <button (click)="close()"
              class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut absolute right-0 top-0 p-5">
        <i class="fas fa-times text-lg"></i>
      </button>
    </div>
    <div class="card-content">
      <div class="p-2">
        <app-cancel-subscription-feedback [isUnsubscribe]="true" (onSave)="confirm($event)"
                                          (onClose)="close()"></app-cancel-subscription-feedback>
      </div>
    </div>
  </ng-container>
</div>
