<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="Change entity category modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Spremeni tip</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="p-3 sm:p-5 sm:pt-0">
    <ng-container class="p-5" *ngIf="isLoading; else showContent">
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </ng-container>

    <ng-template #showContent>
      <div *ngIf="categories">
        <label for="categories"><span i18n="Change status modal">Nov tip:</span>
          <div class="dropdown mt-2">
            <select name="categories" id="categories" [(ngModel)]="selectedCategoryUuid">
              <option *ngFor="let category of categories" [ngValue]="category.entityCategoryUuid">{{ category.name }}</option>
            </select>
          </div>
        </label>
      </div>
    </ng-template>
  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Prekliči</button>
      <button class="btn btn-primary ml-1" (click)="save()" i18n="">Posodobi</button>
    </div>
  </div>
</div>
