<div class="relative flex">
  <div class="relative">
    <input type="number"
           [formControl]="control"
           [attr.aria-label]="field.config.label"
           [ngClass]="{ 'input-error': control.invalid }"
           placeholder="{{ field.config.placeholder }}"
           (change)="calculateHpFromKw($event)"
           autocomplete="off"
           [max]="field.config.max"
           [attr.data-field]="field?.fieldUuid"
           [attr.data-typeId]="field?.config?.typeId"
           [attr.tabIndex]="0">
    <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark" style="margin-top: 0.85rem;"
          *ngIf="field.config.append">{{ field.config.append }}</span>
  </div>
  <div class="max-h-50vh flex flex-col justify-center mx-1">
    <i class="fas fa-exchange-alt text-grayLight"></i>
  </div>
  <div class="relative">
    <input type="number"
           [disabled]="control.disabled"
           [ngModel]="engineHorsePower"
           (change)="calculateKwFromHp($event)"
           autocomplete="off"
           [attr.tabIndex]="0">
    <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark" style="margin-top: 0.85rem;"
          i18n="Horse power">KM</span>
  </div>
</div>
