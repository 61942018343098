import {Component, Input, OnChanges, SimpleChanges,} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Field} from '../../../../interfaces';

@Component({
  selector: 'app-form-input-multi-checkbox',
  templateUrl: './form-input-multi-checkbox.component.html',
  styleUrls: ['./form-input-multi-checkbox.component.scss']
})
export class FormInputMultiCheckboxComponent {
  @Input() control: FormGroup;

  @Input() field: Field<number>;

  keys(item: {}) {
    return Object.keys(item);
  }

  asFormGroup(control: any): FormGroup {
    return control as FormGroup;
  }
}
