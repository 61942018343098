import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency} from '@angular/common';
import {CurrencyObject} from '@app/interfaces/config/currency-object';
import {Observable} from 'rxjs';
import {CurrencyConversionObject} from '@app/services/businessOverview/business-overview-stats.service';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {map} from 'rxjs/operators';


@Pipe({
  name: 'multiCurrency'
})
export class MultiCurrencyPipe implements PipeTransform {

  public secondaryCurrency: CurrencyObject;
  public primaryCurrency: CurrencyConversionObject;
  private locale: string;

  transform(value: any, accountConfig$: Observable<AccountConfig>, conversionRate?: number): Observable<any> {
    return accountConfig$.pipe(map((accountConfig) => {
      this.primaryCurrency = accountConfig.currencyConversion;
      this.secondaryCurrency = accountConfig.secondaryCurrency;
      this.locale = accountConfig.locale;

      if (this.secondaryCurrency) {
        conversionRate = conversionRate || this.secondaryCurrency.latest_rate;
        return `${formatCurrency(value, this.primaryCurrency.currency_locale, this.primaryCurrency.symbol)} (${formatCurrency(value * conversionRate, this.locale, this.secondaryCurrency.symbol)})`;
      } else {
        return `${formatCurrency(value, this.primaryCurrency.currency_locale, this.primaryCurrency.symbol)}`;
      }
    }));
  }
}
