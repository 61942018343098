<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'registration'">
    <div class="leading-snug relative rounded bg-white border text-grayDark text-center pr-3 pl-8 py-1 uppercase tracking-wide text-sm" style="border-color: #C4C4C4;" *ngIf="identifier">
      <div class="w-5 absolute h-full left-0 top-0" style="background: #003399; border-top-left-radius: 0.20rem; border-bottom-left-radius: 0.20rem;">
        <span class="text-white uppercase font-bold relative" style="top: 0.35rem; font-size: 0.45rem">EU</span>
      </div>
      {{ identifier }}
    </div>
  </ng-container>


  <ng-container *ngSwitchCase="'internal_number'">
    <div class="leading-snug rounded bg-white border text-grayDark text-center px-3 py-1 uppercase tracking-wide text-sm" style="border-color: #C4C4C4;" *ngIf="identifier">
      <span class="font-bold mr-1">#</span>{{ identifier }}
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="leading-snug rounded rounded bg-white border text-grayDark text-center px-3 py-1 uppercase tracking-wide text-sm" style="border-color: #C4C4C4;" *ngIf="identifier">
      {{ identifier }}
    </div>
  </ng-container>
</ng-container>

