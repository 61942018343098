import {Component, Input, OnInit} from '@angular/core';
import {PublicVehicle} from '@app/interfaces';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';


@Component({
  selector: 'app-public-vehicle-price-widget',
  templateUrl: './public-vehicle-price-widget.component.html',
  styleUrls: ['./public-vehicle-price-widget.component.scss']
})
export class PublicVehiclePriceWidgetComponent implements OnInit {

  @Input() vehicle: PublicVehicle;

  public vehicleName: string;
  public vehicleVersion: string;

  constructor(private vehicleService: VehicleApiService) {
  }

  ngOnInit(): void {
    this.vehicleName = this.getPublicVehicleName(this.vehicle);
    this.vehicleVersion = this.getPublicVehicleVersion(this.vehicle);
  }

  private getPublicVehicleName(vehicle: PublicVehicle) {
    return this.vehicleService.extractPublicVehicleName(vehicle);
  }

  private getPublicVehicleVersion(vehicle: PublicVehicle) {
    return this.vehicle.customVehicleType;
  }
}
