import {Component, Input} from '@angular/core';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {ProductPackageCodeEnum} from '@app/enums/ProductPackageCodeEnum';
import {ProductPackageSubscriptionTypeEnum} from '@app/enums/ProductPackageSubscriptionTypeEnum';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';

@Component({
  selector: 'app-registration-page-product-package-features',
  templateUrl: './registration-page-product-package-features.component.html',
  styleUrls: ['./registration-page-product-package-features.component.scss']
})
export class RegistrationPageProductPackageFeaturesComponent {

  @Input() public productPackages: ProductPackage[];
  @Input() public subscriptionType: ProductPackageSubscriptionTypeEnum;

  protected readonly AccountFeaturesEnum = AccountFeaturesEnum;
}
