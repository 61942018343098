<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="Alarm modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Opomnik</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <div class="card-content pt-0">
    <ng-container class="p-5" *ngIf="isLoading; else showContent">
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </ng-container>
    <form (ngSubmit)="submit()" [formGroup]="form">

      <ng-template #showContent>

        <div class="mb-5">
          <label for="name" class="block"><span  i18n="Alarm modal">Naslov opomnika</span>
            <input type="text" name="name" id="name" formControlName="name"
                   autocomplete="none"
                   [ngClass]="{'input-error': (form.controls.name.invalid && form.controls.name.touched) }">
          </label>

          <p class="input-error-message mt-1" *ngIf="form.controls.name.invalid && form.controls.name.touched">
            <span i18n="Form validation error">Polje je obvezno!</span>
          </p>
        </div>

        <div class="mb-3">

          <label class="relative"><span i18n="Alarm modal">Datum opomnika</span>

            <button (click)="myDatepicker.open()"
                    class="absolute right-0 z-20 p-4 mt-6 opacity-50 rounded-full hover:opacity-100 focus:opacity-100">
              <i class="fas fa-calendar-alt"></i></button>

            <input (click)="myDatepicker.open()" [matDatepicker]="myDatepicker" [formControl]="form.controls.date"
                   class="mt-2"
                   [ngClass]="{'input-error': (form.controls.date.invalid && form.controls.date.touched) }">

            <mat-datepicker #myDatepicker></mat-datepicker>

          </label>

          <div class="mt-6 div flex flex-row flex-wrap">
            <button type="button" class="btn  btn-sm font-bold mr-3 mb-2 flex-1 btn-primary-outline"
                    [disabled]="isSubmitting"
                    i18n="Alarm modal"
                    (click)="setAlarmFor(1, 'M')">čez 1 mesec
            </button>
            <button type="button" class="btn  btn-sm font-bold mr-3 mb-2 flex-1 btn-primary-outline"
                    [disabled]="isSubmitting"
                    i18n="Alarm modal"
                    (click)="setAlarmFor(3, 'M')">čez 3 mesece
            </button>
            <button type="button" class="btn btn-sm font-bold mb-2 flex-1 btn-primary-outline"
                    [disabled]="isSubmitting"
                    i18n="Alarm modal"
                    (click)="setAlarmFor(1, 'y')">čez 1 leto
            </button>
          </div>
        </div>
      </ng-template>

      <div class="mt-8 flex flex-row justify-end">
        <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Prekliči</button>
        <button class="btn btn-primary ml-1" type="submit"
                [disabled]="isSubmitting || isLoading" i18n="">Potrdi
        </button>
      </div>
    </form>
  </div>
</div>

