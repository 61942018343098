<ng-multiselect-dropdown
        [formControl]="control"
        [ngClass]="{'input-error': control.invalid }"
        [attr.data-field]="field?.fieldUuid"
        [attr.data-typeId]="field?.config?.typeId" [attr.tabIndex]="0"
        [placeholder]="'Izberi'"
        [settings]="dropdownFilterSettings"
        [data]="field.config.choices"
>
</ng-multiselect-dropdown>
