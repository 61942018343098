import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {LogoutPageComponent} from './shared/pages/logout-page/logout-page.component';
import {PublicRootComponent} from './shared/roots/public-root/public-root.component';
import {AdminRootComponent} from './shared/roots/admin-root/admin-root.component';
import {VehicleListingPageComponent} from './modules/public/pages/vehicle-listing-page/vehicle-listing-page.component';
import {PublicVehicleResolver} from './resolvers/public-vehicle-resolver';
import {PageNotFoundComponent} from './shared/pages/page-not-found/page-not-found.component';
import {MaintenanceModePageComponent} from './shared/pages/maintenance-mode-page/maintenance-mode-page.component';
import {VehicleListingListPageComponent} from './modules/public/pages/vehicle-listing-list-page/vehicle-listing-list-page.component';
import {PublicVehicleListingResolver} from './resolvers/public-vehicle-listing-resolver';
import {UnauthorizedPageComponent} from './shared/pages/unauthorized-page/unauthorized-page.component';
import {PermissionGuard} from '@app/guards/permission/permission-guard.service';
import {PermissionEnum} from '@app/enums/permissions/PermissionEnum';
import {DeactivatedPageComponent} from '@app/shared/pages/deactivated-page/deactivated-page.component';

const routes: Routes = [
  {
    path: 'v/:vehicleUuid',
    component: VehicleListingPageComponent,
    data: {
      title: 'title.vehicleListing',
    },
    resolve: {
      result: PublicVehicleResolver,
    }
  },
  {
    path: 'a/:accountUuid',
    component: VehicleListingListPageComponent,
    data: {
      title: 'title.vehicleListing',
    },
    resolve: {
      result: PublicVehicleListingResolver,
    }
  },
  {
    path: 'maintenance',
    component: MaintenanceModePageComponent,
    data: {
      title: 'title.maintenance',
    }
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'title.logout',
    }
  },
  {
    path: 'admin',
    component: AdminRootComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.DASHBOARD_DASHBOARD_VIEW],
        }
      },
      {
        path: 'business-overview',
        loadChildren: () => import('./modules/business-overview/business-overview.module').then(mod => mod.BusinessOverviewModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_BUSINESS_OVERVIEW_VIEW],
        }
      },
      {
        path: 'workflow-results',
        loadChildren: () => import('./modules/workflow-results/workflow-results.module').then(mod => mod.WorkflowResultsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'workflows',
        loadChildren: () => import('./modules/workflows/workflows.module').then(mod => mod.WorkflowsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./modules/vehicles/vehicles.module').then(mod => mod.VehiclesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, AuthGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_VEHICLES_VIEW],
        }
      },
      {
        path: 'documents',
        loadChildren: () => import('./modules/documents/documents.module').then(mod => mod.DocumentsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_DOCUMENTS_VIEW],
        }
      },
      {
        path: 'market-study',
        loadChildren: () => import('./modules/market-study/market-study.module').then(mod => mod.MarketStudyModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_MARKET_STUDY_VIEW],
        }
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/clients/clients.module').then(mod => mod.ClientsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_CLIENTS_VIEW],
        }
      },
      {
        path: 'assets',
        loadChildren: () => import('./modules/assets/assets.module').then(mod => mod.AssetsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_ASSETS_VIEW],
        }
      },
      {
        path: 'imports',
        loadChildren: () => import('./modules/imports/imports.module').then(mod => mod.ImportsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.TAB_VEHICLES_IMPORTS_VIEW],
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(mod => mod.SettingsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'administration',
        loadChildren: () => import('./modules/administration/administration.module').then(mod => mod.AdministrationModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_ADMINISTRATION_VIEW],
        }
      },
      {
        path: 'leasings',
        loadChildren: () => import('./modules/leasings/leasings.module').then(mod => mod.LeasingsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_FINANCING_VIEW],
        }
      },
      {
        path: 'warranties',
        loadChildren: () => import('./modules/warranties/warranties.module').then(mod => mod.WarrantiesModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_WARRANTIES_VIEW],
        }
      },
      {
        path: 'warrantiesv2',
        loadChildren: () => import('./modules/warrantiesv2/warranties.module').then(mod => mod.WarrantiesModuleV2),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_WARRANTIES_VIEW],
        }
      },
      {
        'path': 'auctions',
        redirectTo: 'trading/auctions',
      },
      {
        path: 'trading',
        loadChildren: () => import('@app/modules/trading/trading-hub.module').then(mod => mod.TradingHubModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: [PermissionEnum.MENU_GLOBAL_TRADING_HUB_VIEW],
        }
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then(mod => mod.NotificationsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'business-units',
        loadChildren: () => import('./modules/business-units/business-units.module').then(mod => mod.BusinessUnitsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        redirectTo: '/admin/dashboard',
      }
      ,
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/admin/dashboard',
      }
    ],
  },
  {
    path: 'signature',
    loadChildren: () => import('./modules/signature/signature.module').then(mod => mod.SignatureModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: PublicRootComponent,
    loadChildren: () => import('./modules/public/public.module').then(mod => mod.PublicModule),
  },
  {
    path: '403',
    component: UnauthorizedPageComponent,
    data: {
      title: 'title.pageNotFound',
    }
  },
  {
    path: 'deactivated',
    component: DeactivatedPageComponent,
    data: {
      title: 'title.pageDeactivated',
    }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: {
      title: 'title.pageNotFound',
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'title.pageNotFound',
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
