import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

export interface SpaceInvoicesDocument {
  uuid: string;
  price_gross_sum: string;
  number: string;
  reference: string;
  type: string;
  created_at: string;
  document_id: string;
}

@Component({
  selector: 'app-global-search-result-card-invoice',
  templateUrl: './global-search-result-card-invoice.component.html',
  styleUrls: ['./global-search-result-card-invoice.component.scss']
})
export class GlobalSearchResultCardInvoiceComponent {

  @Input() result: SpaceInvoicesDocument;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private router: Router) {
  }

  redirect() {
    this.onClose.emit(true);
    if (this.router.url.includes(this.result.document_id)) {
      return window.location.reload();
    } else {
      this.router.navigate([`/admin/administration/document/${this.result.document_id}`]);
    }
  }

}
