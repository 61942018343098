<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">{{ currencyField.config.label }}</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut">
        <i
                class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <hr class="mt-0 mb-0">

  <div class="grid grid-cols-1">
    <div class="px-10 pt-5">
      <label class="block" style="white-space: pre-wrap" [for]="conversionRateField.fieldUuid">
        <span class="leading-normal">{{ conversionRateField.config.label }}</span>
      </label>
    </div>
    <div class="py-5 px-4 sm:px-10 overflow-auto max-h-75vh">
      <app-form-input-currency (onKeyUp)="onKeyUp(conversionRateField.fieldUuid, $event)" [field]="conversionRateField"
                               [disabled]="isFixedConversionRate"
                               [control]="formGroup.controls[conversionRateField.fieldUuid]"
                               [currencySymbol]=" currencyField.currencyConversion.currencySymbol + ' = 1 EUR'"></app-form-input-currency>
    </div>
    <div class="flex justify-center">
      <div class="border-t w-10/12 border-grayLight"></div>
    </div>
    <div class="px-10 pt-5">
      <label class="block" style="white-space: pre-wrap" [for]="currencyField.fieldUuid">
        <span class="leading-normal">{{ currencyField.config.label }}</span>
      </label>
    </div>
    <div class="flex justify-between px-8 pb-5">
      <div class="p-2 pt-1 overflow-auto max-h-75vh">
        <app-form-input-currency (onKeyUp)="onKeyUp(currencyField.fieldUuid, $event)" [field]="currencyField"
                                 [control]="formGroup.controls[currencyField.fieldUuid]"></app-form-input-currency>
      </div>
      <div class="max-h-75vh flex flex-col justify-center">
        <i class="fas fa-exchange-alt text-grayLight"></i>
      </div>
      <div class="p-2 pt-1 overflow-auto max-h-75vh relative">
        <input type="number"
               [id]="'foreignCurrency'"
               lang="en-150"
               [disabled]="isFixedConversionRate"
               step='0.01'
               (keyup)="onKeyUp(null, $event)"
               [(ngModel)]="valueInForeignCurrency"
               autocomplete="off">

        <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark"
              style="margin-top: 0.85rem;">{{currencyField.currencyConversion.currencySymbol}}</span>
      </div>
    </div>
  </div>

  <hr class="mt-0 mb-0">
  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Prekliči</button>
      <button class="btn btn-primary ml-1" (click)="save()" [disabled]="isSubmitting || formGroup?.invalid">
        <i class="fas fa-save mr-2"></i><span i18n="Item form modal">Shrani</span>
      </button>
    </div>
  </div>
</div>
