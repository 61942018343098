import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {RefreshBrowserDialogComponent} from '../shared/dialogs/refresh-browser-dialog/refresh-browser-dialog.component';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private dialogOpened = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private httpClient: HttpClient,
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  check(forceAutomaticReload = false) {
    //  Don't do version checks if you're not in production
    if (!environment.production) {
      return;
    }

    if (this.dialogOpened) {
      return;
    }

    this.getLatestVersion().subscribe((latestVersion: { version: string }) => {
      const appVersion = this.loadAppVersion();

      //  We don't have a version yet, so we're on the latest
      if (!appVersion || appVersion.length === 0) {
        this.storeVersion(latestVersion.version);
      } else if (appVersion.toString() !== latestVersion.version.toString()) {
        if (forceAutomaticReload) {
          this.reload();
          return;
        }

        const dialogRef = RefreshBrowserDialogComponent.open(this.matDialog);
        this.dialogOpened = true;

        dialogRef.afterClosed().subscribe((result) => {
          this.dialogOpened = false;
          if (result) {
            this.reload();
          }
        });
      }
    });
  }

  private loadAppVersion() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('version');
    }

    return '';
  }

  private getLatestVersion() {
    return this.httpClient.get('/sl/assets/version.json');
  }

  private storeVersion(version: string) {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.setItem('version', version);
    }
  }

  private clearAppVersion() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.removeItem('version');
    }
  }

  private reload() {
    this.clearAppVersion();
    this.document.location.href = this.document.location.href;
  }
}
