<div class="w-full h-screen flex justify-center items-center" style="background: #F5F5F5">

  <div class="flex flex-col md:flex-row justify-center items-center w-full" style="max-width: 900px">
    <div class="grow p-4" style="flex-basis: 100px">
      <img src="assets/images/403.png" alt="404 error">
    </div>
    <div class="grow p-4" style="flex-basis: 100px">

      <h1 class="text-2xl font-bold"><span i18n>Nimate dostopa do te vsebine</span></h1>

      <p><span i18n>Če mislite, da gre za napako nas <a
        href="mailto:info@briefd.io">kontaktirajte.</a></span></p>

    </div>

  </div>
</div>
