<div>
  <p class="text-xs text-center font-bold mb-4" *ngIf="isUnsubscribe">Za konec bi vas le še vprašali, kateri je ključni
    razlog, da ste
    se odločili preklicati naročnino?</p>
  <p class="text-xs text-center font-bold mb-4" *ngIf="!isUnsubscribe">Za konec bi vas le še vprašali, kateri je ključni
    razlog, da ste
    se odločili izbrisati račun?</p>

  <p class="text-xs text-center mb-4">Vaša povratna informacija nam bo pomagala pri dodatnem razvoju AutoBrief
    platforme.</p>
  <input type="radio" id="complicated" name="feedback" value="Platforma je prezahtevna za moje delo."
         [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="complicated" class="text-xs mt-4 ml-2">Platforma je prezahtevna za moje delo.</label><br>

  <input type="radio" id="not_functional" name="feedback"
         value="Platforma nima funkcij, ki bi mi prinašale dodano vrednost."
         [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="not_functional" class="text-xs mt-4 ml-2">Platforma nima funkcij, ki bi mi prinašale dodano
    vrednost.</label><br>

  <input type="radio" id="technical_issues" name="feedback" value="Tehnične težave povezane s platformo."
         [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="technical_issues" class="text-xs mt-4 ml-2">Tehnične težave povezane s platformo.</label><br>

  <input type="radio" id="platform" name="feedback" value="Platforma je predraga."
         [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="platform" class="text-xs mt-4 ml-2">Platforma je predraga.</label><br>

  <input type="radio" id="replacement" name="feedback"
         value="Platformo želim zamenjati z drugo tehnično rešitvijo." [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="replacement" class="text-xs mt-4 ml-2">Platformo želim zamenjati z drugo tehnično
    rešitvijo.</label><br>

  <input type="radio" id="closing" name="feedback" value="Zapiram podjetje ali dejavnost."
         [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="closing" class="text-xs mt-4 ml-2">Zapiram podjetje ali dejavnost.</label><br>

  <input type="radio" id="other" name="feedback" value="Drugo" [formControl]="form.controls.feedback"
         [ngClass]="{'input-error': (form.controls.feedback.invalid && form.controls.feedback.touched) }">
  <label i18n for="other" class="text-xs mt-4 ml-2">Drugo</label><br>
  <app-field-errors [control]="form.controls.feedback"></app-field-errors>
  <textarea class="form-control mt-4" placeholder="Tukaj lahko vpišete vaš razlog..."></textarea>
</div>

<div class="mt-4 text-sm">

  <ng-container *ngIf="isUnsubscribe">
    <span class="text-center" i18n>Če se strinjate z preklicom naročnine, vnesite besedo </span><span
          class="font-bold">"CANCEL"</span><span i18n> v spodnje okno.</span>
  </ng-container>
  <ng-container *ngIf="!isUnsubscribe">
    <span class="text-center" i18n>Če se strinjate z izbrisom računa, vnesite besedo </span>&nbsp;<span
          class="font-bold">"CANCEL"</span>&nbsp;<span i18n> v spodnje okno.</span>
  </ng-container>

  <input [formControl]="form.controls.confirm"
         class="mt-1"
         placeholder="CANCEL"
         [ngClass]="{'input-error': (form.controls.confirm.invalid && form.controls.confirm.touched) }">
  <app-field-errors [control]="form.controls.confirm"></app-field-errors>
</div>


<div class="flex justify-center mt-4">
  <button class="btn btn-lg border-none text-grayDark px-4 text-sm" (click)="close()" i18n>Rad bi ostal
    uporabnik
  </button>
  <button class="btn btn-primary ml-4 px-4 text-sm" [disabled]="form.invalid"
          (click)="save()">
    <span *ngIf="isUnsubscribe" i18n>Prekliči naročnino</span>
    <span *ngIf="!isUnsubscribe" i18n>Želim izbrisati račun</span>

  </button>
</div>
