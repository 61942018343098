import {Pipe, PipeTransform} from '@angular/core';
import {firstValue} from "@app/util/object.helper";

@Pipe({
  name: 'firstvalue'
})
export class FirstValuePipe implements PipeTransform {

  transform(object) {
    if (object) {
      return firstValue(object);
    }
    return object;
  }
}
