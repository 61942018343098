<div class="relative">

  <input type="text"
         [formControl]="control"
         [attr.aria-label]="field.config.label"
         [ngClass]="{ 'input-error': control.invalid }"
         placeholder="{{ field.config.placeholder }}"
         maxlength="{{ field.config.max }}"
         minlength="{{ field.config.min }}"
         autocomplete="off"
         [attr.data-field]="field?.fieldUuid"
         [attr.data-typeId]="field?.config?.typeId"
         [attr.tabIndex]="0">
  <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark" style="margin-top: 0.85rem;"
        *ngIf="field.config.append">{{ field.config.append}}</span>
</div>

<div *ngIf="field.config.presets" class="flex flex-row flex-wrap mt-3">
  <button *ngFor="let preset of field.config.presets; let i = index"
          class="focus:outline-none focus:border-primary text-sm rounded-full px-3 py-2 mx-1 border border-grayLight font-semibold text-primary mb-2"
          [ngClass]="{'mr-1': i < field.config.presets.length - 1, 'ml-1': i > 0}"
          (click)="setPreset(preset)"
  >{{ preset }}</button>
</div>
