<div class="page h-screen" style="background: #F5F5F5">

  <ng-template #showNotFound>
    <app-not-found-error></app-not-found-error>
  </ng-template>

  <div class="w-full mx-auto md:w-10/12 lg:w-2/3" *ngIf="!isNotFound; else showNotFound">

    <!--   <div class="my-5">
      <a href="/a/{{account.accountUuid}}"><i class="fa fa-chevron-left"></i> Nazaj na vsa vozila</a>
    </div> -->

    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-2/3 md:mr-2 mb-3 md:mb-0">
        <div class="card bg-white">

          <ng-container *ngIf="photos && photos.value.length > 0">
            <div>

              <div class="mb-3">
                <img [src]="addParameters(photos.value[0].files?.[0]?.conversions.large, photos.fieldUuid)" alt=""
                     (click)="openLightbox(photos, 0)"
                     loading="lazy"
                     class="w-full cursor-pointer">
              </div>

              <div class="px-5 pt-4">
                <div class="flex flex-row flex-wrap">
                  <ng-container *ngFor="let upload of photos.value.slice(0, 9); let i = index">
                    <div class="w-1/5 p-1">
                      <img [src]="addParameters(upload.files?.[0]?.conversions.thumbnail, photos.fieldUuid)" alt=""
                           loading="lazy"
                           (click)="openLightbox(photos, i)"
                           class="w-full cursor-pointer">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="photos.value.length >= 10">
                    <div class="w-1/5 p-1 relative">
                      <img [src]="addParameters(photos.value[9].files?.[0]?.conversions.thumbnail, photos.fieldUuid)"
                           alt=""
                           loading="lazy"
                           (click)="openLightbox(photos, 9)"
                           class="w-full cursor-pointer">

                      <div
                        class="absolute top-0 bottom-0 left-0 right-0 bg-black flex justify-center items-center text-center p-1 opacity-85 pointer-events-none">
                        <span class="text-sm text-white font-bold"><span
                          class="mr-1">+</span>{{ photos.value.length - 9}}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

          </ng-container>

          <div class="p-4">
            <ng-container *ngIf="isPageLoading">
              <ng-container *ngTemplateOutlet="shimmerContent"></ng-container>
            </ng-container>

            <!-- TODO: Extract this as a non editable value table  -->
            <div *ngFor="let section of sections; let i = index" [ngClass]="{'mb-6': i < sections.length - 1}">
              <div *ngIf="section.items.length >= 1">
                <div class="flex flex-row flex-wrap justify-between items-center mb-3">
                  <h6 class="text-sm text-grayDark ">{{ section.title }}</h6>
                </div>
              </div>

              <ng-container *ngFor="let field of section.items">

                <div class="border border-grayTableBorder border-b-0" style="margin-top: -1px">
                  <div class="flex flex-col md:flex-row border-grayTableBorder border-b">
                    <div
                      class="w-full md:w-1/4 bg-grayNavi border-b md:border-b-0 md:border-r border-grayTableBorder p-4 shrink-0"
                      style="min-width: 250px">
                      <span class="overline font-xs text-grayDark"
                            style="padding-top: 1px">{{ field.config.label}} <span *ngIf="field.config.description"
                                                                                   class="text-primary"
                                                                                   matTooltipClass="bg-black px-4 py-2 text-white text-sm"
                                                                                   [matTooltip]="field.config.description"><i
                        class="fas fa-info-circle"></i></span></span>
                    </div>
                    <div class="grow max-w-full overflow-x-auto">
                      <div class="text-black text-sm">

                        <div class="flex flex-row">
                          <div class="grow p-4">
                            <app-field-table-value [field]="field"></app-field-table-value>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/3 md:ml-2">
        <div class="card bg-white">
          <div class="p-4">
            <ng-container *ngIf="isPageLoading; else showCompany">
              <ng-container *ngTemplateOutlet="shimmerCompany"></ng-container>
            </ng-container>

            <ng-template #showCompany>
              <app-public-vehicle-price-widget [vehicle]="vehicle"></app-public-vehicle-price-widget>

              <ng-container *ngIf="account.name || account.address || account.contacts">
                <hr>
                <app-public-account-widget [account]="account"></app-public-account-widget>
              </ng-container>

              <hr>

              <app-share-vehicle-widget [url]="shareUrl"></app-share-vehicle-widget>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #shimmerCompany>
  <div class="mb-3">
    <app-content-shimmer height="2rem"></app-content-shimmer>
  </div>
  <div class="mb-3">
    <app-content-shimmer height="2rem"></app-content-shimmer>
  </div>
  <div class="mb-3">
    <app-content-shimmer height="6rem"></app-content-shimmer>
  </div>
  <div class="">
    <app-content-shimmer height="8rem"></app-content-shimmer>
  </div>
</ng-template>

<ng-template #shimmerContent>
  <div class="mb-4">
    <app-content-shimmer height="14rem"></app-content-shimmer>
  </div>
  <div class="mb-4">
    <app-content-shimmer height="6rem"></app-content-shimmer>
  </div>
  <div class="mb-4">
    <app-content-shimmer height="6rem"></app-content-shimmer>
  </div>

  <div class="flex flex-row">
    <div class="w-1/2 mr-1">
      <app-content-shimmer height="30rem"></app-content-shimmer>
    </div>
    <div class="w-1/2 ml-1">
      <app-content-shimmer height="30rem"></app-content-shimmer>
    </div>
  </div>
</ng-template>
