  <label class="leading-normal flex mt-2 items-start" style="min-height: 2rem">
    <input
      class="mr-2 form-checkbox text-green h-5 w-5"
      type="checkbox"
      style="display: inline; margin-top: 1px;"
      [formControl]="control"
      [ngClass]="{ 'input-error': control.invalid }"
      [attr.aria-label]="field.config.label"
        [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId"
      [attr.tabIndex]="0"
    />

    <span class="leading-normal" *ngIf="!hideLabel" [innerHTML]="field.config.label">
    </span>

    <app-tooltip class="ml-1" [content]="field.config.description"></app-tooltip>
  </label>
