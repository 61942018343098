import { productFruits } from 'product-fruits';
import {ApplicationRef, enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from '@app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import {enableDebugTools} from "@angular/platform-browser";

if (environment.production) {
  Sentry.init({
    environment: environment.name,
    release: environment.version,
    // Ignore HttpErrorResponse errors
    ignoreErrors: ['Non-Error exception captured'],
    dsn: 'https://6398376691bd4ab28f15dd0ebb75a4fa@o468863.ingest.sentry.io/5498752',
    integrations: [
      new Sentry.BrowserTracing(),
      // new Sentry.Replay({
      //   _experiments: {
      //     mutationBreadcrumbLimit: 1000,
      //     mutationLimit: 1000,
      //   }
      // })
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });

  if (!localStorage.getItem('debug')) {
    enableProdMode();
  }
}

function main() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  main()
} else {
  document.addEventListener('DOMContentLoaded', main);
}
