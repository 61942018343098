import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {Field} from '../../../../interfaces';

@Component({
  selector: 'app-form-input-mileage',
  templateUrl: './form-input-mileage.component.html',
  styleUrls: ['./form-input-mileage.component.scss']
})
export class FormInputMileageComponent {
  @Input() control: AbstractControl;

  @Input() field: Field<number>;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
