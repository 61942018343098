import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ActivatedRoute} from '@angular/router';
import {SelectFilesDialogComponent} from '../select-files-dialog/select-files-dialog.component';

@Component({
  selector: 'app-share-ad-dialog',
  templateUrl: './select-file-upload-from-dialog.component.html',
  styleUrls: ['./select-file-upload-from-dialog.component.scss']
})
export class SelectFileUploadFromDialogComponent implements OnInit {

  private vehicleUuid: string;
  private clientUuid: string;
  private result: Array<object>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<unknown>) {
    data.route.params.subscribe(params => {
      this.vehicleUuid = params.vehicleUuid;
      this.clientUuid = params.clientUuid;
    });

  }

  static open(dialog: MatDialog, route: ActivatedRoute) {
    return dialog.open(SelectFileUploadFromDialogComponent, {
      width: '600px',
            data: {
        route
      }
    });
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(this.result);
  }

  showCarExistingFilesDialog() {
    const fileTypeNames = {
      'Druge datoteke': 'briefd.vehicle.uploaded_files',
      'CoC datoteke': 'briefd.vehicle.coc_files'
    };
    const dialog = SelectFilesDialogComponent.open(this.dialog, fileTypeNames, 'vehicle', this.vehicleUuid);

    dialog.afterClosed().subscribe((result) => {
      this.result = result;
      this.close();
    });
  }

  showCustomerExistingFilesDialog() {
    const fileTypeNames = {
      'Druge datoteke': 'briefd.client.uploaded_files',
    };
    const dialog = SelectFilesDialogComponent.open(this.dialog, fileTypeNames, 'client', this.clientUuid);

    dialog.afterClosed().subscribe((result) => {
      this.result = result;
      this.close();
    });
  }
}
