<div class="px-4 py-4">
  <h3 i18n="Left sidebar menu item title" class="overline text-gray">Administracija</h3>
</div>

<ng-container *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_DASHBOARD_VIEW]">
  <a [routerLink]="['/admin/administration/dashboard']" i18n-title title="Namizje"
     *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
     [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_DASHBOARD_VIEW"
     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
    <div class="px-4 text-lg w-12">
      <i class="fas fa-th-large opacity-75"></i>
    </div>
    <span i18n class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">Namizje</span>
  </a>
</ng-container>

<button
        *appIfPermission="[
        PermissionEnum.MENU_ADMINISTRATION_INVOICES_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_PROFORMAS_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_ADVANCES_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_CREDIT_NOTES_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_PAYMENTS_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_CASH_RECEIPTS_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_EXPORTS_VIEW
        ]"
        class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut w-full"
        (click)="toggleInvoices()"
        [routerLinkActive]="'active text-greenDarkOlive'">

  <div class="px-4 text-lg w-12">
    <i class="fas fa-file-invoice opacity-75"></i>
  </div>
  <span i18n class="font-bold pl-1 pr-3 relative text-sm grow text-left" style="top: 2px">Fakture</span>

  <div class="px-2 grow-0">
    <ng-container *ngIf="!opened">
      <div>
        <i class="fas fa-chevron-down"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="opened">
      <div>
        <i class="fas fa-chevron-up"></i>
      </div>
    </ng-container>
  </div>

</button>

<div class="ml-8" *ngIf="opened">
  <a [routerLink]="['/admin/administration/invoices']" i18n-title title="Računi"
     *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_INVOICES_VIEW]"
     [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_INVOICES_VIEW"
     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
     [queryParams]="{subType:'all'}"
     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
    <span i18n class="pl-5 pr-3 relative text-sm" style="top: 2px">Računi</span>
  </a>

  <a [routerLink]="['/admin/administration/estimates']" i18n-title title="Predračuni"
     *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_PROFORMAS_VIEW]"
     [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_PROFORMAS_VIEW"
     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
    <span i18n class="pl-5 pr-3 relative text-sm" style="top: 2px">Predračuni</span>
  </a>

  <ng-container *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_ADVANCES_VIEW]">
    <a [routerLink]="['/admin/administration/advances']" i18n-title title="Avansi"
       [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_ADVANCES_VIEW"
       *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
       routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
       class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
      <span i18n class="pl-5 pr-3 relative text-sm" style="top: 2px">Avansi</span>
    </a>
  </ng-container>
  <ng-container *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_CREDIT_NOTES_VIEW]">
    <a [routerLink]="['/admin/administration/credit-note']" i18n-title title="Dobropisi"
       [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_CREDIT_NOTES_VIEW"
       *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
       routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
       class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
      <span i18n class="pl-5 pr-3 relative text-sm" style="top: 2px">Dobropisi</span>
    </a>
  </ng-container>
</div>

<ng-container *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_PAYMENTS_VIEW]">
  <a [routerLink]="['/admin/administration/payments']" i18n-title title="Plačila"
     *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
     [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_PAYMENTS_VIEW"
     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
    <div class="px-4 text-lg w-12">
      <i class="fas fa-file-alt opacity-75"></i>
    </div>
    <span i18n class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">Plačila</span>
  </a>
</ng-container>

<button
        *appIfPermission="[
        PermissionEnum.MENU_ADMINISTRATION_CASH_RECEIPTS_VIEW,
        PermissionEnum.MENU_ADMINISTRATION_CASH_EXPENDITURES_VIEW
        ]"
        class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut w-full"
        [routerLinkActive]="'active text-greenDarkOlive'">

  <div class="px-4 text-lg w-12">
    <i class="fas fa-file-alt opacity-75"></i>
  </div>
  <span i18n class="font-bold pl-1 pr-3 relative text-sm grow text-left" style="top: 2px">Blagajna</span>

  <div class="px-2 grow-0">
    <ng-container *ngIf="!opened">
      <div>
        <i class="fas fa-chevron-down"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="opened">
      <div>
        <i class="fas fa-chevron-up"></i>
      </div>
    </ng-container>
  </div>

</button>

<a [routerLink]="['/admin/administration/cash-receipts']" i18n-title title="Blagajniški prejemki"
   [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_CASH_RECEIPTS_VIEW"
   *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
   routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
   class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut ml-8">
  <span i18n class="pl-5 pr-3 relative text-sm" style="top: 1px">Blagajniški prejemki</span>
</a>


<a [routerLink]="['/admin/administration/cash-expenditures']" i18n-title title="Blagajniški izdatki"
   *appIfAccountFeatureFlag="[]; hideFor:[AccountFlagEnum.LIMITED_ADMINISTRATION]"
   [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_CASH_EXPENDITURES_VIEW"
   routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
   class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut ml-8">
  <span i18n class="pl-5 pr-3 relative text-sm" style="top: 2px">Blagajniški izdatki</span>
</a>

<ng-container *appIfPermission="[PermissionEnum.MENU_ADMINISTRATION_EXPORTS_VIEW]">
  <a [routerLink]="['/admin/administration/exports']" i18n-title title="Izvoz faktur"
     [attr.data-test]="PermissionEnum.MENU_ADMINISTRATION_EXPORTS_VIEW"
     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
    <div class="px-4 text-lg w-12">
      <i class="fas fa-file-download opacity-75"></i>
    </div>
    <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
          style="top: 2px">Izvoz faktur</span>
  </a>
</ng-container>
