<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">{{ field.config.label }}</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <hr class="mb-0 mt-0">


    <div class="overflow-y-auto p-3 sm:p-5 max-h-75vh">
      <app-form-input-repeater [field]="field" [control]="formGroup.controls[field.fieldUuid]" [hideExcept]="item"></app-form-input-repeater>
    </div>

    <hr class="mb-0 mt-0">
  <div class="card-content">
    <div class="flex flex-col sm:flex-row justify-center">
      <button class="btn btn-grayDark-link mr-0 sm:mr-1" (click)="close()" i18n>Prekliči</button>
      <button class="btn btn-primary-outline ml-0 sm:ml-1 mt-3 sm:mt-0" (click)="save(false)" *ngIf="!isEditingExistingItem"
              [disabled]="isSubmitting || item?.invalid"><i class="fas fa-save mr-2" data-test="save-button"></i><span i18n="Repeater item form modal">Shrani</span>
      </button>
      <button class="btn btn-primary ml-0 sm:ml-1 mt-3 sm:mt-0" (click)="save(true)" *ngIf="!isEditingExistingItem && !hideNextAdd"
              [disabled]="isSubmitting || item?.invalid"><i class="fas fa-plus mr-2"></i><span i18n="Repeater item form modal">Dodaj naslednje</span>
      </button>
      <button class="btn btn-primary ml-0 sm:ml-1 mt-3 sm:mt-0" (click)="save(false)" i18n="Repeater item form modal" *ngIf="isEditingExistingItem"
              [disabled]="isSubmitting || item?.invalid">Posodobi
      </button>
    </div>
  </div>
</div>
