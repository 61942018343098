<ng-container *ngIf="account">
  <div>
    <h4 class="text-base text-base leading-normal font-bold mb-5">{{ account.name }}</h4>

    <div class="text-gray text-sm">

      <div class="mb-4" *ngIf="account.address">
        <i class="fas fa-home mr-2 text-base"></i><span
        class="text-black text-sm">{{ account.address }}</span>
      </div>

      <ng-container *ngFor="let contact of account.contacts">
        <ng-container [ngSwitch]="contact.type">
          <div class="mb-4">

            <ng-template ngSwitchCase="phone">
              <i class="fas fa-phone mr-2 text-base text-gray"></i>
              <a [href]="'tel:' + contact.value" target="_blank"
                 class="text-black hover:no-underline hover:text-primary no-transition">
                <span class="">{{ contact.label }}</span>
              </a>
            </ng-template>

            <ng-template ngSwitchCase="email">
              <i class="fas fa-envelope mr-2 text-base text-gray"></i>
              <a [href]="'mailto:' + contact.value" target="_blank"
                 class="text-black hover:no-underline hover:text-primary no-transition">
                <span class="">{{ contact.label }}</span>
              </a>
            </ng-template>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
