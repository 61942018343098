import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Field} from '@app/interfaces';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NotificationService} from '@app/services/notification.service';
import {FormHelperService} from '@app/util/form-helper.service';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-repeater-item-form-dialog',
  templateUrl: './repeater-item-form-dialog.component.html',
  styleUrls: ['./repeater-item-form-dialog.component.scss']
})
export class RepeaterItemFormDialogComponent implements OnInit {

  public field: Field;
  public item: any;

  public isSubmitting = false;
  public isEditingExistingItem = false;

  public formGroup: FormGroup;

  private entityApi: { patchField: (entityUuid: string, fieldUuid: string, values: unknown) => Subject<unknown> };
  private entityUuid: string;

  public hideNextAdd = false;
  private modalData: any;
  private subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              public dialogRef: MatDialogRef<unknown>,
              private notificationService: NotificationService
  ) {
    this.entityApi = data.entityApi;
    this.entityUuid = data.entityUuid;
    this.modalData = data;

    const controls = {};
    controls[data.field.fieldUuid] = this.formHelperService.buildRepeater({...data.field}, this.formBuilder.array([]));

    this.formGroup = this.formBuilder.group(controls);

    if (data.item) {
      this.item = (this.formGroup.controls[data.field.fieldUuid] as FormArray).controls.find(
        (element: FormGroup) => element.controls.wrappedItemUuid.value === data.item.wrappedItemUuid
      );
      this.isEditingExistingItem = true;
    }

    this.field = {...data.field};

    //  We're adding a new item!
    if (!this.item) {
      this.addRepeaterItem();
    }

    this.subscription.add(this.modalData?.onFormGroupSetup?.(this.field, this.formGroup));
  }

  static open(dialog: MatDialog,
              entityUuid: string,
              entityApi: any,
              field: Field<any>,
              item: any,
              onFormGroupSetup?: (repeaterField: Field<unknown>, formGroup: FormGroup) => Subscription
  ) {
    return dialog.open(RepeaterItemFormDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        entityUuid,
        entityApi,
        field,
        item,
        onFormGroupSetup
      }
    });
  }

  ngOnInit(): void {
  }

  public setValue(id: string, value: any) {
    if (this.item.controls.value.controls[id]) {
      this.item.controls.value.controls[id].setValue(value, {emitEvent: false});
    }
    const fieldUuid = Object.keys(this.field.config.subFields).find((a) => this.field.config.subFields[a].config.typeId === id);
    if (fieldUuid) {
      this.item.controls.value.controls[fieldUuid].setValue(value, {emitEvent: false});
    }
  }

  addRepeaterItem() {
    // @ts-ignore
    this.item = this.formHelperService.prepareEmptyRepeaterItem(this.field);
    (this.formGroup.controls[this.field.fieldUuid] as FormArray).push(this.item);
  }

  close() {
    this.subscription.unsubscribe();
    this.dialogRef.close(false);
  }

  save(shouldReopen: boolean) {
    this.isSubmitting = true;
    this.formGroup.disable();

    const values = this.formHelperService.getValuesFromTabbedForm(this.formGroup);

    this.entityApi.patchField(this.entityUuid, this.field.fieldUuid, values)
      .subscribe((result) => {
        this.subscription.unsubscribe();
        this.dialogRef.close({result, shouldReopen});
      }, (error) => {
        this.isSubmitting = false;
        this.formGroup.enable();
        this.notificationService.showErrorAlert();
      });
  }

  hideAddNext() {
    this.hideNextAdd = true;
  }
}
