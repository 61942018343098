import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '../../../../interfaces';

@Component({
  selector: 'app-form-input-text-description',
  templateUrl: './form-input-text-description.component.html',
  styleUrls: ['./form-input-text-description.component.scss']
})
export class FormInputTextDescriptionComponent {
  @Input() control: AbstractControl;

  @Input() field: Field<string>;

  setPreset(preset: string) {
    this.control.setValue(preset);
  }
}
