<form [formGroup]="form">
  <div class="mb-3">
    <label for="linkToEntity" class="block">{{ 'dialogs.fieldFormInputs.attachToVehicle'  }}
      <input type="checkbox" name="linkToEntity" id="linkToEntity" formControlName="linkToEntity" autocomplete="off">
    </label>
    <p class="mt-2 text-xs italic mb-4">{{ 'dialogs.fieldFormInputs.attachToVehicleDescription'  }}</p>
  </div>
  <div class="mb-3">
    <label for="isVisible" class="block">Ali je polje vidno na strani ?
      <input type="checkbox" name="vehicle" id="isVisible" formControlName="isVisible" autocomplete="off">
    </label>
    <p class="mt-2 text-xs italic mb-4">Polje bo v vsakem primeru vidno v obrazcih.</p>
  </div>
  <div class="mb-3">
    <label for="label" class="block">{{ 'fields.label'  }}
      <input type="text" name="label" id="label" formControlName="label" autocomplete="off"
             [ngClass]="{'input-error': (form.controls.label.invalid) }">
    </label>

    <p class="input-error-message mt-1" *ngIf="form.controls.label.invalid">
      {{ 'validation.required'  }}
    </p>
  </div>

  <div class="mb-3">
    <label for="isRequired" class="block">is Required
      <input type="checkbox" name="isRequired" id="isRequired" formControlName="isRequired" autocomplete="off">
    </label>
  </div>

  <div class="mb-3">
    <label for="typeId" class="block">ID polja
      <input type="text" name="typeId" id="typeId" formControlName="typeId" autocomplete="off">
    </label>
  </div>

  <div class="mb-3">
    <label for="description" class="block">{{ 'fields.description'  }}
      <textarea type="text" name="description" id="description" formControlName="description"
                autocomplete="off"></textarea>
    </label>
  </div>

  <div class="mb-3">
    <label for="prepend" class="block">{{ 'fields.prepend'  }}
      <input type="text" name="prepend" id="prepend" formControlName="prepend"
                autocomplete="off">
    </label>
  </div>

  <div class="mb-3">
    <label for="prepend" class="block">{{ 'fields.append'  }}
      <input type="text" name="append" id="append" formControlName="append"
                autocomplete="off">
    </label>
  </div>

  <div class="mb-3">
    <label for="placeholder" class="block">Placeholder
      <input type="text" name="placeholder" id="placeholder" formControlName="placeholder"
      autocomplete="off">
    </label>
  </div>


  <ng-container [ngSwitch]="template.type">
    <ng-template [ngSwitchCase]="'dropdown'">
      <div class="mb-3">
        <table class="mt-6">
          <tr>
            <th class="py-3 px-4"></th>
            <th class="py-3 px-4">{{ 'dialogs.fieldFormInputs.key' }}</th>
            <th class="py-3 px-4">{{ 'dialogs.fieldFormInputs.label'  }}</th>
          </tr>
          <tr *ngFor="let choice of choices">
            <td style="width: 100px" class="py-3 px-4 text-center">
              <div class="p-3 cursor-pointer hover:text-red" (click)="removeChoice(choice)">
                <i class="fas fa-times"></i>
              </div>
            </td>
            <td class="py-3 px-4">
              <label for="value" class="block">
                <input class="m-0" type="text" name="value" id="value" autocomplete="off"
                       [(ngModel)]="choice.value"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{'input-error': !choice.value }">
              </label>

              <p class="input-error-message mt-1" *ngIf="!choice.value">
                {{ 'validation.required'  }}
              </p>
            </td>
            <td class="py-3 px-4">
              <label for="choiceLabel" class="block">
                <input class="m-0" type="text" name="choiceLabel" id="choiceLabel" autocomplete="off"
                       [(ngModel)]="choice.label"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{'input-error': !choice.label }">
              </label>

              <p class="input-error-message mt-1" *ngIf="!choice.label">
                {{ 'validation.required'  }}
              </p>
            </td>
          </tr>
        </table>

        <div class="text-right my-3">
          <button class="btn btn-sm btn-primary font-bold" (click)="addChoice()"><i
            class="fas fa-plus"></i> {{ 'dialogs.fieldFormInputs.option'  }}
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'mileage'">
      <div class="mb-3">
        <label for="mileage" class="block">{{ 'fields.unit'  }}
          <input type="text" name="mileage" id="mileage" autocomplete="off" formControlName="mileage">
        </label>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'currency'">
      <div class="mb-3">
        <label for="currency" class="block">{{ 'fields.currency'  }}
          <input type="text" name="currency" id="currency" autocomplete="off" formControlName="currency">
        </label>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'silhouette_photos'">

      <div class="mb-3">
        <div>

          <div class="text-center" *ngIf="upload">

            <img height="150" width="150" class="bg-white cursor-pointer shadow-lg p-3 w-full object-cover"
                 [src]="upload.files?.[0]?.conversions.thumbnail" alt="" (click)="openLightbox([upload], 0)">
          </div>

          <div class="my-6 text-center">
            <button class="btn btn-primary"
                    (click)="uploadSilhouette()">{{ 'dialogs.fieldFormInputs.uploadSilhouette'  }}</button>
          </div>

        </div>
      </div>
    </ng-template>

  </ng-container>
</form>
