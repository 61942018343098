import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {Observable, Subject} from 'rxjs';
import {CurrencyConversionObject} from '@app/services/businessOverview/business-overview-stats.service';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-form-input-percent',
  templateUrl: './form-input-percent.component.html',
  styleUrls: ['./form-input-percent.component.scss']
})
export class FormInputPercentComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() controls: Array<AbstractControl>;

  @Input() field: Field<string>;
  private connectedFieldValue: any;
  public calculatedValue: number;

  private destroy$: Subject<void> = new Subject<void>();
  private currencyConversion: CurrencyConversionObject;
  accountConfig$: Observable<AccountConfig>;

  constructor(
    private configService: ConfigAPIService
  ) {
  }

  ngOnInit() {
    this.accountConfig$ = this.configService.getAccountConfig().pipe(takeUntil(this.destroy$));

    this.configService.getAccountConfig().pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.currencyConversion = result.currencyConversion;
        const connectedFieldControl = this.controls[this.field.config.connected_field_uuid];
        this.connectedFieldValue = connectedFieldControl.value * this.currencyConversion.latest_rate;

        connectedFieldControl.valueChanges.subscribe(x => {
          this.connectedFieldValue = x * this.currencyConversion.latest_rate;
          this.calculateValueFromPercent();
        });
      });

    this.calculateValueFromPercent();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  calculateValueFromPercent() {
    this.calculatedValue = Math.round(this.connectedFieldValue * this.control.value) / 100;
  }

  onPercentFieldChange(event: any) {
    if (this.connectedFieldValue !== 0) {
      this.field.value = Number(event.target.value).toFixed(2);
      this.control.setValue(Number(event.target.value));

      this.calculateValueFromPercent();
    }
  }

  onRawFieldChange(event: any) {
    if (this.connectedFieldValue !== 0) {
      const value = (((event.target.value) / this.connectedFieldValue)) * 100;
      this.field.value = Number(value).toFixed(2);
      this.control.setValue(Number(value));
      this.calculateValueFromPercent();
    }
  }
}
