import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SafariScreenFixService {

  private isSafari = false;

  constructor() { }

  setIsSafari(isSafari: boolean) {
    this.isSafari = isSafari;
  }
  getIsSafari(){
    return this.isSafari;
  }
}
