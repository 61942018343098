import {IntegrationTypeEnum} from "@app/enums/IntegrationTypeEnum";

export interface Integration<Config=Record<string, string | number>> {
  integrationUuid: string;
  isActive: boolean;
  isVisibleToTheUser: boolean;
  config: Config | null;
  type: string;
  integrationCategories: string[];
  extra?: any;
}

export const IntegrationsEnum = IntegrationTypeEnum;

export interface Connections {
  connectionUuid?: string;
  dealershipName?: string;
  businessUnitUuid?: string;
  accessToken?: string;
}
