import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {ProductPackageSubscriptionTypeEnum} from '@app/enums/ProductPackageSubscriptionTypeEnum';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DeleteAccountDialogComponent} from '@app/dialogs/delete-account-dialog/delete-account-dialog.component';

@Component({
  selector: 'app-packages-page',
  templateUrl: './packages-page.component.html',
  styleUrls: ['./packages-page.component.scss']
})
export class PackagesPageComponent implements OnInit, OnDestroy {

  @Input() showAll: boolean = false;

  protected readonly ProductPackageSubscriptionTypeEnum = ProductPackageSubscriptionTypeEnum;

  public allPackages: ProductPackage[];
  public activePackage?: AccountProductPackage;
  public subscriptionType: ProductPackageSubscriptionTypeEnum = ProductPackageSubscriptionTypeEnum.YEARLY;
  public canChangeSubscriptionType: boolean = false;
  public showChangeSubscriptionWarning: boolean = false;

  public isLoading: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(private productPackagesService: ProductPackagesService,
              private configApi: ConfigAPIService,
              private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.configApi.getAccountConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.activePackage = data.activeAccountProductPackage;
        if (this.activePackage) {
          this.subscriptionType = this.activePackage.subscriptionType === ProductPackageSubscriptionTypeEnum.YEARLY ? ProductPackageSubscriptionTypeEnum.YEARLY : ProductPackageSubscriptionTypeEnum.MONTHLY;
          this.canChangeSubscriptionType = this.showAll || !(this.activePackage.subscriptionType === ProductPackageSubscriptionTypeEnum.YEARLY && this.activePackage.priceYearly);
        } else {
          this.subscriptionType = ProductPackageSubscriptionTypeEnum.MONTHLY;
          this.canChangeSubscriptionType = this.showAll;
        }

        const packages$ = this.showAll ? this.productPackagesService.getAllProductPackages() : this.productPackagesService.getProductPackagesForChange();
        packages$
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            this.allPackages = result.data;
            this.isLoading = false;
          });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleSubscriptionType() {
    if (this.canChangeSubscriptionType) {
      this.subscriptionType = this.subscriptionType === ProductPackageSubscriptionTypeEnum.YEARLY ? ProductPackageSubscriptionTypeEnum.MONTHLY : ProductPackageSubscriptionTypeEnum.YEARLY;
    } else {
      this.showChangeSubscriptionWarning = true;
    }
  }

  deleteAccount(activePackage: AccountProductPackage) {
    DeleteAccountDialogComponent.open(this.matDialog, activePackage);
  }
}
