import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {FormGroup} from '@angular/forms';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {NotificationService} from '@app/services/notification.service';
import {Subject} from 'rxjs';
import {UnsubscribeDialogStepsEnum} from '@app/enums/UnsubscribeDialogStepsEnum';

@Component({
  selector: 'app-confirm-unsubscribe-dialog',
  templateUrl: './confirm-unsubscribe-dialog.component.html',
  styleUrls: ['./confirm-unsubscribe-dialog.component.scss']
})
export class ConfirmUnsubscribeDialogComponent implements OnDestroy {
  public productPackage: ProductPackage;
  step: UnsubscribeDialogStepsEnum = UnsubscribeDialogStepsEnum.WARNING;
  protected readonly UnsubscribeDialogStepsEnum = UnsubscribeDialogStepsEnum;
  private destroy$ = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private productPackagesService: ProductPackagesService,
              private notificationService: NotificationService,
              public dialogRef: MatDialogRef<unknown>) {
    this.productPackage = data.productPackage;
  }

  static open(dialog: MatDialog, productPackage: ProductPackage) {
    return dialog.open(ConfirmUnsubscribeDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        productPackage
      }
    });
  }

  nextStep() {
    this.step++;
  }

  confirm(formGroup: FormGroup) {
    this.dialogRef.close(this.getFeedbackFromFormGroup(formGroup));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private getFeedbackFromFormGroup(formGroup: FormGroup) {
    let feedback = formGroup.controls.feedback.value === 'other' ?
      formGroup.controls.otherOption.value :
      formGroup.controls.feedback.value;

    return {
      feedback: feedback,
    };
  }

  close() {
    this.dialogRef.close(false);
  }
}
