<div class="flex">
    <div class="dropdown flex-auto w-10/12">
        <select [formControl]="control" autocomplete="off" [ngClass]="{'input-error': control.invalid }"   [attr.data-field]="field?.fieldUuid"
                [attr.data-typeId]="field?.config?.typeId" [attr.tabIndex]="0">
            <option value="" *ngIf="field.config.allowNull !== false || !field.config.hasOwnProperty('allowNull')"></option>
            <option *ngFor="let optionValue of dropdownOptions$ | async "
                    [ngValue]="optionValue"> {{ optionValue['organization_name'] }}
            </option>
        </select>
    </div>
    <div class="flex-auto w-2/12 text-right">
        <button class="btn btn-primary-outline" (click)="quickAddClient()">
            <i class="fa fa-plus"></i>
        </button>
    </div>
</div>
