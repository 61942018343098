<div class="flex flex-row leading-snug items-left md:items-center flex-col mb-0">
  <div class="flex flex-wrap mb-0" [ngClass]="{'flex-col': !directionRow, 'flex-row': directionRow}">
    <div *ngIf="registrationNumber">
      <app-identification
              matTooltipClass="bg-black px-4 py-2 text-white text-sm"
              matTooltip="Reg. številka" i18n-matTooltip="Vehicle identification"
              class="inline-block" mode="registration" [ngClass]="{'mb-2 mr-2' : vinNumber}"
              [identifier]="registrationNumber">
      </app-identification>
    </div>

    <div *ngIf="vinNumber">
      <app-identification
              matTooltipClass="bg-black px-4 py-2 text-white text-sm"
              matTooltip="Številka šasije (VIN)" i18n-matTooltip="Vehicle identification"
              class="inline-block" [identifier]="vinNumber"></app-identification>
    </div>

    <div *ngIf="internalId">
      <app-identification
              matTooltipClass="bg-black px-4 py-2 text-white text-sm"
              matTooltip="Interna številka" i18n-matTooltip="Vehicle internal number"
              mode="internal_number"
              class="inline-block" [identifier]="internalId"></app-identification>
    </div>
  </div>
</div>
