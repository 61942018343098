<div [ngSwitch]="field.type" [attr.data-test]="field.config.typeId+'_value'">
  <ng-template [ngSwitchCase]="'boolean'">
    <input type="checkbox" class="form-checkbox text-grayLight" disabled="disabled" [(ngModel)]="field.value">
  </ng-template>

  <ng-template [ngSwitchCase]="'multi_checkbox'">
    <ng-container *ngTemplateOutlet="multiCheckbox"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'vehicle_model'">
    <div class="block" *ngIf="field.value">
      {{ vehicleName  }}
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'date'">
    <div class="block"
         *ngIf="field.value">{{ field.value | date:'dd. LL. yyyy' }}
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'color'">
    <div class="block"
         *ngIf="field.value">
      <app-color [color]="field.value"></app-color>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'repeater'">
    <div class="block" *ngIf="field.value">
      <div class="flex flex-row justify-between items-center text-primary cursor-pointer" (click)="toggleIsOpened()"
           [ngClass]="{'pb-3': isOpened}" *ngIf="field.value && field.value.length > 0">
        <button class="text-sm" *ngIf="!isOpened" i18n>Prikaži</button>
        <button class="text-sm" *ngIf="isOpened" i18n>Skrij</button>

        <div [ngClass]="{'fa-rotate-180': isOpened}" class="mr-1">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>

      <ng-container *ngIf="isOpened">
        <div *ngFor="let wrappedItem of field.value; let i = index"
             class="border border-grayTableBorder bg-grayNavi rounded p-3"
             [ngClass]="{'mb-3': i < field.value.length - 1}">

          <div *ngFor="let fieldValue of wrappedItem.value ;let j = index">
            <label class="text-xs font-medium">{{ field.config.subFields[fieldValue.fieldUuid].config.label}}</label>

            <div class="mt-2 text-black text-sm">
              <app-field-table-value
                      [field]="toRepeaterField(field.config.subFields[fieldValue.fieldUuid], fieldValue.value)"
                      *ngIf="fieldValue.value !== null"></app-field-table-value>
              <span *ngIf="fieldValue.value === null">-</span>
            </div>

            <hr class="my-2" *ngIf="j !== wrappedItem.value.length - 1">
          </div>
        </div>

      </ng-container>

    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'mileage'">
    <div class="block"
         *ngIf="field.value !== null">{{ field.value | number }} km
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'number'">
    <div class="block whitespace-pre"
         *ngIf="field.value !== null">{{ field.value }} <span *ngIf="field.config.append">{{field.config.append}}</span>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'currency'">
    <div class="block"
         *ngIf="field.value !== null">{{ field.value | multiCurrency:accountConfig$ | async }}
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'engine_power'">
    <div class="block whitespace-pre"
         *ngIf="field.value !== null">{{ field.value }} <span *ngIf="field.config.append">{{field.config.append}}
      <i class="fas fa-exchange-alt text-grayLight mx-1" style="font-size: 0.75rem;"></i> {{ getHpFromKw() }} <span
              i18n>KM</span></span>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'email'">
    <div class="block"
         *ngIf="field.value !== null"><a [href]="'mailto:' + field.value">{{field.value}}</a>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'photos'">
    <ng-container *ngTemplateOutlet="photos"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'silhouette_photos'">
    <ng-container *ngTemplateOutlet="photos"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'files'">
    <ng-container *ngTemplateOutlet="files"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'client'">
    <app-client-table-cell [client]="field.value"></app-client-table-cell>
  </ng-template>

  <ng-template [ngSwitchCase]="'textblock'">
    <div class="block"
         *ngIf="field.value"><span class="overflow-ellipsis break-words">{{ showValue(field) | async }}</span> <span
            *ngIf="field.config.append">{{field.config.append}}</span>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'text_rich'">
    <div class="block"
         *ngIf="field.value" [innerHTML]="field.value | safeHtml">
    </div>
  </ng-template>

  <ng-template ngSwitchDefault>
    <div class="block whitespace-pre leading-normal"
         *ngIf="field.value">{{ showValue(field) | async }} <span
            *ngIf="field.config.append">{{field.config.append}}</span>
    </div>
  </ng-template>
</div>


<ng-template #photos>
  <div class="flex flex-row flex-wrap mb-2 no-transition" *ngIf="field.value.length"
       [ngClass]="{'justify-between': field.value.length.length > 2}">
    <ng-container *ngFor="let upload of field.value; let i = index">
      <div class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-1 no-transition">
        <div class="relative">
          <img class="photo object-cover rounded w-full cursor-pointer" [src]="upload.files?.[0]?.conversions.thumbnail"
               (click)="openGallery(field, i)"
               loading="lazy">
        </div>
      </div>
    </ng-container>
  </div>

  <div class="text-right my-2" *ngIf="field.value.length">
    <button [disabled]="isDownloading" class="btn btn-sm btn-primary-outline" (click)="downloadPhotos(field)"><i
            class="fas fa-download mr-2"></i><span i18n="Download photos">Prenesi vse fotografije</span></button>
  </div>
</ng-template>

<ng-template #files>
  <div class="flex flex-col">
    <div *ngFor="let item of field.value; let i = index" [ngClass]="{'mb-2': i < field.value.length -1}">
      <app-file-table-value [upload]="item" [uploads]="field.value"></app-file-table-value>
    </div>
  </div>
</ng-template>

<ng-template #multiCheckbox>
  <ng-container *ngFor="let item of prepareMultiCheckboxValues(field)">
    <div class="block whitespace-pre leading-normal">
      - {{ item }}
    </div>
  </ng-container>
</ng-template>
