import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {catchError, debounceTime, startWith, switchMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {GlobalSearchApiService} from '@app/services/api/global-search-api.service';
import {GlobalSearchResult} from '@app/interfaces/entities/global-search-result';
import {EMPTY, of, Subscription} from 'rxjs';

@Component({
  selector: 'app-global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.scss']
})
export class GlobalSearchBarComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  control = new FormControl('');

  isLoading: boolean;
  showDropdown: boolean;
  public searchResult: GlobalSearchResult;

  constructor(
    private globalSearchApiService: GlobalSearchApiService,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      tap(inputText => {
          this.isLoading = true;
          this.showDropdown = inputText.length > 1;
          this.searchResult = null;
        }
      ),
      switchMap((value: string) => {
        if (value.length > 1) {
          return this.globalSearchApiService.search(value)
            .pipe(catchError(() => of(null)));
        } else {
          return of({data: [], type_count: 0});
        }
      }),
      catchError(() => EMPTY),
      tap(() => {
        this.isLoading = false;
      })
    ).subscribe((results: GlobalSearchResult | null) => {
      this.searchResult = results || {data: [], type_count: 0};
    }, console.error);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showDropdown = false;
    } else {
      const inputText = String(this.control.value);
      this.showDropdown = inputText.length > 1;
    }
  }

  clearSearchAndHideDropdown() {
    this.control.setValue('');
    this.showDropdown = false;
  }

  hideDropdown() {
    this.clearSearchAndHideDropdown();
  }
}
