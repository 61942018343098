import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {EntityGroup} from '../../../interfaces/entities/entity-group';
import {EntityGroupApiService} from '../../../services/api/entity-group-api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import {EditEntityGroupDialogComponent} from "../edit-entity-group-dialog/edit-entity-group-dialog.component";
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {DialogType} from '../confirm-dialog/dialog.type';
import {Subscription} from "rxjs";

const TAG_ROW_SIZE = 70;

@Component({
  selector: 'app-change-entity-groups-dialog',
  templateUrl: './change-entity-groups-dialog.component.html',
  styleUrls: ['./change-entity-groups-dialog.component.scss']
})
export class ChangeEntityGroupsDialogComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();


  public isLoading = true;
  public isSaving = false;
  public selectedEntityGroups: EntityGroup[] = [];
  public entityGroups: EntityGroup[] = [];

  public form = new FormGroup({
    label: new FormControl('', [Validators.required]),
  });

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private entityGroupApi: EntityGroupApiService,
              private notificationService: NotificationService,
              private matDialog: MatDialog,
              public dialogRef: MatDialogRef<unknown>) {
    this.selectedEntityGroups = [...data.currentEntityGroups];
  }

  static open(dialog: MatDialog,
              currentEntityGroups: EntityGroup[],
              entityType: string) {
    return dialog.open(ChangeEntityGroupsDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        currentEntityGroups,
        entityType,
      }
    });
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit() {
    if (this.form.valid) {
      this.form.disable();
      this.isSaving = true;

      this.entityGroupApi.storeEntityGroup(this.form.controls.label.value, this.data.entityType)
        .subscribe((response) => {
          this.selectGroup(response.data);

          if (!this.entityGroups.find((item) => item.entityGroupUuid === response.data.entityGroupUuid)) {
            // Has to be mutable change for virtaul list to detect new items
            this.entityGroups = [...this.entityGroups, response.data];
          }

          this.form.controls.label.setValue('');
          this.form.controls.label.markAsUntouched();
          this.form.enable();
          this.isSaving = false;
        }, (error) => {
          this.notificationService.showErrorAlert();
          this.form.enable();
          this.isSaving = false;
        });

    } else {
      this.form.markAllAsTouched();
    }
  }

  selectGroup(entityGroup: EntityGroup) {
    if (!this.selectedEntityGroups.find((selectedItem) => selectedItem.entityGroupUuid === entityGroup.entityGroupUuid)) {
      this.selectedEntityGroups.push(entityGroup);
    }
  }

  removeGroup(entityGroup: EntityGroup) {
    if (this.selectedEntityGroups.indexOf(entityGroup) !== -1) {
      this.selectedEntityGroups.splice(this.selectedEntityGroups.indexOf(entityGroup), 1);
    }
  }

  private refresh() {
    this.isLoading = false;
    this.subscription.add(
      this.entityGroupApi.getEntityGroups(this.data.entityType)
        .subscribe((response) => {
          this.entityGroups = response.data;
          this.isLoading = false;

          // trigger update of virtual wrapper
          window.dispatchEvent(new Event('resize'));
        })
    );
  }

  save() {
    this.dialogRef.close({entityGroups: this.selectedEntityGroups});
  }

  close() {
    this.dialogRef.close(false);
  }

  deleteEntityGroup($event: MouseEvent, group: EntityGroup) {
    $event.preventDefault();
    $event.stopPropagation();

    const diaglogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.REMOVE_ENTITY_GROUP, true);

    diaglogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.isLoading = true;
        this.entityGroupApi.deleteEntityGroup(group.entityGroupUuid)
          .subscribe(() => {
            const removedGroup = this.selectedEntityGroups.find(innerGroup => innerGroup.entityGroupUuid === group.entityGroupUuid);
            if (removedGroup) {
              this.removeGroup(removedGroup);
            }
            this.refresh();
          }, (error) => this.notificationService.showErrorAlert());
      }
    });
  }

  editEntityGroup($event: MouseEvent, group: EntityGroup) {
    $event.preventDefault();
    $event.stopPropagation();

    const diaglogRef = EditEntityGroupDialogComponent.open(this.matDialog, group);

    diaglogRef.afterClosed().subscribe((editedGroup) => {
      if (editedGroup) {
        const selected = this.selectedEntityGroups.find((group) => group.entityGroupUuid === editedGroup.entityGroupUuid);

        if (selected) {
          selected.name = editedGroup.name;
        }

        this.refresh();
      }
    });
  }

  // Used for trackBy in ngFor - performance optimization
  public getGroupId(group: EntityGroup) {
    return group.entityGroupUuid;
  }

  get virtualWrapperHeight() {
    // Wrapper should be max 1/3 of window height, optimally fit to number of rows or minimum of 3 rows (3*55)
    return `${Math.max(Math.min(window.innerHeight / 3, Math.ceil(this.entityGroups.length * TAG_ROW_SIZE / 2)), 3 * TAG_ROW_SIZE)}px`;
  }
}
