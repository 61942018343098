<div *ngIf="appHasError"
     class="m-4 alert-box">Aplikacija ima težave v delovanju. Poskusite ponovno čez nekaj minut.
</div>

<router-outlet></router-outlet>

<div class="fixed bottom-0 w-sidebar bg-redHover px-3 py-2 text-white border-t-1 border-red font-semibold"
     *ngIf="control$ | async as control"
     style="z-index: 1234511;">
  <div class="flex flex-col items-center w-100">
    <div class="flex-1 py-5 px-1 overflow-auto w-100">
      <a [routerLink]="['/admin/settings/super-admin/accounts', control.accountUuid]" class="text-white">
        {{ control.name }}
      </a>
    </div>
    <div class="pb-5">
      <button class="btn btn-sm btn-grayDark" (click)="releaseControl()" i18n>Sprosti nadzor</button>
    </div>
  </div>
</div>
