import {Component, OnDestroy, OnInit} from '@angular/core';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {MetaService} from '@src/@ngx-meta/core/src';
import {NotificationService} from '@app/services/notification.service';
import {Field, PublicAccount, PublicVehicle} from '@app/interfaces';
import {GalleryService} from '@app/services/gallery.service';
import {SectionItem} from '@app/interfaces/form/section-item';
import {FieldItem} from '@app/interfaces/form/field-item';
import {SafariScreenFixService} from '@app/services/safari-screen-fix.service';
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {AccountConfig} from "@app/interfaces/config/account.config";
import {FieldSection} from "@app/interfaces/fields/field.section";
import {VirtualSalonApiService} from "@app/services/api/virtual-salon-api.service";
import {map, takeUntil} from "rxjs/operators";
import {AccountFeaturesEnum} from "@app/interfaces/config/accountFeaturesEnum";

@Component({
  selector: 'app-vehicle-listing-page',
  templateUrl: './vehicle-listing-page.component.html',
  styleUrls: ['./vehicle-listing-page.component.scss']
})
export class VehicleListingPageComponent implements OnInit, OnDestroy {

  public isPageLoading = true;
  public isNotFound = false;

  public account: PublicAccount;
  public vehicle: PublicVehicle;
  private vehicleUuid: string;

  public shareUrl: string;

  public sections: FieldSection[] = [];

  public photos: Field<any>;
  private accountConfig: AccountConfig;
  public accountConfig$: Observable<AccountConfig>;
  private hasVirtualSalonV2: boolean;
  private destroy$ = new Subject<void>();

  constructor(private vehicleApi: VehicleApiService,
              private virtualSalonApiService: VirtualSalonApiService,
              private activatedRoute: ActivatedRoute,
              private notificationService: NotificationService,
              private router: Router,
              private galleryService: GalleryService,
              private meta: MetaService,
              private safariScreenFix: SafariScreenFixService,
              private configApi: ConfigAPIService
  ) {
  }

  async ngOnInit() {
    this.accountConfig$ = this.configApi.getAccountConfig();
    this.accountConfig = await firstValueFrom(this.accountConfig$);

    this.accountConfig$.pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.hasVirtualSalonV2 = result.accountFeatureFlags?.[AccountFeaturesEnum.HAS_VIRTUAL_SALON_V2]
      });

    // @ts-ignore
    const resolvedResult = this.activatedRoute.snapshot.data.result;


    //  As we're using SSR, we have a resolver that preloads the data so we can easily setup the necessary data
    if (resolvedResult) {
      this.account = resolvedResult.data.publicAccount;
      this.vehicle = resolvedResult.data.publicVehicle;
      this.vehicleUuid = this.vehicle.vehicleUuid;

      this.setupFields(this.vehicle);
      this.setupMeta(this.vehicle, this.account);
      this.isPageLoading = false;
      this.isNotFound = false;
    } else {
      this.isPageLoading = false;
      this.isNotFound = true;
    }

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((map) => {
          const vehicleUuid = map.get('vehicleUuid');

          //  We don't want to reload data when SSR gets replaced with the app
          if (this.vehicleUuid === vehicleUuid) {
            return;
          }

          this.isPageLoading = true;
          this.isNotFound = false;
          this.vehicleUuid = vehicleUuid;

          this.virtualSalonApiService.getPublishedVehicle(this.vehicleUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {

              this.account = result.data.publicAccount;
              this.vehicle = result.data.publicVehicle;
              this.setupFields(this.vehicle);
              this.setupMeta(this.vehicle, this.account);

              this.isPageLoading = false;
            }, (error) => {
              if (error.status === 404) {
                this.isNotFound = true;
                this.isPageLoading = false;
              } else {
                this.notificationService.showErrorAlert();
              }
            });
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setupFields(vehicle: PublicVehicle) {
    this.photos = this.findValueByTypeId('briefd.vehicle.photographs', vehicle.fields);

    const layouts = vehicle.layouts;
    const sections = [];

    layouts.forEach((layout) => {

      // @ts-ignore
      layout.items.forEach((layoutItem) => {
          const fieldGroupSection: FieldSection = {title: (layout as SectionItem).title, items: []};

          if (layoutItem.type === 'field') {
            fieldGroupSection.items.push(this.findValue((layoutItem as FieldItem).fieldUuid, vehicle.fields));
          } else {
            (layoutItem as SectionItem).items.forEach((innerLayout) => {
              if (innerLayout.type === 'field') {
                fieldGroupSection.items.push(this.findValue((innerLayout as FieldItem).fieldUuid, vehicle.fields));
              } else {
                (innerLayout as SectionItem).items.forEach((innerInnerLayout) => {
                  if (innerInnerLayout.type === 'field') {
                    fieldGroupSection.items.push(this.findValue((innerInnerLayout as FieldItem).fieldUuid, vehicle.fields));
                  }
                });
              }
            });
          }

          fieldGroupSection.items = fieldGroupSection.items.filter((item) => item.value !== null && item.type !== 'photos');

          sections.push(fieldGroupSection);
        }
      );
    });

    this.sections = sections;
  }

  addParameters(url: string, fieldUuid: string) {
    const publicUrl = url.replace('/api/', '/public/');
    return `${publicUrl}?vehicleUuid=${this.vehicleUuid}&fieldUuid=${fieldUuid}`;
  }

  openLightbox(photos: Field<any>, index) {
    const images = [];

    photos.value.forEach((upload) => {
      images.push({
        src: this.addParameters(upload.files?.[0]?.conversions.large, photos.fieldUuid),
        caption: null,
        thumb: this.addParameters(upload.files?.[0]?.conversions.thumbnail, photos.fieldUuid),
      });
    });

    this.galleryService.openGallery(images, index);
  }

  private findValue(fieldUuid: string, fields: Field<any>[]) {
    return fields.find((field) => field.fieldUuid === fieldUuid);
  }

  private findValueByTypeId(typeId: string, fields: Field<any>[]) {
    return fields.find((field) => field.config.typeId === typeId);
  }

  private setupMeta(vehicle: PublicVehicle, account: PublicAccount) {
    this.shareUrl = this.hasVirtualSalonV2
      ? this.vehicleApi.getPublicUrlForVehicleV2(this.vehicleUuid, this.accountConfig.locale)
      : this.vehicleApi.getPublicUrlForVehicle(this.vehicleUuid, this.accountConfig.locale);

    //  Featured image
    const featuredImageUrl = this.photos && this.photos.value.length > 0 ? this.photos.value[0].files?.[0]?.conversions.large : null;

    if (featuredImageUrl) {
      this.meta.setTag('og:image', this.addParameters(featuredImageUrl, this.photos.fieldUuid));
      this.meta.setTag('twitter:image:alt', this.vehicleApi.extractPublicVehicleName(vehicle));
    }

    //  Title
    let title = `${this.vehicleApi.extractPublicVehicleName(vehicle)}`;
    title += account.name ? ' : ' + account.name : '';

    this.meta.setTitle(title);
    this.meta.setTag('og:title', title);

    //  Description
    const description = this.vehicleApi.extractPublicVehicleVersion(vehicle);

    this.meta.setTag('og:description', description);
    this.meta.setTag('description', description);

    //  Extra
    this.meta.setTag('og:url', this.hasVirtualSalonV2
      ? this.vehicleApi.getPublicUrlForVehicleV2(vehicle.vehicleUuid, this.accountConfig?.locale)
      : this.vehicleApi.getPublicUrlForVehicle(vehicle.vehicleUuid, this.accountConfig?.locale));
    this.meta.setTag('twitter:card', 'summary_large_image');
    this.meta.setTag('og:site_name', 'Briefd');
  }
}
