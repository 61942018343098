import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {EntityGroupApiService} from "../../../services/api/entity-group-api.service";
import {NotificationService} from "../../../services/notification.service";
import {EntityGroup} from "../../../interfaces/entities/entity-group";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-change-client-entity-category-dialog',
  templateUrl: './change-client-entity-group-dialog.component.html',
  styleUrls: ['./change-client-entity-group-dialog.component.scss']
})
export class ChangeClientEntityGroupDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private entityGroupApi: EntityGroupApiService,
              private notificationService: NotificationService,
              public dialogRef: MatDialogRef<unknown>) {
    this.selectedEntityGroups = [...data.currentEntityGroups];
  }

  public isLoading = true;
  public isSaving = false;
  public selectedEntityGroups: EntityGroup[] = [];
  public entityGroups: EntityGroup[] = [];

  public form = new FormGroup({
    label: new FormControl('', [Validators.required]),
  });

  static open(dialog: MatDialog,
              currentEntityGroups: EntityGroup[],
              entityType: string) {
    return dialog.open(ChangeClientEntityGroupDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        currentEntityGroups,
        entityType,
      }
    });
  }

  ngOnInit() {
    this.entityGroupApi.getEntityGroups(this.data.entityType)
      .subscribe((response) => {
        this.isLoading = false;
        this.entityGroups = response.data;
      });
  }

  save() {
    this.dialogRef.close({entityGroups: this.selectedEntityGroups});
  }

  close() {
    this.dialogRef.close(false);
  }

  toggleGroup(entityGroup: EntityGroup) {
    const selectedGroup = this.getSelectedGroup(entityGroup);

    if (selectedGroup) {
      this.selectedEntityGroups.splice(this.selectedEntityGroups.indexOf(selectedGroup), 1);
    } else {
      this.selectedEntityGroups.push(entityGroup);
    }
  }

  getSelectedGroup(entityGroup: EntityGroup) {
    return this.selectedEntityGroups.find((selectedItem) => selectedItem.entityGroupUuid === entityGroup.entityGroupUuid);
  }
}
