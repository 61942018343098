import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {VehicleApiService} from '../../../services/api/vehicle-api.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-already-sold-move-to-archive-dialog',
  templateUrl: './already-sold-move-to-archive-dialog.component.html',
  styleUrls: ['./already-sold-move-to-archive-dialog.component.scss']
})
export class AlreadySoldMoveToArchiveDialogComponent implements OnInit {

  private vehicleUuid: string;
  public vehicleInvoices = [];
  public isLoadingInvoices = true;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              public dialogRef: MatDialogRef<any>,
              private vehicleApiService: VehicleApiService,
              private notificationService: NotificationService,
              ) {
    this.vehicleUuid = data.vehicleUuid;
  }

  static open(dialog: MatDialog, vehicleUuid: string) {
    return dialog.open(AlreadySoldMoveToArchiveDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        vehicleUuid
      }
    });
  }

  ngOnInit() {
    this.vehicleApiService.getVehicleInvoices(this.vehicleUuid, {column: 'date', order: 'desc'}, {
      type: 'invoice', canceled: false, draft: false,
    }, true).subscribe((result) => {
      this.vehicleInvoices = result.data.filter((item) => {
        return item.documentItems.find((documentItem) => {
          return documentItem.custom?.lineItemType === 'sale' && documentItem.custom?.vehicleUuid === this.vehicleUuid;
        });
      });

      this.isLoadingInvoices = false;
    });
  }

  archiveVehicle() {
    this.vehicleApiService.archive(this.vehicleUuid)
      .subscribe((result) => {
        this.dialogRef.close({vehicleUuid: this.vehicleUuid});
      }, (error) => {
        this.notificationService.showErrorAlert();
      });
  }

  close() {
    this.dialogRef.close(true);
  }
}
