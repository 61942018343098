<div class="card bg-white relative border-none">
  <div class="card-header p-5 sm:p-5 flex">
    <div class="w-full flex items-center justify-center">
      <p class="flex mb-0 pb-0 pr-0 text-greenDarkOlive text-lg sm:text-xl font-bold text-center"><span i18n>Potrditev izbire paketa</span>
      </p>
    </div>
    <button (click)="close()"
            class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut absolute top-0 right-0">
      <i class="fas fa-times text-lg"></i>
    </button>
  </div>
  <div class="card-content overflow-auto" style="max-height: 70vh">
    <div *ngIf="isLoading;  else showContent">
      <app-form-shimmer></app-form-shimmer>
    </div>

    <ng-template #showContent>
      <ng-container *ngIf="!(activePackage.isTrial && !activePackage.isExpired); else showTrialUpsell">
        <div class="flex flex-col items-center">
          <p class="text-xs px-2 text-center">
            <span i18n>S klikom na gumb NADALJUJ boste izbrali paket</span>&nbsp;
            <span class="font-bold uppercase">{{ productPackage.name }}</span>&nbsp;-&nbsp;
            <ng-container [ngSwitch]="productType">
              <span *ngSwitchCase="productPackageSubscriptionTypeEnum.MONTHLY" i18n>mesečno</span>
              <span *ngSwitchCase="productPackageSubscriptionTypeEnum.YEARLY" i18n>letno</span>
            </ng-container>
            <span class="block" i18n *ngIf="!productPackage.isFree">Omenjeni paket NI brezplačen, zato vam bomo zanjo zaračunali naročnino. </span>
          </p>
        </div>
      </ng-container>

      <ng-template #showTrialUpsell>
        <div class="flex flex-col items-center">
          <p class="text-xs px-2 text-center">
            <span i18n>Izbrali ste paket: </span>&nbsp;
            <span class="font-bold uppercase">{{ productPackage.name }}</span>.
            <span class="block" i18n>Še vedno se nahajate znotraj preizkusnega obdobja, do izteka le - tega lahko neomejeno preizkušate platformo.</span>
          </p>
        </div>

      </ng-template>


      <ng-template #downgradeToFree>
        <div class="flex flex-col items-center">
          <p class="text-xs px-2 text-justify">
            <span i18n>Pozor, preklapljate na paket <b>MINI</b>, ki ne vključuje vseh funkcionalnosti in hrambe podatkov kot ostala dva paketa. To lahko pomeni izgubo dostopa do nekaterih funkcij in podatkov na platformi, ki niso del paketa <b>MINI</b>. V kolikor se s tem strinjate, lahko nadaljujete z izbiro.</span>
          </p>
          <br>
          <p class="text-xs px-2 text-center">
            <span i18n>Uporaba paketa MINI je brezplačna.</span>
          </p>
        </div>

      </ng-template>

      <div class="flex justify-center mt-4">
        <button class="btn btn-lg border-none text-grayDark" (click)="close()" i18n>Prekliči</button>
        <button class="btn btn-primary ml-4" [disabled]="disableButton" (click)="changeProductPackage()">
          <span *ngIf="!disableButton" i18n>Potrdi</span>
          <app-spinner *ngIf="disableButton" i18n></app-spinner>
        </button>
      </div>
    </ng-template>
  </div>
</div>
