import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotificationListPageComponent} from './notification-list-page/notification-list-page.component';

const routes: Routes = [
  {
    path: '',
    component: NotificationListPageComponent,
    data: {
      title: 'title.notifications',
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule {
}
