import {Component, Input, OnInit} from '@angular/core';
import {Upload} from '../../../interfaces/entities/upload';
import {GalleryService} from '../../../services/gallery.service';
import {IAlbum} from "ngx-lightbox";

@Component({
  selector: 'app-file-table-value',
  templateUrl: './file-table-value.component.html',
  styleUrls: ['./file-table-value.component.scss']
})
export class FileTableValueComponent implements OnInit {

  @Input() upload: Upload;
  @Input() uploads: Upload[];
  @Input() borderless = false;

  constructor(private galleryService: GalleryService) {
  }

  ngOnInit(): void {
  }

  getFontClass(upload: Upload) {
    if (this.isUploadImage(upload)) {
      return 'fa-image';
    }

    return 'fa-file';
  }

  isUploadImage(upload: Upload) {
    return upload.files[0].mimeType.indexOf('image/') >= 0;
  }

  openLightBox($event: MouseEvent, upload: Upload) {
    $event.stopPropagation();
    $event.preventDefault();

    const images: IAlbum[] = [];

    const allImages = this.uploads.filter(item => this.isUploadImage(item));

    allImages.forEach((item) => {
      images.push({
        src: item.files[0].url,
        downloadUrl: item.files[0].url,
        caption: item.files[0].fileName,
        thumb: item.files[0].url,
      });
    });

    this.galleryService.openGallery(images, allImages.indexOf(upload));
  }

  getClass(upload: Upload) {
    const result = {};

    if (!this.borderless) {
      result['border'] = true;
      result['rounded'] = true;
      result['border-grayLight'] = true;
    }

    if (this.isUploadImage(upload)) {
      result['pl-4'] = true;
      result['py-1'] = true;
    } else {
      result['py-3'] = true;
      result['pl-4'] = true;
      result['pr-2'] = true;
    }

    return result;
  }
}
