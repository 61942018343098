import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UserApiService} from '@app/services/api/user-api.service';
import {SwitchToFullModeDialogComponent} from '@app/dialogs/switch-to-full-mode-dialog/switch-to-full-mode-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ApiCachingService} from '@app/services/api/api-caching.service';
import {Router} from '@angular/router';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';
import {FeatureFlagService} from '@app/services/feature-flag.service';
import {map, takeUntil} from 'rxjs/operators';
import {IntegrationsEnum} from '@app/interfaces/entities/integration';
import {PermissionEnum} from '@src/app/enums/permissions/PermissionEnum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Input() isMobile = false;

  public isDemo;
  public businessOverviewMenuOpened = false;
  public financingMenuOpened = false;
  public AccountFlagEnum = AccountFeaturesEnum;
  public nlbLeasingRoute$: Observable<string>;
  public showAdminSideMenu = false;

  protected readonly IntegrationsEnum = IntegrationsEnum;
  public PermissionEnum = PermissionEnum;

  private destroy$ = new Subject<void>();

  constructor(
    private userApiService: UserApiService,
    private cacheApi: ApiCachingService,
    private matDialog: MatDialog,
    private router: Router,
    private featureFlagsService: FeatureFlagService
  ) {
  }

  ngOnInit() {
    this.userApiService.getIsDemoStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.isDemo = result;
      });

    this.financingMenuOpened = this.router.url.startsWith('/admin/leasings/');
    if (this.isMobile && this.router.url.startsWith('/admin/administration/')) {
      this.toggleAdministrationSideMenu();
    } else if (this.isMobile && this.router.url.startsWith('/admin/business-overview/')) {
      this.toggleBusinessOverviewMenu();
    }

    this.nlbLeasingRoute$ = this.featureFlagsService.getFeatureFlags()
      .pipe(takeUntil(this.destroy$))
      .pipe(map((result) => {
        return result.nlbLeasingPreUX ? '/admin/leasings/drafts' : '/admin/leasings/ux';
      }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openSwitchToFull() {
    const dialog = SwitchToFullModeDialogComponent.open(this.matDialog);
  }

  toggleBusinessOverviewMenu() {
    this.showAdminSideMenu = false;
    this.businessOverviewMenuOpened = !this.businessOverviewMenuOpened;
  }

  toggleFinancingMenu() {
    this.financingMenuOpened = !this.financingMenuOpened;
  }

  public isAdministrationUrl(): boolean {
    return this.router.url.includes('/admin/administration');
  }

  public toggleAdministrationSideMenu() {
    this.businessOverviewMenuOpened = false;
    this.showAdminSideMenu = !this.showAdminSideMenu;
  }

  public isBusinessOverviewUrl() {
    return this.router.url.includes('/admin/business-overview');
  }
}
