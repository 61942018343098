<ng-container *ngIf="!isPreloading; else showLoading">
  <label for="brand" class="mb-2 block"><span i18n="Vehicle autocomplete">Znamka vozila</span></label>
  <input name="brand" id="brand" type="text" matInput [formControl]="brandControl" (blur)="onBrandBlur()"
         placeholder=""
         [ngClass]="{ 'input-error': brandControl.invalid }"
         [matAutocomplete]="brandAutocomplete" class="mb-4" [attr.tabIndex]="0">

  <mat-autocomplete [autoActiveFirstOption]="brandsLoading" #brandAutocomplete="matAutocomplete" [displayWith]="display"
                    (optionSelected)="onBrandSelected($event)" autocomplete="off">

    <div *ngIf="$filteredBrands | async as filteredBrands">
      <mat-option *ngFor="let brand of filteredBrands" [value]="brand">
        {{ brand.name }}
      </mat-option>

      <mat-option *ngIf="!brandsLoading && filteredBrands.length === 0" value="">
        <span class="italic"><span i18n="Autocomplete results">Ni rezultatov</span></span>
      </mat-option>
    </div>

    <mat-option *ngIf="brandsLoading">
      <app-content-shimmer></app-content-shimmer>
    </mat-option>

  </mat-autocomplete>

  <label for="model" class="mb-2 block"><span i18n="Vehicle autocomplete">Model vozila</span></label>
  <input name="model" id="model" type="text" matInput [formControl]="modelControl" [matAutocomplete]="modelAutocomplete"
         (blur)="onModelBlur()"
         [ngClass]="{ 'input-error': modelControl.invalid }"
         placeholder=""
         autocomplete="off" class="mb-4" [attr.tabIndex]="0">

  <mat-autocomplete [autoActiveFirstOption]="modelsLoading" #modelAutocomplete="matAutocomplete" [displayWith]="display"
                    (optionSelected)="onModelSelected($event)" >

    <div *ngIf="$filteredModels | async as filteredModels">
      <mat-option *ngFor="let model of filteredModels" [value]="model">
        {{ model.name }}
      </mat-option>

      <mat-option *ngIf="!modelsLoading && filteredModels.length === 0" value="">
        <span i18n="Autocomplete results">Ni rezultatov</span>
      </mat-option>
    </div>

    <mat-option *ngIf="modelsLoading">
      <app-content-shimmer></app-content-shimmer>
    </mat-option>

  </mat-autocomplete>
</ng-container>

<ng-template #showLoading>
  <div class="flex justify-center items-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
