<div class="grid grid-cols-1 md:grid-cols-6 mt-2">
  <div class="col-start-2 md:col-span-4 md:col-start-2 lg:col-span-2 lg:col-start-3">
    <div class="flex flex-row justify-between items-center">
      <span i18n class="font-bold text-2xl ml-1 my-3">Vsa obvestila</span>
      <span class="text-primary cursor-pointer text-sm" (click)="readAllNotifications()" i18n>Označi vse kot prebrano</span>
    </div>
    <div *ngIf="!isLoading && notifications.length; else noResults">
      <app-notification
        *ngFor="let notification of notifications"
        [notification]="notification">
      </app-notification>
    </div>

    <ng-template #noResults><span i18n>Ni obvestil</span></ng-template>

  </div>
</div>
