import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Alarm, PaginatedResult} from '@app/interfaces';
import {environment} from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlarmApiService {

  constructor(private http: HttpClient) {
  }

  store(body: { entityUuid: string; entityType: string; name: any; severity: string, triggers: { date: any }[] }) {
    return this.http.post(`${environment.apiUrl}/api/alarms`, body);
  }

  update(alarmUuid: string, body: { entityUuid: string; entityType: string; name: any; severity: string, triggers: { date: any }[] }) {
    return this.http.put(`${environment.apiUrl}/api/alarms/${alarmUuid}`, body);
  }

  get(entityUuid: string, page: number, perPage: number): Observable<PaginatedResult<Alarm[]>> {
    let params = new HttpParams();
    params = params.append('entityUuid', entityUuid);
    params = params.append('page', page.toString());
    params = params.append('perPage', perPage.toString());

    return this.http.get<PaginatedResult<Alarm[]>>(`${environment.apiUrl}/api/alarms`, {params});
  }

  dismissNotification(alarmNotificationUuid: string) {
    return this.http.put(`${environment.apiUrl}/api/alarm_notifications/${alarmNotificationUuid}`, {});
  }

  delete(alarmUuid: string) {
    return this.http.delete(`${environment.apiUrl}/api/alarms/${alarmUuid}`);
  }
}
