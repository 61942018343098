<div class="card bg-white relative border-none">
  <div class="card-header p-5 pb-0">
    <div class="flex items-center justify-between">
      <p i18n="Change status modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Spremeni status</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="p-5">
    <ng-container class="p-5" *ngIf="isLoading; else showContent">
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </ng-container>

    <ng-template #showContent>
      <ng-container *ngIf="currentStatus && !isValidStatus(currentStatus)">

        <div class="text-sm flex flex-row p-4 rounded border-orange bg-orangeWashed border text-orange border-orange leading-normal mt-2 mb-4">
          <i class="fas fa-exclamation-triangle mr-2 mt-1"></i>
          <div class="leading-normal font-semibold" i18n>Statusa "{{ currentStatus.name }}" ne podpiramo več. Izberi nov status iz spodnjega seznama.</div>
        </div>

      </ng-container>

      <div *ngIf="statuses">
        <label class="mb-4 block"><span i18n="Change status modal">Vozilu bo nastavljen naslednji status:</span>        </label>

        <div class="flex flex-col">
            <ng-container *ngFor="let status of statuses">
              <div class="mb-3">
                <label><input class="mr-3 form-radio text-green h-5 w-5 border-green mt-0 relative" type="radio" name="status" [value]="status.statusUuid" [(ngModel)]="selectedStatusUuid">{{ status.name}}</label>
              </div>
            </ng-container>
          </div>
      </div>

<!--      <div *ngIf="isLoadingInvoices">-->
<!--        <div class="mb-3">-->
<!--          <app-content-shimmer></app-content-shimmer>-->
<!--        </div>-->
<!--        <div class="mb-3">-->
<!--          <app-content-shimmer></app-content-shimmer>-->
<!--        </div>-->
<!--        <div class="mb-3">-->
<!--          <app-content-shimmer></app-content-shimmer>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div *ngIf="!isLoadingInvoices && vehicleInvoices.length">-->
<!--        <div class="card p-3 -mb-5 mt-2">-->
<!--          <div class="">-->
<!--            <p class="text-base font-bold text-black" i18n>Je bilo vozilo prodano?</p>-->
<!--          </div>-->
<!--          <div class="pt-4">-->
<!--            <p i18n class="text-sm">Za vozilo so bili izdani naslednji računi:</p>-->

<!--            <ul>-->
<!--              <li *ngFor="let invoice of vehicleInvoices">-->
<!--                <a [routerLink]="['/admin/administration/document', invoice.documentId]" class="font-semibold text-sm" target="_blank">{{ invoice.number }}</a>-->
<!--              </li>-->
<!--            </ul>-->

<!--          </div>-->

<!--          <div class="pt-6">-->
<!--            <p class="text-sm">S klikom boš vozilo prestavil med prodana.</p>-->

<!--            <div class="mt-2">-->
<!--              <button class="btn btn-primary" (click)="archiveVehicle()">Prestavi vozilo med prodana</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
    </ng-template>
  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n="Prekliči@@cancel">Prekliči</button>
      <button class="btn btn-primary ml-4" (click)="updateStatus()" i18n="Posodobi@@update">Posodobi</button>
    </div>
  </div>
</div>
