<div class="card bg-white relative border-none">
  <div class="card-content">
    <div class="flex flex-col items-center mt-4">
      <img src="assets/images/img_refresh_platform.png">
      <div class="text-lg font-bold text-greenDarkOlive mt-4"><span i18n>Potrebna je osvežitev AutoBrief platforme</span></div>
      <div class="leading-normal mt-5 text-grayDark">
        <p>Za nemoteno delovanje je treba osvežiti spletno stran.</p>
      </div>

      <div class="mt-8 flex flex-row">
        <button class="btn btn-primary ml-auto mr-2" (click)="refresh()"><span i18n>Osveži platformo</span></button>
      </div>
    </div>
  </div>
</div>
