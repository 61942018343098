import {NgModule} from '@angular/core';
import {FirstValuePipe, SafeHtmlPipe, SafeUrlPipe, TimeAgoPipe} from './index';
import {MultiCurrencyPipe} from './multi-currency/multi-currency.pipe';
import {ConvertToDefaultCurrencyPipe} from './default-currency/convert-to-default-currency.pipe';
import {DatediffPipe} from '@app/shared/pipes/datediff/datediff.pipe';
import {PipeFunction} from "@app/shared/pipes/fn/fn.pipe";
import {GlobalPipeFunction} from "@app/shared/pipes/global-pipe-function/global-pipe-function.pipe";
import {SortByPipe} from "@app/shared/pipes/sort-by/sort-by.pipe";

@NgModule({
  declarations: [
    TimeAgoPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MultiCurrencyPipe,
    ConvertToDefaultCurrencyPipe,
    DatediffPipe,
    FirstValuePipe,
    PipeFunction,
    GlobalPipeFunction,
    SortByPipe,
  ],
  imports: [],
  exports: [
    TimeAgoPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MultiCurrencyPipe,
    ConvertToDefaultCurrencyPipe,
    DatediffPipe,
    FirstValuePipe,
    GlobalPipeFunction,
    SortByPipe,
  ]
})
export class PipesModule {
}
