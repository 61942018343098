<div class="card bg-white relative border-none">
  <div class="card-header p-5 pb-0">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
        <span i18n>Potrebujem pomoč</span>
      </p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="card-content">

    <form [formGroup]="form">

      <label for="help"><span i18n>Opis težave:</span></label>

      <textarea formControlName="help" class="mt-2" [ngClass]="{ 'input-error': form.controls.help.invalid }"
        autocomplete="off" rows="5"></textarea>

      <app-field-errors [control]="form.controls.help"></app-field-errors>

    </form>
  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()"><span i18n>Prekliči</span></button>
      <button class="btn ml-1 btn-primary" [disabled]="form.invalid" (click)="confirm()"><span i18n>Zahtevaj
          pomoč</span>
      </button>
    </div>
  </div>
</div>
