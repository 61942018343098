import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';
import {ConfirmDialogComponent, DialogType} from '../../../dialogs';
import {FormHelperService} from '@app/util/form-helper.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {FormDataService} from '@app/services/data/form-data.service';
import {takeUntil} from "rxjs/operators";
import {sortByValue} from "@app/util/object.helper";

@Component({
  selector: 'app-form-input-repeater',
  templateUrl: './form-input-repeater.component.html',
  styleUrls: ['./form-input-repeater.component.scss']
})
export class FormInputRepeaterComponent implements OnInit, OnDestroy {

  @Input() control: FormArray;

  @Input() hideExcept: FormGroup = null;

  private destroy$ = new Subject<void>();

  public subFieldsOrder: string[];
  public field: Field<string>;

  constructor(private formHelperService: FormHelperService,
              private matDialog: MatDialog,
              private ref: ChangeDetectorRef,
              private formDataService: FormDataService) {
  }

  @Input('field') set setField(field: Field<string>) {
    this.field = field;

    let subFieldsOrder = [];
    // get order of subfields
    if (this.field.config.subFieldsOrder) {
      subFieldsOrder = Object.entries(this.field.config.subFieldsOrder || {});
    } else {
      subFieldsOrder = Object.entries(this.field.config.subFields).map(([key], index) => [key, index]);
    }

    // sort array of pairs by second value
    subFieldsOrder.sort(sortByValue((pair => pair[1])));

    this.subFieldsOrder = subFieldsOrder.map(([key]) => key);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.formDataService.fieldValueUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((updateValues) => {
        if (updateValues !== null && updateValues !== undefined && updateValues[this.field.config.typeId] !== undefined && updateValues[this.field.config.typeId].length > 0 && this.field.type === 'repeater' && this.field.config.typeId === 'briefd.client.accounts') {
          this.addRepeaterItem();

          let ibanSubFieldUuid = '';
          for (const key in this.field.config.subFields) {
            if (this.field.config.subFields[key].config.typeId === 'briefd.client.accounts_iban') {
              ibanSubFieldUuid = key;
            }
          }

          this.asFormGroup(this.asFormGroup(this.control.controls[0]).controls.value).controls[ibanSubFieldUuid].setValue(updateValues[this.field.config.typeId][0]);
        }
      });
  }

  addRepeaterItem() {
    this.control.push(this.formHelperService.prepareEmptyRepeaterItem(this.field));
    this.ref.markForCheck();
  }

  removeRepeaterItem(index) {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.DELETE_REPEATER_ITEM, true);

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.control.removeAt(index);
          this.ref.markForCheck();
        }
      }
    );
  }

  asFormGroup(item: AbstractControl) {
    return item as FormGroup;
  }

  fieldsUpdated(event: Array<{ fieldUuid: string, value: number | string }> | Field, formGroup: FormGroup) {
    const values = formGroup.value;


    if ('value' in event) {
      values.value[event.fieldUuid] = event.value;
    } else {
      event.forEach((setField) => {
        // @ts-ignore
        values.value[setField.fieldUuid] = setField.value;
      });
    }


    formGroup.setValue(values);
  }
}
