import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';

@Component({
  selector: 'app-form-input-currency',
  templateUrl: './form-input-currency.component.html',
  styleUrls: ['./form-input-currency.component.scss']
})
export class FormInputCurrencyComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() disabled = false;

  @Input() field: Field<number>;
  @Input() currencySymbol = '€';

  @Output() onKeyUp = new EventEmitter<any>();

  ngOnInit() {
    if (this.disabled) {
      this.control.disable();
    }
  }

  onKeyUpTrigger() {
    this.onKeyUp.emit(this.field.uuid);
  }

  onBlurTrigger() {
    if (this.control.value === null && this.field.config.min === 0) {
      this.control.setValue(0);
    }
  }
}
