import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {takeUntil} from 'rxjs/operators';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {Observable, Subject} from 'rxjs';
import {ProductPackageSubscriptionTypeEnum} from '@app/enums/ProductPackageSubscriptionTypeEnum';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';
import {UpgradeProductPackageDialogComponent} from '@app/dialogs/upgrade-product-package-dialog/upgrade-product-package-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent, DialogType} from '@app/shared/dialogs';
import {ProductPackageCodeEnum} from '@app/enums/ProductPackageCodeEnum';
import {NotificationService} from '@app/services/notification.service';
import {ConfirmUnsubscribeDialogComponent} from '@app/dialogs/confirm-unsubscribe-dialog/confirm-unsubscribe-dialog.component';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {UserService} from '@app/services/userService/user.service';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {FinancingIntegrationTypeEnum} from '@app/enums/financing/FinancingIntegrationTypeEnum';

@Component({
  selector: 'app-package-card',
  templateUrl: './package-card.component.html',
  styleUrls: ['./package-card.component.scss']
})
export class PackageCardComponent implements OnInit, OnDestroy {

  @Input() trialExpired: boolean = false;
  @Input() productPackage: ProductPackage;
  @Input() subscriptionType: ProductPackageSubscriptionTypeEnum;

  public isLoading: boolean = false;

  public activePackage: AccountProductPackage;
  public readonly productPackageSubscriptionTypeEnum = ProductPackageSubscriptionTypeEnum;

  public countryCode: string;

  public AccountFeatureFlags = AccountFeaturesEnum;
  public isActive = false;
  public toggle: boolean = false;

  public destroy$ = new Subject<void>();
  public daysTillEndOfSubscription: number = null;

  protected readonly ProductPackageCodeEnum = ProductPackageCodeEnum;
  accountConfig$: Observable<AccountConfig>;
  userProfile$: Observable<UserProfile>;

  constructor(private productPackagesService: ProductPackagesService,
              private configApi: ConfigAPIService,
              private notificationService: NotificationService,
              private userService: UserService,
              private matDialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.userProfile$ = this.userService.getUserProfile().pipe(takeUntil(this.destroy$));

    this.accountConfig$ = this.configApi.getAccountConfig();

    this.accountConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.daysTillEndOfSubscription = data.daysTillEndOfSubscription;
        this.activePackage = data.activeAccountProductPackage;

        if (this.activePackage.productPackageUuid === this.productPackage.uuid) {
          this.isActive = true;
        }

        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleFeatures() {
    this.toggle = !this.toggle;
  }

  upgradePackage(productPackage: ProductPackage, productType: string) {
    const dialogRef = UpgradeProductPackageDialogComponent.open(this.matDialog, productPackage, productType);
  }

  unsubscribePackage() {
    const dialogRef = ConfirmUnsubscribeDialogComponent.open(this.matDialog, this.productPackage);

    dialogRef.afterClosed().subscribe((feedback) => {
      if (feedback) {
        this.productPackagesService.unsubscribe(feedback).subscribe(next => {
          this.notificationService.showSuccessAlert();
          window.location.reload();
        });
      }
    });
  }

  reSubscribePackage() {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.RESUBSCRIBE, false);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productPackagesService.reSubscribe().subscribe(result => {
          this.notificationService.showSuccessAlert();
          window.location.reload();
        });
      }
    });
  }

  protected readonly FinancingIntegrationTypeEnum = FinancingIntegrationTypeEnum;

  public openSubscriptionPaymentSettings() {
      window.open(this.activePackage.paymentUrl, '_blank');
  }
}
