<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="Change entity group modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
        Značke</p>

      <button (click)="close()" class="btn border-transparent shadow-none" [disabled]="isSaving"><i
        class="fas fa-times text-lg text-gray"></i></button>
    </div>
  </div>
  <hr class="mb-0 mt-0">

  <div class="p-3 sm:p-5 overflow-auto block max-h-75vh">
    <ng-container class="p-5" *ngIf="isLoading; else showContent">
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </ng-container>

    <ng-template #showContent>
      <div class="mb-4">
        <form (ngSubmit)="submit()" [formGroup]="form">
          <label for="label"><span i18n>Dodaj novo značko:</span></label>

          <div class="flex flex-row mt-2">
            <input type="text" name="label" id="label" formControlName="label" autocomplete="off"
                   class="mr-2"
                   [ngClass]="{'input-error': (form.controls.label.invalid && form.controls.label.touched)}">
            <button class="btn btn-primary" type="submit" i18n>Dodaj</button>
          </div>

          <p class="input-error-message mt-1"
             *ngIf="form.controls.label.invalid && form.controls.label.touched">
            <span i18n="Change entity group modal">Vpišite ime značke</span>
          </p>
        </form>
      </div>

      <div class="mb-4">
        <label><span i18n="Change entity group modal">Trenutne značke:</span></label>
        <div class="flex flex-row flex-wrap">
          <ng-container *ngFor="let group of selectedEntityGroups">
            <button *ngIf="group.canBeAssigned" type="button"
                    class="px-3 py-2 bg-grayNavi text-grayDark border border-gray rounded-full mr-3 my-1 text-sm"
                    (click)="removeGroup(group)">
              {{ group.name }} <i class="fas fa-times-circle ml-2 text-gray"></i>
            </button>
          </ng-container>
        </div>
        <div *ngIf="selectedEntityGroups.length === 0" class="py-2 border-transparent text-lg">-</div>
      </div>

      <div *ngIf="entityGroups.length > 0">
        <label class="mb-3" i18n="Change entity group modal">Vse značke na voljo:</label>
        <cdk-virtual-scroll-viewport itemSize="30"
                                     class="flex flex-row flex-wrap"
                                     [style.minHeight]="virtualWrapperHeight">
          <ng-container *ngFor="let group of entityGroups">
            <button
                    *ngIf="group.canBeAssigned"
                    type="button"
                    class="px-3 py-2 text-primary border border-grayLight bg-white rounded-full mr-3 my-1 text-sm"
                    (click)="selectGroup(group)">
              {{ group.name }}

              <button
                      class="btn btn-sm p-1 border-transparent"
                      (click)="editEntityGroup($event, group)"
                      *ngIf="!group.isSystemTag">
                <i class="fas fa-pen"></i>
              </button>
              <button
                      class="btn btn-sm p-1 border-transparent"
                      (click)="deleteEntityGroup($event, group)"
                      *ngIf="!group.isSystemTag">
                <i class="fas fa-trash"></i>
              </button>
            </button>
          </ng-container>

        </cdk-virtual-scroll-viewport>
      </div>
    </ng-template>
  </div>

  <hr class="mb-0 mt-0">

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n [disabled]="isSaving">Prekliči</button>
      <button class="btn btn-primary ml-1" (click)="save()" i18n="" [disabled]="isSaving">Posodobi</button>
    </div>
  </div>
</div>
