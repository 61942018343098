import {Component, OnInit} from '@angular/core';
import {NotificationApiService} from '../../../services/api/notification-api.service';
import {InAppNotification} from '../../../interfaces/entities/in-app-notification';

@Component({
  selector: 'app-notification-list-page',
  templateUrl: './notification-list-page.component.html',
  styleUrls: ['./notification-list-page.component.scss']
})
export class NotificationListPageComponent implements OnInit {
  public notifications: Array<InAppNotification>;
  public isLoading = false;

  constructor(private notificationApiService: NotificationApiService) {
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    this.isLoading = true;
    this.notificationApiService.getAllNotifications().subscribe((notifications) => {
      this.notifications = notifications.data;
      this.isLoading = false;
    });
  }

  readAllNotifications() {
    this.notificationApiService.readAllNotifications().subscribe((result) => {
      this.getNotifications();
    });
  }
}
