<div class="card bg-white border-none max-h-75vh sm:max-h-initial">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Pozdravljeni, dobrodošli v
        prihodnosti...</p>

      <button (click)="close()" class="btn border-transparent shadow-none" [disabled]="isLoading"><i
              class="fas fa-times text-lg text-gray"></i></button>
    </div>
  </div>
  <hr class="mt-0 mb-0 block lg:hidden">
  <div class="card-content overflow-y-auto max-h-75vh sm:max-h-full">

    <ng-container *ngIf="isIniting">
      <div class="mb-4">
        <app-content-shimmer height="4rem"></app-content-shimmer>
      </div>
      <div class="mb-4">
        <app-content-shimmer height="4rem"></app-content-shimmer>
      </div>
      <div class="mb-4">
        <app-content-shimmer height="4rem"></app-content-shimmer>
      </div>
      <div class="mb-4">
        <app-content-shimmer height="4rem"></app-content-shimmer>
      </div>
      <div class="mb-4">
        <app-content-shimmer height="4rem"></app-content-shimmer>
      </div>
    </ng-container>

    <ng-container *ngIf="!isIniting">

      <p class="text-sm mb-2"><span i18n>S to pristopno izjavo potrjujem:</span></p>
      <p class="text-sm"><span
              i18n>• da sem pooblaščen registrirati uporabniški račun v imenu organizacije:</span> <span
              class="font-bold ml-2">{{ companyName }}</span>,</p>
      <p class="text-sm"><span i18n>• da soglašam s</span><a [href]="policyLinks.privacy" target="_blank" i18n>Politiko
        varstva posredovanih osebih podatkov</a><span>,</span></p>
      <p class="text-sm"><span i18n>• da soglašam s</span><a [href]="policyLinks.terms" target="_blank" i18n>Splošnimi
        pogoji uporabe platforme AutoBrief</a><span>,</span></p>
      <p class="text-sm"><span i18n>• da soglašam s</span><a [href]="policyLinks.dataPolicy" target="_blank" i18n>Politiko
        varstva poslovnih podatkov</a><span>.</span></p>

      <hr>

      <div class="text-center">

        <div style="max-width: 280px" class="mx-auto card border-primary bg-grayNavi">
          <div class="card-content">
            <p class="text-lg text-primary font-bold uppercase mb-2"><span i18n>Standardni račun</span></p>

            <p class="text-gray font-bold text-lg uppercase line-through">75€</p>

            <div class="mb-2 mt-1">
              <span class="rounded-full bg-primary text-white px-4 py-1 text-xs">40% popust</span>
            </div>


            <p class="text-primary font-bold text-3xl uppercase">45€*</p>

            <p class="text-sm text-grayDark text-sm"><span i18n>/na mesec</span></p>
          </div>
        </div>

        <div class="mt-2 text-sm">
          <p class="text-sm text-grayDark" i18n>*DDV ni vključen v ceno</p>
          <p class="text-sm text-grayDark"><span i18n>*Obračunsko obdobje se začne po izteku brezplačnega obdobja</span> (
            <span class="font-bold">
              <ng-container [ngSwitch]="daysTillTrialEnds">
                <span *ngSwitchCase="1" i18n>{{daysTillTrialEnds}} dan</span>
                <span *ngSwitchCase="2" i18n>{{daysTillTrialEnds}} dneva</span>
                <span *ngSwitchDefault i18n>{{daysTillTrialEnds}} dni</span>
              </ng-container>
            </span>)
          </p>
        </div>
      </div>

      <hr>

      <p class="text-sm" i18n>
        Z oddajo te izjave vstopate v naročniško razmerje z družbo BRIEFD, d.o.o. kot uporabnik platforme AutoBrief.
        Želimo vam prijetno uporabniško izkušnjo in uspešno prodajo.
      </p>


      <div class="text-center mt-8">

        <div class="flex justify-center items-center" *ngIf="isLoading">
          <div class="mx-auto">
            <app-spinner></app-spinner>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n [disabled]="isLoading">Zapri</button>
        <button class="btn btn-primary ml-1" (click)="confirm()" [disabled]="isLoading"><span i18n>Potrdi</span>
        </button>
      </div>

    </ng-container>

  </div>


</div>

