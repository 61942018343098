import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import {environment} from "@src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    console.error('AB ERROR:', error);

    if (environment.production) {
      Sentry.captureException(error);
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      console.error('Chunk loading error: Reloading window');
      window.location.reload();
    }
  }
}
