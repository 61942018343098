<div class="flex gap-4">
  <div class="flex-2">
    <label class="mb-1 block" style="white-space: pre-wrap" [for]="field.fieldUuid">
      <span class="leading-normal">{{ field.config.label }}</span>
      <app-tooltip class="ml-1" [content]="field.config.description"></app-tooltip>
    </label>

    <input type="text"
           [placeholder]="field.config.placeholder"
           aria-label="Number"
           matInput
           [formControl]="postNumberControl"
           [ngClass]="{ 'input-error': postNumberControl.invalid }"
           [matAutocomplete]="numberAuto"
           [attr.tabIndex]="0">
    <mat-autocomplete #numberAuto="matAutocomplete">
      <mat-option *ngFor="let post of filteredPostNumbers$ | async" [value]="post.code">
        {{ post.code }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="flex-1">
    <label class="mb-1 block" style="white-space: pre-wrap" [for]="field.fieldUuid">
      <span class="leading-normal">{{ field.connectedField.config.label }}</span>
      <app-tooltip class="ml-1" [content]="field.connectedField.config.description"></app-tooltip>
    </label>

    <input type="text"
           [placeholder]="field.connectedField.config.placeholder"
           aria-label="Number"
           matInput
           [formControl]="postNameControl"
           [ngClass]="{ 'input-error': postNameControl.invalid }"
           [matAutocomplete]="nameAuto"
           [attr.tabIndex]="0">
    <mat-autocomplete #nameAuto="matAutocomplete">
      <mat-option *ngFor="let name of filteredPostNames$ | async" [value]="name">
        {{ name }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
