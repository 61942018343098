<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold" *ngIf="!options.entityUuid">Hitro dodajanje</p>
      <p i18n="" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold" *ngIf="options.entityUuid">Hitro urejanje</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <hr class="mb-0 mt-0">

  <div class="card-content">
    <div class="flex items-center">
      <div class="flex flex-row items-center p-4 rounded border-red bg-redWashed border text-red text-sm leading-normal w-full"
           *ngIf="vehicleIsInStock">
        <i class="fas fa-info-circle mr-2 mt-1"></i>
        <div class="flex flex-col flex-grow">
          <span class="font-bold text-base"
                i18n>Vozilo z vnešenim VIN je že na zalogi!</span>
          <button class="mt-2 w-auto btn btn-primary ml-4" (click)="selectVehicle()"><span i18n>Izberi vozilo</span></button>
        </div>
      </div>

      <div class="my-4 p-4 rounded border-red bg-redWashed border text-red text-sm leading-normal"
           *ngIf="vehicleIsArchived">
        <i class="fas fa-info-circle mr-2"></i>
        <span class="ml-1" i18n>Vozilo z tem VIN-om se že nahaja na seznamu vseh vozil, vendar je bilo</span>
        <span class="ml-1 font-bold" i18n>že prodano.</span>
      </div>
    </div>
  </div>

  <div class="p-3 py-0 sm:py-4 sm:pt-0 overflow-y-auto relative overflow-x-hidden max-h-75vh">
    <ng-container *ngIf="!isPageLoading && formGroup; else formShimmer">
      <ng-container *ngFor="let tab of form.layouts; let i = index">
        <app-form-layout [sectionItem]="asSection(tab)"
                         [formGroup]="formGroup.controls[i]"
                         [form]="form"></app-form-layout>
      </ng-container>
    </ng-container>
  </div>

  <hr class="mt-0 mb-0">

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Zapri</button>
      <button class="btn btn-primary ml-1" i18n="" (click)="submit()"
              [disabled]="isSubmitting || !formGroup?.valid || vehicleIsInStock"
              data-test="save-button"
      >
        Shrani
      </button>
    </div>
  </div>
</div>

<ng-template #formShimmer>
  <div class="card-content">
    <div class="mb-4">
      <app-content-shimmer height="3.5rem"></app-content-shimmer>
    </div>
    <div class="mb-4">
      <app-content-shimmer height="3.5rem"></app-content-shimmer>
    </div>
    <div class="mb-4">
      <app-content-shimmer height="3.5rem"></app-content-shimmer>
    </div>
  </div>
</ng-template>
