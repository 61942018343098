import {Injectable} from '@angular/core';
import {Field} from '@app/interfaces';


@Injectable({
    providedIn: 'root'
})
export class FieldHelperService {

    public getFieldByTypeId(typeId: string, fields: { [key: string]: Field }): Field | null {
        for (const fieldUuid in fields) {
            if (fields.hasOwnProperty(fieldUuid)) {
                const field = fields[fieldUuid];
                if (field?.config.type_id === typeId || field?.config.typeId === typeId) {
                    return fields[fieldUuid];
                }
            }
        }
        return null;
    }

    public getField(typeId: string, fields: { [key: string]: Field<unknown> }, values: Field<unknown>[]) {
        const field = this.getFieldByTypeId(typeId, fields);

        if (field) {
            const copy = {...field};
            copy.value = this.findFieldValue(values, field)?.value || [];

            return copy;
        }

        return null;
    }

    // TODO: Refactor this function
    public getValue(typeId: string, fields: { [key: string]: Field<unknown> }, values: Field<unknown>[]) {
        const field = this.getFieldByTypeId(typeId, fields);

        if (field) {
            const f = this.findFieldValue(values, field);
            if (f) {
                return f.value;
            }
            //  oh oh, the values for this fields have NEVER been set before, use a new COPY from the subfields
            return {...field}.value;
        }

        return null;
    }

    getFieldValue(typeId: string, fields: any) {
        for (const field of fields.fields) {
            if (field.config.typeId === typeId && field.value !== null) {
                return field.value;
            }
        }

        return null;
    }

    // TODO: Refactor this function
    public getDropdownValue(typeId: string, fields: { [key: string]: Field<unknown> }, values: Field<unknown>[]) {
        return Object.values(this.getValue(typeId, fields, values) || {})[0];
    }

    public getCurrencyConversionField(typeId: string, conversionRateTypeId: string, fields: { [key: string]: Field<unknown> }, values: Field<unknown>[]) {
        const field = this.getFieldByTypeId(typeId, fields);

        if (field) {
            const copy = {...field};
            const currencyConversionRateValue = this.getValue(conversionRateTypeId, fields, values);
            if (currencyConversionRateValue && copy.currencyConversion) {
                copy.currencyConversion.conversionRate = currencyConversionRateValue;
            }

            copy.value = this.findFieldValue(values, field)?.value ?? [];

            return copy;
        }

        return null;
    }

    public getSubFieldByTypeId(typeId: string, field: Field) {
        return this.getFieldByTypeId(typeId, field.config.subFields);
    }

    public createProxyField(value: unknown, type: string): Field {
        return {
            config: {}, fieldTemplateUuid: '', fieldUuid: '',
            type,
            value
        };
    }

    private findFieldValue(values, field) {
        return values.find((element) => field.fieldUuid === element.fieldUuid || field.uuid === element.fieldUuid);
    }
}
