<div *ngIf="isLoading; else content">
  <div class="card bg-white">
    <div class="card-content">
      <div class="mb-3">
        <app-content-shimmer height="60px"></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer height="60px"></app-content-shimmer>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="card h-full bg-white"
       [ngClass]="{'border-green border-2': isActive && !activePackage.to, 'border-grayTableBorder': !isActive, 'border-orange border-2': isActive && activePackage.to && daysTillEndOfSubscription > 0 && !activePackage.isExpired}">
    <div class="bg-primary text-white text-center font-bold" *ngIf="isActive && !activePackage.to">AKTIVEN</div>
    <div class="bg-orange text-white text-center font-bold"
         *ngIf="isActive && activePackage.to && daysTillEndOfSubscription > 0 && !activePackage.isExpired">AKTIVEN
      še
      <ng-container [ngSwitch]="daysTillEndOfSubscription">
        <span *ngSwitchCase="1" i18n>{{ daysTillEndOfSubscription }} dan</span>
        <span *ngSwitchCase="2" i18n>{{ daysTillEndOfSubscription }} dneva</span>
        <span *ngSwitchDefault i18n>{{ daysTillEndOfSubscription }} dni</span>
      </ng-container>
    </div>
    <div class="card-content flex flex-row ">
      <div class="w-full h-full flex flex-col items-start">
        <div class="flex w-full text-lg">
          <div class="font-bold"><span class="uppercase">{{ productPackage.name }}</span>&nbsp;<span i18n>Paket</span>
          </div>
          <div class="grow flex justify-start md:justify-end items-end">
            <div class="font-bold text-primary text-right">
              {{ (subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? (++productPackage.priceMonthly) : (++productPackage.priceYearly / 12)) | multiCurrency:accountConfig$ | async }}
              <span *ngIf="((!productPackage.priceMonthly || productPackage.priceMonthly === 0) && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY) || ((!productPackage.priceYearly || productPackage.priceYearly === 0) && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY)"
                    i18n> BREZPLAČNO</span>
              <span *ngIf="productPackage.priceMonthly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY"
                    i18n> / mesec</span>
              <span *ngIf="productPackage.priceYearly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                    i18n> / mesec*</span>
              <span *ngIf="productPackage.priceYearly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                    class="text-xs text-gray block"
                    i18n>*prihranite 10% z letnim plačilom</span>

              <span *ngIf="((productPackage.priceMonthly && productPackage.priceMonthly > 0) && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY) || ((productPackage.priceYearly && productPackage.priceYearly > 0) && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY)"
                    class="text-xs text-gray block"
                    i18n>cena ne vsebuje DDV</span>
            </div>

          </div>
        </div>

        <div class="flex flex-row w-full">
          <div class="grow flex justify-start md:justify-end items-end"
               *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? productPackage.discountMonthly : productPackage.discountYearly">
            <div class="text-grayDark text-sm leading-normal">
                <span class="line-through">
                  {{ (subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? (++productPackage.priceMonthly + ++productPackage.discountMonthly) : ((++productPackage.priceYearly / 12) + (++productPackage.discountYearly / 12))) | multiCurrency:accountConfig$ | async }}
                  <span *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY" i18n> / mesec</span>
                <span *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                      i18n> / mesec</span></span>
            </div>
          </div>
        </div>

        <div class="flex w-full mt-4">
          <i class="text-primary fas fa-users"></i>
          <div class="text-sm ml-2 text-left font-bold">{{ productPackage.limitUsers }}
            <span *ngIf="productPackage.limitUsers === 1" i18n>uporabnik</span>
            <span *ngIf="productPackage.limitUsers === 2" i18n>uporabnika</span>
            <span *ngIf="productPackage.limitUsers >= 3 && productPackage.limitUsers <= 4" i18n>uporabniki</span>
            <span *ngIf="productPackage.limitUsers >= 5" i18n>uporabnikov</span>

          </div>
        </div>

        <hr class="h-px w-full mt-4 mb-4 bg-grayHover dark:bg-grayDark">

        <div class="w-full h-full bg-grayNavi rounded p-3"
             *ngIf="productPackage.nextPackageFeatures.length > 0; else showAllFunctionality">
          <button class="btn-sm btn-link text-xs p-0 text-primary hover:no-underline hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut"
                  (click)="toggleFeatures()">
            <span *ngIf="!toggle" i18n>Paket vključuje</span>
            <span *ngIf="toggle" i18n>Skrij podrobnosti paketa</span>
            <i class="ml-2 mt-1 fa"
               [ngClass]="{'fa-angle-down text-primary': !toggle, 'fa-angle-up text-gray': toggle}"></i>
          </button>
          <ul class="mt-2 grid grid-cols-2 text-xs" *ngIf="toggle">
            <li class="pl-4 mt-2" *ngIf="productPackage.productCode === ProductPackageCodeEnum.SUBSCRIPTION_STARTER"><i
                    class="fas fa-check text-primary mr-2"></i><span i18n>vse kar vsebuje Paket MINI</span></li>
            <li class="pl-4 mt-2"
                *ngIf="productPackage.productCode === ProductPackageCodeEnum.SUBSCRIPTION_PRO">
              <i class="fas fa-check text-primary mr-2"></i><span i18n>vse kar vsebuje Paket STARTER</span></li>
            <ng-container *ngFor="let featureFlag of productPackage.nextPackageFeatures">
              <ng-container [ngSwitch]="featureFlag">
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.CALCULATIONS_AND_COSTS_MANAGEMENT"><i
                        class="fas fa-check text-primary mr-2"></i><span i18n>Kalkulacije in vodenje stroškov</span>
                </li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.DOCUMENT_ARCHIVE"><i
                        class="fas fa-check text-primary mr-2"></i><span i18n>Arhiv dokumentov pri nabavi</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.FEATURE_FLAG_DMV_CALCULATION"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Informativni izračun davka za uvoz vozila</span></li>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ASSETS"><i
                        class="fas fa-check text-primary mr-2"></i><span i18n>Samostojen modul za prodajo blaga in storitev</span>
                </li>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ADVERTISEMENT_VIRTUAL_SALON"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Objava vozila v virtualni salon</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ADVERTISEMENT_SOCIAL_NETWORKS"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Direktna organska objava oglasov na socialna omrežja</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.IS_AVAILABLE_PHYRON"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Avtomatizirana objava oglasov na socialna omrežja</span></li>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.CLIENT_REMINDERS"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Opomniki</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.CLIENT_ACTIVITY_TRACKING"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Beleženje aktivnosti na kontaktih</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.CLIENT_VAT_SEARCH"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Vnos pravnih oseb po davčni številki</span></li>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.DOCUMENTS"><i
                        class="fas fa-check text-primary mr-2"></i><span i18n>Dokumenti</span></li>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.FEATURE_FLAG_FISCALIZATION_V2"><i
                        class="fas fa-check text-primary mr-2"></i><span i18n>Davčna blagajna</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ADMINISTRATION_EXPORT"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Izvoz izdanih računov za računovodstvo</span></li>

                <ng-container *ngSwitchCase="AccountFeatureFlags.FEATURE_FLAG_ANALYTICS">
                  <li class="pl-4 mt-2"><i class="fas fa-check text-primary mr-2"></i><span i18n>Tabelarični pregled poslovanja</span>
                  </li>
                  <li class="pl-4 mt-2"><i class="fas fa-check text-primary mr-2"></i><span i18n>Grafični pregled poslovanja</span>
                  </li>
                  <li class="pl-4 mt-2"><i class="fas fa-check text-primary mr-2"></i><span i18n>Izvoz podatkov</span>
                  </li>
                </ng-container>

                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ROLES_AND_PERMISSIONS"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Določanje vlog in pravic uporabnikov</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.FEATURE_FLAG_BUSINESS_UNITS"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Dodatne poslovne enote</span></li>
                <li class="pl-4 mt-2" *ngSwitchCase="AccountFeatureFlags.ACCOUNTANT_ACCESS"><i
                        class="fas fa-check text-primary mr-2"></i><span
                        i18n>Direkten dostop za računovodje</span></li>


              </ng-container>
            </ng-container>
          </ul>

        </div>

        <ng-container *ngIf="userProfile$ | async as userProfile">
          <div class="flex justify-between mt-3 w-full">
            <div>
              <button class="btn btn-primary px-3 py-1" *ngIf="!isActive || trialExpired"
                      [disabled]="!userProfile.isAdmin || (!activePackage.isFree && !activePackage.isTrial && !activePackage.isExpired && !activePackage.isPaid)"
                      (click)="upgradePackage(productPackage, subscriptionType)">
                <span i18n>Izberi paket</span>
                <app-tooltip class="ml-1" classes="text-primary"
                             *ngIf="(!activePackage.isFree && !activePackage.isTrial && !activePackage.isExpired && !activePackage.isPaid)"
                             content="Paketa ne morete izbrati ker imate odprte dolgove">
                </app-tooltip>
                <app-tooltip class="ml-1" classes="text-primary" *ngIf="(!userProfile.isAdmin)"
                             content="Samo administrator oz. glavni uporabnik lahko spreminja paket">
                </app-tooltip>
              </button>
              <button class="btn btn-danger-link px-3 py-1"
                      *ngIf="isActive && !trialExpired && !activePackage.to && productPackage.order > 1 && !activePackage.isTrial"
                      (click)="unsubscribePackage()">
                <span i18n>Prekliči naročnino</span>
              </button>
              <button class="btn btn-primary px-3 py-1"
                      *ngIf="isActive && !trialExpired && activePackage.to  && productPackage.order > 1 && !activePackage.isTrial"
                      (click)="reSubscribePackage()">
                <span i18n>Ponovno aktiviraj</span>
              </button>
              <button class="btn btn-primary-link px-3 py-1"
                      *ngIf="isActive && !trialExpired && !activePackage.to && productPackage.order > 1 && !activePackage.isTrial && activePackage.paymentUrl"
                      (click)="openSubscriptionPaymentSettings()">
                <span i18n>Nastavitve Naročnine</span>
              </button>
            </div>
          </div>
          <div *ngIf="!userProfile.isAdmin">
            <span class="text-sm text-gray font-bold" i18n>Samo lastnik računa lahko upravlja z paketi</span>
          </div>
        </ng-container>

        <ng-template #showAllFunctionality>
          <div class="w-full h-full bg-grayNavi rounded p-3">
            <button class="btn-sm btn-link text-xs p-0 text-primary hover:no-underline hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut"
                    (click)="toggleFeatures()">
              <span *ngIf="!toggle" i18n>Paket vključuje</span>
              <span *ngIf="toggle" i18n>Skrij podrobnosti paketa</span>
              <i class="ml-2 mt-1 fa"
                 [ngClass]="{'fa-angle-down text-primary': !toggle, 'fa-angle-up text-gray': toggle}"></i>
            </button>
            <div class="w-full h-full bg-grayNavi rounded p-3" *ngIf="toggle">
              <app-registration-page-product-package-features
                      [productPackages]="[productPackage]"></app-registration-page-product-package-features>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

