import {Component, OnInit} from '@angular/core';
import {AccountFeaturesEnum} from "@app/interfaces/config/accountFeaturesEnum";
import {PermissionEnum} from '@app/enums/permissions/PermissionEnum';

@Component({
  selector: 'app-administration-sidebar',
  templateUrl: './administration-sidebar.component.html',
  styleUrls: ['./administration-sidebar.component.scss']
})
export class AdministrationSidebarComponent implements OnInit {
  opened = true;
  public AccountFlagEnum = AccountFeaturesEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleInvoices() {
    this.opened = !this.opened;
  }

  protected readonly PermissionEnum = PermissionEnum;
}
