import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Vehicle} from '@app/interfaces';
import {Router} from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';
import {NotificationService} from '@app/services/notification.service';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {firstValueFrom, Observable, Subject} from "rxjs";
import {AccountConfig} from '@app/interfaces/config/account.config';
import {map, takeUntil} from "rxjs/operators";
import {AccountFeaturesEnum} from "@app/interfaces/config/accountFeaturesEnum";

@Component({
  selector: 'app-share-ad-dialog',
  templateUrl: './share-ad-dialog.component.html',
  styleUrls: ['./share-ad-dialog.component.scss']
})
export class ShareAdDialogComponent implements OnInit, OnDestroy {

  public vehicle: Vehicle;
  private accountConfig$: Observable<AccountConfig>;
  private accountConfig: AccountConfig;
  private destroy$ = new Subject<void>();
  private hasVirtualSalonV2: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService,
              private vehicleApi: VehicleApiService,
              public dialogRef: MatDialogRef<unknown>,
              private configApi: ConfigAPIService,
  ) {

    this.vehicle = data.vehicle;
  }

  static open(dialog: MatDialog, vehicle: Vehicle) {
    return dialog.open(ShareAdDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {
        vehicle,
      }
    });
  }

  async ngOnInit() {
    this.accountConfig$ = this.configApi.getAccountConfig();
    this.accountConfig = await firstValueFrom(this.accountConfig$);

    this.accountConfig$.pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.hasVirtualSalonV2 = result.accountFeatureFlags?.[AccountFeaturesEnum.HAS_VIRTUAL_SALON_V2]
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  close() {
    this.dialogRef.close(false);
  }

  getPublicAdUrl() {
    return this.hasVirtualSalonV2
      ? this.vehicleApi.getPublicUrlForVehicleV2(this.vehicle.vehicleUuid, this.accountConfig.locale)
      : this.vehicleApi.getPublicUrlForVehicle(this.vehicle.vehicleUuid, this.accountConfig.locale);
  }

  selectText(event) {
    event.target.setSelectionRange(0, this.getPublicAdUrl().length);
  }

  copyToClipboard() {
    this.clipboard.copy(this.getPublicAdUrl());
    this.notificationService.showInfoAlert($localize`:@@notification.copy-to-clipboard-success:Tekst je bil uspešno skopiran`);
  }
}
