<a class="bg-white border rounded border-grayTableBorder p-3 flex flex-row hover:border-primary cursor-pointer"
   (click)="redirect()"
   [routerLink]="'/admin/administration/document/'+result.document_id"
   style="min-height: 90px;">
  <div class="flex flex-col w-full my-auto pointer-events-none">
    <div class="flex flex-row items-center group">
      <i class="fa fa-book text-greenDarkOlive group-hover:text-primary fadeInFadeOut mr-2"></i>
      <a [routerLink]="['/admin/clients', result.uuid, {outlets: {tab: 'data/client/' + result.uuid}}]"
         class="hover:no-underline focus:no-underline">
        <div
          class="text-greenDarkOlive text-sm font-semibold leading-normal group-hover:text-primary fadeInFadeOut">{{ result.type }}</div>
      </a>
    </div>

    <div class="leading-loose" [ngClass]="{'mt-1': result.reference}">
      <ul class="text-grayDark text-xs ml-6">
        <li class="leading-relaxed" *ngIf="result.reference">
          <span i18n class="">Št:</span>
          <span
            class="ml-1 font-semibold">{{ result.reference }}</span>
        </li>
        <li class="leading-relaxed" *ngIf="result.created_at">
          <span i18n class="">Datum:</span>
          <span
            class="ml-1 font-semibold">{{ result.created_at | date }}</span>
        </li>
      </ul>
    </div>
  </div>
</a>
