import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Client} from '@app/interfaces/entities/client';
import {Router} from '@angular/router';

@Component({
  selector: 'app-global-search-result-card-client',
  templateUrl: './global-search-result-card-client.component.html',
  styleUrls: ['./global-search-result-card-client.component.scss']
})
export class GlobalSearchResultCardClientComponent {

  @Input() result: Client;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private router: Router) {
  }

  redirect() {
    this.onClose.emit(true);
    if (this.router.url.includes(this.result.clientUuid)) {
      return window.location.reload();
    } else {
      this.router.navigate([`/admin/clients/${this.result.clientUuid}`]);
    }
  }

}
