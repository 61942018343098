import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Field} from '../../../interfaces';
import {FormBuilder} from '@angular/forms';
import {FormHelperService} from '../../../util/form-helper.service';
import {NotificationService} from '../../../services/notification.service';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {Observable, Subscription} from 'rxjs';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {CurrencyObject} from '@app/interfaces/config/currency-object';
import {CurrencyConversionObject} from '@app/services/businessOverview/business-overview-stats.service';

@Component({
  selector: 'app-field-currency-form-dialog',
  templateUrl: './field-currency-form-dialog.component.html',
  styleUrls: ['./field-currency-form-dialog.component.scss']
})
export class FieldCurrencyFormDialogComponent implements OnInit, OnDestroy {
  private entityApi: any;
  public entityUuid: string;
  public currencyField: Field<any>;
  public conversionRateField: Field<any>;
  public formGroup;
  public isSubmitting: boolean;
  public valueInForeignCurrency: number;
  private currencyValueUnfixed: number;
  private subscription: Subscription = new Subscription();
  isFixedConversionRate = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<any>,
              private formHelperService: FormHelperService,
              private configAPIService: ConfigAPIService,
              private notificationService: NotificationService) {

    this.entityApi = data.entityApi;
    this.entityUuid = data.entityUuid;
    this.currencyField = Object.assign({}, data.field);
    this.conversionRateField = Object.assign({}, data.connectedField);
    this.formGroup = data.formGroup;
    this.valueInForeignCurrency = 0;
    this.currencyValueUnfixed = data.field.value;


  }

  static open(dialog: MatDialog, entityUuid: string, entityApi: any, field: Field<any>, connectedField: Field<any>) {
    return dialog.open(FieldCurrencyFormDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        entityUuid,
        entityApi,
        field,
        connectedField
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(this.configAPIService.getAccountConfig().subscribe((accountConfig) => {
      this.isFixedConversionRate = this.getFixedConversionRate(this.currencyField.currencyConversion.currencyCode, accountConfig.currencies);
    }));

    if (!this.conversionRateField.value) {
      this.conversionRateField.value = this.currencyField.currencyConversion.conversionRate;
    }



    const controls = {};
    controls[this.currencyField.fieldUuid] = this.formHelperService.prepareFormGroupControlForFieldItem(this.currencyField);
    if (this.currencyValueUnfixed) {
      controls[this.currencyField.fieldUuid].setValue(this.currencyValueUnfixed.toFixed(2));
    }
    if (this.conversionRateField) {
      controls[this.conversionRateField.fieldUuid] = this.formHelperService.prepareFormGroupControlForFieldItem(this.conversionRateField);
    }
    this.formGroup = this.formBuilder.group(controls);

    this.subscription.add(this.configAPIService.getAccountConfig().subscribe((accountConfig) => {
      this.refreshConversionRate(accountConfig.currencyConversion.latest_rate);

      this.isFixedConversionRate = this.getFixedConversionRate(this.conversionRateField.currencyConversion.currencyCode, accountConfig.currencies);
    }));
  }

  onKeyUp(uuid: any, event: any) {
    switch (uuid) {
      case this.currencyField.fieldUuid:
        this.currencyValueUnfixed = this.formGroup.controls[this.currencyField.fieldUuid].value;
        this.valueInForeignCurrency = +(this.formGroup.controls[this.currencyField.fieldUuid].value * this.formGroup.controls[this.conversionRateField.fieldUuid].value).toFixed(2);
        break;
      case this.conversionRateField.fieldUuid:
        this.currencyValueUnfixed = this.valueInForeignCurrency / this.formGroup.controls[this.conversionRateField.fieldUuid].value;
        this.formGroup.get(this.currencyField.fieldUuid).setValue(+(this.currencyValueUnfixed).toFixed(2));
        break;
      default:
        this.currencyValueUnfixed = event.target.value / this.formGroup.controls[this.conversionRateField.fieldUuid].value;
        this.formGroup.get(this.currencyField.fieldUuid).setValue(+(this.currencyValueUnfixed).toFixed(2));
        break;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    this.isSubmitting = true;

    const fieldValue = this.currencyValueUnfixed;
    const connectedFieldValue = this.formGroup.controls[this.conversionRateField.fieldUuid].value;

    const patchFields = [
      {
        fieldUuid: this.currencyField.fieldUuid,
        value: fieldValue
      },
      {
        fieldUuid: this.conversionRateField.fieldUuid,
        value: connectedFieldValue
      },
    ];

    if (this.entityApi) {
      this.entityApi.patchFields(this.entityUuid, patchFields)
        .subscribe((result) => {
          this.dialogRef.close(patchFields);
        }, (error) => {
          this.isSubmitting = false;
          this.notificationService.showErrorAlert();
        });
    } else {
      this.dialogRef.close(patchFields);
    }
  }

  getFixedConversionRate(currencyCode: string, currencies: CurrencyObject[]): boolean {

    let isFixedConversionRate = true;
    currencies.forEach((currency) => {
      if (currency.code === currencyCode) {
        isFixedConversionRate = !!currency.fixed_conversion_rate;
      }
    });

    return isFixedConversionRate;
  }

  private refreshConversionRate(latestRate: number) {
    this.valueInForeignCurrency = +(this.currencyField.value * this.conversionRateField.value).toFixed(2);
    this.conversionRateField.value = latestRate;
    this.formGroup.get(this.conversionRateField.fieldUuid).setValue(+(this.conversionRateField.value));

    this.currencyValueUnfixed = this.valueInForeignCurrency / this.formGroup.controls[this.conversionRateField.fieldUuid].value;
    this.formGroup.get(this.currencyField.fieldUuid).setValue(+(this.currencyValueUnfixed).toFixed(2));
  }
}
