import {ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {CompaniesSearchApiService} from '@app/services/api/companies-search-api.service';
import {EMPTY, of, Subject} from 'rxjs';
import {FormDataService} from '@app/services/data/form-data.service';
import {catchError, debounceTime, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {CompanySearchResult} from '@app/interfaces/responses/company-search-result';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {ArrayResult} from "@app/interfaces/responses/array-result";

@Component({
  selector: 'app-form-input-vat-search',
  templateUrl: './form-input-vat-search.component.html',
  styleUrls: ['./form-input-vat-search.component.scss']
})
export class FormInputVatSearchComponent implements OnInit, OnDestroy {
  control = new FormControl('');

  @Input() field: Field<number> | null;

  isLoading: boolean;
  showDropdown: boolean;
  searchResults: CompanySearchResult[];
  private localeCode: string;
  private destroy$ = new Subject<void>();

  constructor(
    private thirdPartyApiService: CompaniesSearchApiService,
    private formDataService: FormDataService,
    private configApi: ConfigAPIService,
    private ref: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.localeCode = this.locale;
  }

  ngOnInit(): void {
    this.configApi.getAccountConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountConfig) => {
        this.localeCode = accountConfig.locale || this.localeCode;
      });

    this.control.setValue('');

    this.control.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      debounceTime(250),
      tap(inputText => {
          this.isLoading = true;
          this.showDropdown = inputText.length > 1;
          this.searchResults = null;
        }
      ),
      switchMap((value: string) => {
        if (value.length > 1) {
          return this.thirdPartyApiService.getSearchCompanyByText(value, this.localeCode)
            .pipe(catchError(() => of(null)));
        } else {
          return of({data: [], type_count: 0});
        }
      }),
      catchError(() => EMPTY),
      tap(() => {
        this.isLoading = false;
      })
    ).subscribe((results: (ArrayResult<CompanySearchResult> | null)) => {
      this.searchResults = results.data || [];
      this.ref.markForCheck();
    }, console.error);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private formatApiResultValuesAndSetValuesToFields(result: CompanySearchResult) {
    if (this.field !== null) {
      const typeIdMapping = this.field.config.api_fields_map;
      const mappedResults = {};

      Object.keys(typeIdMapping).forEach((key) => {
        if (typeIdMapping[key] !== undefined) {
          mappedResults[typeIdMapping[key]] = result[key];
        }
      });
      this.formDataService.updateFieldValues(mappedResults);
    } else {
      this.formDataService.updateFieldValues(result);
    }
  }

  clearSearchAndHideDropdown() {
    this.control.setValue('');
    this.showDropdown = false;
  }

  selectResult($event: MouseEvent, result: CompanySearchResult) {
    this.formatApiResultValuesAndSetValuesToFields(result);
    this.clearSearchAndHideDropdown();
  }
}
