<div class="card">
  <div class="card-header p-5 pb-0">
    <h2>Dokument</h2>
  </div>
  <div class="card-content">
    <form [formGroup]="form">

      <div class="mb-3">
        <label for="name" class="block">Ime dokumenta
          <input type="text" name="name" id="name" formControlName="name"
                 [ngClass]="{'input-error': (form.controls.name.invalid && form.controls.name.touched) }">
        </label>

        <p class="input-error-message mt-1" *ngIf="form.controls.name.invalid && form.controls.name.touched">
          {{ 'validation.required'  }}
        </p>
      </div>

      <div class="mb-3">
        <label for="description" class="block">Opis dokumenta
          <textarea type="text" name="description" id="description" formControlName="description"></textarea>
        </label>
      </div>

      <div class="mb-3">
        <label for="workflowTypeId" class="block">Workflow type id
          <input type="text" name="workflowTypeId" id="workflowTypeId" formControlName="workflowTypeId">
        </label>
      </div>

      <div class="mb-3">
        <label for="workflowUuid" class="block">Workflow uuid
          <input type="text" name="workflowUuid" id="workflowUuid" formControlName="workflowUuid">
        </label>
      </div>

      <div class="mb-3">
        <label for="typeId" class="block">Type id
          <input type="text" name="typeId" id="typeId" formControlName="typeId">
        </label>
      </div>


      <div class="mb-3">
        <label for="hasClient" class="block">Has client
          <input type="checkbox" name="hasClient" id="hasClient" formControlName="hasClient">
        </label>
      </div>

      <div class="mb-3">
        <label for="hasVehicle" class="block">Has vehicle
          <input type="checkbox" name="hasVehicle" id="hasVehicle" formControlName="hasVehicle">
        </label>
      </div>

      <div ngx-dropzone *ngIf="files.length === 0" (change)="onSelect($event)" [multiple]="false"
           class="dropzone">
        <ngx-dropzone-label><span class="text-lg">Naloži dokument</span></ngx-dropzone-label>
      </div>

      <div *ngIf="files.length">
        <ul>
          <li *ngFor="let file of files">{{ file.name }}</li>
        </ul>
      </div>
    </form>
    <div class="mt-12 flex flex-row">
      <button class="btn btn-grayDark-link ml-auto mr-2" (click)="close()" [disabled]="isSubmitting">{{ 'misc.cancel'  }}</button>
      <button class="btn ml-2 border-none btn-primary-link" (click)="save()" [disabled]="isSubmitting">Potrdi</button>
    </div>
  </div>
</div>
