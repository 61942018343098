<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="Change entity group modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Kategorije</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"
              [disabled]="isSaving"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <div class="card-content pt-0">
    <ng-container class="p-5" *ngIf="isLoading; else showContent">
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-5">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </ng-container>

    <ng-template #showContent>
      <p class="text-sm text-grayDark mb-3"><span i18n>Za boljši pregled nad svojim imenikom izberi v katero kategorijo spada kontakt.</span></p>

      <div class="flex flex-col">
        <label *ngFor="let group of entityGroups" class="flex">
          <label class="leading-normal flex items-center" style="min-height: 2rem">
            <input
              class="form-checkbox mr-2"
              type="checkbox"
              style="display: inline; margin-top: 0;"
              (change)="toggleGroup(group)" [checked]="getSelectedGroup(group)"/>

            <span class="leading-normal">{{ group.name }}</span>
          </label>
        </label>
      </div>

    </ng-template>

    <div class="mt-12 flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n [disabled]="isSaving">Prekliči</button>
      <button class="btn btn-primary ml-1" (click)="save()" i18n="" [disabled]="isSaving">Posodobi</button>
    </div>
  </div>
</div>
