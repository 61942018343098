import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '../../../../interfaces';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-form-input-engine-power',
  templateUrl: './form-input-engine-power.component.html',
  styleUrls: ['./form-input-engine-power.component.scss']
})
export class FormInputEnginePowerComponent implements OnInit {
  @Input() control: AbstractControl;

  @Input() field: Field<number>;
  engineHorsePower: number;

  readonly kwToHpRatio = environment.kwToHpRatio;

  ngOnInit() {
    this.engineHorsePower = this.getHpFromKw(Number(this.field.value));
  }

  calculateKwFromHp(event: any) {
    const hp = Number(event.target.value);
    this.field.value = Math.round(hp / this.kwToHpRatio);
    this.control.setValue(this.field.value);
  }

  calculateHpFromKw(event: any) {
    const kw = Number(event.target.value);
    this.engineHorsePower = this.getHpFromKw(kw);
  }

  getHpFromKw(kw: number) {
    return Math.round(kw * this.kwToHpRatio);
  }
}
