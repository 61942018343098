<div class="relative flex" style="grid-column-gap: 1rem" *ngIf="accountConfig$ | async as accountConfig">
  <div class="relative mb-3 w-1/2">
    <input type="number"
           [ngModel]="field.value"
           [attr.aria-label]="field.config.label"
           [ngClass]="{ 'input-error': control.invalid }"
           placeholder="{{ field.config.placeholder }}"
           (change)="onPercentFieldChange($event)"
           autocomplete="off"
           [max]="field.config.max"
           [min]="field.config.min"
           [attr.data-field]="field?.fieldUuid"
           [attr.data-typeId]="field?.config?.typeId"
           [attr.tabIndex]="0">

    <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark" style="margin-top: 0.85rem;"
          *ngIf="field.config.append">{{ field.config.append }}</span>
  </div>
  <div class="relative mb-3 w-1/2">
    <input type="number"
           [ngModel]="calculatedValue"
           [disabled]="control.disabled"
           (change)="onRawFieldChange($event)"
           autocomplete="off"
           [attr.tabIndex]="0">
    <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark"
          style="margin-top: 0.85rem;">{{ accountConfig.currencyConversion.symbol }}</span>
  </div>
</div>
