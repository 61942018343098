import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';

@Component({
  selector: 'app-form-input-multi-dropdown',
  templateUrl: './form-input-multi-dropdown.component.html',
  styleUrls: ['./form-input-multi-dropdown.component.scss']
})
export class FormInputMultiDropdownComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() formGroup: FormGroup;
  @Input() field: Field<number>;

  public dropdownFilterSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: 'value',
    textField: 'name',
    enableCheckAll: false,
    itemsShowLimit: 2,
    allowSearchFilter: false,
  };

  ngOnInit(): void {
    if (!this.control.value && this.field.value) {
      this.control.setValue(this.field.value);
    }
  }
}
