<div [ngClass]="{'border border-grayTableBorder rounded p-3': hideExcept === null}"
     [attr.data-field]="field?.fieldUuid"
     [attr.data-typeId]="field?.config?.typeId">

  <div *ngFor="let wrappedItem of control.controls; let i = index;"
       class="repeater-wrapped-item"
       [ngClass]="{'border border-grayTableBorder bg-grayNavi rounded p-3': hideExcept === null}">

    <ng-container *ngIf="field">
      <ng-container *ngIf="hideExcept === null">
        <div class="flex flex-row justify-between items-center">
        <span class="text-md text-black font-bold">
          {{ field.config.texts.item }}<span class="ml-2">#{{ i + 1}}</span>
        </span>

          <button class="btn btn-sm text-gray border-transparent shadow-none hover:text-grayDark fadeInFadeOut"
                  (click)="removeRepeaterItem(i)"
                  type="button"><i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </ng-container>



      <ng-container *ngFor="let subFieldUuid of subFieldsOrder; let i = index">
          <div class="mb-3" [hidden]="hideExcept !== null && wrappedItem !== hideExcept"
               *ngIf="field.config.subFields[subFieldUuid].type !== 'currency_conversion_rate'">

            <app-form-input [formGroup]="asFormGroup(asFormGroup(wrappedItem).controls.value)"
                            [field]="field.config.subFields[subFieldUuid]"
                            *ngIf="field.config.subFields[subFieldUuid].type !== 'currency'; else view"></app-form-input>

            <ng-template #view>
              <app-inline-field-edit [cancelEditOnSave]="true" textSize="sm"
                                     [updateField]="false"
                                     [formGroup]="asFormGroup(asFormGroup(wrappedItem).controls.value)"
                                     [field]="field.config.subFields[subFieldUuid]"
                                     [subFields]="field.config.subFields"
                                     [isHiddenRepeaterField]="hideExcept !== null && wrappedItem !== hideExcept"
                                     (update)="fieldsUpdated($event, asFormGroup(wrappedItem))"></app-inline-field-edit>
            </ng-template>
          </div>
      </ng-container>
    </ng-container>
  </div>

  <app-field-errors class="mt-3 block" [control]="control"></app-field-errors>

  <div class="text-right" *ngIf="hideExcept === null">
    <hr class="my-4" *ngIf="control.controls.length > 0">


    <button class="btn btn-primary btn-sm" (click)="addRepeaterItem()" type="button"><i
            class="fas fa-plus mr-2"></i>{{ field.config.texts.add }}</button>
  </div>
</div>


