import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UserApiService} from '@app/services/api/user-api.service';
import {TranslationService} from '@app/services/translation.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {DefaultsEnum} from '@app/interfaces/defaults.enum';
import {NotificationService} from '@app/services/notification.service';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit, OnDestroy {

  public isSaving = false;
  public error;
  public errorMessage?: string;

  private submittedEmail: string;

  private destroy$ = new Subject<void>();

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private userApiService: UserApiService,
              private translation: TranslationService,
              private notificationService: NotificationService
  ) {

  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.addUser();
    }
  }


  close() {
    this.dialogRef.close(false);
  }

  addUser() {
    this.isSaving = true;
    this.error = undefined;
    this.errorMessage = undefined;
    this.form.disable();

    this.submittedEmail = this.form.controls.email.value;

    this.userApiService.inviteUser(this.form.controls.email.value)
      .subscribe((data) => {
        this.notificationService.showSuccessAlert($localize`:@@title.successful-user-invited:Uporabnik je bil uspešno dodan`, {duration: DefaultsEnum.LONG_TOAST_DURATON});
        this.isSaving = false;
        this.form.enable();

        this.dialogRef.close(true);
      }, (error) => {
        if (error.status >= 400 && error.status < 500 && error.error?.message) {
          this.error = error;
          this.errorMessage = error.error?.message;
        }

        this.notificationService.showErrorAlert(error.error?.message, error);

        this.isSaving = false;
        this.form.enable();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.form.controls.email.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.submittedEmail) {
        this.error = undefined;
      }
    });
  }
}
