import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import * as dayjs from 'dayjs';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-form-input-date',
  templateUrl: './form-input-date.component.html',
  styleUrls: ['./form-input-date.component.scss']
})
export class FormInputDateComponent implements OnChanges, OnInit, OnDestroy {

  @Input() control: AbstractControl;

  @Input() field: Field<string>;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field.currentValue) {
      if (this.field.config.defaultToNow) {
        if (this.field.value === null) {
          this.control.setValue(dayjs());
        }
      }
    }
  }

}
