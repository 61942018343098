<ng-container *ngIf="url">
  <h3 class="text-sm text-black font-normal mb-2"><span i18n="Share vehicle link">Deli z drugimi</span></h3>

  <div class="flex flex-row flex-wrap">
    <ng-container *ngFor="let medium of mediums">
      <a [href]="medium.url" class="mr-2 mb-2" target="_blank">
        <button class="btn btn-primary-outline">
          <ng-container [ngSwitch]="medium.id">
            <ng-template ngSwitchCase="fb">
              <i class="fab fa-facebook text-lg"></i>
            </ng-template>

            <ng-template ngSwitchCase="email">
              <i class="fas fa-envelope text-lg"></i>
            </ng-template>

            <ng-template ngSwitchCase="twitter">
              <i class="fab fa-twitter text-lg"></i>
            </ng-template>

            <ng-template ngSwitchCase="other">
              <i class="fas fa-share-alt text-lg"></i>
            </ng-template>
          </ng-container>
        </button>
      </a>
    </ng-container>
    
    <button class="btn btn-primary-outline mr-2 mb-2" (click)="share()" *ngIf="hasShare()">
      <i class="fas fa-share-alt text-lg"></i>
    </button>
  </div>
</ng-container>
