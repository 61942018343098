<div class="flex flex-col">
  <div *ngFor="let item of files; let i = index; let last = last" [ngClass]="{'py-1': i > 0, 'pb-1': i === 0, 'mb-3': last}">
    <ng-container *ngIf="item.upload; else showLoadingFile">
      <div class="card bg-white flex flex-row w-full justify-center items-center text-sm pl-2 py-1">
        <div style="flex-basis: 20px; shrink: 0"><i class="fas fa-file mr-2 text-primary"></i></div>
        <div style="grow: 1; white-space: nowrap; text-overflow: ellipsis; overflow: hidden" class="pr-2">
            {{ item.upload.data.files[0].fileName }}
        </div>
        <div style="flex-basis: 120px; shrink: 0">
          <span class="text-grayDark mr-2">{{(item.upload.data.files[0].size / 1000000).toFixed(2) | number }} Mb</span>

          <button (click)="removeFile(item)" type="button"
                  class="btn btn-sm btn-grayDark-link">
            <i class="fas fa-trash-alt"></i></button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #showLoadingFile>
    <div class="card bg-white flex flex-row w-full text-sm pl-2 py-2 mb-3">
      <span i18n="Loading text for file upload" class="text-grayDark text-sm py-1">Datoteka se nalaga...</span>
    </div>
  </ng-template>
</div>

<div class="mb-2">
  <div
       class="dropzone bg-grayHover"
         [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId"
       style="background: #F3F3F3 !important; color: black !important; min-height: 10rem !important; border: 2px dashed #DDDDDD; border-radius: 5px;">

    <div class="flex flex-col items-center pt-3">
      <div><img src="assets/images/upload.png"></div>
      <div><p class="text-grayDark text-sm">Mesto za datoteke</p></div>
    </div>
    <div class="flex flex-row justify-center m-2">
      <div>
        <div class="inline">
          <button class="bg-primary text-white font-bold py-2 px-4 rounded mr-2 border border-primary" (click)="openExistingDialog();">IZBERI ŽE OBSTOJEČO DATOTEKO</button>
        </div>
        <label for="file-upload" class="custom-file-upload inline">
          <button class="text-primary font-bold py-2 px-4 rounded border border-primary" style="font-size: 1rem;" i18n="Upload hint" onclick=" document.getElementById('file-upload').click();">NALOŽI DATOTEKO</button>
        </label>
        <input id="file-upload" type="file" (change)="onSelectCustom($event)" style="display: none;"/>
      </div>
    </div>
  </div>

</div>
