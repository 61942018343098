import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AccountConfig} from '@app/interfaces/config/account.config';

@Component({
  selector: 'app-subscription-expired-dialog',
  templateUrl: './subscription-expired-dialog.component.html',
  styleUrls: ['./subscription-expired-dialog.component.scss']
})
export class SubscriptionExpiredDialogComponent {
  public isLoading: boolean = false;
  public accountConfig: AccountConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    this.accountConfig = data.accountConfig;
  }

  static open(dialog: MatDialog, accountConfig: AccountConfig) {
    return dialog.open(SubscriptionExpiredDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {
        accountConfig
      }
    });
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
