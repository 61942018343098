<div class="card bg-white relative border-none">
  <div class="card-header p-5 pb-0">

    <h2 class="text-greenDarkOlive text-xl font-bold"><span i18n="">Dodajanje uporabnika</span></h2>
  </div>
  <div class="card-content">

    <div class="p-4 rounded border-orange bg-orangeWashed border text-orange text-sm leading-normal my-2 flex-row flex"
         role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-1" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"/>
      </svg>
      <span class="block sm:inline w-11/12 text-orange font-bold"
            *ngIf="activePackage.priceAdditionalUser; else cantAddUsers" i18n>
        V naročnini imate {{ activePackage.limitUsers }} brezplačne uporabniške račune. Z dodajanjem novega uporabnika se strinjate
        <a [href]="policyLinks?.terms" target="_blank">s pogoji Autobrief-a</a>
        in zvišano mesečno naročnino v višini {{ activePackage.priceAdditionalUser | multiCurrency:accountConfig$ | async }}
        + DDV/uporabnika
      </span>
      <ng-template #cantAddUsers>
        <span class="block sm:inline w-11/12 text-orange font-bold" i18n>
        V naročnini imate {{ activePackage.limitUsers }} brezplačne uporabniške račune. V brezplačnem računu ni možno dodajati uporabnikov.
      </span>
      </ng-template>
    </div>


    <form (ngSubmit)="submit()" [formGroup]="form" class=" mt-4">
      <div class="mb-5">
        <label for="email" class="block"><span i18n="">Email:</span>
          <input type="text" name="email" id="email" formControlName="email" autocomplete="email"
                 [ngClass]="{'input-error': (form.controls.email.invalid && form.controls.email.touched) || (error) }">
        </label>

        <p class="input-error-message mt-1" *ngIf="form.controls.email.invalid && form.controls.email.touched">
          <span i18n="Form validation error">Polje je obvezno!</span>

        </p>

        <div *ngIf="error">
          <p class="input-error-message mt-1">
            <span *ngIf="!errorMessage" i18n="">Uporabnik s tem emailom že obstaja.</span>
            <span *ngIf="errorMessage">{{errorMessage}}</span>
          </p>
        </div>
      </div>
      <div class="mt-2 mb-2 " *ngIf="activePackage.priceAdditionalUser">
        <label class="inline-flex items-center">
          <input type="checkbox" class="form-checkbox mt-0 cursor-pointer" formControlName="newUser" name="newUser"
                 id="newUser" [(ngModel)]="extraUserAgree">
          <span class="ml-2" i18n>
            Strinjam se, da z dodatnim uporabnikom se mi zaračuna {{ activePackage.priceAdditionalUser | multiCurrency:accountConfig$ | async }}
            + DDV na mesec
          </span>
        </label>
      </div>

      <div class="mt-12 flex flex-row" *ngIf="!isSaving">
        <button class="btn btn-grayDark-link ml-auto mr-2"
                type="button"
                i18n-title="Prekliči@@cancel"
                title="Prekliči@@cancel"
                (click)="close()"><span i18n="Prekliči@@cancel">Prekliči</span></button>

        <button class="btn text-right btn-primary-link ml-2 border-none" type="submit"
                [disabled]="!extraUserAgree || !activePackage.priceAdditionalUser">
          <span i18n="">Ustvari</span>
        </button>
      </div>


      <div class="mt-12 flex justify-center items-center" *ngIf="isSaving">
        <app-spinner></app-spinner>
      </div>
    </form>
  </div>
</div>
