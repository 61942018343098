<div *ngIf="globalSearchResultType.data.total > 0" class="pt-1 h-100" style="z-index: 203;">
  <div class="card mt-1" style="height: 100%; z-index: 204;">
    <div class="pt-1 px-2">
      <span class="text-grayDarker text-sm">{{globalSearchResultType.title}} ({{globalSearchResultType.data.total}})</span>
    </div>
    <div class="card-content grid gap-y-1 h-100" style="padding: 5px !important; max-height: 31rem; overflow-x: scroll;">
      <ng-container [ngSwitch]="globalSearchResultType.type" *ngFor="let result of globalSearchResultType.data.data">
        <div>
          <ng-template ngSwitchCase="clients">
            <app-global-search-result-card-client
              [result]="result" (onClose)="hideResults()"></app-global-search-result-card-client>
          </ng-template>
          <ng-template ngSwitchCase="vehicles">
            <app-global-search-result-card-vehicle
              [result]="result" (onClose)="hideResults()"></app-global-search-result-card-vehicle>
          </ng-template>
          <ng-template ngSwitchCase="documents">
            <app-global-search-result-card-document
              [result]="result" (onClose)="hideResults()"></app-global-search-result-card-document>
          </ng-template>
          <ng-template ngSwitchCase="invoices">
            <app-global-search-result-card-invoice
              [result]="result" (onClose)="hideResults()"></app-global-search-result-card-invoice>
          </ng-template>
          <ng-template ngSwitchDefault>
            Unknown type!
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
