import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-form-input-text',
    templateUrl: './form-input-text.component.html',
    styleUrls: ['./form-input-text.component.scss']
})
export class FormInputTextComponent implements OnInit, OnDestroy {
    @Input() control: FormControl;

    @Input() field: Field<string>;

    private destroy$ = new Subject<void>();

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.control.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    setPreset(preset: string) {
        this.control.setValue(preset);
    }
}
