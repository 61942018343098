import { Component, OnInit } from '@angular/core';
import {SafariScreenFixService} from '../../../services/safari-screen-fix.service';

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent implements OnInit {

  constructor(private safariScreenFix: SafariScreenFixService) { }

  ngOnInit(): void {
  }

  isSafari() {
    return this.safariScreenFix.getIsSafari();
  }
}
