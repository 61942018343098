import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SingleResult} from '@app/interfaces';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {GlobalSearchResult} from '@app/interfaces/entities/global-search-result';
import {ArrayResult} from "@app/interfaces/responses/array-result";

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchApiService {

  constructor(private http: HttpClient) {
  }

  search(text: string): Observable<SingleResult<GlobalSearchResult>> {
    return this.http.post<SingleResult<GlobalSearchResult>>(`${environment.apiUrl}/api/search/global-search`, {text});
  }

  searchAnyDocument(text: string, pagination: { perPage: number, page: number }, administrationFilter: object, documentsFilter: object): Observable<ArrayResult<any>> {
    return this.http.post<ArrayResult<any>>(`${environment.apiUrl}/api/search/any-document`, {text, administrationFilter, documentsFilter, pagination});
  }
}
