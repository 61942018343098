import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UserApiService} from '@app/services/api/user-api.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {DefaultsEnum} from '@app/interfaces/defaults.enum';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject, zip} from 'rxjs';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {PolicyLinks} from "@app/interfaces/config/initial.config";
import {NotificationService} from '@app/services/notification.service';

@Component({
  selector: 'app-max-user-dialog',
  templateUrl: './max-user-dialog.component.html',
  styleUrls: ['./max-user-dialog.component.scss']
})
export class MaxUserDialogComponent implements OnInit {
  public policyLinks: PolicyLinks;

  public isSaving = false;
  public error;
  public errorMessage?: string;
  public extraUserAgree = false;
  public activePackage: AccountProductPackage;
  private destroy$ = new Subject<void>();

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    newUser: new FormControl('', []),
  });

  public accountConfig$: Observable<AccountConfig>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private userApiService: UserApiService,
              private notificationService: NotificationService,
              private configApi: ConfigAPIService) {
  }

  ngOnInit(): void {
    this.accountConfig$ = this.configApi.getAccountConfig().pipe(takeUntil(this.destroy$));

    zip(this.accountConfig$, this.configApi.getInitialConfig(null))
      .pipe(takeUntil(this.destroy$))
      .subscribe(([data, config]) => {
        this.activePackage = data.activeAccountProductPackage;

        this.policyLinks = config.policyLinks[data.locale] || config.policyLinks['default'];
      });
  }


  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.addUser();
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  addUser() {
    this.isSaving = true;
    this.error = undefined;
    this.errorMessage = undefined;

    this.form.disable();

    this.userApiService.inviteUser(this.form.controls.email.value, this.extraUserAgree)
      .subscribe((data) => {
        this.notificationService.showSuccessAlert();
        this.isSaving = false;
        this.form.enable();

        this.dialogRef.close(true);
      }, (error) => {
        console.error('Inviting users error:', error.error);

        if (error.status >= 400 && error.status < 500 && error.error) {
          this.error = error;

          if (error.status === 400) {
            this.errorMessage = error.error?.message;
          }
        } else {
          this.notificationService.showErrorAlert();
        }

        this.isSaving = false;
        this.form.enable();
      });
  }
}
