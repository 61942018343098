<div class="card bg-white">
  <div class="card-heading flex-row">
    <div class="flex justify-between">
      <span i18n="">Paketi in naročnina</span>

      <div class="grow flex justify-start md:justify-end items-end">
        <label class="flex cursor-pointer items-end justify-end">
                  <span class="mr-3 text-gray-900 text-md"
                        [ngClass]="{'font-bold text-primary': subscriptionType === ProductPackageSubscriptionTypeEnum.YEARLY}"
                        i18n>Letno</span>
          <input type="checkbox" (change)="toggleSubscriptionType()" class="sr-only">
          <div class="toggle-bg border border-grayLight rounded-full relative {{ subscriptionType }}"
               style="height: 25px; width:45px;"></div>
          <span class="ml-3 text-gray-900 text-md"
                [ngClass]="{'font-bold text-primary': subscriptionType === ProductPackageSubscriptionTypeEnum.MONTHLY}"
                i18n>Mesečno</span>
        </label>
      </div>
    </div>
    <div class="flex justify-end" *ngIf="!canChangeSubscriptionType && showChangeSubscriptionWarning">
      <span class="text-sm text-grayLight" i18n>Ne morete spremeniti naročnine iz letne na mesečno</span>
    </div>
  </div>

  <div class="card-content">
    <div *ngIf="isLoading;  else showContent">
      <app-form-shimmer></app-form-shimmer>
    </div>

    <ng-template #showContent>
      <div class="flex flex-row flex-wrap">
        <ng-container *ngFor="let productPackage of allPackages">
          <div class="w-full mb-4">
            <app-package-card [trialExpired]="showAll" [productPackage]="productPackage"
                              [subscriptionType]="subscriptionType"></app-package-card>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!showAll">
        <button class="btn-link text-grayDark hover:text-grayDark fadeInFadeOut" (click)="deleteAccount(activePackage)">
          <span class="text-sm text-primary font-bold" i18n>Želite izbrisati račun ?</span>
        </button>
      </div>
    </ng-template>
  </div>
</div>

