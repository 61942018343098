import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {Observable} from "rxjs";
import {SingleResult} from "@app/interfaces";
import {Upload} from "@app/interfaces/entities/upload";

@Injectable({
  providedIn: 'root'
})
export class UploadApiService {

  constructor(private httpClient: HttpClient) {
  }

  upload(files: File[]) {
    const formData = new FormData();
    formData.append('file', files[0], files[0].name);
    return this.httpClient.post(`${environment.uploadsApiUrl}/api/uploads`, formData);
  }

  uploadFile(file: File): Observable<SingleResult<Upload>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<SingleResult<Upload>>(`${environment.uploadsApiUrl}/api/uploads`, formData);
  }

  uploadFileNlb(file: File): Observable<SingleResult<Upload>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<SingleResult<Upload>>(`${environment.apiUrl}/api/integrations/nlb/leasing/upload`, formData);
  }

  uploadImageFile(file: File): Observable<SingleResult<Upload>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<SingleResult<Upload>>(`${environment.uploadsApiUrl}/api/image-uploads`, formData);
  }
}
