export function pluck<Item extends object, Value extends keyof Item, Key extends keyof Item>(array: Item[], value: Value, key: Key): { [key: string]: Item[Value] } {
  return array.reduce((acc, item) => {
    // @ts-ignore
    acc[item[key]] = item[value];
    return acc;
  }, {});
}

export function keyBy<Item extends object, Key extends keyof Item>(array: Item[], key: Key): { [key: string]: Item } {
  return array.reduce((acc, item) => {
    // @ts-ignore
    acc[item[key]] = item;
    return acc;
  }, {});
}

export function toObject(array: string[]): { [key: string]: true } {
  return array.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
}

export function convertCamelToSnakeKeys(obj: any, onlyKeys: string[]) {
  return Object.keys(obj).reduce((acc, key) => {
    if (onlyKeys.includes(key)) {
      acc[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = obj[key];
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function firstValue(value: unknown): any {
  if (value && typeof value === 'object') {
    const values = Object.values(value);
    return values.length > 0 ? values[0] : null;
  }

  return value;
}

export function firstKey(value: unknown): any {
  if (value && typeof value === 'object') {
    const values = Object.keys(value);
    return values.length > 0 ? values[0] : null;
  }

  return value;
}

export function sortByValue(extractFn: (a: unknown) => unknown) {
  const fn = typeof extractFn === 'function' ? extractFn : (a) => a;

  return (a: unknown, b: unknown): number => {
    const aVal = fn(a);
    const bVal = fn(b);

    if (aVal === null || aVal === undefined) {
      return -1;
    }

    if (bVal === null || bVal === undefined) {
      return 1;
    }

    if (aVal === bVal) {
      return 0;
    }

    return aVal > bVal ? 1 : -1;
  }
}

export function isNullable(value: unknown): boolean {
  return value === null || value === undefined;
}

export function valuesToDict(array: (string)[]): { [key: string]: string } {
  return array.reduce((acc, item) => ({...acc, [item]: item}), {});
}
