<div class="card">
  <div class="card-header p-5 pb-0">
    <h2>Uredi polje</h2>
  </div>
  <div class="card-content">

    <div *ngIf="isLoading; else showNew">
      <div class="mb-3">
        <app-content-shimmer height="80px"></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer height="80px"></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer height="80px"></app-content-shimmer>
      </div>
    </div>

    <ng-template #showNew>
      <label>{{ 'fields.fieldType'  }}
        <!--  <div class="dropdown mt-2 mb-3">
          <select [disabled]="isLoading" [(ngModel)]="selectedFieldTemplate"
                  autocomplete="off">
            <option *ngFor="let optionValue of fieldTemplates"
                    [ngValue]="optionValue"> {{ optionValue.type }}</option>
          </select>
        </div> -->

        <input type="text" disabled [value]="field.type">
      </label>

      <div class="mt-6">
        <app-field-form-inputs [template]="selectedFieldTemplate" #newFieldInput [field]="field"></app-field-form-inputs>
      </div>

      <div class="mt-10 flex flex-row justify-between">
        <button class="btn btn-grayDark-link text-left" (click)="close()">{{ 'misc.cancel'  }}</button>
        <button class="btn btn-primary text-right" (click)="edit()"
                [disabled]="isLoading || !newFieldInput.isValid()">Posodobi</button>
      </div>
    </ng-template>
  </div>
</div>
