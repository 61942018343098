import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LogoutPageComponent} from './logout-page/logout-page.component';
import {RouterModule} from '@angular/router';
import {EntityAlarmsTabComponent} from './entity-alarms-tab/entity-alarms-tab.component';
import {EntityBasePageComponent} from './entity-base-page/entity-base-page.component';
import {EntityWorkflowsTabComponent} from './entity-workflows-tab/entity-workflows-tab.component';
import {VehicleExpendituresTabComponent} from './vehicle-expenditures-tab/vehicle-expenditures-tab.component';
import {VehicleMarketsPageComponent} from './vehicle-markets-page/vehicle-markets-page.component';
import {PartialsModule} from '../partials/partials.module';
import {PipesModule} from '../pipes/pipes.module';
import {MatSortModule} from '@angular/material/sort';
import {CdkTableModule} from '@angular/cdk/table';
import {MaintenanceModePageComponent} from './maintenance-mode-page/maintenance-mode-page.component';
import {BriefdFormModule} from '../briefd-form/briefd-form.module';
import {EntityRelationshipTabComponentComponent} from './entity-relationship-tab-component/entity-relationship-tab-component.component';
import {UnauthorizedPageComponent} from './unauthorized-page/unauthorized-page.component';
import {EntityConsentsTabComponent} from '@app/shared/pages/entity-consents-tab/entity-consents-tab.component';
import {DisplayPackagesPageComponent} from './display-packages-page/display-packages-page.component';
import {PackagesPageComponent} from '@app/shared/pages/packages-page/packages-page.component';
import {SharedModule} from '@app/shared/shared.module';
import {NotificationsModule} from '@app/modules/notifications/notifications.module';
import { DeactivatedPageComponent } from './deactivated-page/deactivated-page.component';

@NgModule({
  declarations: [

    LogoutPageComponent,
    PageNotFoundComponent,
    EntityAlarmsTabComponent,
    EntityConsentsTabComponent,
    EntityBasePageComponent,
    EntityWorkflowsTabComponent,
    VehicleExpendituresTabComponent,
    VehicleMarketsPageComponent,
    MaintenanceModePageComponent,
    EntityRelationshipTabComponentComponent,
    UnauthorizedPageComponent,
    DisplayPackagesPageComponent,
    PackagesPageComponent,
    DeactivatedPageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,

    PartialsModule,
    PipesModule,
    MatSortModule,
    CdkTableModule,
    BriefdFormModule,
    NotificationsModule,
  ],
  exports: [
    PageNotFoundComponent,
    LogoutPageComponent,
    EntityAlarmsTabComponent,
    EntityBasePageComponent,
    EntityWorkflowsTabComponent,
    VehicleExpendituresTabComponent,
    VehicleMarketsPageComponent,
    DisplayPackagesPageComponent,
    PackagesPageComponent,
  ]
})
export class PagesModule {
}
