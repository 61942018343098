<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5 pb-0">
    <div class="flex items-center justify-between">
      <div class="flex">
        <span i18n="Select  files from vehicle or customer"
              class=" mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Izberi obstoječe datoteke
        </span>
        <span class="mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
          <ng-container *ngIf="type === 'vehicle'; then vehicle else client">
          </ng-container>
          <ng-template #vehicle>&nbsp;<span i18n>(Vozilo)</span></ng-template>
          <ng-template #client>&nbsp;<span i18n>(Stranka)</span></ng-template>
        </span>
      </div>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut">
        <i
          class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <div class="card-content pt-0">
    <ng-container *ngIf="!isPreloading; else showLoading">
      <ng-container *ngIf="fileTypes.length; else noFiles">
        <ng-container *ngFor="let fileType of fileTypes">
          <div class="card mb-3">
            <div class="card-header pt-3">
              <p i18n="Other documents:">{{fileType[0].fileType}}:</p>
            </div>
            <div class="card-content pt-3">
              <ng-container *ngFor="let file of fileType">
                <div class="card bg-white mb-2">
                  <div class="card-content p-3">
                    <label class="inline-flex items-start">
                      <input type="checkbox" class="form-checkbox mt-0 cursor-pointer align-bottom"
                             (click)="selectAttachment(file.uploadUuid)">
                      <span class="ml-2">
                            <span
                              class="text-gray"
                              style="font-size: 1.2rem;"
                              matTooltipClass="bg-black px-4 py-2 text-white text-sm"><i
                              class="fas fa-file-alt"></i></span>
                        {{ file.files[0].fileName }}
                          </span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noFiles>
        <span i18n>Na tem vozilu/stranki dokumenti ne obstajajo.</span>
      </ng-template>
    </ng-container>

    <ng-template #showLoading>
      <div class="card-content">
        <div class="mb-6">
          <app-content-shimmer height="2rem"></app-content-shimmer>
        </div>
        <div class="mb-6">
          <app-content-shimmer height="2rem"></app-content-shimmer>
        </div>
        <div class="mb-6">
          <app-content-shimmer height="2rem"></app-content-shimmer>
        </div>
        <div class="mb-6">
          <app-content-shimmer height="2rem"></app-content-shimmer>
        </div>
        <div class="">
          <app-content-shimmer height="2rem"></app-content-shimmer>
        </div>
      </div>
    </ng-template>

    <div class="mt-10 flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n="Close">Zapri</button>
      <button class="btn btn-primary" (click)="uploadFiles()" [disabled]="fileTypes.length === 0 || selectedFileUuids.length === 0"><i
        class="fas fa-save mr-2"></i><span i18n="Select">Izberi</span>
      </button>
    </div>
  </div>
</div>
