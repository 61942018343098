<div class="card bg-white">
  <div class="card-content text-center" *ngIf="accountConfig.activeAccountProductPackage?.isTrial">
    <span class="text-xl font-bold text-primary" i18n>Brezplačno obdobje se je izteklo.</span>
    <p>
      <span i18n>Vašega preizkusnega obdobja je konec. Za nadaljevanje uporabe AutoBrief platforme, lahko izbirate med naslednjimi paketi.
</span>
    </p>
  </div>
  <div class="card-content text-center" *ngIf="!accountConfig.activeAccountProductPackage?.isTrial">
    <span class="text-xl font-bold text-primary" i18n>Naročnina je potekla.</span>
    <p>
      <span i18n>Spoštovani,</span>
      <span i18n>vaša naročnina se je IZTEKLA.</span>
    </p>
    <p>
      <span i18n>Na tej točki izberite paket</span>
    </p>
  </div>
  <div>
    <app-packages-page [showAll]="true"></app-packages-page>
  </div>
</div>
