import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Field} from "../../../../interfaces";

@Component({
  selector: 'app-form-input-time',
  templateUrl: './form-input-time.component.html',
  styleUrls: ['./form-input-time.component.scss']
})
export class FormInputTimeComponent {
  @Input() control: AbstractControl;

  @Input() field: Field<string>;
}
