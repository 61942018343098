import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable, throwError} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BusinessUnitsService} from "@app/services/accounts/business-units.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private businessUnitsService: BusinessUnitsService,
                private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //  Add an Authorization header, if the user is logged in
        if (this.authService.isLoggedIn()) {
            const headers = {
                Authorization: `Bearer ${this.authService.getToken()}`,
            };

            //  Add a controlled account uuid header, if a super-admin is controlling an account
            if (this.authService.getAccountControl()) {
                headers['for-account-uuid'] = `${this.authService.getAccountControl().accountUuid}`;
            }

            //  Add a controlled business unit uuid header, if requests for a specific business unit
            if (this.businessUnitsService.getStoredBusinessUnitId()) {
                headers['for-bu-uuid'] = `${this.businessUnitsService.getStoredBusinessUnitId()}`;
            }

            req = req.clone({
                setHeaders: headers,
            });
        }

        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {

                //  401 errors should logout the user as that means the stored access token is not valid anymore
                if (err.status === 401) {
                    this.authService.logout();
                } else if (err.status === 403) {
                    this.authService.logout();
                    this.router.navigate(['/403']);
                } else {
                    return throwError(err);
                }
            })
        );
    }
}
