<div class="dropdown">
  <select [formControl]="control" autocomplete="off" [ngClass]="{'input-error': control.invalid }"
          [attr.data-field]="field?.fieldUuid"
          [attr.data-typeId]="field?.config?.typeId" [attr.tabIndex]="0">
    <option value="" *ngIf="field.config.allowNull !== false || !field.config.hasOwnProperty('allowNull')"></option>

    <option *ngFor="let optionValue of outputChoices"
            [disabled]="optionValue?.disabled"
            [ngValue]="optionValue">{{ optionValue | firstvalue }}
    </option>
  </select>
</div>
