import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Field} from '../../../interfaces';

@Component({
  selector: 'app-refresh-browser-dialog',
  templateUrl: './refresh-browser-dialog.component.html',
  styleUrls: ['./refresh-browser-dialog.component.scss']
})
export class RefreshBrowserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<unknown>) {
  }

  static open(dialog: MatDialog) {
    return dialog.open(RefreshBrowserDialogComponent, {
      width: '650px',
            disableClose: true,
    });
  }

  ngOnInit(): void {
  }


  refresh() {
    this.dialogRef.close(true);
  }
}
