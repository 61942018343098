import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {NotificationService} from '@app/services/notification.service';

@Component({
  selector: 'app-open-product-package-offer-dialog-component',
  templateUrl: './open-product-package-offer-dialog-component.component.html',
  styleUrls: ['./open-product-package-offer-dialog-component.component.scss']
})
export class OpenProductPackageOfferDialogComponentComponent implements OnInit, OnDestroy {
  public accountConfig: AccountConfig;
  public accountProductPackage: AccountProductPackage;
  private destroy$ = new Subject<void>();
  public linkOpened: boolean;
  protected isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private configApi: ConfigAPIService,
    private productPackageService: ProductPackagesService,
    private notificationService: NotificationService,
  ) {
    this.accountConfig = data.accountConfig;
    this.accountProductPackage = data.accountConfig.activeAccountProductPackage;
  }

  static open(dialog: MatDialog, accountConfig: AccountConfig) {
    return dialog.open(OpenProductPackageOfferDialogComponentComponent, {
      width: '600px',
            disableClose: true,
      data: {
        accountConfig,
      }
    });
  }

  ngOnInit(): void {
    setInterval(() => {
      this.configApi.getAccountConfig(null, true).pipe(take(1), takeUntil(this.destroy$)).subscribe((config) => {
        if (config.activeAccountProductPackage.isSigned || config.activeAccountProductPackage.isRejected) {
          // this.refresh();
        }
      });
    }, 15000);
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openPaymentUrlInTab() {
    window.open(this.accountProductPackage.paymentUrl, '_blank');
    this.linkOpened = true;
  }

  public refresh() {
    this.isLoading = true;
    window.location.reload();
  }

  public recheckStateAndRefresh() {
    this.isLoading = true;
    this.productPackageService.syncAccountProductPackage()
      .pipe(map((result) => result.data))
      .subscribe({
      next: (accountProductPackage) => {
        if (accountProductPackage.isSigned || accountProductPackage.isRejected) {
          this.notificationService.showSuccessAlert();
          this.refresh();
        } else {
          this.notificationService.showErrorAlert();
        }
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
