import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {ProductPackageSubscriptionTypeEnum} from '@app/enums/ProductPackageSubscriptionTypeEnum';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {Subject} from 'rxjs';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {ProductPackagesService} from '@app/services/api/product-packages.service';
import {NotificationService} from '@app/services/notification.service';

@Component({
  selector: 'app-proforma-product-package-payment-dialog',
  templateUrl: './proforma-product-package-payment-dialog.component.html',
  styleUrls: ['./proforma-product-package-payment-dialog.component.scss']
})
export class ProformaProductPackagePaymentDialogComponent implements OnInit, OnDestroy {
  public productPackage: ProductPackage;
  public productType: string;
  public accountConfig: AccountConfig;
  public accountProductPackage: AccountProductPackage;
  public previousAccountProductPackage: AccountProductPackage;

  protected readonly productPackageSubscriptionTypeEnum = ProductPackageSubscriptionTypeEnum;
  protected linkOpened = false;
  private destroy$ = new Subject<void>();
  protected isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private configApi: ConfigAPIService,
    private productPackageService: ProductPackagesService,
    private notificationService: NotificationService,
  ) {
    this.productPackage = data.productPackage;
    this.productType = data.productType;
    this.accountConfig = data.accountConfig;
    this.accountProductPackage = data.accountProductPackage;
    this.previousAccountProductPackage = data.accountConfig.activeAccountProductPackage;
  }

  static open(dialog: MatDialog, productPackage: ProductPackage, productType: string, accountConfig: AccountConfig, accountProductPackage: AccountProductPackage) {
    return dialog.open(ProformaProductPackagePaymentDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {
        productPackage,
        productType,
        accountConfig,
        accountProductPackage
      }
    });
  }

  ngOnInit(): void {

    setInterval(() => {
      this.configApi.getAccountConfig().pipe(take(1), takeUntil(this.destroy$)).subscribe((config) => {
        if (config.activeAccountProductPackage?.isSigned || config.activeAccountProductPackage?.isRejected) {
          window.location.reload();
        }
      });
    }, 15000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openPaymentUrlInTab() {
    this.linkOpened = true;
    window.open(this.accountProductPackage.paymentUrl, '_blank');
  }

  public refresh() {
    this.isLoading = true;
    window.location.reload();
  }

  public recheckStateAndRefresh() {
    this.isLoading = true;
    this.productPackageService.syncAccountProductPackage()
      .pipe(map((result) => result.data))
      .subscribe({
        next: (accountProductPackage) => {
          if (accountProductPackage.isSigned || accountProductPackage.isRejected) {
            this.notificationService.showSuccessAlert();
            this.refresh();
          } else {
            this.notificationService.showErrorAlert();
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }
}
