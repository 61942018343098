<div class="card-content card bg-greenWashed border-none flex flex-col justify-center items-center">
  <ng-container *ngIf="!isSuccess">

    <p class="text-primaryDark text-xs font-semibold"><span i18n>Se vam je zataknilo?</span></p>

    <div class="mt-4" *ngIf="!isSuccess">
      <ng-container *ngIf="!isLoading">
        <button style="height: 35px; font-size: 10px" class="btn btn-promo" (click)="openDialog()"><span i18n>Potrebujem pomoč</span>
        </button>
      </ng-container>

      <ng-container *ngIf="isLoading">
        <div style="height: 35px" class="flex justify-center items-center">
          <app-spinner></app-spinner>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isSuccess">
    <div class="text-center flex flex-col justify-center items-center">
      <div class="text-center mx-auto text-3xl mb-2">
        <i class="fas fa-check text-primary"></i>
      </div>

      <div class="flex flex-row items-center justify-center">
        <p class="text-primary text-xs" i18n>V kratkem vas kontaktiramo.</p>
      </div>
    </div>
  </ng-container>
</div>
