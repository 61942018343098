import {Injectable} from '@angular/core';
import {Auth} from '../interfaces';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  private lastUserId: string | null;

  constructor() {
  }

  setUser(auth: Auth) {
    try {
      // Sentry reported too many changes, limit to only when user changes
      const isUserDifferent = this.lastUserId !== auth?.userUuid;

      if (!isUserDifferent) {
        return;
      }

      this.lastUserId = auth?.userUuid;

      if (auth) {
        Sentry.setUser({email: auth.email, id: auth.userUuid});
      } else {
        Sentry.configureScope(scope => scope.setUser(null));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
