import {Component, Inject, OnInit} from '@angular/core';
import {Vehicle} from '../../../interfaces';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NotificationService} from '../../../services/notification.service';
import {LeadGenerationService} from '../../../services/lead-generation.service';
import {SwitchToFullModeSuccessfullDialogComponent} from '../switch-to-full-mode-successfull-dialog/switch-to-full-mode-successfull-dialog.component';
import {zip} from 'rxjs';
import {SignupFormDialogComponent} from "../signup-form-dialog/signup-form-dialog.component";

@Component({
  selector: 'app-switch-to-full-mode-dialog',
  templateUrl: './switch-to-full-mode-dialog.component.html',
  styleUrls: ['./switch-to-full-mode-dialog.component.scss']
})
export class SwitchToFullModeDialogComponent implements OnInit {

  public vehicle: Vehicle;

  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private notificationService: NotificationService,
              private leadGeneration: LeadGenerationService,
              public dialogRef: MatDialogRef<any>,
              private matDialog: MatDialog) {

    this.vehicle = data.vehicle;
  }

  static open(dialog: MatDialog) {
    return dialog.open(SwitchToFullModeDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {}
    });
  }

  ngOnInit() {

  }

  close(success) {
    this.dialogRef.close(success);
  }

  requestSwitchToFullMode() {
    this.close(false);
    SignupFormDialogComponent.open(this.matDialog);
  }
}
