import {Component, Input, OnInit} from '@angular/core';
import {Client} from '@app/interfaces/entities/client';

@Component({
  selector: 'app-client-table-cell',
  templateUrl: './client-table-cell.component.html',
  styleUrls: ['./client-table-cell.component.scss']
})
export class ClientTableCellComponent implements OnInit {

  @Input() client: Client;
  @Input() disableLink = false;

  constructor() {
  }

  ngOnInit() {
  }

  getIconTitle() {
    const notifictions = [];

    this.client.alarmNotifications.forEach((alarmNotification) => {
      notifictions.push(alarmNotification.alarm.name);
    });

    return notifictions.join('\n');
  }

  getName(client: Client) {
    let name = '-';
    if (client.isPerson) {
      if (client.name) {
        name = client.name;
      }
    } else {
      if (client.organizationName) {
        name = client.organizationName;
      }
    }

    return name;
  }
}
