import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {UserApiService} from '@app/services/api/user-api.service';
import {PermissionEnum} from '@app/enums/permissions/PermissionEnum';
import {map, take, takeUntil} from 'rxjs/operators';
import {RoleEnum} from '@app/enums/RoleEnum';

@Directive({
  selector: '[appIfPermission]'
})
export class IfPermissionDirective implements OnInit, OnDestroy {

  @Input('appIfPermission') public permissions: PermissionEnum[];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private viewContainerRef: ViewContainerRef,
              private userService: UserApiService,
              private templateRef: TemplateRef<any>) {
  }

  ngOnInit(): void {
    this.userService.getUserProfile()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        map((result) => result.data),
      )
      .subscribe({
        next: (userData) => {
          if (userData.roles[RoleEnum.SUPER_ADMIN] || this.permissions.some(permission => userData.permissions[permission] === true)) {
            this.doPositiveAction();
          } else {
            this.doNegativeAction();
          }
        },
        error: () => this.doNegativeAction()
      });
  }

  private doPositiveAction() {
    // Clear to prevent duplicates
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }

  private doNegativeAction() {
    this.viewContainerRef.clear();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
