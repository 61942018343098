import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Field} from '@app/interfaces';
import {FieldFormInputsComponent} from '@app/partials/partials/field-form-inputs/field-form-inputs.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {FieldApiService} from '@app/services/api/field-api.service';

@Component({
  selector: 'app-edit-field-dialog',
  templateUrl: './edit-field-dialog.component.html',
  styleUrls: ['./edit-field-dialog.component.scss']
})
export class EditFieldDialogComponent implements OnInit {
  public isLoading = false;

  public field: Field<any>;

  @ViewChild(FieldFormInputsComponent) formInputs: FieldFormInputsComponent;

  public form = new FormGroup({
    label: new FormControl('', [Validators.required]),
    description: new FormControl(''),
  });
  selectedFieldTemplate = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private fieldApiService: FieldApiService) {

    this.field = data.field;
    this.form.controls.label.setValue(this.field.config.label);
    this.form.controls.description.setValue(this.field.config.description);
  }

  ngOnInit() {
    this.isLoading = true;
    this.fieldApiService.getFieldTemplates().subscribe((response) => {

      response.data.forEach((item, index) => {
        if (item.type === this.field.type) {
          this.selectedFieldTemplate = item;
        }
      });
      this.isLoading = false;
    });
  }

  edit() {
    this.isLoading = true;

    this.fieldApiService.updateField(this.field.fieldUuid, this.formInputs.getField().config)
      // @ts-ignore
      .subscribe(response => this.dialogRef.close(response.data), error => {
        this.isLoading = false;
        console.error(error);
      });
  }

  close() {
    this.dialogRef.close(false);
  }

  getChoice(choice: any): Choice {
    const keys = Object.keys(choice);

    return {
      value: keys[0],
      label: choice[keys[0]],
    };
  }

  submit() {

  }
}

interface Choice {
  value: string;
  label: string;
}
