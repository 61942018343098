import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Upload} from '@app/interfaces/entities/upload';
import {AddPhotoDialogComponent} from '@app/shared/dialogs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Field} from '@app/interfaces';
import {GalleryService} from "@app/services/gallery.service";

@Component({
  selector: 'app-field-form-inputs',
  templateUrl: './field-form-inputs.component.html',
  styleUrls: ['./field-form-inputs.component.scss']
})
export class FieldFormInputsComponent implements OnInit, OnChanges {

  @Input() public template: any;
  @Input() public field: Field<any>;
  @Input() public entityType: string;

  public config: Config = null;
  public choices: Choice[] = [];

  public isDisabled = false;

  public form = new FormGroup({
    label: new FormControl('', [Validators.required]),
    typeId: new FormControl(''),
    description: new FormControl(''),
    append: new FormControl(false),
    prepend: new FormControl(false),
    placeholder: new FormControl(''),
    linkToEntity: new FormControl(true),
    isVisible: new FormControl(true),
    isRequired: new FormControl(false),
    currency: new FormControl({value: 'EUR', disabled: true}),
    mileage: new FormControl({value: 'km', disabled: true}),
  });
  upload: Upload;

  constructor(private dialog: MatDialog, private galleryService: GalleryService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //  Reset the config on every change just in case you switch the template and we won't store unneeded configs
    this.config = {label: '', description: '', isVisible: true};
    this.choices = [];

    if (changes.field && changes.field.currentValue) {
      this.isDisabled = true;

      this.template = {fieldTemplateUuid: this.field.fieldTemplateUuid, type: this.field.type};

      this.form.controls.label.setValue(this.field.config.label);
      this.form.controls.description.setValue(this.field.config.description);
      this.form.controls.placeholder.setValue(this.field.config.placeholder);
      this.form.controls.append.setValue(this.field.config.append);
      this.form.controls.prepend.setValue(this.field.config.prepend);
      this.form.controls.isRequired.setValue(this.field.config.isRequired);

      this.form.controls.currency.setValue(this.field.config.unit);
      this.form.controls.mileage.setValue(this.field.config.unit);

      this.form.controls.isVisible.setValue(this.field.config.isVisible);
      this.form.controls.typeId.setValue(this.field.config.typeId);

      if (this.field.config.choices) {
        for (const choice of this.field.config.choices) {
          this.choices.push({
            value: Object.keys(choice)[0],
            label: choice[Object.keys(choice)[0]]
          });
        }
      }
    }
  }

  getChoice(choice: any): Choice {
    const keys = Object.keys(choice);

    return {
      value: keys[0],
      label: choice[keys[0]],
    };
  }

  addChoice() {
    this.choices.push({value: '', label: ''});
  }

  removeChoice(choice: Choice) {
    this.choices.splice(this.choices.indexOf(choice), 1);
  }

  isValid() {
    return this.form.valid && this.areChoicesValid();
  }

  getField() {
    if (this.isValid()) {
      const choices = this.choices.map((choice) => {
        const mapped = {};
        mapped[choice.value] = choice.label;
        return mapped;
      });

      const entityTypes = [];

      if (this.form.controls.linkToEntity.value) {
        entityTypes.push(this.entityType);
      } else {
        entityTypes.splice(0, entityTypes.length);
      }

      const config: Config = {
        label: this.form.controls.label.value,
        typeId: this.form.controls.typeId.value,
        isVisible: this.form.controls.isVisible.value,
        append: this.form.controls.append.value,
        prepend: this.form.controls.prepend.value,
        isRequired: this.form.controls.isRequired.value,
        placeholder: this.form.controls.placeholder.value,
      };

      if (this.form.controls.description.value) {
        config.description = this.form.controls.description.value;
      }

      if (this.template.type === 'mileage' || this.template.type === 'currency') {
        if (this.getUnit()) {
          config.unit = this.getUnit();
        }
      }

      if (this.template.type === 'dropdown') {
        if (choices) {
          config.choices = choices;
        }
      }

      if (this.template.type === 'silhouette_photos') {
        if (this.upload) {
          config.silhouetteUuid = this.upload.uploadUuid;
        }

        config.placeholder = this.form.controls.placeholder.value;
      }

      return {
        type: this.template.type,
        fieldTemplateUuid: this.template.fieldTemplateUuid,
        entityTypes,
        config,
      };
    }

    return null;
  }

  private getUnit() {
    if (this.template.type === 'mileage') {
      return this.form.controls.mileage.value;
    } else if (this.template.type === 'currency') {
      return this.form.controls.currency.value;
    }

    return undefined;
  }

  private areChoicesValid() {
    for (const choice of this.choices) {
      if (!choice.value || !choice.label) {
        return false;
      }
    }
    return true;
  }

  uploadSilhouette() {
    const dialogRef = AddPhotoDialogComponent.open(this.dialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.upload = result;
      }
    });
  }

  openLightbox(uploads: Upload[], index: number) {
    const images = [];

    uploads.forEach((upload) => upload.files.forEach((file) => {
      images.push({src: file.conversions.large, caption: '', thumb: file.conversions.thumbnail});
    }));

    this.galleryService.openGallery(images, index);
  }
}

interface Config {
  label: string;
  isVisible: boolean;
  prepend?: boolean;
  append?: boolean;
  isRequired?: boolean;
  typeId?: string;
  description?: string;
  placeholder?: string;
  unit?: string;
  choices?: any[];
  silhouetteUuid?: string;
}

interface Choice {
  value: string;
  label: string;
}
