import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

interface WorkflowDocument {
  client_name: string;
  uuid: string;
  title: string;
  reference_number: string;
  workflow_result_uuid: string;
}

@Component({
  selector: 'app-global-search-result-card-document',
  templateUrl: './global-search-result-card-document.component.html',
  styleUrls: ['./global-search-result-card-document.component.scss']
})
export class GlobalSearchResultCardDocumentComponent {

  @Input() result: WorkflowDocument;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private router: Router) {
  }

  redirect() {
    this.onClose.emit(true);

    if (this.router.url.includes(this.result.workflow_result_uuid)) {
      return window.location.reload();
    } else {
      this.router.navigate([`/admin/workflow-results/${this.result.workflow_result_uuid}`], {queryParams: {documentUuid: this.result.uuid}});
    }
  }
}
