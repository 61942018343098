<div class="relative">
  <input type="number"
         [formControl]="control"
         [attr.aria-label]="field.config.label"
         [ngClass]="{ 'input-error': control.invalid }"
         placeholder="{{ field.config.placeholder }}"
         autocomplete="off"
           [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId"
         [attr.tabIndex]="0">

  <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark" style="margin-top: 0.85rem;" *ngIf="field.config.append">{{ field.config.append}}</span>
</div>
