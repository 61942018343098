import {registerLocaleData} from "@angular/common";
import localeSl from "@angular/common/locales/sl";
import localeSlExtra from "@angular/common/locales/extra/sl";
import localeHr from "@angular/common/locales/hr";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import localeFr from "@angular/common/locales/fr";
import localeHrExtra from "@angular/common/locales/extra/hr";
import localeEnExtra from "@angular/common/locales/extra/en";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeDeExtra from "@angular/common/locales/extra/de";
import {LocaleEnum} from "@app/enums/LocaleEnum";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import * as timezone from "dayjs/plugin/timezone";
import * as localeData from "dayjs/plugin/localeData";
import * as utc from "dayjs/plugin/utc";

import 'dayjs/locale/sl';
import 'dayjs/locale/hr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(utc);

export function setupAppModule() {
  registerLocaleData(localeSl, LocaleEnum.LOCALE_SI, localeSlExtra);
  registerLocaleData(localeHr, LocaleEnum.LOCALE_HR, localeHrExtra);
  registerLocaleData(localeEn, LocaleEnum.LOCALE_EN, localeEnExtra);
  registerLocaleData(localeFr, LocaleEnum.LOCALE_FR, localeFrExtra);
  registerLocaleData(localeDe, LocaleEnum.LOCALE_DE, localeDeExtra);
  registerLocaleData(localeEn, LocaleEnum.LOCALE_EN, localeEnExtra);
  registerLocaleData(localeEn, LocaleEnum.LOCALE_EN, localeEnExtra);
}
