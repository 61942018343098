import {Injectable} from '@angular/core';
import {SingleResult} from '@app/interfaces';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {AccountProductPackage} from '@app/interfaces/packages/account-product-package';
import {ProductPackage} from '@app/interfaces/packages/product-package';
import {Observable} from 'rxjs';
import {PackageUsage} from '@app/interfaces/packages/package-usage';
import {ApiCachingService} from '@app/services/api/api-caching.service';

@Injectable({
  providedIn: 'root'
})
export class ProductPackagesService {

  constructor(private http: HttpClient,
              private apiCaching: ApiCachingService) {
  }

  getActiveAccountPackage() {
    return this.http.get<SingleResult<AccountProductPackage>>(`${environment.apiUrl}/api/product-packages/account`);
  }

  getProductPackagesForCountry(countryCode: string) {
    return this.http.post<SingleResult<ProductPackage[]>>(`${environment.apiUrl}/api/product-packages/available`, {countryCode});
  }

  getPackageUsage(accountUuid: string): Observable<PackageUsage> {
    return this.apiCaching.getCachedWithTTL(`productPackageUsage${accountUuid}`, 60, this.http.get<PackageUsage>(`${environment.apiUrl}/api/product-packages/usage`));
  }

  getProductPackagesForChange() {
    return this.http.get<SingleResult<ProductPackage[]>>(`${environment.apiUrl}/api/product-packages/all-change`);
  }

  getAllProductPackages() {
    return this.http.get<SingleResult<ProductPackage[]>>(`${environment.apiUrl}/api/product-packages/all`);
  }

  getUpsellProductPackagesForAccount() {
    return this.http.get<SingleResult<ProductPackage[]>>(`${environment.apiUrl}/api/product-packages/upsell`);
  }

  changeProductPackage(packageUuid: string, productType: string) {
    return this.http.post<SingleResult<AccountProductPackage>>(`${environment.apiUrl}/api/product-packages/change`, {productPackageUuid: packageUuid, productPackageType: productType});
  }

  deleteAccount(data: { feedback: string }) {
    return this.http.post<AccountProductPackage>(`${environment.apiUrl}/api/product-packages/delete-account`, data);
  }

  unsubscribe(data: { feedback: string }) {
    return this.http.post<AccountProductPackage>(`${environment.apiUrl}/api/product-packages/unsubscribe`, data);
  }

  reSubscribe() {
    return this.http.post<AccountProductPackage>(`${environment.apiUrl}/api/product-packages/resubscribe`, {});
  }

  syncAccountProductPackage(){
    return this.http.post<SingleResult<AccountProductPackage>>(`${environment.apiUrl}/api/product-packages/sync`, {});
  }
}
