import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminRootComponent} from './admin-root/admin-root.component';
import {PublicRootComponent} from './public-root/public-root.component';
import {RouterModule} from '@angular/router';
import {PartialsModule} from '../partials/partials.module';
import {NotificationsModule} from "../../modules/notifications/notifications.module";

@NgModule({
  declarations: [
    PublicRootComponent,
    AdminRootComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PartialsModule,
        NotificationsModule,
  ],
  exports: [
    PublicRootComponent,
    AdminRootComponent,
  ]
})
export class RootsModule {
}
