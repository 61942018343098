import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SignatureService} from '../../../services/signature.service';
import {NotificationService} from '../../../services/notification.service';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-signature-component-dialog',
  templateUrl: './signature-component-dialog.component.html',
  styleUrls: ['./signature-component-dialog.component.scss']
})
export class SignatureComponentDialogComponent implements OnInit, OnDestroy {

  isLoading = true;
  isRefreshing = false;

  private signatureHook;
  private label;

  private checkInSeconds = 5;
  private interval;

  private fieldUuid: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private signatureService: SignatureService,
              private notificationService: NotificationService,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject(DOCUMENT) private document: Document,
              private router: Router,
              public dialogRef: MatDialogRef<unknown>) {

    this.fieldUuid = data.fieldUuid;
    this.label = data.label;
  }

  static open(dialog: MatDialog, fieldUuid: string, label: string) {
    return dialog.open(SignatureComponentDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        fieldUuid,
        label
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;

    this.signatureService.startHook(this.fieldUuid, this.label).subscribe((result) => {
      this.signatureHook = result.data;
      this.isLoading = false;
      this.runRefresher();
    }, (error) => {
      this.notificationService.showErrorAlert();
    });
  }

  confirm(signature: string) {
    this.signatureService.clear().subscribe((result) => {
    });
    this.clearInterval();
    this.dialogRef.close(signature);
  }

  close() {
    this.signatureService.clear().subscribe((result) => {
    });
    this.dialogRef.close(false);
  }

  getLink() {
    const url = this.router.createUrlTree(['/signature']).toString();
    return this.document.location.origin + url;
  }

  private runRefresher() {
    if (isPlatformBrowser(this.platformId)) {
      this.clearInterval();

      this.interval = setInterval(() => {
        this.refresh();
      }, this.checkInSeconds * 1000);
    }
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private refresh() {
    this.isRefreshing = true;
    this.signatureService.refresh().subscribe((result) => {
      this.isRefreshing = false;

      if (result.data.token === this.signatureHook.token && result.data.signature) {
        this.confirm(result.data.signature);
      }
    });
  }
}
