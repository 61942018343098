import {Component, Input, OnInit} from '@angular/core';
import {Status, Vehicle} from '@app/interfaces';
import * as dayjs from 'dayjs';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AlreadySoldMoveToArchiveDialogComponent} from '@app/dialogs/already-sold-move-to-archive-dialog/already-sold-move-to-archive-dialog.component';
import {ImportedVehicle} from '@app/interfaces/integration/imported-vehicle';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {Observable} from 'rxjs';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {EntityGroupEnum} from "@app/enums/EntityGroupEnum";

@Component({
  selector: 'app-vehicle-table-cell',
  templateUrl: './vehicle-table-cell.component.html',
  styleUrls: ['./vehicle-table-cell.component.scss']
})
export class VehicleTableCellComponent implements OnInit {

  @Input() disableLink = false;
  @Input() openInBlank = false;
  @Input() hideStockTurnover = false;
  @Input() identificationShow = false;
  @Input() dialogSelected = false;
  @Input() hideWarning = false;
  @Input() showInvoice = false;
  @Input() hideActions = false;
  @Input() showAdvertisedPrice = false;

  public stockTurnover: string;
  public vehicle: Vehicle | ImportedVehicle = null;
  public vehicleBeforeSoldStatus: Status | null = null;

  public showPlaceholder = false;
  public invoiceType: string = null;
  public vehicleBrandSlug: string = null;
  public vehicleDescription: string = null;
  accountConfig$: Observable<AccountConfig>;
  public isSaleAndLeaseBack: boolean;

  constructor(
    private vehicleAPIService: VehicleApiService,
    private configAPIService: ConfigAPIService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.accountConfig$ = this.configAPIService.getAccountConfig();
  }

  @Input('vehicle')
  set setVehicle(vehicle: Vehicle | ImportedVehicle | null) {
    this.vehicle = vehicle;

    if (vehicle) {
      this.vehicleDescription = this.vehicleAPIService.extractVehicleDescription(this.vehicle);
      this.vehicleBeforeSoldStatus = this.getVehicleBeforeSoldStatus(this.vehicle);
      this.invoiceType = this.getVehicleInvoicesBadge(this.vehicle);

      if (vehicle.vehicle) {
        this.vehicleBrandSlug = this.getVehicleBrandSlug(vehicle?.vehicle?.brand || '');
      }

      if ('autobrief_uuid' in this.vehicle) {
        this.vehicle.vehicleUuid = this.vehicle.autobrief_uuid;
      }

      if (this.vehicle.hasOwnProperty('imported') && !this.vehicle.imported) {
        this.disableLink = true;
      }
      if ("entityGroups" in this.vehicle && this.vehicle.entityGroups.length > 0) {
        this.isSaleAndLeaseBack = this.vehicle.entityGroups.some( (entityGroup) => entityGroup['typeId'] === EntityGroupEnum.SALE_AND_LEASE_BACK_TYPE_ID);
      }
    }
  }

  compareDate(date) {
    const start = dayjs(date);
    const today = dayjs();
    return today.diff(start, 'days');
  }

  private getVehicleBrandSlug(input: string): string {
    return input.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  forcePlaceholder() {
    this.showPlaceholder = true;
  }

  private getVehicleInvoicesBadge(vehicle: Vehicle | ImportedVehicle | null): string {
    if (vehicle && ('saleInvoice' in vehicle)) {
      return vehicle.saleInvoice?.data._documentItems.find((item) => item.custom.vehicleUuid === vehicle.vehicleUuid && item.custom.lineItemType)?.custom.lineItemType ?? null;
    }

    return null;
  }

  openDialogToArchive() {
    const dialog = AlreadySoldMoveToArchiveDialogComponent.open(this.matDialog, this.vehicle.vehicleUuid);
    dialog.afterClosed().subscribe((result) => {
      location.reload();
    });
  }

  private getVehicleBeforeSoldStatus(vehicle: Vehicle | ImportedVehicle) {
    const isSoldStatus = vehicle.statuses?.find((status) => status.typeId === 'briefd.default_vehicle_status_sold');

    return isSoldStatus ? null : vehicle.statuses?.[0] ?? null;
  }

}
