import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AssetData} from '@app/interfaces/entities/asset';
import {SectionItem} from '@app/interfaces/form/section-item';
import {FieldItem} from '@app/interfaces/form/field-item';
import {Field, VehicleData} from '@app/interfaces';
import {ClientData} from '@app/interfaces/entities/client';
import {FieldSection} from "@app/interfaces/fields/field.section";

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  public fieldValueUpdate: Subject<object> = new Subject<object>();

  constructor() {

  }

  fieldValueUpdates(): Observable<object> {
    return this.fieldValueUpdate;
  }

  updateFieldValues(fieldValues: object) {
    this.fieldValueUpdate.next(fieldValues);
  }

  setupFields(asset: AssetData | VehicleData | ClientData) {
    const layouts = asset.layouts;
    const sections = [];
    const fieldsArray = this.createAssocArray(asset.fields);


    layouts.forEach((layout: SectionItem) => {
      let fieldGroupSection: FieldSection = {title: layout.title, items: [], show: layout.defaultOpen};

      const fields = layout.items.filter((item) => item.type === 'field');
      const subsection = layout.items.filter((item) => item.type !== 'field');
      fieldGroupSection.items = fields.map((field) => fieldsArray[(field as FieldItem).fieldUuid]);

      subsection.forEach((layoutItem: SectionItem) => {
          fieldGroupSection = {title: layoutItem.title, items: [], show: layoutItem.defaultOpen || layout.defaultOpen};

          (layoutItem as SectionItem).items.forEach((innerLayout) => {
            if (innerLayout.type === 'field') {
              fieldGroupSection.items.push(fieldsArray[(innerLayout as FieldItem).fieldUuid]);
            } else {
              (innerLayout as SectionItem).items.forEach((innerInnerLayout) => {
                if (innerInnerLayout.type === 'field') {
                  fieldGroupSection.items.push(fieldsArray[(innerInnerLayout as FieldItem).fieldUuid]);
                }
              });
            }
          });


          sections.push(fieldGroupSection);
        }
      );
    });

    return sections;
  }

  private createAssocArray(fields: Field<any, string>[]): any[] {
    const form = [];

    fields.forEach(field => form[field.fieldUuid] = field);

    return form;
  }
}
