import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GlobalSearchResultType} from '@app/interfaces/entities/global-search-result-type';

@Component({
  selector: 'app-global-search-result-type-card',
  templateUrl: './global-search-result-type-card.component.html',
  styleUrls: ['./global-search-result-type-card.component.scss']
})
export class GlobalSearchResultTypeCardComponent {
  @Input() globalSearchResultType: GlobalSearchResultType;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  hideResults() {
    this.onClose.emit(true);
  }
}
