import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {EntityCategoryApiService} from '../../../services/api/entity-category-api.service';
import {EntityCategory} from '../../../interfaces/entities/entity-category';

@Component({
  selector: 'app-change-entity-category-dialog',
  templateUrl: './change-entity-category-dialog.component.html',
  styleUrls: ['./change-entity-category-dialog.component.scss']
})
export class ChangeEntityCategoryDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private entityCategoryApi: EntityCategoryApiService,
              public dialogRef: MatDialogRef<unknown>) {
    this.selectedCategoryUuid = data.currentEntityCategory ? data.currentEntityCategory.entityCategoryUuid : null;
  }

  public selectedCategoryUuid: any;
  public isLoading = true;
  public categories: EntityCategory[] = [];

  static open(dialog: MatDialog,
              currentEntityCategory: EntityCategory,
              entityType: string) {
    return dialog.open(ChangeEntityCategoryDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        currentEntityCategory,
        entityType,
      }
    });
  }

  ngOnInit() {
    this.entityCategoryApi.getEntityCategories(this.data.entityType)
      .subscribe((response) => {
        this.isLoading = false;
        this.categories = response.data;
      });
  }

  save() {
    this.dialogRef.close({entityCategoryUuid: this.selectedCategoryUuid});
  }

  close() {
    this.dialogRef.close(false);
  }
}
