import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {VehicleApiService} from '../../../services/api/vehicle-api.service';
import {ClientApiService} from '../../../services/api/client-api.service';
import {IntegrationService} from "../../../services/integration.service";

@Component({
  selector: 'app-share-ad-dialog',
  templateUrl: './select-files-dialog.component.html',
  styleUrls: ['./select-files-dialog.component.scss'],
})
export class SelectFilesDialogComponent implements OnInit {

  public vehicleUuidsArray: Array<object> = [];
  public fileTypes: Array<object> = [];
  public selectedFileUuids: Array<string> = [];
  public type: string;
  private uuid: string;

  private readonly fileTypeNames: object;
  public isPreloading = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private vehicleApiService: VehicleApiService,
              private integrationService: IntegrationService,
              private clientApiService: ClientApiService,
              public dialogRef: MatDialogRef<unknown>) {
    this.fileTypeNames = data.fileTypeNames;
    this.type = data.type;
    this.uuid = data.uuid;
  }

  static open(dialog: MatDialog, fileTypeNames: object, type: string, uuid: string) {
    return dialog.open(SelectFilesDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {
        fileTypeNames,
        type,
        uuid
      }
    });
  }

  ngOnInit() {
    switch (this.type) {
      case 'vehicle':
        this.getFiles(this.vehicleApiService);
        break;
      case 'client':
        this.getFiles(this.clientApiService);
        break;
    }
  }

  close() {
    this.selectedFileUuids = [];
    this.dialogRef.close(false);
  }

  selectAttachment(uploadUuid: string) {
    if (this.selectedFileUuids.includes(uploadUuid)) {
      this.selectedFileUuids.splice(this.selectedFileUuids.indexOf(uploadUuid), 1);
    } else {
      this.selectedFileUuids.push(uploadUuid);
    }
  }

  uploadFiles() {
    this.integrationService.uploadExistingFilesToNlb(this.selectedFileUuids)
      .subscribe((result) => {
        this.dialogRef.close(result);
      });
  }

  private getFiles(apiService: VehicleApiService | ClientApiService) {
    for (const fileType of Object.keys(this.fileTypeNames)) {
      apiService.getField(this.uuid, this.fileTypeNames[fileType]).subscribe((result) => {
        const fileTypes = this.fileTypes;
        if (result.data.value.length !== 0) {
          result.data.value[0].fileType = fileType;
          fileTypes.push(result.data.value);
          this.fileTypes = fileTypes;
        }
        this.isPreloading = false;
      });
    }
  }
}
