import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {debounceTime, filter, finalize, map, startWith} from 'rxjs/operators';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {ConfigAPIService} from '../../../../services/api/config-api.service';

@Component({
  selector: 'app-vehicle-model-autocomplete',
  templateUrl: './vehicle-model-autocomplete.component.html',
  styleUrls: ['./vehicle-model-autocomplete.component.scss']
})
export class VehicleModelAutocompleteComponent implements OnInit, OnChanges {

  @Input() disable = false;

  @Input('vehicleModelUuid') initialModelUuid = '';

  @Output() vehicleModelSelected = new EventEmitter();

  public $filteredBrands: Observable<any[]>;
  public $filteredModels: Observable<any[]>;

  public brandControl = new FormControl();
  public modelControl = new FormControl({value: '', disabled: true});

  public brandsLoading = false;
  public modelsLoading = false;

  public isPreloading = false;

  private selectedBrand: any;
  private selectedModel: any;

  private debounceTime = 250;

  constructor(private configAPI: ConfigAPIService, private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.setupControl(this.brandControl)
      .subscribe((q) => {
        this.brandsLoading = true;
        this.runBrandsQuery(q);
        this.ref.markForCheck();
      });

    this.setupControl(this.modelControl)
      .subscribe((q) => {
        this.modelsLoading = true;
        this.runModelsQuery(q);
        this.ref.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable) {
      if (changes.disable.currentValue) {
        this.brandControl.disable();
        this.modelControl.disable();
      } else {
        this.brandControl.enable();

        if (this.selectedBrand) {
          this.modelControl.enable();
        }
      }
    }

    if (changes.initialModelUuid && changes.initialModelUuid.currentValue) {
      this.isPreloading = true;
      this.preloadModel(this.initialModelUuid);
    }

    this.ref.markForCheck();
  }

  display(item?: any) {
    return item ? item.name : '';
  }

  onBrandSelected($event: MatAutocompleteSelectedEvent) {
    this.selectedBrand = null;
    this.selectedBrand = $event.option.value;

    if (this.selectedBrand) {
      this.setupModelControl();
      this.$filteredModels = of();
    } else {
      this.disableModel();
    }

    this.vehicleModelSelected.emit(null);
  }

  onModelSelected($event: MatAutocompleteSelectedEvent) {
    this.selectedModel = null;
    this.selectedModel = $event.option.value;
    this.vehicleModelSelected.emit($event.option.value);
  }

  private runBrandsQuery(q: any) {
    if (!this.queryIsString(q)) {
      return;
    }

    this.selectedBrand = null;

    this.disableModel();

    this.$filteredBrands = this.configAPI.getVehicleBrands(q)
      .pipe(
        // @ts-ignore
        map(data => data.data),
        finalize(() => this.brandsLoading = false)
      );
  }

  private runModelsQuery(q: any) {
    if (!this.queryIsString(q) || !this.selectedBrand) {
      return;
    }

    this.selectedModel = null;


    this.$filteredModels = this.configAPI.getVehicleModels(this.selectedBrand.vehicleBrandUuid, q)
      .pipe(
        // @ts-ignore
        map(data => data.data),
        finalize(() => this.modelsLoading = false)
      );
  }

  private setupModelControl() {
    this.selectedModel = null;
    this.modelControl.setValue('');
    this.modelControl.enable();
  }

  private disableModel() {
    this.selectedModel = null;
    this.modelControl.setValue('');
    this.modelControl.disable();
  }

  private setupControl(control: FormControl) {
    return control.valueChanges.pipe(
      debounceTime(this.debounceTime)
    );
  }

  private queryIsString(q: any) {
    return typeof q === 'string';
  }

  private preloadModel(modelUuid: string) {
    // @ts-ignore
    this.configAPI.getVehicleModel(modelUuid).pipe(map(data => data.data))
      .subscribe((data) => {
        this.selectedBrand = data.vehicleBrand;
        this.brandControl.enable();
        this.brandControl.setValue(this.selectedBrand);

        this.selectedModel = data;
        this.modelControl.enable();
        this.modelControl.setValue(this.selectedModel);

        this.isPreloading = false;
        this.ref.markForCheck();
      });
  }

  onBrandBlur() {
    if (!this.selectedBrand) {
      this.brandControl.setValue(null);
      this.vehicleModelSelected.emit(null);
      this.ref.markForCheck();
    }
  }

  onModelBlur() {
    if (!this.selectedModel) {
      this.modelControl.setValue(null);
      this.vehicleModelSelected.emit(null);
      this.ref.markForCheck();
    }
  }
}
