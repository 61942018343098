<div class="w-100 h-100">
  <div class="relative md:mr-3">
    <input type="text" name="search" id="search" [formControl]="control"
           style="padding-left: 2.25rem; padding-right: 2.25rem"
           placeholder="Išči ..."
           autocomplete="off"
           i18n-placeholder>

    <button class="text-grayDark hover:text-red absolute top-0 right-0 mt-1 mr-1 p-2" *ngIf="control.value"
            type="button" (click)="clearSearchAndHideDropdown()" i18n-title="Pobriši" title="Pobriši">
      <i class="fas fa-times"></i>
    </button>

    <div class="absolute text-grayDark pointer-events-none left-0 top-0 ml-3 mt-3">
      <i class="fas fa-search"></i>
    </div>
  </div>

  <ng-container *ngIf="searchResult as resultTypes;">
    <div class="mt-11" *ngIf="showDropdown && !isLoading" style="z-index: 200; position: absolute;">
      <div class="global-searchbar-dropdown card p-3 pt-2 bg-white shadow-2xl" style="z-index: 201">
        <div *ngIf="resultTypes.type_count > 0; else noResultFoundMessage" class="grid grid-cols-1 md:grid-cols-2 gap-x-2"
             [ngClass]="{'md:grid-cols-1': resultTypes.type_count == 1}" style="z-index: 202">
          <ng-container *ngFor="let type of resultTypes.data">
            <app-global-search-result-type-card [globalSearchResultType]="type" (onClose)="hideDropdown()"
                                                *ngIf="type.data.total > 0"></app-global-search-result-type-card>
          </ng-container>
        </div>
        <ng-template #noResultFoundMessage>
          <span class="text-sm text-grayDarker" i18n>Ni rezultatov</span>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="fixed mt-11 w-1/4" *ngIf="showDropdown">
      <div class="card p-3 bg-white mr-2 shadow-2xl">
        <div class="grid grid-cols-1 gap-x-2">
          <ng-container>
            <app-content-shimmer height="2rem"></app-content-shimmer>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
