import {Component, OnInit} from '@angular/core';
import {AuthService} from '@app/services/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-logout-page',
    templateUrl: './logout-page.component.html',
    styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router) {
    }

    ngOnInit() {
        this.authService.logout();

        this.router.navigate(['/']);
    }
}
