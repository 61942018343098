import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './guards/auth/auth.interceptor';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning} from '@src/@ngx-meta/core/src';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DAYJS_DATE_ADAPTER_OPTIONS} from '@tabuckner/material-dayjs-adapter';
import {LocaleInterceptor} from './guards/locale.interceptor';
import {SharedModule} from './shared/shared.module';
import {MaintenanceInterceptor} from './guards/maintenance/maintenance.interceptor';
import {APP_DATE_FORMATS, DayjsDateAdapter} from './shared/material-dayjs-adapter';
import {NotificationsModule} from './modules/notifications/notifications.module';
import {LoggingService} from '@app/services/util/logging.service';
import {environment} from '@src/environments/environment';
import {setupAppModule} from "@app/i18n";
import {GlobalErrorHandler} from "@app/util/global-error.handler";
import {MAT_LEGACY_DIALOG_DEFAULT_OPTIONS} from "@angular/material/legacy-dialog";


setupAppModule();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    SharedModule,

    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory)
    }),
    NotificationsModule,
    // GoogleTagManagerModule.forRoot({
    //   id: environment.gtmID !== 'x' ? environment.gtmID : undefined,
    // })
  ],
  providers: [
    LoggingService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'sl'
    },
    {provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: 'googleTagManagerId', useValue: environment.gtmID},
    {provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: {maxWidth: '95vw', maxHeight: '90vh', width: '960px', hasBackdrop: true, panelClass: 'dialog'}},
  ],
  exports: [
    SharedModule,
    HttpClientModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' | ',
    applicationName: 'AutoBrief',
    defaults: {
      title: 'AutoBrief',
      description: 'AutoBrief',
      'og:type': 'website',
    }
  });
}
