import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogType} from './dialog.type';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public isDangerous: boolean;
  public DialogTypes = DialogType;

  public type: DialogType = DialogType.DEFAULT;
  public custom: any;
  public checkCustom = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<unknown>) {

    this.isDangerous = data.isDangerous;
    this.type = data.type;
    this.custom = data.custom;
  }

  static open(dialog: MatDialog, type: DialogType, isDangerous: boolean, custom?: any) {
    return dialog.open(ConfirmDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        type,
        isDangerous,
        custom
      }
    });
  }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close([true, this.checkCustom]);
  }

  close() {
    this.dialogRef.close(false);
  }
}
