import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Vehicle} from '@app/interfaces';
import {MetaService} from '@src/@ngx-meta/core/src';
import {VirtualSalonApiService} from "@app/services/api/virtual-salon-api.service";

@Component({
  selector: 'app-vehicle-listing-list-page',
  templateUrl: './vehicle-listing-list-page.component.html',
  styleUrls: ['./vehicle-listing-list-page.component.scss']
})
export class VehicleListingListPageComponent implements OnInit, OnDestroy {

  listing = {
    publicAccount: null,
    publicVehicles: null
  };

  isLoading = true;
  isMissing = false;
  private activatedRouteSubscription: Subscription;
  accountUuid: string;

  // url = '/a/' + this.accountUuid;

  constructor(private vehicleApi: VehicleApiService,
              private virtualSalonApiService: VirtualSalonApiService,
              private meta: MetaService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const resolvedResult = this.activatedRoute.snapshot.data.result;

    this.isLoading = true;

    if (resolvedResult) {
      this.listing.publicAccount = resolvedResult.data.publicAccount;
      this.listing.publicVehicles = resolvedResult.data.publicVehicles;

      this.setupMetaTags(this.listing);
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }

    this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe((map) => {
      this.accountUuid = map.get('accountUuid');
      this.loadVehicles(this.accountUuid);
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
  }

  private loadVehicles(accountUuid: string) {
    this.virtualSalonApiService.getPublishedVehicles()
      .subscribe(data => {
        this.isLoading = false;
        // @ts-ignore
        this.listing = data.data;
        this.setupMetaTags(this.listing);
      }, error => {
        this.isLoading = false;
        this.isMissing = true;
      });
  }

  getTitle(vehicle: Vehicle) {
    if (!vehicle.vehicle) {
      return 'Vozilo';
    }

    return vehicle.vehicle.brand + ' ' + vehicle.vehicle.name;
  }

  getFeaturedPhoto(vehicle: any) {
    let featured = null;

    vehicle.fields.forEach((item) => {
      if (item.type === 'photos' || item.type === 'silhouette_photos') {
        if (item.value && item.value.length && !featured) {
          featured = item;
        }
      }
    });

    return featured;
  }

  getValue(typeId: string, vehicle: any) {
    for (const field of vehicle.fields) {
      if (field.config.typeId === typeId && field.value !== null) {
        return field.value;
      }
    }

    return null;
  }

  addParameters(url: string, fieldUuid: string, vehicleUuid: string) {
    const publicUrl = url.replace('/api/', '/public/');
    return `${publicUrl}?vehicleUuid=${vehicleUuid}&fieldUuid=${fieldUuid}`;
  }

  private setupMetaTags(listing: any) {

    const description = 'Seznam vozil za prodajalca ' + listing.publicAccount.name + '.';

    this.meta.setTitle(description);
    this.meta.setTag('og:title', listing.publicAccount.name);
    this.meta.setTag('og:description', description);
    this.meta.setTag('description', description);
    this.meta.setTag('og:url', this.router.url);
    this.meta.setTag('twitter:card', 'summary_large_image');
    this.meta.setTag('og:site_name', 'Briefd');
  }
}
