<div class="card bg-white cursor-pointer relative m-1 z-50"
     [ngClass]="{
     'bg-greenWashed': notification.read_at === null && !loudNotification,
     'bg-yellowWashed80': notificationType === 'information',
     'bg-redWashed': notificationType === 'alert'
     }">
  <div class="card-body flex p-4">
    <div *ngIf="showCloseMark" class="p-1 absolute text-grayDark hover:text-red" style="top:6px; right:10px;"
         (click)="removeNotification()"
         i18n-title title="Odstrani obvestilo">
      <i class="fas fa-times"></i>
    </div>
    <div class="flex-auto cursor-pointer">
      <p class="notification-title font-bold">{{notification.data.notification_title}}</p>
      <div class="notification-body text-grayDark text-sm" style="max-height: 50vh; overflow-y: auto;"
           [innerHTML]="notification.data.notification_content | safeHtml">
      </div>
      <p class="text-sm text-gray">{{ createdAt }}</p>
      <p class="my-3" *ngIf="notification.data?.button_link?.length > 0">
        <button class="btn btn-sm btn-primary-outline text-sm"
                [attr.data-link]="notification.data.button_link"
                (click)="redirectToNotification(notification)">
          {{ notification.data.button_text }}
        </button>
      </p>
    </div>
  </div>
</div>
