<a class="bg-white border rounded border-grayTableBorder p-3 pb-1 flex flex-row hover:border-primary cursor-pointer"
   (click)="redirect()"
   [routerLink]="'/admin/vehicles/'+result.vehicleUuid"
   style="min-height: 90px;">
  <div class="flex flex-col w-full pointer-events-none">
    <app-vehicle-table-cell [vehicle]="result"
                            [disableLink]="true"
                            [hideStockTurnover]="true"
                            [identificationShow]="true"
                            [dialogSelected]="true"
                            [hideActions]="true">
    </app-vehicle-table-cell>
  </div>
</a>
