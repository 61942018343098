import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PaginatedResult, Status} from '@app/interfaces';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {EntityCategory} from '@app/interfaces/entities/entity-category';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {ApiCachingService} from './api-caching.service';

@Injectable({
  providedIn: 'root'
})
export class EntityCategoryApiService {

  constructor(private http: HttpClient, private apiCaching: ApiCachingService) {
  }

  getEntityCategories(entityType: string): Observable<ArrayResult<EntityCategory>> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);

    return this.apiCaching.getCached(`entityCategories${entityType}`, this.http.get<ArrayResult<EntityCategory>>(`${environment.apiUrl}/api/entity-categories`, {params}));
  }
}
