import { Component } from '@angular/core';

@Component({
  selector: 'app-deactivated-page',
  templateUrl: './deactivated-page.component.html',
  styleUrls: ['./deactivated-page.component.scss']
})
export class DeactivatedPageComponent {

}
