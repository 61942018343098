import {Injectable} from "@angular/core";
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {map, take, tap} from "rxjs/operators";
import {BusinessUnit} from "@app/interfaces/entities/accounts/BusinessUnit";
import {Observable, ReplaySubject, zip} from "rxjs";
import {environment} from "@src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {SingleResult} from "@app/interfaces";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitsService {

  private selectedBusinessUnitId$ = new ReplaySubject<string | null>(1);

  constructor(private configAPIService: ConfigAPIService, private http: HttpClient) {
    window.removeEventListener('storage', this.storageEventListener);
    window.addEventListener('storage', this.storageEventListener);
  }

  public onPageLoad() {
    return this.configAPIService.getAccountConfig().pipe(tap((config) => {
      // Always set current storage key for business unit
      this.setLastStorageKey(config.uuid);
    }), map((config) => config.businessUnits));
  }

  public afterAuthChanged() {
    return this.configAPIService.getAccountConfig().pipe(take(1)).subscribe((config) => {
      this.setLastStorageKey(config.uuid);
      this.selectedBusinessUnitId$.next(this.getStoredBusinessUnitId());
    })
  }

  public getBusinessUnits(): Observable<BusinessUnit[]> {
    return this.configAPIService.getAccountConfig().pipe(map((config) => config.businessUnits));
  }

  public getBusinessUnit(businessUnitUuid): Observable<BusinessUnit | null> {
    return zip(this.getBusinessUnits()).pipe(
      map(([businessUnits]) => {
        return businessUnits.find(unit => unit.uuid === businessUnitUuid);
      })
    );
  }

  public deleteBusinessUnit(businessUnitUuid: string) {
    return this.http.delete(`${environment.apiUrl}/api/business-unit/${businessUnitUuid}`);
  }

  public createBusinessUnit(businessUnit: BusinessUnit) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/business-unit`, businessUnit);
  }

  public updateBusinessUnit(businessUnit: BusinessUnit) {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/business-unit`, businessUnit);
  }

  public updateBusinessUnits(businessUnits: BusinessUnit[]) {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/business-units`, {businessUnits: businessUnits});
  }

  public getSelectedBusinessUnit(): Observable<[BusinessUnit | null, BusinessUnit[]]> {
    return zip(this.selectedBusinessUnitId$, this.getBusinessUnits()).pipe(
      map(([businessUnitId, businessUnits]) => {
        if (businessUnitId) {
          const unit = businessUnits.find(unit => unit.uuid === businessUnitId);
          return [unit, businessUnits];
        }
        return [null, businessUnits];
      })
    );
    }

    public switchBusinessUnit(businessUnitUuid: string | null) {
        // Set to local storage
        this.configAPIService.getAccountConfig().pipe(take(1)).subscribe((config) => {
            const storageKey = this.setLastStorageKey(config.uuid);
            // Avoid to set null value - will be saved as string "null"
            localStorage.setItem(storageKey, businessUnitUuid || '');
        })
    }

    public getStoredBusinessUnitId() {
        // Restore select AllUnits to null value
    return localStorage.getItem(this.getLastStorageKey()) || null;
  }

  private storageEventListener = (event: StorageEvent) => {
    if (event.key === this.getLastStorageKey()) {
      this.selectedBusinessUnitId$.next(event.newValue);
    }
  }

  private storageKey(accountUuid: string) {
    return `${accountUuid}-businessUnit`;
  }

  private getLastStorageKey() {
    return localStorage.getItem('lastActiveBusinessUnitKey');
  }

  private setLastStorageKey(uuid: string) {
    const storageKey = this.storageKey(uuid);

    localStorage.setItem('lastActiveBusinessUnitKey', storageKey);

    return storageKey;
  }

  getBusinessUnitForm() {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/business-unit-form`);
  }
}
