import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Field} from "../../../../interfaces";
import {Upload} from "../../../../interfaces/entities/upload";
import {Subscription} from "rxjs";
import {UploadApiService} from "../../../../services/api/upload-api.service";
import {NotificationService} from "../../../../services/notification.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ConfirmDialogComponent, DialogType} from "../../../dialogs";

@Component({
  selector: 'app-form-input-files',
  templateUrl: './form-input-files.component.html',
  styleUrls: ['./form-input-files.component.scss']
})
export class FormInputFilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormGroup;

  @Input() field: Field<Upload[]>;

  public files: TempFile[] = [];

  isDisabled = false;

  private statusSubscription: Subscription;

  constructor(private uploadApiService: UploadApiService,
              private notificationService: NotificationService,
              private matDialog: MatDialog,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.statusSubscription = this.control.statusChanges.subscribe((status) => this.isDisabled = status === 'DISABLED');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue) {
      if (changes.control.currentValue.value) {
        this.files = changes.control.currentValue.value.map((currentUpload) => {
          return {upload: currentUpload};
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  keys(item: {}) {
    return Object.keys(item);
  }

  upload(fileToBeUploaded: File, temporaryFile: { upload?: Upload }) {
    this.uploadApiService.uploadFile(fileToBeUploaded)
      .subscribe((result) => {
          temporaryFile.upload = result.data;
          this.updateControlValue();
          this.ref.markForCheck();
        },
        (error) => {
          this.notificationService.showErrorAlert();
          this.files.splice(this.files.indexOf(temporaryFile), 1);
        });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.addedFiles) {
      $event.addedFiles.forEach((addedFile) => {
        const tempFile = {upload: null};
        this.files = [...this.files, tempFile];

        this.upload(addedFile, tempFile);
      });
    }
  }

  drop($event: CdkDragDrop<any>) {
    if (!this.isDisabled) {
      moveItemInArray(this.files, $event.previousIndex, $event.currentIndex);
      this.updateControlValue();
      this.ref.markForCheck();
    }
  }

  removeFile(item: any) {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.DELETE_PHOTO, true);

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.files.splice(this.files.indexOf(item), 1);
          this.updateControlValue();
          this.ref.markForCheck();
        }
      }
    );
  }

  private updateControlValue() {
    this.control.setValue(this.files.filter((item) => item.upload).map((item) => item.upload));
  }
}

interface TempFile {
  upload?: Upload;
}
