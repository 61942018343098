import {Injectable} from '@angular/core';
import {IAlbum, Lightbox} from 'ngx-lightbox';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private lightbox: Lightbox) {
  }

  openGallery(images: IAlbum[], index: number) {
    //  Don't change the options, otherwise it might stop loading/showing images as there's an issue with tranisions/animations
    this.lightbox.open(images, index, {
      enableTransition: false,
    });
  }
}
