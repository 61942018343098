import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';

export enum IntegrationTypeEnum {
  AVTONET = 'avtonet',
  DOBER_AVTO = 'dober_avto',
  NJUSKALO = 'njuskalo',
  INDEX_HR = 'index_hr',
  FACEBOOK_DYNAMIC_CATALOG = 'facebook_dynamic_catalog',
  SHOPAJ = 'shopaj24',

  INSTAGRAM_DYNAMIC_CATALOG = 'instagram_dynamic_catalog',
  INSTAGRAM_POSTS = 'instagram_posts',
  TIKTOK_POSTS = 'tiktok_posts',
  SNAPCHAT_POSTS = 'snapchat_posts',
  GOOGLE_BUSINESS = 'google_business',

  VIRTUAL_SALON = 'virtual_salon',
  SPACE_INVOICES = 'space_invoices',
  NLB_LEASING = 'nlb_leasing',

  /** @deprecated */
  AMP = 'amp',
  TRADING_HUB = 'trading_hub',

  LEANPAY_LEASING = 'leanpay_leasing',
  PARTNERS_PORTAL = 'partners_portal',
  MOTIVE_SERVICE = 'motive_service',
  FACEBOOK = 'facebook',
  PHYRON = 'phyron',
  INTRIX_CRM = 'intrix_crm',
  BOLHA = 'bolha',
  ADDIKO = 'addiko',
}

export const IntegrationTypeFeatureFlagMapping = {
  'phyron': AccountFeaturesEnum.IS_AVAILABLE_PHYRON,
  'dober_avto': AccountFeaturesEnum.IS_AVAILABLE_DOBERAVTO,
  'njuskalo': AccountFeaturesEnum.IS_AVAILABLE_NJUSKALO,
  'index_hr': AccountFeaturesEnum.IS_AVAILABLE_INDEX_HR,
  'shopaj24': AccountFeaturesEnum.IS_AVAILABLE_SHOPAJ,
  'virtual_salon': AccountFeaturesEnum.ADVERTISEMENT_VIRTUAL_SALON,
};

export const IntegrationsNames = {
  [IntegrationTypeEnum.DOBER_AVTO]: 'DoberAvto.si',
  [IntegrationTypeEnum.PHYRON]: 'AI Video by Phyron',
  [IntegrationTypeEnum.NJUSKALO]: 'Njuskalo.hr',
  [IntegrationTypeEnum.INDEX_HR]: 'Index.hr',
  [IntegrationTypeEnum.SHOPAJ]: 'Shopaj24.si',
  [IntegrationTypeEnum.VIRTUAL_SALON]: 'Virtual Salon',
};

export enum AdIntegrationTypeEnum {
  DOBER_AVTO = 'dober_avto',
  NJUSKALO = 'njuskalo',
  INDEX_HR = 'index_hr',
  SHOPAJ = 'shopaj24',
  FACEBOOK_DYNAMIC_CATALOG = 'facebook_dynamic_catalog',
  INSTAGRAM_DYNAMIC_CATALOG = 'instagram_dynamic_catalog',
  INSTAGRAM_POSTS = 'instagram_posts',
  TIKTOK_POSTS = 'tiktok_posts',
  SNAPCHAT_POSTS = 'snapchat_posts',
  PHYRON = 'phyron',
}
