import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {NotificationApiService} from '@app/services/api/notification-api.service';

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit {
  @Input() notifications: Array<InAppNotification>;
  @Input() totalNotifications = 0;
  @Output() closeNotification = new EventEmitter();
  @Output() closeDropdown = new EventEmitter();
  @Output() readAllNotification = new EventEmitter();

  public dropDownNotifications: Array<InAppNotification>;

  constructor(
    private notificationApiService: NotificationApiService,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.dropDownNotifications = this.notifications.slice(0, 3);
  }

  childCloseEventHandler() {
    this.closeNotification.emit();
  }

  readAllNotifications() {
    this.closeDropdown.emit();
    this.readAllNotification.emit();
    this.notificationApiService.readAllNotifications().subscribe((result) => {
    });
  }
}
