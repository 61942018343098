<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
        <span i18n>Uredi značko</span>
      </p>


      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="card-content pt-0">
    <form [formGroup]="form">

      <div class="mb-2">
        <label>
          <span i18n="">Ime značke:</span>
          <div class="mt-2">
            <input formControlName="name" [ngClass]="{'input-error': form.controls.name.invalid }"/>
          </div>
        </label>

        <app-field-errors [control]="form.controls.name"></app-field-errors>
      </div>
    </form>

  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()"><span i18n>Prekliči</span></button>
      <button class="btn btn-primary ml-1"
              [disabled]="form.invalid || form.disabled"
              (click)="confirm()">
        <span i18n>Shrani</span>
      </button>
    </div>
  </div>
</div>
