<textarea
  [formControl]="control"
  [ngClass]="{ 'input-error': control.invalid }"
  [attr.aria-label]="field.config.label"
  [placeholder]="field.config.placeholder"
  autocomplete="off"
  [attr.rows]="field?.config?.rows ?? 5"
  [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId"
  [attr.tabIndex]="0"
></textarea>
