<a class="bg-white border rounded border-grayTableBorder p-3 flex flex-row hover:border-primary h-100 cursor-pointer"
   (click)="redirect()"
   [routerLink]="'/admin/clients/'+result.clientUuid"
   style="min-height: 90px;">
  <div class="flex flex-col w-full h-100 my-auto pointer-events-none">
    <app-client-table-cell [client]="result" [disableLink]="true"></app-client-table-cell>

    <div class="leading-loose" *ngIf="result.clientDetails" [ngClass]="{'mt-1': result.clientDetails.personRegistrationNumber ||
                result.clientDetails.personTaxNumber ||
                result.clientDetails.companyRegistrationNumber ||
                result.clientDetails.companyTaxNumber}">
      <ul class="text-grayDark text-xs ml-6">
        <ng-container *ngIf="result.isPerson">
          <li class="leading-relaxed" *ngIf="result.clientDetails.personRegistrationNumber">
            <span i18n class="">EMŠO:</span>
            <span
              class="ml-1 font-semibold">{{ result.clientDetails.personRegistrationNumber }}</span>
          </li>
          <li class="leading-relaxed" *ngIf="result.clientDetails.personTaxNumber">
            <span i18n class="">Davčna št.:</span>
            <span class="ml-1 font-semibold">{{ result.clientDetails.personTaxNumber }}</span>
          </li>
        </ng-container>
        <ng-container *ngIf="!result.isPerson">
          <li class="leading-relaxed" *ngIf="result.clientDetails.companyRegistrationNumber">
            <span i18n class="">Matična št.:</span>
            <span
              class="ml-1 font-semibold">{{ result.clientDetails.companyRegistrationNumber }}</span>
          </li>
          <li class="leading-relaxed" *ngIf="result.clientDetails.companyTaxNumber">
            <span i18n class="">Davčna št.:</span>
            <span class="ml-1 font-semibold">{{ result.clientDetails.companyTaxNumber }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</a>
