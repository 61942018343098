import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {EntityGroup} from '../../../interfaces/entities/entity-group';
import {FormBuilder, Validators} from "@angular/forms";
import {EntityGroupApiService} from "../../../services/api/entity-group-api.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-edit-entity-group-dialog',
  templateUrl: './edit-entity-group-dialog.component.html',
  styleUrls: ['./edit-entity-group-dialog.component.scss']
})
export class EditEntityGroupDialogComponent implements OnInit {

  public entityGroup: EntityGroup;

  public form = this.formBuilder.group({name: this.formBuilder.control(null, Validators.required)});

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private entityGroupApi: EntityGroupApiService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder) {

    this.entityGroup = data.entityGroup;
    this.form.controls.name.setValue(this.entityGroup.name);
  }

  static open(dialog: MatDialog, entityGroup: EntityGroup) {
    return dialog.open(EditEntityGroupDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        entityGroup,
      }
    });
  }

  ngOnInit() {
  }

  confirm() {
    this.form.disable();

    this.entityGroupApi.updateEntityGroup(this.entityGroup.entityGroupUuid, {name: this.form.controls.name.value})
      .subscribe((result) => {
        this.dialogRef.close(result.data);
      }, (error) => {
        this.form.enable();
        this.notificationService.showErrorAlert();
      });
  }

  close() {
    this.dialogRef.close(false);
  }
}
