import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';

import {Observable} from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = {
      locale: this.locale,
    };

    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req);
  }
}
