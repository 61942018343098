<div class="card">
  <div class="card-header p-5 pb-0">
    <h2>{{ 'dialogs.addFile.title'}}</h2>
  </div>
  <div class="card-content">
    <div *ngIf="!isLoading; else showLoading">
      <div ngx-dropzone *ngIf="files.length === 0" (change)="onSelect($event)" [multiple]="false"
           class="dropzone"
           [accept]="'image/jpeg,image/jpg,image/png,image/gif'">
        <ngx-dropzone-label><span class="text-lg">{{ 'dialogs.addFile.dropFile' }}</span>
        </ngx-dropzone-label>
      </div>
    </div>

    <ng-template #showLoading>
      <!--   <app-content-shimmer height="150px"></app-content-shimmer> -->
    </ng-template>

    <div class="mt-12 text-center">
      <button class="btn btn-grayDark-link text-left" (click)="close()"
              *ngIf="!isLoading"
              [disabled]="isLoading">{{ 'misc.cancel'}}</button>

      <div class="flex justify-center items-center" *ngIf="isLoading">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
