import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Auth} from '../interfaces';
import {environment} from '@src/environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import Hotjar from '@hotjar/browser';
import {EMPTY} from "rxjs";
import {AccountConfig} from "@app/interfaces/config/account.config";

declare let fbq: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private isAccountUnderControl = false;
  private lastAuth: Auth | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private httpClient: HttpClient,
    private gtmService: GoogleTagManagerService
  ) {
    //  When loads, setup analytics
    if (isPlatformBrowser(this.platformId)) {
      if (this.isConfigured(environment.facebookTrackingID)) {
        fbq('init', environment.facebookTrackingID);
      }

      if (this.isConfigured(environment.hotjarTrackingID)) {
        this.setupHotjar(environment.hotjarTrackingID as unknown as number);
      }

      // Check if credentials are set
      if (this.isConfigured(environment.gtmID)) {
        // Track DOM events
        this.gtmService.addGtmToDom();
      }
    }
  }

  setUser(auth: Auth | null) {
    if (this.isAccountUnderControl) return;

    this.lastAuth = auth;

    if (isPlatformBrowser(this.platformId)) {
      if (auth) {
        this.isConfigured(environment.gtmID) && this.gtmService.pushTag({userId: auth.userUuid})
        Hotjar.isReady() && Hotjar.identify(auth.userUuid, {email: auth.email});
      } else {
        this.isConfigured(environment.gtmID) && this.gtmService.pushTag({userId: null})
      }
    }
  }

  setAccountConfig(accountConfig: AccountConfig) {
    if (this.isAccountUnderControl) return;

    if (isPlatformBrowser(this.platformId)) {
      if (accountConfig) {
        this.isConfigured(environment.gtmID) && this.gtmService.pushTag({
          userId: this.lastAuth?.userUuid,
          accountUuid: accountConfig.uuid,
          package: accountConfig.activeAccountProductPackage?.productPackage?.name,
          isTrial: accountConfig.activeAccountProductPackage?.isTrial,
        })
        Hotjar.isReady() && Hotjar.identify
      } else {
        this.isConfigured(environment.gtmID) && this.gtmService.pushTag({
          userId: this.lastAuth?.userUuid,
          accountUuid: accountConfig.uuid,
          package: accountConfig.activeAccountProductPackage?.productPackage.name,
          isTrial: accountConfig.activeAccountProductPackage?.isTrial,
        })
      }
    }
  }

  firePageEvent(url: string, title: string) {
    if (this.isAccountUnderControl) return;

    if (isPlatformBrowser(this.platformId)) {
      this.isConfigured(environment.gtmID) && this.gtmService.pushTag({event: 'PageView', page_title: title, page_path: url});
    }
  }

  fireBriefdAnalyticsEvent(event: string, data?: any) {
    if (this.isAccountUnderControl) return EMPTY;

    return this.httpClient.post(`${environment.apiUrl}/api/analytics-events`, {event, data});
  }

  public trackEvent(event: string, params: Object) {
    if (this.isAccountUnderControl) return;

    try {
      this.isConfigured(environment.gtmID) && this.gtmService.pushTag({event, params})
      this.isConfigured(environment.facebookTrackingID) && fbq('trackCustom', event, params);
      this.isConfigured(environment.hotjarTrackingID) && Hotjar.event(event);
    } catch (e) {
      console.error(e);
    }
  }

  private setupHotjar(hotjarTrackingId: number) {
    Hotjar.init(hotjarTrackingId, 6, {debug: !environment.production});
  }

  private isConfigured(environmentId: string | undefined): boolean {
    return environmentId !== undefined && environmentId !== 'x';
  }

  public setIsAccountControl(control: boolean) {
    this.isAccountUnderControl = control;
  }
}
