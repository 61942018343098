<div class="flex flex-row items-center bg-white text-sm font-normal" [ngClass]="getClass(upload)">
  <div>
    <i class="fas mr-2 text-primary" [ngClass]="getFontClass(upload)"></i>
  </div>
  <div class="grow" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">

    <a [href]="upload.files[0].url + '?download=1'" [title]="upload.files[0].fileName" target="_blank"
       *ngIf="!isUploadImage(upload)"
       class="text-primary">
      {{ upload.files[0].fileName}}
    </a>

    <a (click)="openLightBox($event, upload)"
       *ngIf="isUploadImage(upload)"
       class="  text-primary cursor-pointer">
      {{ upload.files[0].fileName}}
    </a>

    <span class="text-gray text-sm italic">({{(upload.files[0].size / 1000000).toFixed(2) | number }} Mb)</span>
  </div>

  <a [href]="upload.files[0].url + '?download=1'" [title]="upload.files[0].fileName" target="_blank"
     *ngIf="isUploadImage(upload)"
     class="  text-primary">
    <button class="btn btn-sm btn-grayDark-link border-transparent shadow-none no-transition"><i
      class="fas fa-download"></i></button>
  </a>
</div>
