import { Component, OnInit } from '@angular/core';
import {SafariScreenFixService} from '../../../services/safari-screen-fix.service';

@Component({
  selector: 'app-not-found-error',
  templateUrl: './not-found-error.component.html',
  styleUrls: ['./not-found-error.component.scss']
})
export class NotFoundErrorComponent implements OnInit {

  constructor(private safariScreenFix: SafariScreenFixService) { }

  ngOnInit() {
  }

  isSafari() {
    return this.safariScreenFix.getIsSafari();
  }
}
