import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormInputComponent} from "@app/shared/briefd-form/form-input/form-input.component";

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss']
})
export class FieldInputComponent implements OnInit, OnChanges {

  @ViewChild(FormInputComponent) formInputComponent!: FormInputComponent;
  @Input() field = null;
  @Input('disabled') isDisabled = false;
  @Input() formGroup: FormGroup;
  @Input() hideLabel = false;
  @Input() isRequired = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    }
  }

  removeFiles() {
    if (this.formInputComponent) {
      this.formInputComponent.removeFiles();
    } else {
      console.error('Cannot remove files, component is undefined.');
    }
  }
}
