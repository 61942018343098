import {Component, Input, OnInit} from '@angular/core';
import {PublicAccount} from '../../../../interfaces';

@Component({
  selector: 'app-public-account-widget',
  templateUrl: './public-account-widget.component.html',
  styleUrls: ['./public-account-widget.component.scss']
})
export class PublicAccountWidgetComponent implements OnInit {

  @Input() account: PublicAccount;

  constructor() {
  }

  ngOnInit(): void {
  }

}
