<main class="p-6 md:p-12 overflow-y-auto max-h-screen" style="background-color: #E5E5E5; height:100vh">
  <div class="w-full lg:w-8/12 mx-auto">

    <ng-container *ngIf="isLoading; else showContent">
      <app-content-shimmer height="40px"></app-content-shimmer>
      <hr class="my-4 md:my-8">
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-2/3 md:mr-2">
          <app-content-shimmer height="500px"></app-content-shimmer>

          <div class="mt-10">
            <app-content-shimmer height="500px"></app-content-shimmer>
          </div>
        </div>

        <div class="w-full md:w-1/3 md:ml-2 mt-10 md:mt-0">
          <app-content-shimmer height="500px"></app-content-shimmer>
        </div>
      </div>
    </ng-container>

    <ng-template #showContent>
      <ng-container *ngIf="!isMissing; else showNotFound">

        <div class="flex flex-row justify-between">
          <h1 class="mb-0"><span i18n>Virtualni salon</span></h1>
        </div>
        <div class="text-base font-bold mt-3">Skupaj vozil: {{ listing.publicVehicles.length }}</div>

        <div class="flex flex-col md:flex-row mt-3">
          <div class="w-full md:w-2/3 md:mr-2">
            <ng-container *ngIf="listing.publicVehicles.length; else showEmpty">
              <div class="card bg-white mb-4" *ngFor="let vehicle of listing.publicVehicles">
                <div class="card-content flex flex-row justify-between">
                  <div class="mr-4 grow-0" style="max-width: 200px;" *ngIf="getFeaturedPhoto(vehicle) as featuredPhoto">
                    <a [routerLink]="['/v', vehicle.vehicleUuid]"><img class="rounded object-cover"
                         [src]="addParameters(featuredPhoto.value[0].files?.[0]?.conversions.thumbnail, featuredPhoto.fieldUuid, vehicle.vehicleUuid)"
                         alt=""></a>
                  </div>
                  <div class="grow">
                    <div class="text-greenDarkOlive text-lg font-bold"><a [routerLink]="['/v', vehicle.vehicleUuid]">{{ getTitle(vehicle) }}</a></div>
                    <div class="text-base text-black mt-1">{{ vehicle.customVehicleType }}, {{ vehicle.modelYear }}</div>
                    <div class="text-sm text-grayDark">
                      <div class="my-2" *ngIf="getValue('briefd.vehicle.current_mileage', vehicle)">
                        {{ getValue('briefd.vehicle.current_mileage', vehicle) }} km
                      </div>
                      <div class="my-2" *ngIf="getValue('briefd.vehicle.engine_size_in_ccm', vehicle) && getValue('briefd.vehicle.engine_power_in_kw', vehicle)">
                        {{ getValue('briefd.vehicle.engine_size_in_ccm', vehicle) }} ccm, {{ getValue('briefd.vehicle.engine_power_in_kw', vehicle) }} kW
                      </div>
                    </div>
                  </div>
                  <div class="border-l border-grayLight ml-8" *ngIf="vehicle.publishDetails.price">
                    <div
                      class="text-greenDarkOlive text-xl text-right font-bold ml-6">{{ vehicle.publishDetails.price | currency: 'EUR':'symbol':'4.2-2':'sl' }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #showEmpty>
              <div class="table-empty-content card">
                <h2 i18n>Trenutno ni na voljo nobenega vozila</h2>
              </div>
            </ng-template>


          </div>

          <div class="w-full md:w-1/3 md:ml-2 mt-10 md:mt-0">
            <div class="card bg-white">
              <div class="card-content">
                <div class="text-base font-bold">{{ listing.publicAccount.name }}</div>
                <div class="text-sm mt-3">{{ listing.publicAccount.address }}</div>
                <hr class="my-5">
                <ng-container *ngFor="let contact of listing.publicAccount.contacts" [ngSwitch]="contact.type">
                  <div>
                    <a href="tel:{{contact.value}}" *ngSwitchCase="'phone'">
                      <i class="fas fa-phone text-gray mr-3"></i> <span
                      class="text-green text-lg font-bold break-words">{{ contact.label }}</span>
                    </a>
                  </div>
                  <div class="mt-4">
                    <a href="mailto:{{contact.value}}" *ngSwitchCase="'email'" class="mt-4">
                      <i class="fas fa-envelope text-gray mr-3"></i> <span
                      class="text-green text-lg font-bold break-words">{{ contact.label }}</span>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--  <div class="card bg-white mt-4">
              <div class="card-content">
                <app-share-vehicle-widget [url]="url"></app-share-vehicle-widget>
              </div>
            </div> -->

          </div>
<
        </div>
      </ng-container>

      <ng-template #showNotFound>
        <div class="card w-full text-center">
          <div class="card-content">
            <h1 class="mb-0 p-6 md:p-12">{{ 'tabTitle.pageNotFound' }}</h1>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</main>
