<app-content-shimmer height="1.5rem"></app-content-shimmer>

<hr>

<div class="mt-4">
  <app-content-shimmer height="3rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<div class="mt-4">
  <app-content-shimmer height="2rem"></app-content-shimmer>
</div>

<hr>

<div class="mt-4">
  <app-content-shimmer height="3rem"></app-content-shimmer>
</div>
