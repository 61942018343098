<div class="card bg-white relative border-none">
  <div class="card-header p-5 sm:p-5 flex">
    <div class="w-full flex items-center justify-center">
      <p class="flex mb-0 pb-0 pr-0 text-greenDarkOlive text-lg sm:text-xl font-bold text-center"><span i18n>Izbrali ste paket</span>&nbsp;<span
              class="uppercase">{{ productPackage.name }}</span>
      </p>
    </div>
  </div>
  <div class="card-content">
    <div>
      <span i18n>Uspešno ste izbrali paket </span><b class="uppercase">{{ productPackage.name }}
      -
      <ng-container>
        <span *ngIf="productType === productPackageSubscriptionTypeEnum.YEARLY" i18n>Letno</span>
        <span *ngIf="productType === productPackageSubscriptionTypeEnum.MONTHLY" i18n>Mesečno</span>
      </ng-container>
    </b>
    </div>

    <!-- First paid package selection -->
    <div class="mt-3"
         *ngIf="accountProductPackage.isTrial && !productPackage.isFree && accountProductPackage.isExpired">
      <p>
        <span i18n>Na email naslov <b>{{ accountConfig.billingEmail }}</b> bo poslan email s predračunom ter povezavo do plačila.</span>
      </p>

      <p>
        <span i18n>S klikom na gumb NA PONUDBO boste preusmerjeni na stran z avtomatskim sistemom za plačilo s kartico. V kolikor ne uporabljate službene plačilne kartice, lahko plačate tudi preko SEPA.</span>
      </p>
    </div>

    <!-- Upsell -->
    <div class="mt-3" *ngIf="!accountProductPackage.isTrial && !productPackage.isFree && !accountProductPackage.isFree">
      <p>
        <span i18n>Na email naslov <b>{{ accountConfig.billingEmail }}</b> bo poslan email s računom ter povezavo do plačila.</span>
      </p>
      <br>
      <p>
        <span i18n>S klikom na gumb NA PONUDBO boste preusmerjeni na stran z avtomatskim sistemom za plačilo s kartico. V kolikor ne uporabljate službene plačilne kartice, lahko izberete tudi plačilo SEPA.</span>
      </p>
    </div>


    <div class="flex flex-row justify-center mt-5 gap-2">
      <button [disabled]="isLoading"  class="btn btn-primary" (click)="refresh()"><span
              i18n>Zapri</span></button>
      <button [disabled]="isLoading" *ngIf="accountProductPackage.paymentUrl && !accountProductPackage.isPaid && !accountProductPackage.isRejected && !accountProductPackage.isSigned" class="btn btn-primary" (click)="recheckStateAndRefresh()"><span
              i18n>Ponudbo smo sprejeli/zavrnili</span></button>
      <button class="btn btn-primary" *ngIf="accountProductPackage.paymentUrl && !accountProductPackage.isPaid && !accountProductPackage.isRejected && !accountProductPackage.isSigned" [disabled]="isLoading"  (click)="openPaymentUrlInTab()"><span
              i18n>Na ponudbo</span></button>
    </div>
  </div>

</div>
