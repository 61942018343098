<div class="dropdown">
    <input type="text"
           [placeholder]="field.config.placeholder"
           matInput
           [formControl]="optionsControl"
           [matAutocomplete]="numberAuto"
           [attr.data-field]="field?.fieldUuid"
           [attr.data-typeId]="field?.config?.typeId">
    <mat-autocomplete #numberAuto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
          {{ option.value }}
        </mat-option>
    </mat-autocomplete>

  <button class="mt-4 text-sm text-primary hover:text-greenDarkOlive" (click)="editTestPlates()" i18n>Uredi preizkusne tablice</button>
</div>
