<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
        <ng-container [ngSwitch]="type">
          <span *ngSwitchCase="DialogTypes.DELETE_VEHICLE" i18n="Confirm modal">Izbris vozila</span>

          <span *ngSwitchCase="DialogTypes.DELETE_CLIENT" i18n="Confirm modal">Izbris kontakta</span>

          <span *ngSwitchCase="DialogTypes.DELETE_ALARM" i18n="Confirm modal">Izbris opomnika</span>

          <span *ngSwitchCase="DialogTypes.DELETE_PHOTO" i18n="Confirm modal">Izbris fotografije</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_USER_FROM_ACCOUNT" i18n="Confirm modal">Odstranitev uporabnika</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_EXPENSE" i18n="Confirm modal">Izbris postavke</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_AD" i18n="Confirm modal">Izbris oglasa</span>

          <span *ngSwitchCase="DialogTypes.DISABLE_ADVERTISING"
                i18n="Confirm modal">Brisanje oglasov</span>

          <span *ngSwitchCase="DialogTypes.CREATE_DOCUMENT" i18n="Confirm modal">Ustvari dokument</span>

          <span *ngSwitchCase="DialogTypes.ARCHIVE_VEHICLE" i18n="Confirm modal">Arhiviraj vozilo</span>

          <span *ngSwitchCase="DialogTypes.UNARCHIVE_VEHICLE" i18n="Confirm modal">Premakni iz arhiva</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_PAYMENT" i18n="Confirm modal">Izbris plačila</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_INVOICE_DOCUMENT" i18n="Confirm modal">Storno</span>

          <span *ngSwitchCase="DialogTypes.UNCANCEL_INVOICE_DOCUMENT"
                i18n="Confirm modal">Obnova fakture</span>

          <span *ngSwitchCase="DialogTypes.ARCHIVE_DOCUMENT" i18n="Confirm modal">Arhiviraj dokument</span>

          <span *ngSwitchCase="DialogTypes.UNARCHIVE_DOCUMENT" i18n="Confirm modal">Obnovi dokument</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_ENTITY_GROUP" i18n="Confirm modal">Izbriši značko</span>

          <span *ngSwitchCase="DialogTypes.UPDATE_DOCUMENT" i18n="Confirm modal">Posodobi dokument</span>

          <span *ngSwitchCase="DialogTypes.DELETE_NLB_DRAFT" i18n="Confirm modal">Izbris osnutka</span>

          <span *ngSwitchCase="DialogTypes.REMOVE_CASH_RECEIPT"
                i18n="Confirm modal">Storniraj blagajniški prejemek</span>

          <span *ngSwitchCase="DialogTypes.REACTIVATE_ACCOUNT" i18n="Confirm modal">Potrditev reaktivacija računa</span>

          <span *ngSwitchCase="DialogTypes.ARCHIVE_ASSET" i18n="Confirm modal">Izbris storitve</span>

          <span *ngSwitchCase="DialogTypes.UNARCHIVE_ASSET" i18n="Confirm modal">Obnovitev storitve</span>

          <span *ngSwitchCase="DialogTypes.DELETE_BUSINESS_UNIT" i18n="Confirm modal">Zbriši poslovno enoto</span>

          <span *ngSwitchCase="DialogTypes.RESUBSCRIBE" i18n="Confirm modal">Ponovno aktiviraj naročnino</span>

          <span *ngSwitchDefault i18n="Confirm modal">Potrditev akcije</span>
        </ng-container>
      </p>


      <button (click)="close()"
              class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut">
        <i class="fas fa-times text-lg"></i>
      </button>
    </div>
  </div>

  <div class="card-content pt-0">
    <p class="flex mb-0 pb-0 text-grayDark text-base">
      <ng-container [ngSwitch]="type">
        <span *ngSwitchCase="DialogTypes.DELETE_VEHICLE" i18n="Confirm modal">Z izbrisom vozila boste izbrisali tudi vse povezane podatke.</span>

        <span *ngSwitchCase="DialogTypes.DELETE_CLIENT"
              i18n="Confirm modal">Z izbrisom kontakta boste izbrisali tudi vse povezane podatke.</span>

        <span *ngSwitchCase="DialogTypes.DELETE_ALARM"
              i18n="Confirm modal">Potrebna je potrditev za izbris opomnika.</span>

        <span *ngSwitchCase="DialogTypes.DELETE_PHOTO"
              i18n="Confirm modal">Potrebna je potrditev za izbris fotografije.</span>

        <span *ngSwitchCase="DialogTypes.REMOVE_USER_FROM_ACCOUNT" i18n="Confirm modal">Odstranjen uporabnik ne bo imel več dostopa do tega računa.</span>

        <span *ngSwitchCase="DialogTypes.REMOVE_EXPENSE"
              i18n="Confirm modal">Potrebna je potrditev za izbris postavke.</span>

        <span *ngSwitchCase="DialogTypes.REMOVE_AD"
              i18n="Confirm modal">Potrebna je potrditev za izbris oglasa.</span>

        <span *ngSwitchCase="DialogTypes.DISABLE_ADVERTISING" i18n="Confirm modal">Izbrisali boste oglase na vseh portalih za oglaševanje.</span>
      </ng-container>
    </p>
    <ng-container [ngSwitch]="type">

      <ng-container *ngSwitchCase="DialogTypes.CREATE_DOCUMENT">
        <div class="text-sm text-grayDark">
          <div class="mb-1">
            <div class="flex flex-row">
              <div class="text-lg">
                <i class="text-primary fas fa-info-circle mr-3">
                </i>
              </div>
              <div>
                <span i18n>Ustvarjen dokument lahko dodatno urejaš v Wordu.</span>
              </div>
            </div>
          </div>
          <div class="mb-1">
            <div class="flex flex-row">
              <div class="text-lg">
                <i class="text-primary fas fa-info-circle mr-3">
                </i>
              </div>
              <div>
                <span i18n>Vnešeni podatki bodo posodobili podatke na vozilu in kontaktu.</span>
              </div>
            </div>
          </div>
          <div class="">
            <div class="flex flex-row">
              <div class="text-lg">
                <i class="text-red fas fa-exclamation-circle mr-3">
                </i>
              </div>
              <div>
                <span i18n>Naknadno urejanje bo povzročilo neujemanje podatkov znotraj sistema. Spremenjeni dokument naj se kot datoteka naloži nazaj v sistem.</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="DialogTypes.ARCHIVE_VEHICLE">
        <div class="flex flex-col">
          <p class="mb-2" i18n="Confirm modal">Z arhiviranjem boste vozilo premaknili v arhiv.</p>
          <p class="mb-2" i18n="Confirm modal">Vozilo lahko kadarkoli premaknete iz arhiviranega stanja v
            aktivno
            stranje.</p>
          <p class="mb-2" i18n="Confirm modal">Ob arhiviranju se tudi odstranijo vsi obstoječi oglasi za to
            vozilo.</p>
        </div>
      </ng-container>
      <span *ngSwitchCase="DialogTypes.UNARCHIVE_VEHICLE" i18n="Confirm modal">S to akcijo boste vozilo ponovno aktivirali in jo postavili med aktivna.</span>
      <span *ngSwitchCase="DialogTypes.REMOVE_PAYMENT"
            i18n="Confirm modal">Potrebna je potrditev za izbris plačila.</span>
      <ng-container *ngSwitchCase="DialogTypes.REMOVE_INVOICE_DOCUMENT">
        <div class="flex flex-col">
          <span i18n>Želiš stornirati to fakturo?</span>

          <div class="card mt-4">
            <div class="card-content flex flex-col text-sm">
              <span i18n>V kolikor je bilo na računu vozilo bo premaknjeno iz “Prodano” nazaj na v zavihek “Vozila”. Prav tako bo ponastavljen “Končna prodana cena vozila na postavki” in “Datum prodaje” v zavihku "Kalkulacija".</span>
            </div>
          </div>
          <div class="text-xs text-grayDark mt-2" *ngIf="!custom?._furs" i18n>
            *V primeru, da je račun bil davčno potrjen, se vam bo ustvaril dobropis za znesek računa
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="DialogTypes.REMOVE_INVOICE_ASSET_DOCUMENT">
        <div class="flex flex-col">
          <span i18n="Confirm modal">Želiš stornirati to fakturo?</span>

          <div class="text-xs text-grayDark mt-2" *ngIf="!custom?._furs" i18n>
            *V primeru, da je račun bil davčno potrjen, se vam bo ustvaril dobropis za znesek računa
          </div>
        </div>
      </ng-container>
      <span *ngSwitchCase="DialogTypes.UNCANCEL_INVOICE_DOCUMENT"
            i18n="Confirm modal">Želiš obnoviti to fakturo?</span>
      <span *ngSwitchCase="DialogTypes.ARCHIVE_DOCUMENT" i18n="Confirm modal">Želiš arhivirati dokument?</span>
      <span *ngSwitchCase="DialogTypes.UNARCHIVE_DOCUMENT"
            i18n="Confirm modal">Želiš obnoviti ta dokument in ga premakniti med izdane dokumente?</span>
      <span *ngSwitchCase="DialogTypes.REMOVE_ENTITY_GROUP" i18n="Confirm modal">Želiš izbrisati to značko?</span>

      <ng-container *ngSwitchCase="DialogTypes.UPDATE_DOCUMENT">
        <div class="text-sm text-grayDark">
          <div class="mb-1">
            <div class="flex flex-row">
              <div class="text-lg">
                <i class="text-primary fas fa-info-circle mr-3">
                </i>
              </div>
              <div>
                <div>
                  <span i18n>Obrazec je predizpolnjen s podatki, ki so bili vnešeni ob zadnji izdaji dokumenta.</span><br><br>
                  <span i18n>Podatki se lahko razlikujejo od trenutno nastavljenih na vozilu ali kontaktu.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-1">
            <div class="flex flex-row">
              <div class="text-lg">
                <i class="text-red fas fa-exclamation-circle mr-3">
                </i>
              </div>
              <div>
                <span i18n>Naknadno urejanje dokumentov ne bo posodobilo podatkov na vozilu in kontaktu.</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <span *ngSwitchCase="DialogTypes.REMOVE_CASH_RECEIPT" i18n="Confirm modal">Želiš stornirati ta blagajniški prejemek?</span>
      <span *ngSwitchCase="DialogTypes.REACTIVATE_ACCOUNT"
            i18n="Confirm modal">Za nadaljevanje potrebujemo potrditev.</span>
      <span *ngSwitchCase="DialogTypes.CHANGE_BUSINESS_UNIT" i18n="Confirm modal">Zamenjaj poslovno enoto?</span>
      <span *ngSwitchCase="DialogTypes.RESUBSCRIBE" i18n="Confirm modal">Ponovno aktiviraj naročnino ?</span>

      <ng-container *ngSwitchCase="DialogTypes.DELETE_BUSINESS_UNIT">
        <div class="space-y-2 text-red font-bold">
          <span class="flex">POZOR!!!</span>
          <ul class="list-disc list-inside">
            <li>Z izbrisom poslovne enote vozila in dokumenti nebodo več vidni. Do njih lahko še zmeraj dostopate preko
              arhiva poslovnih enot.
            </li>
            <li>Uporabniki, ki dostopajo do poslovne enote bodo iz njih izbrisani</li>
          </ul>
        </div>
      </ng-container>

      <span *ngSwitchDefault i18n="Confirm modal">Za nadaljevanje potrebujemo potrditev.</span>
    </ng-container>
  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()"><span i18n>Prekliči</span></button>
      <button class="btn ml-1"
              [ngClass]="{'btn-danger': isDangerous, 'btn-primary': !isDangerous}"
              (click)="confirm()">
        <ng-container [ngSwitch]="type">
          <span *ngSwitchCase="DialogTypes.REMOVE_USER_FROM_ACCOUNT" i18n="Confirm modal">Odstrani</span>
          <span *ngSwitchCase="DialogTypes.DISABLE_ADVERTISING" i18n="Confirm modal">Odstrani</span>

          <span *ngSwitchCase="DialogTypes.CREATE_DOCUMENT" i18n="Confirm modal">Ustvari dokument</span>

          <span *ngSwitchCase="DialogTypes.UNARCHIVE_VEHICLE" i18n="Confirm modal">Arhiviraj</span>
          <span *ngSwitchCase="DialogTypes.REMOVE_PAYMENT" i18n="Confirm modal">Potrdi</span>
          <span *ngSwitchCase="DialogTypes.REMOVE_INVOICE_DOCUMENT" i18n="Confirm modal">Storniraj</span>
          <span *ngSwitchCase="DialogTypes.REMOVE_INVOICE_ASSET_DOCUMENT" i18n="Confirm modal">Storniraj</span>
          <span *ngSwitchCase="DialogTypes.UNCANCEL_INVOICE_DOCUMENT" i18n="Confirm modal">Obnovi</span>
          <span *ngSwitchCase="DialogTypes.ARCHIVE_DOCUMENT" i18n="Confirm modal">Arhiviraj</span>
          <span *ngSwitchCase="DialogTypes.UNARCHIVE_DOCUMENT" i18n="Confirm modal">Obnovi</span>

          <span *ngSwitchCase="DialogTypes.UPDATE_DOCUMENT" i18n="Confirm modal">Posodobi dokument</span>
          <span *ngSwitchCase="DialogTypes.REMOVE_CASH_RECEIPT" i18n="Confirm modal">Storniraj</span>
          <span *ngSwitchCase="DialogTypes.REACTIVATE_ACCOUNT"
                i18n="Confirm modal">Potrdi reaktivacijo računa</span>

          <span *ngSwitchCase="DialogTypes.DELETE_NLB_DRAFT" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_ALARM" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_ALARM" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_CLIENT" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_VEHICLE" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_REPEATER_ITEM" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.DELETE_BUSINESS_UNIT" i18n="Confirm modal">Izbriši</span>
          <span *ngSwitchCase="DialogTypes.RESUBSCRIBE" i18n="Confirm modal">Ponovno aktiviraj</span>

          <span *ngSwitchDefault i18n="Confirm modal">Potrdi</span>
        </ng-container>
      </button>
    </div>
  </div>
</div>
