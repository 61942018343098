import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InlineFieldService {

  private subject = new BehaviorSubject<InlineFieldEvent>(null);

  constructor() {
  }

  fire(fieldUuid: string, status: InlineFieldEventStatus) {
    this.subject.next({fieldUuid, status});
  }

  events() {
    return this.subject;
  }
}

interface InlineFieldEvent {
  fieldUuid: string;
  status: InlineFieldEventStatus;
}

export enum InlineFieldEventStatus {
  closed,
  opened,
}
