<div class="dropdown">
  <select [formControl]="control" autocomplete="off" [ngClass]="{'input-error': control.invalid }"   [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId" [attr.tabIndex]="0">
    <option value=""></option>
    <option value="#ffffff" i18n="Color">bela</option>
    <option value="#f5f5dc" i18n="Color">bež</option>
    <option value="#fffdd0" i18n="Color">kremno bež</option>
    <option value="#c0c0c0" i18n="Color">srebrna</option>
    <option value="#a6a6a6" i18n="Color">svetlo siva</option>
    <option value="#808080" i18n="Color">siva</option>
    <option value="#595959" i18n="Color">temno siva</option>
    <option value="#000000" i18n="Color">črna</option>
    <option value="#ff0000" i18n="Color">rdeča</option>
    <option value="#990000" i18n="Color">bordo rdeča</option>
    <option value="#ffff00" i18n="Color">rumena</option>
    <option value="#ff9900" i18n="Color">oranžna</option>
    <option value="#00822f" i18n="Color">olivno zelena</option>
    <option value="#67ff79" i18n="Color">svetlo zelena</option>
    <option value="#00ff00" i18n="Color">temno zelena</option>
    <option value="#00ffb2" i18n="Color">modro zelena</option>
    <option value="#00ff00" i18n="Color">zelena</option>
    <option value="#48d8ff" i18n="Color">svetlo modra</option>
    <option value="#0000ff" i18n="Color">modra</option>
    <option value="#00008b" i18n="Color">temno modra</option>
    <option value="#ffb6c1" i18n="Color">svetlo roza</option>
    <option value="#663399" i18n="Color">vijolčna</option>
    <option value="#ffd700" i18n="Color">zlata</option>
    <option value="#8b4513" i18n="Color">rjava</option>
    <option value="#654321" i18n="Color">temno rjava</option>
    <option value="#b5651d" i18n="Color">svetlo rjava</option>
    <option value="multi" i18n="Color">večbarvno</option>
  </select>
</div>
