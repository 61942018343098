import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {map} from 'rxjs/operators';
import {UploadApiService} from '@app/services/api/upload-api.service';
import {NotificationService} from "@app/services/notification.service";

@Component({
  selector: 'app-add-photo-dialog',
  templateUrl: './add-photo-dialog.component.html',
  styleUrls: ['./add-photo-dialog.component.scss']
})
export class AddPhotoDialogComponent implements OnInit {

  public field: string;
  public isLoading = false;
  public files = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private uploadApiService: UploadApiService,
              private notification: NotificationService) {

    this.field = data.field;
  }

  ngOnInit() {
  }

  static open(dialog: MatDialog, field?: string) {
    return dialog.open(AddPhotoDialogComponent, {
      disableClose: false,
      data: {
        field
      }
    });
  }


  upload() {
    this.isLoading = true;

    this.uploadApiService.upload(this.files)
      .pipe(
        // @ts-ignore
        map(data => data.data)
      )
      .subscribe((result) => {
          this.isLoading = false;
          this.dialogRef.close(result);
        },
        (error) => {
          this.isLoading = false;
          this.notification.showErrorAlert();
        });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.addedFiles) {
      this.files.push(...$event.addedFiles);
      this.upload();
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}
