import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoUtilService {

  constructor() {
  }

  resizeImage(fileToBeUploaded: File): Observable<any> {
    const MAX_LENGTH = 1920;

    return new Observable((observer) => {

      let canvas = document.createElement('canvas');
      const img = document.createElement('img');

      const reader = new FileReader();
      reader.addEventListener('load', () => {

        const scaleCanvas = (c, scale) => {
          const scaledCanvas = document.createElement('canvas');
          scaledCanvas.width = c.width * scale;
          scaledCanvas.height = c.height * scale;

          scaledCanvas.getContext('2d')
            .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

          return scaledCanvas;
        };

        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;

          canvas.getContext('2d')
            .drawImage(img, 0, 0, canvas.width, canvas.height);

          const side = canvas.width > canvas.height ? 'width' : 'height';

          if (canvas[side] <= MAX_LENGTH) {
            observer.next(fileToBeUploaded);
            observer.complete();
            return;
          }

          while (canvas[side] >= 2 * MAX_LENGTH) {
            canvas = scaleCanvas(canvas, .5);
          }

          if (canvas[side] > MAX_LENGTH) {
            canvas = scaleCanvas(canvas, MAX_LENGTH / canvas[side]);
          }

          const blob = canvas.toBlob((resizedBlob) => {
            const resizedFile = new File([resizedBlob], fileToBeUploaded.name, {type: fileToBeUploaded.type});
            observer.next(resizedFile);
            observer.complete();
          }, fileToBeUploaded.type, 80);
        };

        // @ts-ignore
        img.src = reader.result;
      }, false);

      if (fileToBeUploaded) {
        reader.readAsDataURL(fileToBeUploaded);
      }
    });
  }
}
