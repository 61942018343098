<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">
        <span i18n="">Uporaba podpisa</span>
      </p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="card-content">
    <ng-container *ngIf="isLoading; else showContent">
      <div class="mb-3">
        <app-content-shimmer height="3rem"></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer height="3rem"></app-content-shimmer>
      </div>
      <div class="">
        <app-content-shimmer height="3rem"></app-content-shimmer>
      </div>
    </ng-container>

    <ng-template #showContent>
      <div class="rounded border border-grayLight card-content mb-3 text-center">
        <p>Odpri spodnjo povezavo v napravi, ki omogoča podpisovanje (mobilni telefon, tablica).</p>

        <p class="my-6 text-base font-bold"><span i18n>To okno naj bo odprto med samim podpisovanjem.</span></p>

        <div class="mt-6">
          <qrcode [qrdata]="getLink()" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <div class="mt-6 text-center break-words">
          <a [href]="getLink()" target="_blank">{{ getLink() }}</a>
        </div>
      </div>


      <div style="min-height: 50px">
        <div class="relative" *ngIf="isRefreshing">
          <div class="flex flex-row justify-center items-center">
            <div class="relative">
              <app-spinner></app-spinner>
            </div>
            <div class="ml-3 text-sm text-grayDark">
              <span i18n>Osvežujem...</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>

  <div class="card-content">
    <div class="flex flex-col sm:flex-row justify-center sm:justify-end">
      <button class="btn btn-grayDark-link mr-0 sm:mr-1 sm:mb-0" (click)="close()"><span i18n>Zapri</span></button>
    </div>
  </div>
</div>
