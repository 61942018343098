import {Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@app/services/auth.service';
import {Auth, PublicAccount} from '@app/interfaces';
import {Observable, repeat, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {UserApiService} from '@app/services/api/user-api.service';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {environment} from '@src/environments/environment';
import {FeatureFlagService} from '@app/services/feature-flag.service';
import {NotificationApiService} from '@app/services/api/notification-api.service';
import {InAppNotification} from '@app/interfaces/entities/in-app-notification';
import {AccountFeaturesEnum} from '@app/interfaces/config/accountFeaturesEnum';
import {FormControl} from '@angular/forms';
import {AccountConfig} from '@app/interfaces/config/account.config';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {map, takeUntil} from 'rxjs/operators';
import {IntegrationsEnum} from '@app/interfaces/entities/integration';
import {BusinessUnitsService} from '@app/services/accounts/business-units.service';
import {BusinessUnit} from '@app/interfaces/entities/accounts/BusinessUnit';
import {ConfirmDialogComponent, DialogType} from '@app/shared/dialogs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PermissionEnum} from '@app/enums/permissions/PermissionEnum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  public isUserMenuOpened = false;  //  TODO:: Refactor this into a standalone component that auto-closes on clicks or navigation changes
  public isHamburgerOpened = false; //  TODO:: Refactor this into a standalone component that auto-closes on clicks or navigation changes
  public isNotificationDropdownOpened = false;

  public hasAdministrationEnabled$: Observable<boolean>;
  public isInAdministration = false;
  public hasAdministrationV2 = false;

  public user: UserProfile;
  public account?: PublicAccount;
  public accountConfig$: Observable<AccountConfig>;
  public businessUnits: BusinessUnit[];

  public unreadNotifications = 0;
  public totalNotifications = 0;
  public notifications: Array<InAppNotification>;

  public AccountFeaturesEnum = AccountFeaturesEnum;
  protected readonly NavbarColor = environment.navbarColor;
  protected readonly PermissionEnum = PermissionEnum;

  private auth: Auth;

  public ALL_BUSINESS_UNITS = 'all';

  private destroy$ = new Subject<void>();

  public businessUnitsControl = new FormControl();

  constructor(private authService: AuthService,
              private router: Router,
              private notificationApiService: NotificationApiService,
              private featureFlagService: FeatureFlagService,
              private elementRef: ElementRef,
              private userApiService: UserApiService,
              private configAPIService: ConfigAPIService,
              private businessUnitsService: BusinessUnitsService,
              @Inject(LOCALE_ID) public locale: string,
              private matDialog: MatDialog
  ) {


    this.router.events.pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.isUserMenuOpened = false;
          this.isHamburgerOpened = false;

          this.isInAdministration = this.isAdministration(event.urlAfterRedirects);
        }
      });
  }

  ngOnInit() {
    this.authService.getAuthChanges().pipe(takeUntil(this.destroy$))
      .subscribe((auth) => {
        this.auth = auth;
        this.handleUser(this.auth);
      });

    this.accountConfig$ = this.configAPIService.getAccountConfig(null, true).pipe(takeUntil(this.destroy$));

    this.hasAdministrationEnabled$ = this.accountConfig$.pipe(
      map((config) => config.accountIntegrations?.some((integration) => integration.type === IntegrationsEnum.SPACE_INVOICES))
    );

    this.featureFlagService.getFeatureFlags().pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.hasAdministrationV2 = flags.administrationV2;
      });

    this.getCountUnreadNotifications();
    this.getTotalNotifications();
    this.setupBusinessUnitsDropdown();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  isLoggedIn() {
    return this.auth !== null;
  }

  toggleUserMenu() {
    this.isUserMenuOpened = !this.isUserMenuOpened;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (this.isUserMenuOpened) {
      this.isUserMenuOpened = this.elementRef.nativeElement.contains(target);
    }
  }

  private handleUser(auth: Auth | null) {
    if (!auth) {
      this.user = null;
      this.account = null;
      return;
    }

    this.userApiService.getUserProfile().pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response?.data) {
          this.user = response.data;
        }
      });

    this.userApiService.getPublicAccount().pipe(takeUntil(this.destroy$))
      .subscribe((response) => this.account = response.data);
  }

  toggleHamburgerMenu(state?: boolean) {
    this.isHamburgerOpened = (state === undefined) ? !this.isHamburgerOpened : state;
  }

  private isAdministration(url: string) {
    return url.indexOf('/admin/administration') !== -1;
  }

  getAdministrationUrl() {
    if (this.hasAdministrationV2) {
      return ['/admin/administration/dashboard'];
    }

    return ['/admin/administration'];
  }

  getUser(user: any) {
    if (user.profile) {
      return `${user.profile.firstName} ${user.profile.lastName}`;
    }

    return user.email;
  }

  toggleNotificationDropdown() {
    this.isNotificationDropdownOpened = !this.isNotificationDropdownOpened;

    if (this.isNotificationDropdownOpened) {
      this.getLast5Notifications();
    }
  }

  getLast5Notifications() {
    this.notificationApiService.last5notifications.pipe(takeUntil(this.destroy$))
      .subscribe((notifications) => {
        this.notifications = notifications;
      });
  }

  getCountUnreadNotifications() {
    this.notificationApiService.unreadNotifications.pipe(takeUntil(this.destroy$))
      .subscribe((notifications) => {
        if (notifications) {
          this.unreadNotifications = notifications.length;
        }
      });
  }

  clearUnreadNotifications() {
    this.unreadNotifications = 0;
  }

  private getTotalNotifications() {
    this.notificationApiService.last5notifications.pipe(takeUntil(this.destroy$))
      .subscribe((notifications) => {
        if (notifications) {
          this.totalNotifications = notifications.length;
        }
      });
  }

  private setupBusinessUnitsDropdown() {
    this.businessUnitsService.getSelectedBusinessUnit().pipe(takeUntil(this.destroy$))
      .subscribe(([businessUnit, businessUnits]) => {
        this.businessUnits = businessUnits;

        if (businessUnits?.length > 0) {
          this.businessUnitsControl.setValue(businessUnit?.uuid || this.ALL_BUSINESS_UNITS);
        }
      });

    this.businessUnitsControl.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((businessUnitUuid) => {
        const targetBusinessUnitUuid = businessUnitUuid === this.ALL_BUSINESS_UNITS ? null : businessUnitUuid;
        const storedBusinessUnitId = this.businessUnitsService.getStoredBusinessUnitId();
        const isChangingUnits = storedBusinessUnitId !== targetBusinessUnitUuid;

        if (isChangingUnits && !(targetBusinessUnitUuid === null && storedBusinessUnitId === null)) {
          const dialog = ConfirmDialogComponent.open(this.matDialog, DialogType.CHANGE_BUSINESS_UNIT, true);

          this.toggleHamburgerMenu(false);

          dialog.afterClosed().subscribe((result) => {
            if (result) {
              this.businessUnitsService.switchBusinessUnit(targetBusinessUnitUuid);
              window.location.href = `/${this.locale}`;
            } else {
              // Return back to the selected business unit
              this.businessUnitsControl.setValue(storedBusinessUnitId || this.ALL_BUSINESS_UNITS);
            }
          });
        }
      });

  }
}
