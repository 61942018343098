import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NotificationService} from '../../../services/notification.service';
import {LeadGenerationService} from '../../../services/lead-generation.service';
import {UserApiService} from "../../../services/api/user-api.service";
import {zip} from "rxjs";
import {FormHelperService} from "../../../util/form-helper.service";

@Component({
  selector: 'app-switch-to-full-mode-successfull-dialog',
  templateUrl: './switch-to-full-mode-successfull-dialog.component.html',
  styleUrls: ['./switch-to-full-mode-successfull-dialog.component.scss']
})
export class SwitchToFullModeSuccessfullDialogComponent implements OnInit {

  public isLoading = true;

  public administrationForm;
  public accountForm;

  constructor(public dialogRef: MatDialogRef<any>, private matDialog: MatDialog,
              private userApi: UserApiService,
              private notificationService: NotificationService,
              private formHelper: FormHelperService) {
  }

  static open(dialog: MatDialog) {
    return dialog.open(SwitchToFullModeSuccessfullDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {}
    });
  }

  ngOnInit() {
    zip(this.userApi.getAdministrationForm(), this.userApi.getPublicAccountForm())
      .subscribe((result) => {
        this.administrationForm = this.formHelper.toTabbedForm(result[0].data);
        this.accountForm = this.formHelper.toTabbedForm(result[1].data);
        this.isLoading = false;
      }, (error => this.notificationService.showErrorAlert()));
  }

  close() {
    this.dialogRef.close(false);
  }
}
