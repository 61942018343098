<div class="card bg-white border-none">
  <div class="card-header p-3 sm:p-5 pb-0">
    <div class="flex items-center justify-between">
      <p i18n="Share ad modal" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Deli oglas</p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <div class="card-content">

    <p i18n="Share ad modal">Za deljenje tega oglasa uporabite spodnjo povezavo.</p>

    <div class="my-4">
      <label class="relative"><span i18n="Share ad modal">Spletna povezava</span>
        <input readonly [value]="getPublicAdUrl()" (click)="selectText($event)">

        <span class="absolute right-0 mr-1 z-20 mt-3"><button (click)="copyToClipboard()"
                                                              class="btn btn-sm border-none shadow-none text-primary hover:text-greenDarkOlive focus:text-greenDarkOlive bg-white"><i
          class="far fa-copy text-lg"></i></button></span>
      </label>
    </div>

    <div class="mb-3">
      <app-share-vehicle-widget [url]="getPublicAdUrl()"></app-share-vehicle-widget>
    </div>

    <div class="mt-12 flex flex-row justify-end">
      <button class="btn btn-grayDark-link mr-1" (click)="close()" i18n>Zapri</button>
    </div>
  </div>
</div>

