import {Component, Input} from '@angular/core';
import {ShimmerComponent} from '../shimmer/shimmer.component';

@Component({
  selector: 'app-content-shimmer',
  templateUrl: './content-shimmer.component.html',
  styleUrls: ['./content-shimmer.component.scss', '../shimmer/shimmer.component.scss']
})
export class ContentShimmerComponent extends ShimmerComponent {

  @Input() height: string;

  constructor() {
    super();
  }
}
