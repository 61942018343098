<div class="w-full h-screen flex justify-center items-center" style="background: #F5F5F5">

  <div class="flex flex-col md:flex-row justify-center items-center w-full" style="max-width: 900px">
    <div class="grow p-4" style="flex-basis: 100px">
      <img src="assets/images/403.png" alt="404 error">
    </div>
    <div class="grow p-4" style="flex-basis: 100px">

      <h1 class="text-2xl font-bold"><span i18n>Račun je deaktiviran</span></h1>

      <p>
        <span i18n>Za več informacij ali ponovno reaktivacijo nas kontaktirajte:</span>
      </p>
      <p>
        <span><i class="fa fa-envelope text-gray mr-2"></i><a
                href="mailto:info@briefd.io">info@briefd.io</a></span>
      </p>
      <p>
        <span><i class="fa fa-phone text-gray mr-2"></i><a href="tel:386018280181">+386 (0)1 828 0181</a></span>
      </p>

    </div>

  </div>
</div>
