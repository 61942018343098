import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '../../../../interfaces';
import {Upload} from '../../../../interfaces/entities/upload';
import {Subscription} from 'rxjs';
import {UploadApiService} from '../../../../services/api/upload-api.service';
import {NotificationService} from '../../../../services/notification.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ConfirmDialogComponent, DialogType} from '../../../dialogs';
import {SelectFileUploadFromDialogComponent} from "../../../dialogs/select-file-upload-from-dialog/select-file-upload-from-dialog.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-form-input-files-nlb-custom',
  templateUrl: './form-input-files-nlb-custom.component.html',
  styleUrls: ['./form-input-files-nlb-custom.component.scss']
})
export class FormInputFilesNlbCustomComponent implements OnInit, OnDestroy, OnChanges {

  @Input() control: FormGroup;

  @Input() field: Field<Upload[]>;

  public files: TempFile[] = [];

  isDisabled = false;

  private statusSubscription: Subscription;

  constructor(private uploadApiService: UploadApiService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private matDialog: MatDialog,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.statusSubscription = this.control.statusChanges.subscribe((status) => this.isDisabled = status === 'DISABLED');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue) {
      if (changes.control.currentValue.value) {
        this.files = changes.control.currentValue.value.map((currentUpload) => {
          return {upload: currentUpload};
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  keys(item: {}) {
    return Object.keys(item);
  }

  upload(fileToBeUploaded: File, temporaryFile: { upload?: Upload }) {
    this.uploadApiService.uploadFileNlb(fileToBeUploaded)
      .subscribe((result) => {
          temporaryFile.upload = result.data;
          this.updateControlValue();
          this.ref.markForCheck();
        },
        (error) => {
          this.notificationService.showErrorAlert();
          this.files.splice(this.files.indexOf(temporaryFile), 1);
        });
  }

  onSelectCustom($event: Event) {
    const element = $event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      Object.values(fileList).forEach((addedFile) => {
        const tempFile = {upload: null};
        this.files = [...this.files, tempFile];

        this.upload(addedFile, tempFile);
      });
    }
  }

  drop($event: CdkDragDrop<any>) {
    if (!this.isDisabled) {
      moveItemInArray(this.files, $event.previousIndex, $event.currentIndex);
      this.updateControlValue();
      this.ref.markForCheck();
    }
  }

  removeFile(item: any) {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.DELETE_PHOTO, true);

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.files.splice(this.files.indexOf(item), 1);
          this.updateControlValue();
          this.ref.markForCheck();
        }
      }
    );
  }

  private updateControlValue() {
    this.control.setValue(this.files.filter((item) => item.upload).map((item) => item.upload));
  }

  openExistingDialog() {
    const dialogRef = SelectFileUploadFromDialogComponent.open(this.matDialog, this.route);

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        for(let file in result){
          const tempFile = {upload: result[file][Object.keys(result[file])[0]]};
          this.files = [...this.files, tempFile];
        }
        this.updateControlValue();
        this.ref.markForCheck();
      }
    });
  }
}

interface TempFile {
  upload?: Upload;
}
