<ng-container *ngIf="control">
  <ng-container *ngFor="let section of field.config.sections; let i = index">
    <label class="mb-2 block" *ngIf="section.label">{{ section.label }}</label>

    <div class="card" [ngClass]="{'mb-3': i < field.config.sections.length - 1}"   [attr.data-field]="field?.fieldUuid"
  [attr.data-typeId]="field?.config?.typeId">
      <div class="card-content">
        <div class="flex flex-row flex-wrap">
          <div class="w-1/2" *ngFor="let item of section.items; let i = index">
            <label class="inline-flex items-start" [ngClass]="{'mb-3': i < section.items.length - 2 }">
              <input type="checkbox" class="form-checkbox mt-0 cursor-pointer"
                     [attr.tabIndex]="0"
                     [formControl]="asFormGroup(control.controls[section.section_uuid]).controls[keys(item)[0]]">
              <span class="ml-2 mr-2">{{ item[keys(item)[0]]}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
