<div class="card bg-white relative border-none">
  <div class="card-header p-5 pb-0">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg md:text-xl font-bold mr-3">
        <span i18n>Je bilo vozilo prodano?</span>
      </p>
      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut">
        <i class="fas fa-times text-lg"></i></button>
    </div>
  </div>

  <div class="card-content">
    <p class="text-sm text-grayDark">
      <span i18n>Za to vozilo je bil izdan naslednji račun. Zaradi tega je označeno kot prodan. Da
      se vozilo ne prikazuje več na zalogi ga je treba odstaniti iz zaloge.</span>
    </p>

    <div *ngIf="isLoadingInvoices" class="mt-3">
      <div class="mb-3">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer></app-content-shimmer>
      </div>
    </div>

    <div *ngIf="!isLoadingInvoices && vehicleInvoices.length">
      <div class="card p-3 -mb-5 mt-3">
          <p i18n class="text-sm mb-1 text-black">Za vozilo so bili izdani naslednji računi:</p>
          <ul>
            <li *ngFor="let invoice of vehicleInvoices">
              <a [routerLink]="['/admin/administration/document', invoice.documentId]" class="font-semibold text-sm"
                 target="_blank">{{ invoice.number }}</a>
            </li>
          </ul>
      </div>
    </div>
  </div>

  <div class="card-content">
    <div class="flex flex-row justify-end">
      <button class="btn btn-primary" (click)="archiveVehicle()">Prestavi vozilo med prodana</button>
    </div>
  </div>
</div>
