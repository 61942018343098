<div class="card bg-white border-none max-h-75vh sm:max-h-initial">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p i18n="" class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold">Preklop na polni dostop</p>

      <button (click)="close(false)" class="btn border-transparent shadow-none"><i
        class="fas fa-times text-lg text-gray"></i></button>
    </div>
  </div>
  <hr class="mt-0 mb-0 block lg:hidden">
  <div class="card-content overflow-y-auto max-h-75vh sm:max-h-full">

    <div class="text-center">
      <img src="assets/images/register/img_full_access_car.png" alt="Car icon" class="mx-auto my-6">
    </div>
    <div class="text-center">
      <p class="text-greenDarkOlive font-bold mb-2" i18n>Želiš preklopiti na račun s polnim dostopom? Ni problema!</p>

      <p class="leading-normal" i18n>S klikom na spodnji gumb bomo omogočili polno kreiranje in izdajanje vseh faktur, poleg
        tega, boste pa lahko ustvarili več uporabniških računov za vse vaše zaposlene.</p>
    </div>

    <div class="text-center mt-8">
      <button class="btn btn-primary" (click)="requestSwitchToFullMode()" *ngIf="!isLoading"><span i18n>Preklopi v polni dostop</span></button>


      <div class="flex justify-center items-center" *ngIf="isLoading">
        <div class="mx-auto">
          <app-spinner></app-spinner>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-end mt-4">
      <button class="btn btn-grayDark-link mr-1" (click)="close(false)" i18n>Zapri</button>
    </div>

  </div>


</div>

