import {Component, Input, OnInit} from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';
import {AbstractControl, FormControl} from '@angular/forms';
import {AddressPost} from '@app/interfaces/form/address-post';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {Field} from '@app/interfaces';
import {firstKey} from '@app/util/object.helper';

@Component({
  selector: 'app-form-input-post',
  templateUrl: './form-input-post.component.html',
  styleUrls: ['./form-input-post.component.scss']
})
export class FormInputPostComponent implements OnInit {

  @Input() postNumberControl: FormControl = new FormControl();
  @Input() controls: Array<AbstractControl>;
  @Input() field: Field<string>;

  postNameControl: FormControl = new FormControl();
  countryNameControl: FormControl = new FormControl();
  filteredPostNames$: Observable<string[]>;
  filteredPostNumbers$: Observable<AddressPost[]>;

  private addressPosts: AddressPost[];
  private addressPostNames: string[];


  private destroy$ = new Subject<void>();

  private updateFilteredPostNames = new Subject<string>();
  private updateFilteredPostNumbers = new Subject<string>();

  constructor(
    private configApiService: ConfigAPIService
  ) {
    this.getAddressPosts();
  }


  private _filterPostNames(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.addressPostNames.filter(post => post.toLowerCase().includes(filterValue)).slice(0, 10);
  }

  private _filterPostNumbers(code: string): AddressPost[] {
    const filterValue = code.toLowerCase();

    return this.addressPosts.filter(post => post.code.toLowerCase().includes(filterValue)).slice(0, 10);
  }

  private getAddressPosts(countryCode?: string) {
    this.configApiService.getAddressPosts(countryCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((addressPostsResponse) => {
        this.addressPosts = addressPostsResponse.data.addressPosts;
        this.addressPostNames = Object.values(addressPostsResponse.data.addressPostNames);

        this.filteredPostNames$ = merge(
          this.postNameControl.valueChanges,
          this.updateFilteredPostNames
        ).pipe(
          takeUntil(this.destroy$),
          startWith(''),
          map(name => name ? this._filterPostNames(name) : this.addressPostNames.slice(0, 10))
        );

        this.filteredPostNumbers$ = merge(
          this.postNumberControl.valueChanges,
          this.updateFilteredPostNumbers
        ).pipe(
          takeUntil(this.destroy$),
          startWith(''),
          map(value => typeof value === 'string' ? value : value.number),
          map(code => code ? this._filterPostNumbers(code) : this.addressPosts.slice(0, 10))
        );
      });
  }

  ngOnInit() {
    if (this.field.connectedFieldTypeUuids) {
      const countryFieldUuid = this.field.connectedFieldTypeUuids['country'];
      if (countryFieldUuid) {
        this.countryNameControl = this.controls[countryFieldUuid];

        this.countryNameControl.valueChanges.pipe(takeUntil(this.destroy$))
          .subscribe(countryValue => {
            const countryCode = firstKey(countryValue);
            this.getAddressPosts(countryCode);
          });
      }
    }

    const cityFieldUuid = this.field.connectedField.fieldUuid ?? this.field.connectedField.uuid;
    this.postNameControl = this.controls[cityFieldUuid];

    this.postNameControl['hidden'] = true;

    this.postNameControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      let selectedPost = this.addressPosts.find(post => post.name === value && post.code === this.postNumberControl.value);
      selectedPost = selectedPost ?? this.addressPosts.find(post => post.name === value);

      if (selectedPost) {
        this.postNumberControl.setValue(selectedPost.code, {emitEvent: false});
        this.countryNameControl?.setValue({[selectedPost.country_code]: selectedPost.country}, {emitEvent: true});
        this.updateFilteredPostNumbers.next(selectedPost.code);
      }
    });

    this.postNumberControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      const selectedPost = this.addressPosts.find(post => post.code === value);
      if (selectedPost) {
        this.postNameControl.setValue(selectedPost.name, {emitEvent: false});
        this.countryNameControl?.setValue({[selectedPost.country_code]: selectedPost.country}, {emitEvent: true});
        this.updateFilteredPostNames.next(selectedPost.name);
      }
    });
  }

}
