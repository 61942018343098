import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-cancel-subscription-feedback',
  templateUrl: './cancel-subscription-feedback.component.html',
  styleUrls: ['./cancel-subscription-feedback.component.scss']
})
export class CancelSubscriptionFeedbackComponent {
  @Input() isUnsubscribe = false;
  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public form = new FormGroup({
    feedback: new FormControl('', [Validators.required]),
    confirm: new FormControl('', [Validators.required, Validators.pattern(/cancel/i)]),
    otherOption: new FormControl('', [])
  });

  save() {
    this.onSave.emit(this.form);
  }

  close() {
    this.onClose.emit();
  }
}
