import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaginatedResult, SingleResult, Vehicle} from '@app/interfaces';
import {environment} from '@src/environments/environment';
import {EntityGroup} from '@app/interfaces/entities/entity-group';

@Injectable({
  providedIn: 'root'
})
export class EntityGroupApiService {

  constructor(private http: HttpClient) {
  }

  getEntityGroups(entityType: string, onlyActive = false): Observable<PaginatedResult<EntityGroup[]>> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);

    if (onlyActive) {
      params = params.append('onlyActive', '1');
    }

    return this.http.get<PaginatedResult<EntityGroup[]>>(`${environment.apiUrl}/api/entity-groups`, {params});
  }

  storeEntityGroup(name: string, entityType: string): Observable<SingleResult<EntityGroup>> {
    return this.http.post<SingleResult<EntityGroup>>(`${environment.apiUrl}/api/entity-groups`, {name, entityType});
  }

  deleteEntityGroup(entityGroupUuid: string) {
    return this.http.delete<SingleResult<EntityGroup>>(`${environment.apiUrl}/api/entity-groups/${entityGroupUuid}`);
  }

  updateEntityGroup(entityGroupUuid: string, body: { name: any }) {
    return this.http.put<SingleResult<EntityGroup>>(`${environment.apiUrl}/api/entity-groups/${entityGroupUuid}`, {name: body.name});
  }
}
