import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as dayjs from 'dayjs';
import {AlarmApiService} from '@app/services/api/alarm-api.service';
import {Alarm} from '@app/interfaces';

@Component({
  selector: 'app-form-alarm-dialog',
  templateUrl: './form-alarm-dialog.component.html',
  styleUrls: ['./form-alarm-dialog.component.scss']
})
export class FormAlarmDialogComponent implements OnInit {
  public isLoading = false;
  public isSubmitting = false;

  private entityUuid: string;
  private entityType: string;
  private alarm: null | {
    severity: string,
    alarmUuid: string,
    name: string;
    triggers: { date: string }[]
  };

  public documents;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private alarmApi: AlarmApiService,
              public dialogRef: MatDialogRef<unknown>) {

    this.entityUuid = data.entityUuid;
    this.entityType = data.entityType;
    this.alarm = data.alarm;
  }

  static open(dialog: MatDialog, entityUuid: string, entityType: string, alarm?: Alarm) {
    return dialog.open(FormAlarmDialogComponent, {
      width: '500px',
            disableClose: true,
      data: {
        entityUuid,
        entityType,
        alarm,
      }
    });
  }

  ngOnInit() {
    if (this.alarm) {
      this.form.controls.name.setValue(this.alarm.name);
      this.form.controls.date.setValue(this.alarm.triggers[0].date);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.form.valid) {
      this.store();
    } else {
      this.form.markAllAsTouched();
    }
  }

  setAlarmFor(amount: number, type: string) {
    let date = dayjs().startOf('day');

    // @ts-ignore
    date = date.add(amount, type);

    this.form.controls.date.setValue(date.toString());
  }

  private store() {
    this.form.disable();
    this.isSubmitting = true;

    if (!this.alarm) {
      this.alarmApi.store({
        entityUuid: this.entityUuid,
        entityType: this.entityType,
        severity: 'medium',
        name: this.form.controls.name.value,
        triggers: [{date: this.form.controls.date.value}]
      }).subscribe((result) => {
        // @ts-ignore
        this.dialogRef.close(result.data);
      }, (error) => {
        this.form.disable();
        this.isSubmitting = false;
        console.error(error);
      });
    } else {
      this.alarmApi.update(this.alarm.alarmUuid, {
        entityUuid: this.entityUuid,
        entityType: this.entityType,
        severity: this.alarm.severity,
        name: this.form.controls.name.value,
        triggers: [{date: this.form.controls.date.value}]
      }).subscribe((result) => {
        // @ts-ignore
        this.dialogRef.close(result.data);
      }, (error) => {
        this.form.disable();
        this.isSubmitting = false;
        console.error(error);
      });
    }
  }


}
