import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {VehicleApiService} from '@app/services/api/vehicle-api.service';
import {ClientApiService} from '@app/services/api/client-api.service';
import {FormHelperService} from '@app/util/form-helper.service';
import {NotificationService} from '@app/services/notification.service';
import {LayoutItem} from '@app/interfaces/form/layout-item';
import {SectionItem} from '@app/interfaces/form/section-item';
import {ChangeClientEntityGroupDialogComponent} from '@app/dialogs/change-client-entity-group-dialog/change-client-entity-group-dialog.component';
import {catchError, debounceTime, filter, startWith, takeUntil} from "rxjs/operators";
import {of, pairwise, Subject} from "rxjs";
import {FieldHelperService} from "@app/services/util/field-helper.service";

@Component({
  selector: 'app-quick-create-entity-dialog',
  templateUrl: './quick-create-entity-dialog.component.html',
  styleUrls: ['./quick-create-entity-dialog.component.scss']
})
export class QuickCreateEntityDialogComponent implements OnInit {

  public options: { entityType: string, clientType?: string, entityUuid?: string, clientGroup?: string; };
  public isPageLoading = false;
  public isSubmitting = false;
  public formGroup: FormGroup;
  public form;
  public vehicleIsInStock = false;
  public vehicleIsArchived = false;
  public vehicleUuid: string;
  private destroy$ = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private vehicleApi: VehicleApiService,
              private clientApi: ClientApiService,
              private dialog: MatDialog,
              private formHelper: FormHelperService,
              private notificationService: NotificationService,
              private fieldHelperService: FieldHelperService,
              public dialogRef: MatDialogRef<unknown>) {
    this.options = data.options;
  }

  static open(dialog: MatDialog, options: { entityType: string, clientType?: string, entityUuid?: string, clientGroup?: string }) {
    return dialog.open(QuickCreateEntityDialogComponent, {
      width: '600px',
            disableClose: true,
      data: {
        options,
      }
    });
  }

  ngOnInit() {
    this.loadForm(this.options);
  }

  loadForm(options: any, isDuplicatedData: boolean = false) {
    this.isPageLoading = true;

    if (this.options.entityType === 'vehicle') {
      if (this.options.entityUuid) {
        this.vehicleUuid = this.options.entityUuid;
      }
      this.vehicleApi.getQuickForm(this.vehicleUuid)
        .subscribe((result) => {
          this.form = result.data;
          this.formGroup = this.formHelper.toTabbedForm(result.data);
          this.isPageLoading = false;

          this.setupVinNumberChangeHandler(this.formGroup.controls[0]);

          // Clear kilometers
          if (isDuplicatedData) {
            const mileageField = this.fieldHelperService.getFieldByTypeId('briefd.vehicle.current_mileage', result.data.fields);
            this.formHelper.findControl(mileageField.fieldUuid, this.formGroup.controls[0]).setValue(null);
          }
        }, (error) => this.notificationService.showErrorAlert());
    } else if (this.options.entityType === 'client') {
      this.clientApi.getForm(this.options.entityUuid ?? null, this.options.clientType)
        .subscribe((result) => {
          this.form = result.data;
          this.formGroup = this.formHelper.toTabbedForm(result.data);
          this.isPageLoading = false;
        }, (error) => this.notificationService.showErrorAlert());
    }
  }

  private setupVinNumberChangeHandler(formGroupControls: AbstractControl) {
    const vinNumberFieldControl = this.formHelper.findControl('vin_number', formGroupControls);
    vinNumberFieldControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      pairwise(),
      debounceTime(500),
      filter(([prev, next], index) => {
        return next && next != prev && vinNumberFieldControl.valid;
      }),
    ).subscribe(([, enteredVin]) => {
      this.vehicleApi.getVehicleFromVin(enteredVin).pipe(catchError(val => of(null)))
        .pipe(takeUntil(this.destroy$))
        .subscribe((vinVehicle) => {
          if (vinVehicle) {
            this.vehicleIsArchived = !!vinVehicle.data.archivedAt;
            this.vehicleIsInStock = !this.vehicleIsArchived;

            this.vehicleUuid = vinVehicle.data.vehicleUuid;

            this.loadForm('vehicle', true);
          } else {
            this.vehicleIsArchived = false;
            this.vehicleIsInStock = false;
          }
        });
    });
  }

  selectVehicle() {
    this.vehicleApi.getVehicle(this.vehicleUuid).subscribe((vehicle) => {
      this.dialogRef.close(vehicle.data);
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  submit() {
    const values = this.formHelper.getValuesFromTabbedForm(this.formGroup);

    this.formGroup.disable();
    this.isSubmitting = true;
    if (this.options.entityType === 'vehicle') {
      this.vehicleApi.store(values, 'quick').subscribe(result => this.dialogRef.close(result.data),
        (error) => this.onError(error));
    } else if (this.options.entityType === 'client') {
      if (this.options.entityUuid) {
        this.clientApi.update(this.options.entityUuid, values).subscribe(result =>
            this.dialogRef.close(result.data),
          (error) => this.onError(error));
      } else {
        this.clientApi.store(values, this.options.clientType, this.options.clientGroup).subscribe((result) => {
            this.openClientEntityGroupSelectionDialog(result.data.clientUuid, []);
            this.dialogRef.close(result.data);
          },
          (error) => this.onError(error));
      }
    }
  }

  private openClientEntityGroupSelectionDialog(clientUuid, entityGroups) {
    const dialogRef = ChangeClientEntityGroupDialogComponent.open(this.dialog, entityGroups, 'client');

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.clientApi.updateEntityGroups(clientUuid, dialogResult.entityGroups.map((item) => item.entityGroupUuid))
          .subscribe(() => {}, () => {
            this.notificationService.showErrorAlert();
          });
      }
    });
  }

  asSection(layout: LayoutItem) {
    return layout as SectionItem;
  }

  private onError(error: any) {
    this.notificationService.showErrorAlert();
    this.formGroup.enable();
    this.isSubmitting = false;
  }
}
